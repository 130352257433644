import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import TradeHistoryTable from "./TradeHistoryTable";
import TradeHistoryFilter from "./TradeHistoryFilter";
import { setIsTradingHistoryOpen } from "@/redux/actions/ui";
import { useTradeHistory, updatePDFLink } from "@/hooks/trade/useTradeHistory";
import { Loader } from "./Loader";

import styles from "./TradeHistory.module.scss";

export const TradeHistory = (): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { palette } = useTheme();
    const { historyData, handleSetDateFilters, isLoading, dateFrom, dateTo } = useTradeHistory();

    const handleClose = () => {
        dispatch(setIsTradingHistoryOpen(false));
    };

    const handleDownloadPDF = async () => {
        await updatePDFLink(dateFrom, dateTo);
    };

    return (
        <div className={clsx(styles.container, styles[`container_bg_${palette.mode}`])}>
            <div className={styles.header}>
                <div className={styles.title}>{t("tradeHistory")}</div>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    className={styles.closeIcon}>
                    <CloseIcon />
                </IconButton>
            </div>

            <TradeHistoryFilter setFilter={handleSetDateFilters} isLoading={isLoading} />

            {isLoading && (
                <div className={styles.loader_wrp}>
                    <Loader />
                </div>
            )}

            {!isLoading && <TradeHistoryTable historyData={historyData} />}

            <div
                className={styles.downloadPDF}
                onClick={handleDownloadPDF}
            >
                <div className={styles.label}>{t("downloadPdf")}</div>
                <div className={styles.icon}></div>
            </div>
        </div>
    );
};
