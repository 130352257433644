import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { QuoteData } from "hooks/trade/tradeSocket";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { setOperationTimestamp } from "@/utils/helpers";
import { useTouchState } from "@/hooks/common/useTouchState";

import styles from "./TradeButton.module.scss";

export type TradeButtonOperationType = "Buy" | "Sell";

interface ITradeButtonProps {
    operation: TradeButtonOperationType;
    symbolId: string;
    active: boolean;
    showFlashButton?: boolean;
    disabled?: boolean;
    classes?: {
        btn?: string;
        label?: string;
        value?: string;
        buy?: string;
        sell?: string;
    };
    onClick: () => void;
}

const TradeButton = (props: ITradeButtonProps): JSX.Element => {
    const {
        symbolId,
        active = false,
        operation = TRADE_OPERATION_TYPE.BUY,
        classes,
        onClick = () => {},
        disabled = false,
        showFlashButton = false,
    } = props;
    const router = useRouter();
    const { platform: devicePlatform } = useDevice();
    const isDesktop = devicePlatform === "Desktop";
    const { symbolsInfoById } = useTradeInfo();
    const { t } = useTranslation("common");
    const symbolInfo = symbolsInfoById[symbolId];
    const { isTouched, onTouchEnd, onTouchStart } = useTouchState();

    return (
        <div
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className={clsx(styles.btn, classes?.btn, {
                [styles.buy]: operation === TRADE_OPERATION_TYPE.BUY,
                [classes?.buy]: operation === TRADE_OPERATION_TYPE.BUY,
                [styles.sell]: operation === TRADE_OPERATION_TYPE.SELL,
                [classes?.sell]: operation === TRADE_OPERATION_TYPE.SELL,
                [styles.active]: active,
                [styles.desktop]: isDesktop,
                [styles.mobile]: !isDesktop,
                [styles.disabled]: disabled,
                [styles.flashButton]: showFlashButton,
                [styles.touched]: isTouched,
            })}
            onClick={() => {
                if (!disabled) {
                    onClick();
                    setOperationTimestamp();
                }
            }}>
            <span className={clsx(styles.label, classes?.label)}>
                {router?.locale !== "ge"
                    ? t(operation.toLowerCase()).toLocaleUpperCase(router?.locale)
                    : t(operation.toLowerCase())}
            </span>
            <span className={clsx(styles.value, classes?.value)}>
                <QuoteData
                    symbolId={symbolId}
                    render={({ ask, bid }) => {
                        const price = operation === "Buy" ? ask : bid;
                        const pricePrecision = String(Math.round(1 / symbolInfo?.point)).length - 1;
                        const priceStringFull = price?.toFixed(pricePrecision);
                        const priceMain = priceStringFull?.substr(0, priceStringFull?.length - 1);
                        const pip = priceStringFull?.substr(-1, 1);

                        return (
                            <span>
                                {priceMain}
                                <sub>{pip}</sub>
                            </span>
                        );
                    }}
                />
            </span>
        </div>
    );
};

export default React.memo(TradeButton);
