import React, { useState } from "react";
import clsx from "clsx";

import styles from "./ArrowIcon.module.scss";


interface IProps {
    onClick?: (value: boolean) => void;
    isOpen?: boolean;
}

const ArrowIcon: React.FC<IProps> = ({ onClick, isOpen }) => {
    const [isIconOpen, setIsIconOpen] = useState(false);

    const isParentState = isOpen !== undefined;

    return (
        <div
            onClick={() => {
                if (!isParentState) {
                    setIsIconOpen(!isOpen);
                }
                onClick && onClick(!isOpen);
            }}
            className={clsx(styles.arrow_icon, {
                [styles.up]: isParentState ? isOpen : isIconOpen,
                [styles.down]: isParentState ? !isOpen : isIconOpen,
            })}
        >
        </div>
    );
};

export default React.memo(ArrowIcon);
