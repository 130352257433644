import React from "react";
import { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import chunk from "lodash/chunk";
import { generateYears, monthNames } from "../helpers";

import styles from "./MonthYearPicker.module.scss";


interface IYearPicker {
    onChange: (value: number) => void;
    minDate?: Date;
    maxDate?: Date;
    date: Date;
}

export const YearPicker: React.FC<IYearPicker> = ({ onChange, minDate, maxDate, date }) => {

    const years = generateYears({
        startYear: (minDate || maxDate).getFullYear(),
        direction: minDate ? "up" : "down",
    });

    return (
        <div className={styles.year_picker_container}>
            {years.map((rowYears, yearIndex) => {
                return (
                    <div className={styles.row} key={yearIndex}>
                        {rowYears.map((year, index) => (
                            <div
                                key={index}
                                className={clsx(styles.rowItem, {
                                    [styles.active]: date.getFullYear() === year,
                                })}
                                onClick={() => onChange(year)}
                            >
                                {year}
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

interface IMonthPicker {
    onChange: (value: number) => void;
    t: (value: string) => string;
    maxDate?: Date;
    minDate?: Date;
    date: Date;
}

export const MonthPicker: React.FC<IMonthPicker> = ({ onChange, t, maxDate, minDate, date }) => {

    const isMonthDisabled = (year: number, month: string): boolean => {
        if (maxDate) {
            return (
                date.getFullYear() === maxDate.getFullYear() &&
                monthNames.indexOf(month) > maxDate.getMonth()
            );
        }
        if (minDate) {
            return (
                date.getFullYear() === minDate.getFullYear() &&
                monthNames.indexOf(month) < minDate.getMonth()
            );
        }
    };

    const handleChange = (month: string) => {
        if (isMonthDisabled(date.getFullYear(), month)) {
            return;
        }
        return () => onChange(monthNames.indexOf(month));
    };


    return (
        <div className={styles.month_picker_container}>
            {chunk(monthNames, 4).map((rowMonths, monthsIndex) => {
                return (
                    <div className={styles.row} key={monthsIndex}>
                        {rowMonths.map((month, index) => (
                            <div
                                key={index}
                                className={clsx(styles.rowItem, {
                                    [styles.disabled]: isMonthDisabled(date.getFullYear(), month),
                                    [styles.active]: date.getMonth() === monthNames.indexOf(month),
                                })}
                                onClick={handleChange(month)}
                            >
                                {t(`months.${month.toLowerCase()}`).slice(0, 3)}
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

interface IMobileMonthYearPicker {
    onClose: () => void;
    setYear: (year: number) => void;
    setMonth: (month: number) => void;
    minDate?: Date;
    maxDate?: Date;
    date: Date;
}

type PickerType = "year" | "month";


const MobileMonthYearPicker: React.FC<IMobileMonthYearPicker> = ({
    onClose,
    setYear,
    setMonth,
    minDate,
    maxDate,
    date,
}) => {
    const { t } = useTranslation("common");
    const [typeView, setTypeView] = useState<PickerType>("year");

    return (
        <>
            {typeView === "year" && (
                <YearPicker
                    onChange={(year) => {
                        setYear(year);
                        setTypeView("month");
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    date={date}
                />
            )}
            {typeView === "month" && (
                <MonthPicker
                    onChange={(month) => {
                        setMonth(month);
                        onClose();
                    }}
                    maxDate={maxDate}
                    minDate={minDate}
                    date={date}
                    t={t}
                />
            )}
        </>
    );
};

export default React.memo(MobileMonthYearPicker);
