import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import styles from "./Notice.module.scss";


const NoSearchNotice = (): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();

    return (
        <div className={clsx(styles.notice, styles.searchNotice)}>
            <div className={styles.header}>{t("search_results")}:</div>

            <div className={styles.notice__iconContainer}>
                <div
                    className={clsx(styles.iconNoResultFound, {
                        [styles.iconNoResultFound_light]: palette.mode === "light",
                    })}
                />
            </div>
            <div className={styles.title}>{t("no_results")}</div>
            <div className={styles.subTitle}>{t("no_results_text")}</div>
        </div>
    );
};

export default React.memo(NoSearchNotice);
