import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";

const langsToReduceFontSize = [
    "de",
    "ar",
    "cs",
    "el",
    "fr",
    "ge",
    "hu",
    "it",
    "ja",
    "kr",
    "ms",
    "nl",
    "no",
    "pl",
    "ro",
    "ru",
    "sv",
    "th",
    "tr",
    "es",
];

export const useFontSizeChange = () => {
    const { i18n } = useTranslation("common");
    const htmlContainer = document?.getElementsByTagName("html")[0]?.style;

    useEffect(() => {
        const needToReduceFontSize = () => langsToReduceFontSize.includes(i18n.resolvedLanguage);
        const fontSize = needToReduceFontSize() ? "9px" : "10px";
        htmlContainer.fontSize = fontSize;
    }, [i18n.resolvedLanguage]);
};
