import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import moment from "moment";
import { timePickerSettings } from "./utils";

import styles from "./TimePickerSwiper.module.scss";


const Picker = dynamic(() => import("react-mobile-picker"), {
    ssr: false,
});

interface IProps {
    onChange: (value: string) => void;
    date: Date;
}

const TimePickerSwiper: React.FC<IProps> = ({ onChange, date }) => {
    const isVibrationSupported = 'vibrate' in navigator;

    const currentDate = moment(date);

    const [values, setValues] = useState({
        hours: currentDate.format("HH") || "00",
        minutes: currentDate.format("mm") || "00",
    });

    useEffect(() => {
        const { hours, minutes } = values;
        onChange && onChange(moment(`${hours}:${minutes}`, "HH:mm").format("HH:mm:SS"));
    }, [values]);

    const handleChange = (name, value) => {
        if (isVibrationSupported) {
            navigator.vibrate([100]); // Vibrate for 100ms
        }
        
        setValues({
            ...values,
            [name]: value,
        });
    };

    return values.hours ? (
        <div className={styles.swiper_container}>
            <div className={styles.higlighter}>
                <div className={styles.higlighter_h}></div>
                <div className={styles.higlighter_dots}>:</div>
                <div className={styles.higlighter_m}></div>
            </div>
            <Picker
                //@ts-ignore
                optionGroups={timePickerSettings}
                height={120}
                valueGroups={values}
                onChange={handleChange}
            />
        </div>
    ) : null;
};

export default React.memo(TimePickerSwiper);
