import React, { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import PendingPriceWithModalBlock from "@/components/Core/TakeProfitStopLoss/PendingPriceWithModalBlock";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { TradeChartGlobalAPIContext } from "@/contexts/ChartAPI";
import {
    setIsStopLoss,
    setStopLossPrice,
    setIsTakeProfit,
    setTakeProfitPrice,
    setActiveOperationCategory,
} from "@/contexts/TradeOrder/actions";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { AnimationClasses } from "@/hooks/common/useAnimation";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";

import styles from "./StopLossTakeProfitBlock.module.scss";

interface IProps {
    onClose: () => void;
    isOpenDuration?: boolean;
    setHighlightTradeButtons?: (isActive: boolean) => void;
}

const StopLossTakeProfitBlock: React.FC<IProps> = ({
    onClose,
    isOpenDuration = true,
    setHighlightTradeButtons,
}) => {
    const { t } = useTranslation("common");
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();

    const { addTakeProfitLine, removeTakeProfitLine, addStopLossLine, removeStopLossLine } = useContext(
        TradeChartGlobalAPIContext
    );

    const {
        isStopLoss,
        stopLossPrice,
        isTakeProfit,
        takeProfitPrice,
        activeOperationCategory,
    } = useContext(TradeOrderStateContext);

    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { bid } = useQuoteData(activeSymbolId);
    const symbolInfo = symbolsInfoById[activeSymbolId];

    useEffect(() => {
        if (isStopLoss) {
            addStopLossLine(stopLossPrice);
        } else {
            removeStopLossLine();
        }
    }, [isStopLoss, stopLossPrice, addStopLossLine, removeStopLossLine]);

    useEffect(() => {
        if (isTakeProfit) {
            addTakeProfitLine(takeProfitPrice);
        } else {
            removeTakeProfitLine();
        }
    }, [isTakeProfit, takeProfitPrice, addTakeProfitLine, removeTakeProfitLine]);

    const handleTakeProfitToggle = useCallback(
        () => tradeOrderDispatch(setIsTakeProfit(!isTakeProfit, bid)),
        [tradeOrderDispatch, isTakeProfit, bid]
    );

    const handleStopLossToggle = useCallback(() => tradeOrderDispatch(setIsStopLoss(!isStopLoss, bid)), [
        tradeOrderDispatch,
        isStopLoss,
        bid,
    ]);

    const handleStopLossPriceChange = price => tradeOrderDispatch(setStopLossPrice(price));
    const handleTakeProfitPriceChange = price => tradeOrderDispatch(setTakeProfitPrice(price));

    const handleSubmit = useCallback(() => {
        if (!isStopLoss && !isTakeProfit) {
            tradeOrderDispatch(setActiveOperationCategory(null));
        }
        onClose();
    }, [isStopLoss, isTakeProfit, onClose, tradeOrderDispatch]);

    const handleHighlightTradeButtons = () => {
        if (!activeOperationCategory) {
            setHighlightTradeButtons(true);
        }
    };

    const [initialStopLoss, setInitialStopLoss] = useState(false);
    const [initialTakeProfit, setInitialTakeProfit] = useState(false);

    useEffect(() => {
        setInitialStopLoss(isStopLoss);
        setInitialTakeProfit(isTakeProfit);
    }, []);

    const handleClose = () => {
        let actualStopLoss = isStopLoss;
        let actualTakeProfit = isTakeProfit;

        if (isStopLoss !== initialStopLoss) {
            actualStopLoss = initialStopLoss;
            tradeOrderDispatch(setIsStopLoss(initialStopLoss, bid));
        }

        if (isTakeProfit !== initialTakeProfit) {
            actualTakeProfit = initialTakeProfit;
            tradeOrderDispatch(setIsTakeProfit(initialTakeProfit, bid));
        }

        if (!actualStopLoss && !actualTakeProfit) {
            tradeOrderDispatch(setActiveOperationCategory(null));
        }

        onClose();
    };

    return (
        <div
            className={clsx(styles.container, AnimationClasses.fadeInUp)}
            style={{ animationDuration: isOpenDuration ? "0.3s" : "0s" }}>
            <div className={styles.profitLossContainer} onClick={handleHighlightTradeButtons}>
                <PendingPriceWithModalBlock
                    value={isTakeProfit ? takeProfitPrice : 0}
                    onChange={handleTakeProfitPriceChange}
                    label={t("takeProfit")}
                    step={symbolInfo?.point}
                    checked={isTakeProfit}
                    onChangeChecked={handleTakeProfitToggle}
                    disabled={!activeOperationCategory}
                />

                <PendingPriceWithModalBlock
                    value={isStopLoss ? stopLossPrice : 0}
                    onChange={handleStopLossPriceChange}
                    label={t("stopLoss")}
                    step={symbolInfo?.point}
                    checked={isStopLoss}
                    onChangeChecked={handleStopLossToggle}
                    disabled={!activeOperationCategory}
                />
            </div>

            <div className={styles.actionContainer}>
                <button
                    className={clsx(styles.btn, styles.btnCancel, {
                        [styles.touched]: isTouched && buttonName === PressedButtons.CANCEL,
                    })}
                    onClick={handleClose}
                    onTouchStart={() => onTouchStart(PressedButtons.CANCEL)}
                    onTouchEnd={onTouchEnd}>
                    {t("cancel")}
                </button>
                <button
                    onTouchStart={() => onTouchStart(PressedButtons.APPLY)}
                    onTouchEnd={onTouchEnd}
                    className={clsx(styles.btn, styles.btnApply, {
                        [styles.touched]: isTouched && buttonName === PressedButtons.APPLY,
                    })}
                    onClick={handleSubmit}
                    disabled={!activeOperationCategory}>
                    {t("apply")}
                </button>
            </div>
        </div>
    );
};

export default React.memo(StopLossTakeProfitBlock);
