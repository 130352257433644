import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSingleAssetViewActive } from "@/redux/actions/ui";
import { getLocationParam } from "@/utils/url";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";


const useRedirectToAssetFromURL = (activeSymbolId: string) => {
    const dispatch = useDispatch();
    const { symbolsInfo } = useTradeInfo();

    useEffect(() => {
        const assetNameFromUrl = getLocationParam("asset");

        if (assetNameFromUrl && activeSymbolId) {
            const symbol = symbolsInfo.find(item => (
                item.ex?.displayName?.toLowerCase() === assetNameFromUrl.toLowerCase() ||
                item.id.toLowerCase() === assetNameFromUrl.toLowerCase()
            ));

            if (symbol && symbol.id === activeSymbolId) {
                dispatch(setSingleAssetViewActive());
            }
        }
    }, [activeSymbolId]);
};

export default useRedirectToAssetFromURL;
