import clsx from "clsx";
import React from "react";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

import styles from "./Toggle.module.scss";

interface IProps {
    on: boolean;
    onChange?: () => void;
    disabled?: boolean;
    className?: string;
}

const Toggle = (props: IProps): JSX.Element => {
    const { on = false, onChange = () => { }, disabled = false, className } = props;
    const { platform } = useDevice();

    return (
        <div
            className={clsx(styles.container, {
                [className]: !!className,
                [styles.disabled]: disabled,
                [styles.on]: on,
                [styles.isMobile]: platform !== DevicePlatform.Desktop
            })}
            onClick={onChange}
        >
            <div
                className={clsx(styles.thumb, {
                    [styles.on]: on,
                })}
            />
        </div>
    );
};

export default React.memo(Toggle);
