import React from "react";
import clsx from "clsx";

import styles from "./Toggle.module.scss";


interface IProps {
    on: boolean;
    onChange?: () => void;
    disabled?: boolean;
    className?: string;
}

const Toggle: React.FC<IProps> = ({
    on = false,
    onChange = () => {},
    disabled = false,
    className
}) => {

    return (
        <div
            className={clsx(styles.container, {
                [className]: !!className,
                [styles.disabled]: disabled,
            })}
            onClick={onChange}
        >
            <div
                className={clsx(styles.thumb, {
                    [styles.on]: on,
                })}
            />
        </div>
    );
};

export default React.memo(Toggle);
