import { useState, useEffect } from "react";

interface IUseResize {
    isResized: boolean;
    height: number;
    width: number;
}

export const useResize = (): IUseResize => {
    const [isResized, setResize] = useState(false);

    const handleResize = () => {
        setResize(true);
        setTimeout(() => {
            setResize(false);
        }, 100);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return {
        isResized,
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth
    };
};
