import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { IFavoritesAlert } from "@/redux/interfaces/ISystemFeedback";
import { UIActionTypes } from "@/redux/reducers/uiReducer";
import { setFavoritesAlertMessage } from "@/redux/actions/systemFeedback";
import { AnimationClasses } from "@/hooks/common/useAnimation";
import { useUI } from "@/redux/selectors/uiSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { ISymbolInfo } from "@/services/trade/symbols";

import styles from "./FavoritesAlert.module.scss";


interface IFavoritesAlertProps {
    messageData: IFavoritesAlert,
    count: number;
    isMarketOpen: boolean;
}

const FavoritesAlert: React.FC<IFavoritesAlertProps> = ({ messageData, count, isMarketOpen }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { isAssetsTabActive, isSingleAssetViewActive, tradeOrderModal } = useUI();
    const [fadeOutAnimation, setFadeOutAnimation] = useState(false);
    const isAddedAsset = messageData.operationType === UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED;
    const { symbolsInfoById } = useTradeInfo();
    const symbol = symbolsInfoById[messageData.symbolId as string] as ISymbolInfo;

    useEffect(() => {
        if (!isAssetsTabActive && !isSingleAssetViewActive || tradeOrderModal) {
            dispatch(setFavoritesAlertMessage(null));
        }
    }, [isAssetsTabActive, isSingleAssetViewActive, tradeOrderModal]);

    useEffect(() => {
        setTimeout(() => {
            if (count === 1) {
                setFadeOutAnimation(true);
                setTimeout(() => {
                    setFadeOutAnimation(false);
                    dispatch(setFavoritesAlertMessage());
                }, 200);
            } else {
                dispatch(setFavoritesAlertMessage());
            }
        }, 2500);
    }, [messageData]);

    return messageData ? (
        <div
            className={clsx(styles.container, AnimationClasses.fadeIn, {
                [AnimationClasses.fadeOut]: count === 1 && fadeOutAnimation,
                [styles.singleAssetView]: isSingleAssetViewActive,
                [styles.singleAssetView_market_closed]: isSingleAssetViewActive && !isMarketOpen,
            })}
        >
            <div className={styles.message}>
                <div className={clsx(null, {
                    [styles.fav_added_icon]: isAddedAsset,
                    [styles.fav_removed_icon]: !isAddedAsset,
                })}></div>
                <div>
                    {messageData.operationType === UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED ?
                        `${symbol.ex.displayName || symbol.id} ${t("added_to_fav_list")}` :
                        `${symbol.ex.displayName || symbol.id} ${t("removed_from_fav_list")}`}
                </div>
            </div>
        </div>
    ) : null;
};

export default React.memo(FavoritesAlert);
