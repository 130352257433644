import React from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import clsx from "clsx";

import { useActiveSymbolDynamicFields } from "@/hooks/symbols";
import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { formatAmount, normalyzeDecimalLength } from "@/utils/format";

import styles from "./MarginTabletInfo.module.scss";

interface IProps {
    lotSize: number | string;
}

const MarginTabletInfo: React.FC<IProps> = ({ lotSize }) => {
    const { t } = useTranslation("common");
    const { locale } = useRouter();
    const { currency: accountCurrency } = useAccountInfo();
    const { freeMargin = 0 } = useAccountDynamicInfo();

    const {
        requiredBuyMarginDisplayValue,
        isForex,
        pipDisplayValue,
        pointDisplayValue
    } = useActiveSymbolDynamicFields({ lotSize });

    return (
        <div
            className={clsx(styles.marginInfoContainer, {
                [styles.long_text]: locale === "el",
            })}>
            <div className={styles.block}>
                <div className={styles.label}>
                    {t(isForex ? "pips_value" : "pointValue.label")}:
                </div>
                <div className={styles.value}>
                    {isForex ?
                        normalyzeDecimalLength(pipDisplayValue, 2) :
                        pointDisplayValue}
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.label}>{t("requiredMargin")}:</div>
                <div className={styles.value}>{requiredBuyMarginDisplayValue}</div>
            </div>
            <div className={styles.block}>
                <div className={styles.label}>{t("freeMargin")}:</div>
                <div className={styles.value}>{formatAmount(freeMargin, accountCurrency)}</div>
            </div>
        </div>
    );
};

export default React.memo(MarginTabletInfo);
