import React from 'react';
import { useTheme } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useDevice } from "@/redux/selectors/deviceSelector";

import styles from './WithdrawalInfoSection.module.scss';


interface IProps {
    onClose: () => void
}

const WithdrawalInfoSection: React.FC<IProps> = ({ onClose }) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { locale } = useRouter();
    const { isTablet, isMobile } = useDevice();

    return (
        <div className={clsx(styles.container, { [styles.container_tablet]: isTablet })}>
            <header className={clsx(styles.header, { [styles.header_tablet]: isTablet })}>
                <div className={styles[`closeIcon_${palette.mode}`]} onClick={onClose}></div>
                <h1 className={styles[`title_${palette.mode}`]}>{t("messages.withdrawMoney")}</h1>
                <span className={styles[`attentionInfo_${palette.mode}`]}>
                    {t("messages.withdrawVerifyIdentity")}
                </span>
            </header>
            <main>
                <h2 className={clsx(styles[`subtitle_${palette.mode}`], { [styles.subtitle_tablet]: isTablet })}>
                    {t("messages.withdrawProvideFollowing")}
                </h2>
                <div className={clsx(styles[`actionsPanel_${palette.mode}`], {
                    [styles.actionsPanel_tablet]: isTablet,
                    [styles.long_text]: isMobile && locale === "ge",
                })}>
                    <div className={clsx(styles[`action_${palette.mode}`], {
                        [styles.action_tablet]: isTablet
                    })}>
                        <div className={clsx(styles.actionIcon, styles.porIcon, {
                            [styles.actionIcon_tablet]: isTablet,
                            [styles.porIcon_tablet]: isTablet
                        })}></div>
                        <span className={clsx(styles.actionLabel, {
                            [styles.single_line]: isMobile && locale === "de",
                        })}>
                            {t("messages.withdrawProofOfResident")}
                        </span>
                    </div>
                    <div className={clsx(styles[`action_${palette.mode}`], {
                        [styles.action_tablet]: isTablet
                    })}>
                        <div className={clsx(styles.actionIcon, styles.poiIcon, {
                            [styles.actionIcon_tablet]: isTablet,
                            [styles.poiIcon_tablet]: isTablet
                        })}></div>
                        <span className={clsx(styles.actionLabel, {
                            [styles.single_line]: isMobile && locale === "de",
                        })}>
                            {t("messages.withdrawProofOfIdentity")}
                        </span>
                    </div>
                    <div className={clsx(styles[`action_${palette.mode}`], {
                        [styles.action_tablet]: isTablet
                    })}>
                        <div className={clsx(styles.actionIcon, styles.podIcon, {
                            [styles.actionIcon_tablet]: isTablet,
                            [styles.podIcon_tablet]: isTablet
                        })}></div>
                        <span className={clsx(styles.actionLabel, {
                            [styles.single_line]: isMobile && locale === "de",
                        })}>
                            {t("messages.withdrawProofOfDeposit")}
                        </span>
                    </div>
                </div>
                <span className={styles[`approvementInfo_${palette.mode}`]}>
                    <div className={styles.infoIcon}></div>
                    <span>{t("messages.withdrawRequestApprovalInfo")}</span>
                </span>
            </main>
            <footer>
                <div className={clsx(styles[`bottomPanel_${palette.mode}`], { [styles.buttomPanel_tablet]: isTablet })}>
                    <div className={clsx(styles.block, styles.requestBlock)}>
                        <div className={clsx(styles.icon, styles[`requestIcon_${palette.mode}`])}></div>
                        <span className={styles.requestLabel}>{t("messages.withdrawRequest")}</span>
                    </div>
                    <div className={clsx(styles.icon, styles[`arrowIcon_${palette.mode}`])}></div>
                    <div className={clsx(styles.block, styles.businessDayBlock)}>
                        <div className={clsx(styles.icon, styles.block, styles[`businessDayIcon_${palette.mode}`])}></div>
                        <span className={styles.requestLabel}>{t("messages.withdrawBusinessDay")}</span>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default React.memo(WithdrawalInfoSection);
