import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { setToggleExpandFooter, setToggleExpandFooterFullScreen } from "@/redux/actions/ui";
import { useUI } from "@/redux/selectors/uiSelector";
import { OrderTableType } from "@/components/TradesTable";
import { useTranslation } from "next-i18next";
import styles from "../TradesTable.module.scss";

interface IProps {
    type: OrderTableType;
}

export const Header: React.FunctionComponent<IProps> = ({ type }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const { isFooterExpandedFullScreen } = useUI();

    if (isFooterExpandedFullScreen) {
        return (
            <div className={styles.fullScreenHeader}>
                <div>{type === OrderTableType.OPEN_TRADES ? t("openTrades") : t("pendingTrades")}</div>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => dispatch(setToggleExpandFooterFullScreen(false))}
                    aria-label="close"
                    className={styles.closeIcon}>
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }

    return (
        <div className={styles.drawer_header_wrp}>
            <div className={styles.drawer_header_btns_wrp}>
                <div
                    onClick={() => dispatch(setToggleExpandFooter(false))}
                    aria-label="close"
                    className={clsx(
                        styles.drawer_header_btn,
                        styles[`close_icon_${palette.mode}`]
                    )}></div>
                <div
                    onClick={() =>
                        dispatch(setToggleExpandFooterFullScreen(!isFooterExpandedFullScreen))
                    }
                    className={clsx(
                        styles.drawer_header_btn,
                        styles[`maximize_icon_${palette.mode}`]
                    )}></div>
            </div>
        </div>
    );
};
