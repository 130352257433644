import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useTheme } from "@mui/material";

import { QuoteData, PriceChangeType, useQuoteData } from "@/hooks/trade/tradeSocket";
import {
    getAssetsPriceChangePercentage,
    getAssetsPriceChangeType,
    getDefaultAssetsSort,
    getFormattedPrice,
    getSymbolAssets,
    applySymbolFilters,
    getSymbolNameTranslation,
} from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useTranslation } from "next-i18next";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { useAccountInfo, useFavourites } from "@/redux/selectors/accountSelector";
import { addToFavourites, removeFromFavourites } from "@/redux/actions/account";
import { trimNumberToStepValue } from "@/utils/numbers";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { formatAmount } from "@/utils/format";
import { getTradeProfit } from "@/utils/trade";
import { closeTradeRequest, deleteOrderRequest, modifyOrderRequest } from "@/redux/actions/orders";
import { useUI } from "@/redux/selectors/uiSelector";
import EditOrderModal from "@/components/OrderMobile/EditOrderModal";
import NoSearchNotice from "@/components/Home/TradesSectionMobile/components/NoSearchNotice";
import { setActiveSymbol, setActiveSymbolFromURL } from "@/redux/actions/tradeInfo";
import { setSingleAssetViewActive } from "@/redux/actions/ui";
import { ISymbolInfo } from "@/services/trade/symbols";
import { getDirectionsTranslationKey, setOperationTimestamp } from "@/utils/helpers";
import { multiply } from "@/utils/mathUtils";
import { ITradeOrder } from "@/services/trade/order";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";
import { useSystemFeedback } from "@/redux/selectors/systemFeedbackSelector";
import { setFavoritesAlertMessage } from "@/redux/actions/systemFeedback";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { getSeenPrice } from "@/utils/getSeenPrice";
import styles from "./AssetsTableMobile.module.scss";

export enum AssetViewType {
    LIST_TYPE_1 = "LIST_TYPE_1",
    LIST_TYPE_2 = "LIST_TYPE_2",
}

export enum OrderViewType {
    justOpened = "justOpened",
    justModified = "justModified",
    recentlyOpened = "recentlyOpened",
    opened = "opened",
}
const effectsDurationOpenedOrder = 3000; // 3 sec

const ASSETS_LIST_MOBILE_ITEM_HIGHLIGHT_DELAY_IN_MS = 300;

interface IAssetOrderProps {
    order: ITradeOrder;
    isMarketOpen: boolean;
}

const AssetOrderItem: React.FC<IAssetOrderProps> = ({ order, isMarketOpen }) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();
    const [viewType, setViewType] = useState(OrderViewType.opened);
    const { activeOrderId, symbolsInfoById, symbols } = useTradeInfo();
    const { isOrderModifyPopupOpen } = useUI();
    const { favoritesAlerts } = useSystemFeedback();
    const { currency: accountCurrency } = useAccountInfo();
    const symbolInfo = symbolsInfoById[order.symbol];
    const { ask, bid } = useQuoteData(order.symbol);
    const calcProfit = getTradeProfit({
        openPrice: order.openPrice,
        ask,
        bid,
        tradeType: order.type.toLowerCase().includes("buy") ? "Buy" : "Sell",
        lotSize: order.lots,
        accountCurrency,
        symbolInfo,
        symbols,
    });

    // needs only for ui effects
    useEffect(() => {
        const currentTime = new Date();
        const deltaMilliseconds = currentTime.getTime() - new Date(order.openTime).getTime();

        // less than 3 sec
        if (deltaMilliseconds < effectsDurationOpenedOrder) {
            setViewType(OrderViewType.justOpened);

            setTimeout(() => {
                setViewType(OrderViewType.recentlyOpened);
                setTimeout(() => setViewType(OrderViewType.opened), effectsDurationOpenedOrder);
            }, effectsDurationOpenedOrder - deltaMilliseconds);

            // less than 6 sec
        } else if (deltaMilliseconds < 2 * effectsDurationOpenedOrder) {
            setViewType(OrderViewType.recentlyOpened);
            setTimeout(() => {
                setViewType(OrderViewType.opened);
            }, 2 * effectsDurationOpenedOrder - deltaMilliseconds);
        }
    }, [order]);

    const handleEdit = () => {
        if (!isMarketOpen) {
            return;
        }
        if (favoritesAlerts.length) {
            dispatch(setFavoritesAlertMessage(null));
        }

        dispatch(setActiveSymbol(order.symbol, symbolsInfoById[order.symbol].point));
        setOperationTimestamp();
        dispatch(modifyOrderRequest(order));
    };

    const handleDelete = () => {
        if (!isMarketOpen) {
            return;
        }

        dispatch(
            deleteOrderRequest({
                ...order,
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            })
        );
    };

    const handleClose = () => {
        if (!isMarketOpen) {
            return;
        }

        dispatch(
            closeTradeRequest({
                ...order,
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            })
        );
    };

    if (viewType === OrderViewType.justOpened) {
        return <div className={styles.successAlert}>{t("messages.tradeOpened")}</div>;
    }

    if (viewType === OrderViewType.justModified) {
        return <div className={styles.infoAlert}>{t("messages.tradeModified")}</div>;
    }

    const profit = isMarketOpen ? calcProfit : order.profit;

    return (
        <div
            className={clsx(styles.assetOrdersItem, {
                [styles.recentlyOpened]: viewType === OrderViewType.recentlyOpened,
                [styles.marketClosed]: !isMarketOpen,
            })}>
            <div
                className={clsx(null, {
                    [styles.buyIcon]: order.type === "Buy",
                    [styles.sellIcon]: order.type === "Sell",
                    [styles.alarmClock]: order.type !== "Sell" && order.type !== "Buy",
                })}
            />

            <div className={styles.orderType}>
                {order.type === "Buy" || order.type === "Sell" ? (
                    t(getDirectionsTranslationKey(order.type))
                ) : (
                    <>
                        {t("order")}
                        <div className={styles.orderType__small}>
                            {t(getDirectionsTranslationKey(order.type))}
                        </div>
                    </>
                )}
            </div>

            <span className={styles.orderLots}>{order.lots}</span>

            {order.type === "Buy" || order.type === "Sell" ? (
                <div
                    className={clsx({
                        [styles.profit]: profit >= 0,
                        [styles.loss]: profit < 0,
                    })}>
                    {formatAmount(profit, accountCurrency)}
                </div>
            ) : (
                <div className={styles[`pending_${palette.mode}`]}>{order.openPrice}</div>
            )}

            <div className={styles.btnContainer}>
                <div
                    className={clsx(styles.btnEdit, {
                        [styles.touched]: isTouched && buttonName === PressedButtons.EDIT,
                    })}
                    onClick={handleEdit}
                    onTouchStart={() => onTouchStart(PressedButtons.EDIT)}
                    onTouchEnd={onTouchEnd}
                />

                {order.type === "Buy" || order.type === "Sell" ? (
                    <div
                        className={clsx(styles.btnClose, {
                            [styles.touched]: isTouched && buttonName === PressedButtons.CLOSE,
                        })}
                        onClick={handleClose}
                        onTouchStart={() => onTouchStart(PressedButtons.CLOSE)}
                        onTouchEnd={onTouchEnd}
                    />
                ) : (
                    <div
                        className={clsx(styles.btnDelete, {
                            [styles.touched]: isTouched && buttonName === PressedButtons.DELETE,
                        })}
                        onClick={handleDelete}
                        onTouchStart={() => onTouchStart(PressedButtons.DELETE)}
                        onTouchEnd={onTouchEnd}
                    />
                )}
            </div>

            {isOrderModifyPopupOpen && activeOrderId === order.ticket && (
                <EditOrderModal order={order} />
            )}
        </div>
    );
};

interface IAssetsListItemProps {
    symbol: ISymbolInfo;
    activeCategory: string;
    assetsViewType: AssetViewType;
    rowIndex: number;
    setSkipAnimation: (value: boolean) => void;
}

const AssetsListItem = ({
    symbol,
    activeCategory,
    assetsViewType,
    rowIndex,
    setSkipAnimation,
}: IAssetsListItemProps) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { isMobile } = useDevice();
    const assetsListItemRef = useRef<HTMLDivElement>();
    const { symbolsInfoById, isActiveSymbolFromURL, activeSymbolId } = useTradeInfo();
    const isHighlighted = isActiveSymbolFromURL && symbol.id === activeSymbolId;
    const favourites = useFavourites();
    const { pendingOrders, openTrades } = useTradeOrders();
    const { isMarketOpen } = useMarketSchedule(symbol.id);
    const { isTouched, onTouchEnd, onTouchStart } = useTouchState();
    const [assetImage1, assetImage2] = getSymbolAssets(symbol.id, symbol?.group?.name || activeCategory);

    const isInFavourites = favourites && favourites.length ? favourites?.includes(symbol.id) : false;

    const orders = useMemo(() => {
        const currentPending = pendingOrders.filter(item => item.symbol === symbol.id);
        const currentOpen = openTrades.filter(item => item.symbol === symbol.id);

        return [...currentPending, ...currentOpen].sort((a, b) => {
            const aOpenTime = new Date(a.openTime).getTime();
            const bOpenTime = new Date(b.openTime).getTime();

            return aOpenTime < bOpenTime ? -1 : aOpenTime > bOpenTime ? 1 : 0;
        });
    }, [pendingOrders, openTrades, symbol]);

    const handleClickSymbol = symbol => {
        return () => {
            dispatch(setActiveSymbol(symbol.id, symbolsInfoById[symbol.id].point));

            dispatch(setSingleAssetViewActive());
        };
    };

    useEffect(() => {
        if (assetsListItemRef.current && isHighlighted) {
            setTimeout(() => {
                assetsListItemRef?.current?.scrollIntoView({
                    // "smooth" animation looks bad for long lists
                    // when the highlighted item is near the end
                    behavior: "auto",
                });
            }, ASSETS_LIST_MOBILE_ITEM_HIGHLIGHT_DELAY_IN_MS);
        }
    }, [assetsListItemRef, isHighlighted]);

    const getPriceDirectionChangeColor = (changeType: PriceChangeType) => {
        if (changeType === PriceChangeType.Up) {
            return "#2CC47E";
        }
        if (changeType === PriceChangeType.Down) {
            return "#F25555";
        }
        return "inherit";
    };

    return (
        <div className={styles.assetsListItem} ref={assetsListItemRef}>
            {assetsViewType === AssetViewType.LIST_TYPE_1 ? (
                <div
                    className={clsx(styles.assetRow, {
                        [styles.touched]: isTouched,
                        [styles.highlighted]: isHighlighted,
                    })}
                    onClick={handleClickSymbol(symbol)}
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    style={{ animationDelay: `${multiply(rowIndex, 0.02)}s` }}>
                    <div className={styles.favContainer} onClick={e => e.stopPropagation()}>
                        {!isInFavourites && (
                            <span
                                className={styles.favoritesIcon}
                                onClick={() => {
                                    setSkipAnimation(true);
                                    dispatch(addToFavourites(symbol.id));
                                }}
                            />
                        )}
                        {isInFavourites && (
                            <span
                                className={clsx(styles.favoritesIcon, styles.favoritesIconChecked)}
                                onClick={() => {
                                    setSkipAnimation(true);
                                    dispatch(removeFromFavourites(symbol.id));
                                }}
                            />
                        )}
                    </div>

                    <div className={styles.symbolImagesCell}>
                        <div
                            className={clsx(styles.symbolMainInfoImages, {
                                [styles.symbolMainInfoImagesSingleImage]: !assetImage1 || !assetImage2,
                            })}>
                            <object data={assetImage1}>
                                <img src={assetImage1} alt="" />
                            </object>
                            {assetImage2 && (
                                <object data={assetImage2}>
                                    <img src={assetImage2} alt="" />
                                </object>
                            )}
                        </div>
                    </div>

                    <div className={styles.symbolMainInfoCell}>
                        <div className={styles.symbolMainInfoName}>
                            {symbol.ex.displayName || symbol.id}
                        </div>
                        <div className={styles.symbolMainInfoDescription}>
                            <div className={isMarketOpen ? styles.clock_icon : styles.clock_icon_red} />

                            <span className={styles.symbolMainInfoDescriptionText}>
                                {getSymbolNameTranslation(symbol, t, "/")}
                            </span>
                        </div>
                    </div>

                    <QuoteData
                        symbolId={symbol.id}
                        priceChangeTTL={450}
                        render={({ bid, changeType: recentChangeType }) => {
                            const percentage = getAssetsPriceChangePercentage(
                                bid,
                                symbol.quote.midnightPrice
                            );
                            const changeType = getAssetsPriceChangeType(bid, symbol.quote.midnightPrice);

                            return (
                                <div style={{ textAlign: "right" }}>
                                    <div
                                        className={styles.priceValue}
                                        style={{
                                            marginBottom: "4px",
                                            color: getPriceDirectionChangeColor(recentChangeType),
                                        }}>
                                        {getFormattedPrice(bid, symbol.point)}
                                    </div>

                                    <div
                                        className={clsx(styles.priceValueChangeBadge, {
                                            [styles.priceValueChangeUp]:
                                                changeType === PriceChangeType.Up,
                                            [styles.priceValueChangeDown]:
                                                changeType === PriceChangeType.Down,
                                            [styles.priceValueChangeNone]:
                                                changeType !== PriceChangeType.Up &&
                                                changeType !== PriceChangeType.Down,
                                        })}>
                                        {isMarketOpen && percentage
                                            ? `${percentage.toFixed(2)}%`
                                            : "0.00%"}
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            ) : (
                <div
                    className={clsx(styles.assetRowType2, {
                        [styles.assetRowWithOrders]: orders?.length > 0,
                    })}
                    onClick={handleClickSymbol(symbol)}>
                    <div className={styles.favContainer} onClick={e => e.stopPropagation()}>
                        {!isInFavourites && (
                            <span
                                className={styles.favoritesIcon}
                                onClick={() => dispatch(addToFavourites(symbol.id))}
                            />
                        )}
                        {isInFavourites && (
                            <span
                                className={clsx(styles.favoritesIcon, styles.favoritesIconChecked)}
                                onClick={() => dispatch(removeFromFavourites(symbol.id))}
                            />
                        )}
                    </div>

                    <div>
                        <div className={styles.symbolMainInfoName}>
                            {symbol.ex.displayName || symbol.id}
                            &nbsp;
                            <div className={isMarketOpen ? styles.clock_icon : styles.clock_icon_red} />
                        </div>
                        <QuoteData
                            symbolId={symbol.id}
                            priceChangeTTL={450}
                            render={({ bid }) => {
                                const percentage = getAssetsPriceChangePercentage(
                                    bid,
                                    symbol.quote.midnightPrice
                                );
                                const changeType = getAssetsPriceChangeType(
                                    bid,
                                    symbol.quote.midnightPrice
                                );

                                return (
                                    <div
                                        className={clsx(styles.priceChange, {
                                            [styles.priceChangeUp]: changeType === PriceChangeType.Up,
                                            [styles.priceChangeDown]:
                                                changeType === PriceChangeType.Down,
                                        })}>
                                        (
                                        {isMarketOpen && percentage
                                            ? `${percentage.toFixed(2)}%`
                                            : "0.00%"}
                                        )
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <QuoteData
                        symbolId={symbol.id}
                        priceChangeTTL={450}
                        render={({ bid, bidChangeType }) => {
                            const displayValue = trimNumberToStepValue(bid, symbol?.point);
                            return (
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlock__label}>{t("sell")}</div>
                                    <div
                                        className={clsx(styles.infoBlock__value, {
                                            [styles.long_value]: displayValue.length > 10 && isMobile,
                                        })}
                                        style={{ color: getPriceDirectionChangeColor(bidChangeType) }}>
                                        {displayValue}
                                    </div>
                                </div>
                            );
                        }}
                    />

                    <QuoteData
                        symbolId={symbol.id}
                        priceChangeTTL={450}
                        render={({ ask, askChangeType }) => {
                            const displayValue = trimNumberToStepValue(ask, symbol?.point);
                            return (
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlock__label}>{t("buy")}</div>
                                    <div
                                        className={clsx(styles.infoBlock__value, {
                                            [styles.long_value]: displayValue.length > 10 && isMobile,
                                        })}
                                        style={{ color: getPriceDirectionChangeColor(askChangeType) }}>
                                        {displayValue}
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            )}

            {assetsViewType === AssetViewType.LIST_TYPE_2 && orders?.length > 0 && (
                <div className={styles.assetOrdersList}>
                    {orders.map((item, index) => {
                        return (
                            <AssetOrderItem
                                order={item}
                                isMarketOpen={isMarketOpen}
                                key={`${item.symbol}-${index}`}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

interface IAssetsListMobileProps {
    activeCategory: string;
    assetsViewType: AssetViewType;
    isShowMarginBlock: boolean;
}

const AssetsListMobile: React.FC<IAssetsListMobileProps> = ({
    activeCategory,
    assetsViewType,
    isShowMarginBlock,
}) => {
    const { t } = useTranslation("common");
    const { symbolsInfo, search, groups: assetCategories, isActiveSymbolFromURL } = useTradeInfo();
    const favourites = useFavourites();
    const dispatch = useDispatch();
    const [skipAnimation, setSkipAnimation] = useState(false);

    useEffect(() => {
        return () => {
            if (isActiveSymbolFromURL) {
                // stop highlighting the symbol from url after the first list unmount
                dispatch(setActiveSymbolFromURL(false));
            }
        };
    }, [isActiveSymbolFromURL]);

    useEffect(() => {
        if (search && !skipAnimation) {
            setSkipAnimation(true);
        }
    }, [search]);

    const categoriesNames = assetCategories.map(({ name }) => name);

    let tradeData = applySymbolFilters(symbolsInfo, {
        search,
        categoriesNames,
        activeCategory,
        favourites,
    });

    if (activeCategory === "Favourites" && !tradeData.length && !search) {
        if (skipAnimation) {
            setSkipAnimation(false);
        }

        return (
            <div
                className={clsx(styles.emptyList, {
                    [styles.expandedMarginBlock]: isShowMarginBlock,
                })}>
                <div>{t("placeholder.empty")}</div>
                <button className={styles.button} onClick={() => dispatch(addToFavourites())}>
                    <div className={styles.contentBtn}>
                        <div className={styles.resetIcon} />
                        <span className={styles.titleBtn}>{t("btn.empty")}</span>
                    </div>
                </button>
            </div>
        );
    }

    if (search && tradeData?.length === 0) {
        return <NoSearchNotice />;
    }

    tradeData = getDefaultAssetsSort(tradeData);

    return (
        <>
            {search && (
                <div className={styles.searchResultsTitle}>
                    {t("search_results")}:&nbsp;{tradeData?.length}&nbsp;{t("search_items")}
                </div>
            )}

            <div
                className={clsx(styles.assetsWrap, {
                    [styles.skipAnimation]: isActiveSymbolFromURL || skipAnimation,
                })}>
                {tradeData.map((symbol, index) => {
                    return (
                        <AssetsListItem
                            symbol={symbol}
                            activeCategory={activeCategory}
                            assetsViewType={assetsViewType}
                            rowIndex={index + 1}
                            key={`${index}-${symbol.id}`}
                            setSkipAnimation={setSkipAnimation}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default React.memo(AssetsListMobile);
