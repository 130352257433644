import React, { ReactNode } from "react";

import styles from "../InfoPopup.module.scss";

interface IProps {
    handleClose: () => void;
    children: ReactNode;
}

export const Header = (props: IProps): JSX.Element => {
    const { handleClose, children } = props;

    return (
        <div className={styles.header}>
            <div className={styles.header_text}>{children}</div>
            <div onClick={handleClose} className={styles.header_close_btn} />
        </div>
    );
};
