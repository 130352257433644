import { StoreAction } from "@/utils/storeActions";

import { IDispatchCloseModalAction, IDispatchOpenModalAction, IOpenModalAction } from "../interfaces/IModal";

export const openModal = (data?: IOpenModalAction): IDispatchOpenModalAction => ({
    type: StoreAction.OPEN_POPUP,
    ...data,
});

export const closeModal = (): IDispatchCloseModalAction => ({ type: StoreAction.CLOSE_POPUP });
