import Head from "next/head";
import { FunctionComponent, ReactElement } from "react";

import { IProps } from "@/interfaces/IProps";

export interface IPageMetaProps {
    pageTitle: string;
    metaMobileTitle: string;
    metaTitle: string;
    metaDescription: string;
    metaUrl: string;
    metaImage: string;
    css: string;
    gtm: string;
}

export const PageMeta: FunctionComponent<IProps<IPageMetaProps>> = (data): ReactElement => {
    const { pageTitle, metaMobileTitle, metaTitle, metaDescription, metaUrl, metaImage, css, gtm } = data.payload;

    return (
        <Head>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=0" />
            <title>{pageTitle}</title>
            <meta key="meta-title" name="title" content={metaTitle} />
            <meta key="meta-description" name="description" content={metaDescription} />
            <meta key="app-title" name="apple-mobile-web-app-title" content={metaMobileTitle} />
            <meta key="app-name" name="application-name" content={metaMobileTitle} />
            <meta key="og-url" property="og:url" content={metaUrl} />
            <meta key="og-title" property="og:title" content={metaTitle} />
            <meta key="og-description" property="og:description" content={metaDescription} />
            <meta key="og-image" property="og:image" content={metaImage} />
            {css !== "" && <style>{css}</style>}
            {gtm !== "" && (
                <>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${gtm}')`,
                        }}
                    />
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                        }}
                    />
                </>
            )}
        </Head>
    );
};
