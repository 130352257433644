import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import styles from "./Fifth.module.scss";

interface IProps {
    closeModal: () => void;
    children: JSX.Element;
}

export const FifthStep: React.FC<IProps> = ({ closeModal, children }) => {
    const { t, i18n } = useTranslation("common");
    const { palette } = useTheme();

    return (
        <div
            className={styles.container}
            style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>
            <div onClick={closeModal} className={styles.close}>
                <div onClick={closeModal} className={styles.closeIcon}></div>
            </div>

            <div className={styles.title}>{t("guidance.step05.title")}</div>
            <div className={styles.subtitle}>{t("guidance.step05.content01")}</div>
            <div className={styles.table}>
                <div className={styles.table_cell}>
                    <img src={`/images/intro/${palette.mode}/profit_mark.svg`} />
                    <div className={styles.table_cell_title}>{t("guidance.step05.content02")}</div>
                </div>
                <div className={styles.table_cell}>
                    <img src={`/images/intro/${palette.mode}/calendar.svg`} />
                    <div className={styles.table_cell_title}>{t("guidance.step05.content03")}</div>
                </div>
                <div className={styles.table_cell}>
                    <img src={`/images/intro/${palette.mode}/trade_protection.svg`} />
                    <div className={styles.table_cell_title}>{t("guidance.step05.content04")}</div>
                </div>
            </div>
            <div style={{ marginBottom: 10 }} className={styles.subtitle}>
                {t("guidance.step05.content05")}
            </div>
            {children}
        </div>
    );
};
