import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";

import { openModal } from "@/redux/actions/modal";
import { PopupType } from "@/redux/interfaces/IModal";
import { DispatchOpenModal } from "@/utils/modals";
import { PLATFORM_INTRO_COOKIE_KEY } from "@/constants/index";
import parentFrameAPI from "@/services/parent";
import { useAccountInfo } from "@/redux/selectors/accountSelector";

import { Stepper } from "./Stepper";
import {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    SixthStep,
    SeventhStep,
} from "./Steps";

import styles from "./Intro.module.scss";

//const MAX_STEPS = 7;

interface IntroModalProps {
    closeModal: () => void;
    isOpen: boolean;
}

export const openIntroModal = (category, dispatch: DispatchOpenModal): void =>
    dispatch(openModal({ category }));

export const IntroModal = ({ isOpen, closeModal }: IntroModalProps): JSX.Element => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const { isGuest, isLazyMode } = useAccountInfo();

    const trades = useSelector(({ orders }) => {
        return orders.openTrades || [];
    });

    useEffect(() => {
        openIntroModal(PopupType[`Intro_1`], dispatch);
    }, []);

    const openDeposit = () =>
        isGuest && !isLazyMode ? parentFrameAPI.login() : parentFrameAPI.deposit();

    const stepMove = (count = 0, prevCount = 0) => {
        const shouldSkipForward = count === 4 && prevCount === 3 && !trades.length;
        const shouldSkipBackward = count === 4 && prevCount === 5 && !trades.length;
        // need to skip that step if user doesn't have open trades
        if (shouldSkipForward) {
            count = count + 1;
        }

        if (shouldSkipBackward) {
            count = count - 1;
        }

        const category = PopupType[`Intro_${count + 1}`];

        openIntroModal(category, dispatch);
        setStep(count);
    };

    const handleClose = () => {
        const category = PopupType.None;

        openIntroModal(category, dispatch);
        closeModal();
        setStep(0);

        Cookies.set(PLATFORM_INTRO_COOKIE_KEY, "true", {
            expires: 10 * 365,
        });
    };

    return (
        <Modal open={isOpen}>
            <div className={styles.container}>
                {step == 0 && (
                    <FirstStep moveForward={stepMove} closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </FirstStep>
                )}
                {step === 1 && (
                    <SecondStep closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </SecondStep>
                )}
                {step === 2 && (
                    <ThirdStep closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </ThirdStep>
                )}
                {step === 3 && (
                    <FourthStep closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </FourthStep>
                )}
                {step === 4 && (
                    <FifthStep closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </FifthStep>
                )}
                {step === 5 && (
                    <SixthStep closeModal={handleClose}>
                        <Stepper currentStep={step} setStep={stepMove} onClose={handleClose} />
                    </SixthStep>
                )}
                {step === 6 && (
                    <SeventhStep openDeposit={openDeposit} closeModal={handleClose}></SeventhStep>
                )}
            </div>
        </Modal>
    );
};
