import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import { getFormattedPrice, getPrice } from "@/utils/symbols";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { setActiveOperationCategory, setPendingOrderPrice } from "@/contexts/TradeOrder/actions";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { ISymbolInfo } from "@/services/trade/symbols";
import { isInvalidPendingOrderPrice, isInvalidSLTP } from "@/utils/trade";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import { useUI } from "@/redux/selectors/uiSelector";
import parentFrameAPI from "@/services/parent";
import PriceInputMobile from "./PriceInputMobile";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { AnimationClasses } from "@/hooks/common/useAnimation";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";
import useTradeOrderFormData from "@/components/Home/TradingOrderSection/hooks";

import styles from "./PendingOrders.module.scss";


interface IPendingOrders {
    toggleDrawer?: () => void;
    symbol?: ISymbolInfo;
    date?: string;
    isOpenDuration?: boolean;
    isCalendarOpen: boolean;
}

const PendingOrders: React.FC<IPendingOrders> = ({
    isCalendarOpen,
    symbol,
    date,
    isOpenDuration = true,
}) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { platform } = useDevice();
    const { isMobilePendingOrdersOpen } = useUI();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { isLoggedInUser } = useLoginInfo();
    const { isMarketOpen } = useMarketSchedule(symbol.id);
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();
    const { placeOrder, isRequestPending } = useTradeOrderFormData();

    const {
        lotSize,
        activeOperationCategory,
        pendingOrderPrice,
        isStopLoss,
        stopLossPrice,
        isTakeProfit,
        takeProfitPrice,
        pendingOrderType,
    } = useContext(TradeOrderStateContext);

    const priceRef = useRef(null);
    const [price, setPrice] = useState(priceRef.current);
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const { ask, bid } = useQuoteData(activeSymbolId);
    const marketPrice = activeOperationCategory === "Buy" ? ask : bid;
    const value = useQuoteData(symbol.id, 0);

    useEffect(() => {
        if (value) {
            const { ask, bid } = value;
            const initialPriceValue = Number(getFormattedPrice(getPrice(ask, bid), symbol.point));
            priceRef.current = initialPriceValue;
            setPrice(initialPriceValue);
        }
    }, []);

    const handlePriceChange = newPrice => {
        setPrice(newPrice);
        tradeOrderDispatch(setPendingOrderPrice(Number(newPrice), marketPrice));
    };

    const isSellButtonDisabled =
        !isMarketOpen ||
        ((isStopLoss || isTakeProfit) && activeOperationCategory === TRADE_OPERATION_TYPE.BUY);
    const isBuyButtonDisabled =
        !isMarketOpen ||
        ((isStopLoss || isTakeProfit) && activeOperationCategory === TRADE_OPERATION_TYPE.SELL);

    const setActiveOperation = (operationType: TRADE_OPERATION_TYPE) => {
        if (
            !isMarketOpen ||
            (isBuyButtonDisabled && operationType === TRADE_OPERATION_TYPE.BUY) ||
            (isSellButtonDisabled && operationType === TRADE_OPERATION_TYPE.SELL)
        ) {
            return;
        }
        return () => tradeOrderDispatch(setActiveOperationCategory(operationType, ask, bid));
    };

    const isInvalidPendingOrderParams = isMobilePendingOrdersOpen && pendingOrderType === null;
    const isInvalidPendingOrderPriceCheck =
        isMobilePendingOrdersOpen &&
        isInvalidPendingOrderPrice(
            activeOperationCategory,
            pendingOrderType,
            pendingOrderPrice,
            marketPrice
        );

    const isPlaceOrderDisabled =
        !activeOperationCategory ||
        +lotSize <= 0 ||
        isInvalidSLTP(
            activeOperationCategory,
            isStopLoss ? stopLossPrice : 0,
            isTakeProfit ? takeProfitPrice : 0,
            marketPrice
        ) ||
        isInvalidPendingOrderParams ||
        isInvalidPendingOrderPriceCheck ||
        isRequestPending;
        
    const handlePlaceOrder = () => {
        if (!isMarketOpen || !activeOperationCategory || isPlaceOrderDisabled) {
            return;
        }
        return isLoggedInUser ? placeOrder({ expirationDate: date }) : parentFrameAPI.login();
    };

    return (
        <div
            className={clsx(styles.drawerWrp, AnimationClasses.fadeInUp, {
                [styles.isHidden]: isCalendarOpen,
            })}
            style={{ animationDuration: isOpenDuration ? "0.3s" : "0s" }}>
            <div className={styles.controlsWrp}>
                <div
                    className={clsx(styles.controlsContainer, {
                        [styles.controlsContainer_tablet]: platform === DevicePlatform.Tablet,
                    })}>
                    <div
                        onClick={setActiveOperation(TRADE_OPERATION_TYPE.SELL)}
                        className={clsx(styles._button, styles.sell, {
                            [styles.active]:
                                activeOperationCategory === TRADE_OPERATION_TYPE.SELL && isMarketOpen,
                            [styles.sell_disabled]: isSellButtonDisabled,
                        })}>
                        {t("sell")}
                    </div>
                    {price && (
                        <PriceInputMobile
                            value={String(price) || "0"}
                            initialValue={priceRef.current}
                            step={symbolInfo.point}
                            decimalDigitsCount={symbolInfo.digits}
                            onChange={handlePriceChange}
                            disabled={!isMarketOpen || !activeOperationCategory}
                        />
                    )}
                    <div
                        onClick={setActiveOperation(TRADE_OPERATION_TYPE.BUY)}
                        className={clsx(styles._button, styles.buy, {
                            [styles.active]:
                                activeOperationCategory === TRADE_OPERATION_TYPE.BUY && isMarketOpen,
                            [styles.buy_disabled]: isBuyButtonDisabled,
                        })}>
                        {t("buy")}
                    </div>
                </div>
                <div
                    onTouchStart={() => onTouchStart(PressedButtons.PLACE_ORDER)}
                    onTouchEnd={onTouchEnd}
                    onClick={handlePlaceOrder}
                    className={clsx(styles[`orderButton_${palette.mode}`], {
                        [styles.active]:
                            isMarketOpen && activeOperationCategory && !isPlaceOrderDisabled,
                        [styles.active_sell]:
                            isMarketOpen && !isPlaceOrderDisabled && activeOperationCategory === TRADE_OPERATION_TYPE.SELL,
                        [styles.touched_buy]:
                            isTouched &&
                            buttonName === PressedButtons.PLACE_ORDER &&
                            activeOperationCategory === TRADE_OPERATION_TYPE.BUY,
                        [styles.touched_sell]:
                            isTouched &&
                            buttonName === PressedButtons.PLACE_ORDER &&
                            activeOperationCategory === TRADE_OPERATION_TYPE.SELL,
                    })}>
                    {t("place_order")}
                </div>
            </div>
        </div>
    );
};

export default React.memo(PendingOrders);
