import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { useEffect } from "react";
import { getTradeProfit } from "@/utils/trade";
import { isBuyTrade } from "@/components/TradesTable/components/TableItem";
import { IDynamicAccountInfoResponse, quoteSocketManager } from "@/hooks/trade/tradeSocket";
import { setDynamicAccountInfo, setDynamicAccountInfoCalculated } from "@/redux/actions/account";
import { useDispatch } from "react-redux";

const useInterval = (callback, delay) => {
    useEffect(() => {
        const id = setInterval(callback, delay);
        return () => clearInterval(id);
    }, [callback, delay]);
};

export const useBalanceCalculations = (): any => {
    const { symbolsInfoById, symbols } = useTradeInfo();
    const { currency: accountCurrency } = useAccountInfo();
    const { balance, credit, margin } = useAccountDynamicInfo();
    const { openTrades } = useTradeOrders();
    const dispatch = useDispatch();

    useInterval(() => {
        let purePnL = 0;
        let swap = 0;
        let commission = 0;

        openTrades.forEach(order => {
            let symbolInfo = symbolsInfoById[order.symbol];
            if (!symbolInfo) {
                symbolInfo = symbolsInfoById[order.symbol.replace("!", "")];
            }
            let q = quoteSocketManager.getValue(order.symbol);
            if (!q || (q && !q.ask)) {
                q = quoteSocketManager.getValue(order.symbol.replace("!", ""));
            }

            const profit = getTradeProfit({
                openPrice: order.openPrice,
                ask: q.ask,
                bid: q.bid,
                tradeType: isBuyTrade(order) ? "Buy" : "Sell",
                lotSize: order.lots,
                accountCurrency,
                symbolInfo,
                symbols,
            });
            swap += order.swap;
            commission += order.commission;
            purePnL += profit;
        });
        const equity = (balance || 0) + (credit || 0) + (purePnL || 0) + (swap || 0) + (commission || 0);
        const freeMargin = equity - margin;
        const PnL = purePnL + swap + commission;
        const accountInfo: IDynamicAccountInfoResponse = {
            accountEquity: equity,
            accountFreeMargin: freeMargin,
            accountProfit: PnL,
        };

        dispatch(setDynamicAccountInfo(accountInfo));
    }, 500);
};
