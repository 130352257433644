import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";

import { setActiveSymbol } from "@/redux/actions/tradeInfo";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { ITradeOrder } from "@/services/trade/order";
import { OrderTableType } from "@/components/TradesTable";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { formatAmount, formatNumber, normalyzeDecimalLength } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { QuoteData, useQuoteData } from "@/hooks/trade/tradeSocket";
import { getFormattedPrice } from "@/utils/symbols";
import { closeTradeRequest, deleteOrderRequest } from "@/redux/actions/orders";
import { useUI } from "@/redux/selectors/uiSelector";
import { setSingleAssetViewActive } from "@/redux/actions/ui";
import { IOrderSuccessModalParams, ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { getTradeProfit } from "@/utils/trade";
import { setToggleExpandFooterFullScreen } from "@/redux/actions/ui";
import { setOperationTimestamp } from "@/utils/helpers";
import { trimNumberToStepValue } from "@/utils/numbers";
import { useDevice } from "@/redux/selectors/deviceSelector";
import ImagePreloader from "@/components/Core/ImagePreloader/ImagePreloader";
import { getSeenPrice } from "@/utils/getSeenPrice";
import styles from "../TradesTable.module.scss";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";

interface ITableItemProps {
    order: ITradeOrder;
    type: OrderTableType;
    closedOrderTicket?: number;
    setClosedOrderTicket: (order: ITradeOrder) => void;
    handleOrderEditClick: (order: ITradeOrder) => void;
}

export const isBuyTrade = (order: ITradeOrder): boolean => {
    return order.type.toLowerCase() === "buy";
};

export const TableItem = (props: ITableItemProps): JSX.Element => {
    const { order, type, handleOrderEditClick } = props;
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { platform: devicePlatform } = useDevice();
    const dispatch = useDispatch();
    const { symbolsInfoById, symbols } = useTradeInfo();
    const { currency: accountCurrency } = useAccountInfo();
    const symbolId = order.symbol;
    const isIncorrectUserGroupAsset = symbolId !== order.symbol;
    const symbolInfo = symbolsInfoById[symbolId];
    const { ask, bid } = useQuoteData(symbolId);
    const isPendingOrders = type === OrderTableType.PENDING_ORDERS;
    const { tradeOrderModal, tradeOrderModalParams } = useUI();
    const { closingTickets } = useTradeOrders();
    const [expDateColumnHover, setExpDateColumnHover] = useState(false);
    //console.log("closingTickets", closingTickets);
    let calcProfit;

    if (type !== OrderTableType.PENDING_ORDERS) {
        calcProfit = getTradeProfit({
            openPrice: order.openPrice,
            ask,
            bid,
            tradeType: isBuyTrade(order) ? "Buy" : "Sell",
            lotSize: order.lots,
            accountCurrency,
            symbolInfo,
            symbols,
        });
    }

    const handleOrderClose = async () => {
        // REQUEST_PLACED
        if (tradeOrderModal === ITradeOrderModalTypes.CLOSE_TRADE) {
            const params = tradeOrderModalParams as IOrderSuccessModalParams;

            if (params?.order?.ticket === order.ticket) {
                return;
            }
        }

        setOperationTimestamp();
        dispatch(setToggleExpandFooterFullScreen(false));

        dispatch(
            closeTradeRequest({
                ...order,
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            })
        );
    };

    const handleOrderDelete = async () => {
        setOperationTimestamp();
        dispatch(setToggleExpandFooterFullScreen(false));

        dispatch(
            deleteOrderRequest({
                ...order,
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            })
        );
    };

    const handleRowClick = useCallback(
        symbolId => {
            dispatch(
                setActiveSymbol(
                    symbolId,
                    symbolsInfoById[symbolId].point,
                    isIncorrectUserGroupAsset ? order.symbol : null
                )
            );

            if (devicePlatform === DevicePlatform.Mobile) {
                dispatch(setSingleAssetViewActive());
            }
        },
        [devicePlatform, isIncorrectUserGroupAsset, dispatch]
    );

    const CellButton = useCallback(({ onClick, children }) => {
        return (
            <div className={styles.cell_btn} onClick={onClick}>
                {children}
            </div>
        );
    }, []);

    const CloseButton: React.FC<{ disabled?: boolean }> = useCallback(
        ({ disabled = false }) => {
            return (
                <div
                    className={clsx(styles.close_btn, {
                        [styles.disabled]: disabled,
                    })}
                    onClick={() => !disabled && handleOrderClose()}>
                    {t("close")}
                </div>
            );
        },
        [tradeOrderModal, tradeOrderModalParams]
    );

    const DeleteButton: React.FC = useCallback(() => {
        return (
            <div className={clsx(styles.delete_btn)} onClick={handleOrderDelete}>
                {t("delete")}
            </div>
        );
    }, [tradeOrderModal, tradeOrderModalParams]);

    const isBuy = order.type.toLowerCase().includes("buy");

    const formatCommand = type => {
        const addSpace = type.replace(/([A-Z])/g, " $1").trim();
        const replaceSpaceWithUnder = addSpace.split(" ").join("_");
        return replaceSpaceWithUnder.toLowerCase();
    };

    let profit = type === OrderTableType.PENDING_ORDERS ? order.profit : calcProfit;
    let swap = order.swap;

    if (!swap) {
        swap = order.ex.storage;
    }

    if (!profit) {
        profit = order.ex.profit;
    }

    const renderedSymbolId = (symbolInfo.isHidden
        ? symbolId
        : symbolInfo?.ex?.displayName || symbolId
    ).replace(/!$/, "");

    return (
        <>
            <div
                onClick={() => handleRowClick(symbolId)}
                className={clsx(styles.table_row, {
                    [styles.pending]: type === "pending",
                })}
                tabIndex={-1}
                key={order.ticket}>
                <div>#{order.ticket}</div>
                <div>
                    <div className={clsx(styles.table_item_cell_time_container)}>
                        <span className={clsx(styles.table_item_cell_date)}>
                            {moment(order.openTime).format("DD.MM.YYYY")}
                        </span>
                        <span className={clsx(styles.table_item_cell_time)}>
                            {palette.mode === "dark" ? (
                                <img src="images/clock.svg" alt="" />
                            ) : (
                                <img src="images/clock_light.svg" alt="" />
                            )}
                            {moment(order.openTime).format("HH:mm:ss")}
                        </span>
                    </div>
                </div>
                <div>{renderedSymbolId}</div>
                <div>
                    <span className={styles.table_item_cell_direction}>
                        {t(`actionsId.${formatCommand(order.type)}`)}
                        <img src={`images/${isBuy ? "buy" : "sell"}_triangle.svg`} alt="" />
                    </span>
                </div>
                <div>{order.lots}</div>
                <div>
                    <QuoteData
                        symbolId={symbolId}
                        render={({ ask, bid }) => {
                            const marketPrice = isPendingOrders
                                ? isBuy
                                    ? ask
                                    : bid
                                : isBuy
                                ? bid
                                : ask;

                            if (!marketPrice) {
                                return <span>---</span>;
                            }

                            const isCurrencyGroup = symbolInfo?.group?.name === "Currencies";

                            let diff = isBuy
                                ? marketPrice - order.openPrice
                                : order.openPrice - marketPrice;

                            if (isCurrencyGroup) {
                                diff = diff / 10;
                            }

                            const distanceInPips = diff / symbolInfo?.point;

                            return <span>{formatNumber(distanceInPips)}</span>;
                        }}
                    />
                </div>
                <div>
                    {normalyzeDecimalLength(
                        formatAmount(
                            Number(getFormattedPrice(order.openPrice, symbolInfo?.point)),
                            "",
                            symbolInfo?.digits
                        ),
                        symbolInfo?.digits
                    )}
                </div>
                <div>
                    <QuoteData
                        symbolId={symbolId}
                        priceChangeTTL={450}
                        render={({ ask, bid }) => {
                            /**
                             * For Pending Orders, the Current Price is the price that
                             * the order is about to open (Ask for Buy and Bid for Sell).
                             * For Open Trades it’s the price with which the order is
                             * about to close (Bid for Buy and Ask for Sell).
                             */
                            const marketPrice = isPendingOrders
                                ? isBuy
                                    ? ask
                                    : bid
                                : isBuy
                                ? bid
                                : ask;
                            let currentPriceValue = getFormattedPrice(marketPrice, symbolInfo?.point);

                            if (!currentPriceValue || currentPriceValue === "---") {
                                currentPriceValue = order?.closePrice.toString();
                            }

                            return (
                                <span>
                                    {normalyzeDecimalLength(
                                        formatAmount(Number(currentPriceValue), "", symbolInfo?.digits),
                                        symbolInfo?.digits
                                    )}
                                </span>
                            );
                        }}
                    />
                </div>
                <div>
                    <CellButton onClick={() => handleOrderEditClick(order)}>
                        <span>
                            {Number(order.stopLoss.toFixed(4)) === 0
                                ? t("sl")
                                : String(trimNumberToStepValue(order.stopLoss, symbolInfo.point))}
                        </span>
                    </CellButton>
                </div>
                <div>
                    <CellButton onClick={() => handleOrderEditClick(order)}>
                        <span>
                            {Number(order.takeProfit.toFixed(4)) === 0
                                ? t("tp")
                                : String(trimNumberToStepValue(order.takeProfit, symbolInfo.point))}
                        </span>
                    </CellButton>
                </div>
                <ImagePreloader
                    imagePaths={[
                        "/images/datepicker_icon_white.svg",
                        "/images/datepicker_icon_white2.svg",
                        "/images/datepicker_icon_black.svg",
                        "/images/clock_white.svg",
                        "/images/clock_light.svg",
                    ]}
                />
                {type === OrderTableType.PENDING_ORDERS && (
                    <div
                        className={styles.table_item_cell_expirationDate}
                        onClick={() => handleOrderEditClick(order)}
                        onMouseLeave={() => setExpDateColumnHover(false)}
                        onMouseOver={() => setExpDateColumnHover(true)}
                        onFocus={() => void 0}>
                        <div
                            className={clsx(styles.table_item_cell_time_container, {
                                [styles.hiddenDate]: moment().isSameOrAfter(moment(order.expiration)),
                            })}>
                            <span className={clsx(styles.table_item_cell_date)}>
                                {moment(order.expiration).format("DD.MM.YYYY")}
                            </span>
                            <span className={clsx(styles.table_item_cell_time)}>
                                <div
                                    className={clsx(styles.clockIcon, {
                                        [styles.expDateHover]: expDateColumnHover,
                                    })}></div>
                                {moment(order.expiration).format("HH:mm:ss")}
                            </span>
                        </div>
                        <div className={styles.calendarIcon} />
                    </div>
                )}
                <div>{formatAmount(swap, accountCurrency)}</div>
                <div>
                    <span
                        className={clsx({
                            [styles.profit]: profit > 0,
                            [styles.loss]: profit < 0,
                        })}>
                        {formatAmount(profit, accountCurrency)}
                    </span>
                </div>
                {/*  // REQUEST_PLACED */}
                <div>
                    {type === OrderTableType.PENDING_ORDERS ? (
                        <DeleteButton />
                    ) : (
                        <CloseButton disabled={closingTickets?.some(t => t === order?.ticket)} />
                    )}
                </div>
            </div>
        </>
    );
};
