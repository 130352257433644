import React from "react";
import clsx from "clsx";
import { Box, Modal, Slide, useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { calculateData } from "../../../../calculate/Calculate";

import styles from "./TradeCalculateInfoModal.module.scss";


interface IProps {
    onClose: () => void;
}

const TradeCalculateInfoModal: React.FC<IProps> = ({ onClose }) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const isDefaultTheme = palette.mode === "dark";

    return (
        <Modal open={true} onClose={onClose}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box
                    id="app-theme-root"
                    className={clsx(null, {
                        [styles.menu]: isDefaultTheme,
                        [styles.menu__light]: !isDefaultTheme,
                    })}>
                    <h1 className={styles.menu__title}>{t("how_to_calculate")}</h1>
                    <div className={styles.closeIcon} onClick={onClose} />
                    {calculateData.map(({ title, description }, id) => {
                        return (
                            <div
                                className={clsx(null, {
                                    [styles.menu__item]: isDefaultTheme,
                                    [styles.menu__item__light]: !isDefaultTheme,
                                })}
                                key={id}>
                                <span className={styles.menu__itemTitle}>{t(title)}</span>
                                <span
                                    className={clsx(null, {
                                        [styles.menu__itemDesc]: isDefaultTheme,
                                        [styles.menu__itemDesc__light]: !isDefaultTheme,
                                    })}>
                                    {t(description)}
                                </span>
                            </div>
                        );
                    })}
                </Box>
            </Slide>
        </Modal>
    );
};

export default React.memo(TradeCalculateInfoModal);
