import { RefObject, useCallback, useEffect, useMemo } from "react";

import { isMatchKeyboardEvent, KeyboardKeys } from "@/utils/hooks/keyboard/keyboardEvents";

interface Options {
    when?: boolean;
    eventTypes?: Array<string | number>;
    target?: RefObject<HTMLElement>;
}

const defaultOptions = {
    when: true,
    eventTypes: ["keydown"],
};

type RefFn = (e: KeyboardEvent) => any;

export function useKey(
    input: KeyboardKeys | number | Array<KeyboardKeys | number>,
    callbackRef: RefObject<RefFn>,
    opts: Options = {}
): void {
    const keyList: Array<KeyboardKeys | number> = useMemo(() => (Array.isArray(input) ? input : [input]), [input]);
    const { when, eventTypes, target } = useMemo<Options>(() => ({ ...defaultOptions, ...opts }), [opts]);

    const handle = useCallback(
        (e: KeyboardEvent) => {
            if (keyList.some(identifier => isMatchKeyboardEvent(e, identifier))) {
                callbackRef.current(e);
            }
        },
        [keyList]
    );

    useEffect(() => {
        if (!when || typeof window === "undefined") {
            return;
        }

        const targetNode = target?.current || window;

        eventTypes.forEach(eventType => targetNode?.addEventListener?.(`${eventType}`, handle));

        return () => {
            eventTypes.forEach(eventType => targetNode?.removeEventListener?.(`${eventType}`, handle));
        };
    }, [when, eventTypes, keyList]);
}
