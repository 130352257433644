import React, { useCallback, useState } from "react";

import parentFrameAPI from "@/services/parent";
import { WalletSection, WithdrawalInfoSection } from "./components";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";

export enum WalletPages {
    DEPOSIT = "Deposit",
    WITHDRAWAL = "Withdrawal"
};

const WalletSectionMobile: React.FC = () => {
    const [displayWithdrawalInfo, setDisplayWithdrawalInfo] = useState(false);
    const { isLoggedInUser } = useLoginInfo();
    const openWithdrawalSection = () => setDisplayWithdrawalInfo(true);
    const closeWithdrawalSection = () => setDisplayWithdrawalInfo(false);

    const openCard = useCallback((cardType: WalletPages) => {
        return () => {
            if (isLoggedInUser) {
                if (cardType === WalletPages.DEPOSIT) {
                    parentFrameAPI.deposit();
                }

                if (cardType === WalletPages.WITHDRAWAL) {
                    openWithdrawalSection();
                }
            } else {
                parentFrameAPI.login();
            }
        };
    }, [isLoggedInUser]);

    return (
        <>
            {displayWithdrawalInfo ?
                <WithdrawalInfoSection onClose={closeWithdrawalSection} /> :
                <WalletSection openCard={openCard} />}
        </>
    );
};

export default WalletSectionMobile;
