import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { setIsAssetsTableExpanded } from "@/redux/actions/ui";
import { useUI } from "@/redux/selectors/uiSelector";

import styles from "./NoResultsComponent.module.scss";

interface IProps {
    title?: string;
    subtitle?: string;
    className?: string;
}

const NoResultsComponent = ({ title, subtitle, className }: IProps) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { isAssetsTableExpanded, isAssetsTabActive } = useUI();

    useEffect(() => {
        if (isAssetsTabActive && isAssetsTableExpanded) {
            dispatch(setIsAssetsTableExpanded(false));
        }
    }, []);

    return (
        <div className={clsx(styles.component, className)}>
            <div className={styles.search_not_found_icon}></div>
            <div className={styles.title}>{title || t("no_results")}</div>
            <div className={styles.subtitle}>{subtitle || t("no_results_text")}</div>
        </div>
    );
};

export default React.memo(NoResultsComponent);
