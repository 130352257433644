import React, { useCallback, useState } from "react";
import { useTranslation } from "next-i18next";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import moment from "moment";
import SelectCustomRange from "@/components/Core/DateRangePickerMobile/SelectCustomRange";
import { getDateFromPeriod, PeriodTypes } from "@/components/Core/DateRangePickerMobile/helpers";
import Slide from "@mui/material/Slide";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

import styles from "./DateRangePickerMobile.module.scss";

const MENU_TYPE = {
    RANGE_TYPE_SELECT: "RANGE_TYPE_SELECT",
    CALENDAR: "CALENDAR",
};

const getTranslationFromType = (type: string, t: any) => {
    switch (type) {
        case PeriodTypes.TODAY:
            return t("dateRange.today");
        case PeriodTypes.LAST_3_DAYS:
            return t("dateRange.threedays");
        case PeriodTypes.LAST_WEEK:
            return t("dateRange.last_week");
        case PeriodTypes.LAST_MONTH:
            return t("dateRange.last_month");
        case PeriodTypes.LAST_3_MONTHS:
            return t("dateRange.threemonths");
        case PeriodTypes.ALL_PERIOD:
            return t("dateRange.all");
        case PeriodTypes.DATE_RANGE:
            return t("dateRange.date_range");
        default:
            return "";
    }
};

interface IProps {
    from?: Date;
    to?: Date;
    maxDate?: Date;
    onSubmit: (range: { from: Date; to: Date }) => void;
    onRangeType?: (rangeType) => void;
}

export const DateRangePickerMobile: React.FC<IProps> = ({
    from,
    to,
    maxDate,
    onSubmit,
    onRangeType = () => {},
}) => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const { platform } = useDevice();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [rangeType, setRangeType] = useState(PeriodTypes.LAST_3_MONTHS);
    const [menuType, setMenuType] = useState(MENU_TYPE.RANGE_TYPE_SELECT);
    const [range, setRange] = useState({
        from: from,
        to: to,
    });

    const handleSelectType = newRangeType => {
        return () => {
            switch (newRangeType) {
                case PeriodTypes.TODAY:
                    handleSetRange({
                        from: moment(getDateFromPeriod(newRangeType)).startOf("day"),
                        to: new Date(),
                    });
                    break;
                case PeriodTypes.LAST_3_DAYS:
                case PeriodTypes.LAST_WEEK:
                case PeriodTypes.LAST_MONTH:
                case PeriodTypes.LAST_3_MONTHS:
                case PeriodTypes.ALL_PERIOD:
                    handleSetRange({
                        from: getDateFromPeriod(newRangeType),
                        to: new Date(),
                    });
                    break;
                case PeriodTypes.DATE_RANGE:
                    setMenuType(MENU_TYPE.CALENDAR);
                    break;
                default:
                    break;
            }

            setRangeType(newRangeType);
            onRangeType(newRangeType);
        };
    };

    const handleSetRange = useCallback(
        ({ from, to }) => {
            setMenuOpen(false);
            setMenuType(MENU_TYPE.RANGE_TYPE_SELECT);
            setRange({ from, to });
            onSubmit({ from, to });
        },
        [onSubmit]
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.typeView}
                onClick={() => {
                    setMenuOpen(true);
                    setMenuType(MENU_TYPE.RANGE_TYPE_SELECT);
                }}>
                {getTranslationFromType(rangeType, t)}
            </div>

            {rangeType === PeriodTypes.DATE_RANGE && range.from && range.to && (
                <div
                    className={styles.rangeView}
                    onClick={() => {
                        setMenuOpen(true);
                        setMenuType(MENU_TYPE.CALENDAR);
                    }}>
                    <div className={styles.calendarIcon} />
                    <div className={styles.datesRange}>
                        <span>{moment(range.from).format("DD.MM.YYYY")}</span>
                        <span>-</span>
                        <span>{moment(range.to).format("DD.MM.YYYY")}</span>
                    </div>
                </div>
            )}
            <Modal open={isMenuOpen} onClose={() => setMenuOpen(false)}>
                <Slide direction="up" in={isMenuOpen} mountOnEnter unmountOnExit>
                    <Box
                        id="app-theme-root"
                        className={clsx(styles.menu, `theme--${theme?.palette?.mode}`, {
                            [styles.menu_tablet]: platform === DevicePlatform.Tablet,
                        })}>
                        {menuType === MENU_TYPE.RANGE_TYPE_SELECT && (
                            <div className={styles.rangeSelectContainer}>
                                <div className={styles.closeIcon} onClick={() => setMenuOpen(false)} />

                                <div className={styles.rangeSelectContainer__header}>
                                    {t("dateRange.select_range")}
                                </div>

                                {Object.keys(PeriodTypes).map(key => {
                                    return (
                                        <div
                                            key={key}
                                            onClick={handleSelectType(PeriodTypes[key])}
                                            className={clsx(styles.rangeSelectContainer__typeItem, {
                                                [styles.active]: PeriodTypes[key] === rangeType,
                                            })}>
                                            {getTranslationFromType(PeriodTypes[key], t)}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        {menuType === MENU_TYPE.CALENDAR && (
                            <SelectCustomRange
                                from={range.from}
                                to={range.to}
                                maxDate={maxDate}
                                onChange={handleSetRange}
                                onCancel={() => setMenuOpen(false)}
                            />
                        )}
                    </Box>
                </Slide>
            </Modal>
        </div>
    );
};
