import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useTheme } from "@mui/material";

import NumberInput from "@/components/Core/Form/NumberInput/NumberInput";
import TextInput from "@/components/Core/Form/TextInput/TextInput";
import Checkbox from "@/components/Core/Form/Checkbox/Checkbox";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { formatAmount } from "@/utils/format";
import { useEditOrder } from "@/hooks/trade/useEditOrder";
import { useUI } from "@/redux/selectors/uiSelector";
import { ITradeOrder } from "@/services/trade/order";
import checkboxStyles from "@/components/Core/Form/Checkbox/Checkbox.module.scss";
import useTradeChart, { TradeChartType } from "@/hooks/chart";
import { ISymbolInfo } from "@/services/trade/symbols";
import { Loader } from "@/components/Home/TradingAssetSectionMobile/components";
import PendingOrder from "@/components/Home/TradingOrderSection/PendingOrder/PendingOrder";
import { isPendingOrder } from "@/utils/trade";

import styles from "../InfoPopup.module.scss";

interface IChartContainerProps {
    symbolInfo: ISymbolInfo;
    isStopLoss: boolean;
    stopLossPrice: number;
    stopLossPriceDisplayValue: number;
    isTakeProfit: boolean;
    takeProfitPrice: number;
    takeProfitPriceDisplayValue: number;
}

const ChartContainerEl = (props: IChartContainerProps) => {
    const {
        symbolInfo,
        isStopLoss,
        stopLossPrice,
        stopLossPriceDisplayValue,
        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
    } = props;

    const [loaded, setLoaded] = useState<boolean>(false);

    const {
        addStopLossLine,
        removeStopLossLine,
        addTakeProfitLine,
        removeTakeProfitLine,
    } = useTradeChart({
        // NOTE: triggering any re-render of the container causes chart canvas bugs
        showLoader: () => {
            setLoaded(false);
        },
        hideLoader: () => {
            setLoaded(true);
        },
        chartType: TradeChartType.EditOrder,
        symbolInfo,
        isChartLoaded: () => {},
    });

    useEffect(() => {
        if (isStopLoss && stopLossPrice !== null) {
            addStopLossLine(stopLossPriceDisplayValue);
        } else {
            removeStopLossLine();
        }
    }, [isStopLoss, stopLossPrice, stopLossPriceDisplayValue, addStopLossLine, removeStopLossLine]);

    useEffect(() => {
        if (isTakeProfit && takeProfitPrice !== null) {
            addTakeProfitLine(takeProfitPriceDisplayValue);
        } else {
            removeTakeProfitLine();
        }
    }, [
        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        addTakeProfitLine,
        removeTakeProfitLine,
    ]);

    return (
        <>
            <div className={clsx(styles.chart_container)}>
                {!loaded && (
                    <div className={clsx(styles.chart)}>
                        <Loader />
                    </div>
                )}
                <div
                    id={"trading_chart_edit_trade"}
                    className={clsx(styles.chart, !loaded ? styles.chart__block : undefined)}
                />
            </div>
        </>
    );
};

const ChartContainer = React.memo(ChartContainerEl);

interface IProps {
    order: ITradeOrder;
}

const PopupFormRaw = ({ order }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const { isOrderModifyPopupRequestPending } = useUI();
    const themeMode = theme.palette.mode;

    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const tradeOrderContext = useContext(TradeOrderStateContext);

    const {
        handleModifyOrderSubmit,
        handleStopLossToggle,
        handleTakeProfitToggle,
        handleStopLossPipsChange,
        handleTakeProfitPipsChange,
        handleStopLossPriceChange,
        handleTakeProfitPriceChange,

        symbolInfo,
        accountCurrency,
        isForex,
        isValidOrder,

        isStopLoss,
        stopLossPriceFormInputValue,
        stopLossPipsFormInputValue,
        stopLossPrice,
        stopLossPriceDisplayValue,
        stopLossValue,
        stopLossPLValue,

        isTakeProfit,
        takeProfitPriceFormInputValue,
        takeProfitPipsFormInputValue,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        takeProfitValue,
        takeProfitPLValue,
    } = useEditOrder({
        order,
        tradeOrderDispatch,
        tradeOrder: tradeOrderContext,
    });

    const initialLossProfit = useRef({
        isSL: isStopLoss,
        isTP: isTakeProfit,
        sl: stopLossPriceFormInputValue,
        tp: takeProfitPriceFormInputValue,
        expiration: order.expiration,
    });

    const stopLossDisabled = !isStopLoss || isOrderModifyPopupRequestPending;
    const takeProfitDisabled = !isTakeProfit || isOrderModifyPopupRequestPending;

    const isExpirationChanged =
        (initialLossProfit.current.expiration === "1970-01-01T00:00:00.000Z" &&
            tradeOrderContext.pendingOrderExpiration) ||
        (initialLossProfit.current.expiration !== "1970-01-01T00:00:00.000Z" &&
            initialLossProfit.current.expiration !== tradeOrderContext.pendingOrderExpiration);

    const isSubmitDisabled =
        isOrderModifyPopupRequestPending ||
        !isValidOrder ||
        (!initialLossProfit.current.sl &&
            !isStopLoss &&
            !initialLossProfit.current.tp &&
            !isTakeProfit &&
            (isPendingOrder(order) ? !isExpirationChanged : true)) ||
        (initialLossProfit.current.sl === stopLossPriceFormInputValue &&
            initialLossProfit.current.tp === takeProfitPriceFormInputValue &&
            (isPendingOrder(order) ? !isExpirationChanged : true));

    return (
        <div className={styles.content_wrp}>
            <ChartContainer
                symbolInfo={symbolInfo}
                isStopLoss={isStopLoss}
                stopLossPrice={stopLossPrice}
                stopLossPriceDisplayValue={stopLossPriceDisplayValue}
                isTakeProfit={isTakeProfit}
                takeProfitPrice={takeProfitPrice}
                takeProfitPriceDisplayValue={takeProfitPriceDisplayValue}
            />
            {isPendingOrder(order) && <PendingOrder order={order} />}
            <div className={styles.form_container}>
                <form action="" noValidate={true}>
                    <div className={styles[`checkbox_row_${themeMode}`]}>
                        <Checkbox
                            inputId="stop_loss_toggle"
                            checked={isStopLoss}
                            onChange={handleStopLossToggle}
                            disabled={isOrderModifyPopupRequestPending}
                            classes={themeMode === "dark" ? "" : checkboxStyles.checkboxDark}
                        />
                        <label htmlFor="stop_loss_toggle">{t("set_stop_loss")}</label>
                        <div />
                        {/* needed for grid */}
                        <Checkbox
                            inputId="take_profit_toggle"
                            checked={isTakeProfit}
                            onChange={handleTakeProfitToggle}
                            disabled={isOrderModifyPopupRequestPending}
                            classes={themeMode === "dark" ? "" : checkboxStyles.checkboxDark}
                        />
                        <label htmlFor="take_profit_toggle">{t("set_take_profit")}</label>
                    </div>

                    <div className={styles.setter_column}>
                        <div className={styles.setter_row}>
                            <div className={styles.pipsForm}>
                                <NumberInput
                                    value={stopLossPipsFormInputValue}
                                    step={1}
                                    onChange={handleStopLossPipsChange}
                                    disabled={stopLossDisabled}
                                    allowNull
                                    fullWidth
                                    className={
                                        !stopLossDisabled
                                            ? styles[`pips_margin_active_${themeMode}`]
                                            : styles[`margin_disabled_${themeMode}`]
                                    }
                                />
                                {t("value")}
                                <TextInput
                                    value={
                                        isStopLoss && stopLossPriceFormInputValue !== null
                                            ? formatAmount(stopLossValue, accountCurrency)
                                            : ""
                                    }
                                    classes={{ input: styles[`pipsAmountTextInput_${themeMode}`] }}
                                    fullWidth
                                    disabled={true} // it will be removed later
                                />
                            </div>
                            <div className={styles[`row_text_${themeMode}`]}>
                                {t(isForex ? "pips" : "points")}
                            </div>
                            <div className={styles.pipsForm}>
                                <NumberInput
                                    value={takeProfitPipsFormInputValue}
                                    step={1}
                                    onChange={handleTakeProfitPipsChange}
                                    disabled={takeProfitDisabled}
                                    allowNull
                                    fullWidth
                                    className={
                                        !takeProfitDisabled
                                            ? styles[`pips_margin_active_${themeMode}`]
                                            : styles[`margin_disabled_${themeMode}`]
                                    }
                                />
                                {t("value")}
                                <TextInput
                                    value={
                                        isTakeProfit && takeProfitPriceFormInputValue !== null
                                            ? formatAmount(takeProfitValue, accountCurrency)
                                            : ""
                                    }
                                    classes={{ input: styles[`pipsAmountTextInput_${themeMode}`] }}
                                    className={styles.amountValue}
                                    fullWidth
                                    disabled={true} // it will be removed later
                                />
                            </div>
                        </div>
                        <div className={styles.setter_row}>
                            <NumberInput
                                value={stopLossPriceFormInputValue}
                                step={!stopLossDisabled ? symbolInfo?.point : 1}
                                onChange={handleStopLossPriceChange}
                                disabled={stopLossDisabled}
                                allowNull
                                fullWidth
                                className={
                                    !stopLossDisabled
                                        ? styles[`margin_active_${themeMode}`]
                                        : styles[`margin_disabled_${themeMode}`]
                                }
                            />
                            <div className={styles[`row_text_${themeMode}`]}>{t("price")}</div>
                            <NumberInput
                                value={takeProfitPriceFormInputValue}
                                step={!takeProfitDisabled ? symbolInfo?.point : 1}
                                onChange={handleTakeProfitPriceChange}
                                disabled={takeProfitDisabled}
                                allowNull
                                fullWidth
                                className={
                                    !takeProfitDisabled
                                        ? styles[`margin_active_${themeMode}`]
                                        : styles[`margin_disabled_${themeMode}`]
                                }
                            />
                        </div>
                        <div className={styles.setter_row}>
                            <TextInput
                                value={
                                    !stopLossDisabled && stopLossPriceFormInputValue !== null
                                        ? formatAmount(stopLossPLValue, accountCurrency)
                                        : ""
                                }
                                classes={{ input: styles[`plTextInput_${themeMode}`] }}
                                fullWidth
                                disabled
                            />
                            <div className={styles[`row_text_${themeMode}`]}>{t("pnlvalue")}</div>
                            <TextInput
                                value={
                                    !takeProfitDisabled && takeProfitPriceFormInputValue !== null
                                        ? formatAmount(takeProfitPLValue, accountCurrency)
                                        : ""
                                }
                                classes={{ input: styles[`plTextInput_${themeMode}`] }}
                                fullWidth
                                disabled
                            />
                        </div>
                    </div>
                    <div className={styles.container_submit_btn}>
                        <div
                            className={clsx(styles[`submit_btn_${themeMode}`], {
                                [styles.submit_disabled]: isSubmitDisabled,
                            })}
                            onClick={handleModifyOrderSubmit}>
                            {t("submit_changes")}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export const PopupForm = React.memo(PopupFormRaw);
