import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import {
    setIsAssetsTableExpanded,
    setIsFirstRenderAnimationFinishedChartReady,
} from "@/redux/actions/ui";
import { setSearch } from "redux/actions/tradeInfo";
import Search from "@/components/Search";
import TradingAssets from "./TradingAssets";
import ImagePreloader from "@/components/Core/ImagePreloader/ImagePreloader";
import { getIconPath } from "@/utils/helpers";

import styles from "./TradingAssetsSection.module.scss";

const TradingAssetsSection: React.FC = () => {
    const dispatch = useDispatch();

    const { isQuoteDataLoaded } = useTradeInfo();
    const { isAssetsTableExpanded, isExpandButtonLocked } = useUI();
    const { search } = useTradeInfo();
    const introStep = useIntroStep();
    const [isSearching, setIsSearching] = useState(false);
    const ANIMATION_DURATION_IN_MS = 600;
    const isDisabled = introStep === 2;

    useEffect(() => {
        setIsSearching(!!search);
    }, [search]);

    useEffect(() => {
        setTimeout(
            () => dispatch(setIsFirstRenderAnimationFinishedChartReady(true)),
            ANIMATION_DURATION_IN_MS
        );
    }, [dispatch]);

    const toggleExpand = () => dispatch(setIsAssetsTableExpanded(!isAssetsTableExpanded));

    const handleChangeSearch = value => {
        dispatch(setSearch(value));
    };

    return (
        <>
            <ImagePreloader
                imagePaths={[
                    getIconPath({ name: "maximize" }),
                    getIconPath({ name: "maximize-hover" }),
                    getIconPath({ name: "maximize-grey" }),
                ]}
            />
            <div
                className={clsx(styles.container, {
                    [styles.expanded]: !isDisabled && isAssetsTableExpanded,
                    [styles.overlayed]: isDisabled,
                })}>
                <div className={styles.toolbar}>
                    <Search
                        value={search}
                        onChange={handleChangeSearch}
                        isDisabled={!isQuoteDataLoaded}
                    />
                    <span
                        className={clsx(styles.expandButton, {
                            [styles.expandButton_disabled]: isExpandButtonLocked,
                        })}
                        onClick={!isExpandButtonLocked ? toggleExpand : () => {}}
                    />
                </div>

                <TradingAssets isSearching={isSearching} />
            </div>
        </>
    );
};

export default React.memo(TradingAssetsSection);
