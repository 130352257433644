import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import ReactPaginate from "react-paginate";
import { useTheme } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ITradeOrder } from "@/services/trade/order";
import { formatAmount } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useTradeColumnSorter } from "@/hooks/trade/useTradeColumnSorter";
import { OrderTableType } from "../TradesTable";
import { getDirectionsTranslationKey, getIconPath } from "@/utils/helpers";
import { formatDate } from "@/utils/dateTime";
import { TRANSACTIONS_TYPES } from "@/constants/index";
import { TableCellPopup } from "./PopupCell";

import styles from "./TradeHistory.module.scss";

const headers = [
    "dealId",
    "openTime",
    "asset",
    "action",
    "amount",
    "openPrice",
    "closedPrice",
    "closeTime",
    "stopLoss",
    "takeProfit",
    "swap",
    "profit",
];

const itemsPerPage = 10;

interface IProps {
    historyData: ITradeOrder[];
}

const TradeHistoryTable: React.FC<IProps> = ({ historyData }) => {
    const { t } = useTranslation("common");
    const [currentItems, setCurrentItems] = useState<ITradeOrder[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const { currency: accountCurrency } = useAccountInfo();
    const { sortedOrders, setSortColumn } = useTradeColumnSorter(historyData, OrderTableType.CLOSED);
    const urlParams = new URLSearchParams(window?.location?.search);
    const withV5 = urlParams.get("v5") === "1";
    const columns = withV5 ? ["positionId", ...headers] : headers;

    const profitLossDisplayValue = useMemo(
        () =>
            historyData.reduce((acc, order) => {
                if (["Balance", "Credit"].includes(order.type)) {
                    return acc;
                }

                return acc + order.profit + order.swap;
            }, 0),
        [historyData]
    );

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;

        setCurrentItems(sortedOrders.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(sortedOrders.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, sortedOrders, historyData]);

    const handlePageClick = useCallback(
        event => {
            const newOffset = (event.selected * itemsPerPage) % historyData.length;

            setItemOffset(newOffset);
        },
        [historyData.length]
    );

    return (
        <div className={styles.table_wrap}>
            <div className={styles.table_header}>
                <div
                    className={clsx(styles.table_header_row, {
                        [styles.withV5]: withV5,
                    })}>
                    {columns.map((header, i) => {
                        const translatedLabel = t(`${header}`);

                        return (
                            <div key={`${header}-${i}`} className={styles.table_header_cell}>
                                <span
                                    className={styles.header_label}
                                    onClick={() => setSortColumn(header)}>
                                    {translatedLabel}
                                </span>
                                {header === "profit" && (
                                    <div
                                        className={clsx(styles.header_profit_calc, {
                                            [styles.header_profit_calc_loss]: profitLossDisplayValue < 0,
                                            [styles.header_profit_calc_profit]:
                                                profitLossDisplayValue > 0,
                                        })}>
                                        {t("pl")}:&nbsp;
                                        {formatAmount(profitLossDisplayValue, accountCurrency)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.table_container}>
                <PerfectScrollbar>
                    <div className={styles.table_body}>
                        {currentItems.map(trade => (
                            <TradeHistoryTableRow trade={trade} key={trade.ticket} />
                        ))}
                    </div>
                </PerfectScrollbar>
            </div>

            {historyData?.length > 10 && (
                <div className={styles.pagination_wrapper}>
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        nextLabel=""
                        previousLabel=""
                        renderOnZeroPageCount={null}
                        className={styles.pagination_container}
                        pageClassName={styles.pagination_button}
                        pageLinkClassName={styles.pagination_button_text}
                        activeClassName={styles.pagination_button_active}
                        activeLinkClassName={styles.pagination_button_text_active}
                        previousClassName={styles.pagination_previous}
                        nextClassName={styles.pagination_next}
                        previousLinkClassName={styles.pagination_link}
                        nextLinkClassName={styles.pagination_link}
                    />
                </div>
            )}
        </div>
    );
};

const TradeHistoryTableRow = ({ trade }: { trade: ITradeOrder }) => {
    const { t } = useTranslation("common");
    const { symbolsInfoById } = useTradeInfo();
    const { currency: accountCurrency } = useAccountInfo();
    const { palette } = useTheme();
    const symbolInfo = symbolsInfoById[trade.symbol];
    const isBalanceOperation = ["credit", "balance", "deposit", "regular_deposit"].includes(
        trade.type.toLowerCase()
    );
    const urlParams = new URLSearchParams(window.location.search);
    const withV5 = urlParams.get("v5") === "1";
    const comment = trade?.ex?.comment;

    const getCommentValue = () => {
        if (isBalanceOperation && comment) {
            const normalizedCommentKey = comment.toLowerCase().replace(/ /g, "_");
            return TRANSACTIONS_TYPES[normalizedCommentKey] || comment;
        }

        return t(getDirectionsTranslationKey(trade.type)) || "";
    };

    return (
        <div
            className={clsx(styles.table_body_row, {
                [styles.withV5]: withV5,
            })}
            tabIndex={-1}
            key={trade.ticket}>
            {withV5 && <div className={styles.table_body_cell}>#{trade.positionId}</div>}
            <div className={styles.table_body_cell}>#{trade.ticket}</div>
            <div className={styles.table_body_cell}>
                <div className={clsx(styles.table_item_cell_time_container)}>
                    <span className={styles.table_item_cell_date}>
                        {formatDate(trade.openTime, "DD.MM.YYYY")}
                    </span>
                    <span className={styles.table_item_cell_time}>
                        <img src={getIconPath({ name: "clock", themeMode: palette.mode })} alt="clock" />
                        {formatDate(trade.openTime, "HH:mm:ss")}
                    </span>
                </div>
            </div>
            <div className={styles.table_body_cell}>{symbolInfo?.ex?.displayName || trade.symbol}</div>
            <TableCellPopup text={getCommentValue()} />
            <div className={styles.table_body_cell}>{isBalanceOperation ? "" : trade.lots}</div>
            <div className={styles.table_body_cell}>{isBalanceOperation ? "" : trade.openPrice}</div>
            <div className={styles.table_body_cell}>{isBalanceOperation ? "" : trade.closePrice}</div>
            <div className={styles.table_body_cell}>
                <div className={styles.table_item_cell_time_container}>
                    {!isBalanceOperation && (
                        <>
                            <span className={styles.table_item_cell_date}>
                                {formatDate(trade.closeTime, "DD.MM.YYYY")}
                            </span>
                            <span className={styles.table_item_cell_time}>
                                <img
                                    src={getIconPath({ name: "clock", themeMode: palette.mode })}
                                    alt="clock"
                                />
                                {formatDate(trade.closeTime, "HH:mm:ss")}
                            </span>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.table_body_cell}>
                {isBalanceOperation ? null : trade.stopLoss > 0 ? trade.stopLoss : "N/A"}
            </div>
            <div className={styles.table_body_cell}>
                {isBalanceOperation ? null : trade.takeProfit > 0 ? trade.takeProfit : "N/A"}
            </div>
            <div className={clsx(styles.table_body_cell)}>
                {trade?.swap ? formatAmount(trade.swap, accountCurrency) : ""}
            </div>
            <div className={styles.table_body_cell}>{formatAmount(trade.profit, accountCurrency)}</div>
        </div>
    );
};

export default React.memo(TradeHistoryTable);
