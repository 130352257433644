import {  useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AssetPageTabs } from "./constants";
import { setFavoritesAlertMessage } from "@/redux/actions/systemFeedback";
import { useSystemFeedback } from "@/redux/selectors/systemFeedbackSelector";

interface IActionTabsState {
    isShowSentiments: boolean,
    isShowCalculateInfo: boolean,
    isShowInfo: boolean,
    isShowCandlesticks: boolean,
    isShowSetLotSize: boolean,
    isShowSetLossProfit: boolean,
    isShowTimeFrames: boolean,
    activeTab: AssetPageTabs,
    prevTab: AssetPageTabs,

    onChangeTab: (AssetPageTabs) => void,
    onCloseTab: (AssetPageTabs) => void,
    onChangeActiveTab: (AssetPageTabs) => void,
    onChangePrevTab: (AssetPageTabs) => void,
}

export const useMobileActionTabsState = (): IActionTabsState => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(null);
    const [prevTab, setPrevTab] = useState(null);
    const { favoritesAlerts } = useSystemFeedback();

    const [isShowSentiments, setShowSentiments] = useState(false);
    const [isShowCalculateInfo, setShowCalculateInfo] = useState(false);
    const [isShowInfo, setShowInfo] = useState(false);
    const [isShowCandlesticks, setShowCandlesticks] = useState(false);
    const [isShowSetLotSize, setShowSetLotSize] = useState(false);
    const [isShowSetLossProfit, setShowSetLossProfit] = useState(false);
    const [isShowTimeFrames, setShowTimeFrames] = useState(false);

    useEffect(() => {
        if (favoritesAlerts.length && activeTab) {
            dispatch(setFavoritesAlertMessage(null));
        }
    }, [activeTab]);

    function onChangeTab(tabId: AssetPageTabs) {
        if (tabId !== activeTab) {
            setPrevTab(activeTab);
        }
        setActiveTab(tabId !== activeTab ? tabId : null);

        switch (tabId) {
            case AssetPageTabs.SET_LOT:
                return setShowSetLotSize(!isShowSetLotSize);
            case AssetPageTabs.SET_CALCULATOR:
                return setShowCalculateInfo(!isShowCalculateInfo);
            case AssetPageTabs.SET_SENTIMENTS:
                return setShowSentiments(!isShowSentiments);
            case AssetPageTabs.SET_LOSS_PROFIT:
                return setShowSetLossProfit(!isShowSetLossProfit);
            case AssetPageTabs.SET_INFO:
                return setShowInfo(!isShowInfo);
            case AssetPageTabs.SET_TIME_FRAMES:
                return setShowTimeFrames(!isShowTimeFrames);
            case AssetPageTabs.CANDLESTICKS:
                return setShowCandlesticks(!isShowCandlesticks);
            default:
                return null;
        }
    };

    function onChangeActiveTab(tabId: AssetPageTabs) {
        setActiveTab(tabId);
    }

    function onChangePrevTab(tabId: AssetPageTabs) {
        setPrevTab(tabId);
    }

    function onCloseTab(tabId: AssetPageTabs) {
        switch (tabId) {
            case AssetPageTabs.SET_LOT:
                setShowSetLotSize(false);
                break;
            case AssetPageTabs.SET_CALCULATOR:
                setShowCalculateInfo(false);
                break;
            case AssetPageTabs.SET_SENTIMENTS:
                setShowSentiments(false);
                break;
            case AssetPageTabs.SET_LOSS_PROFIT:
                setShowSetLossProfit(false);
                break;
            case AssetPageTabs.SET_INFO:
                setShowInfo(false);
                break;
            case AssetPageTabs.SET_TIME_FRAMES:
                setShowTimeFrames(false);
                break;
            case AssetPageTabs.CANDLESTICKS:
                setShowCandlesticks(false);
                break;
            default:
                break;
        }
        setActiveTab(null);
    }

    return {
        isShowSentiments,
        isShowCalculateInfo,
        isShowInfo,
        isShowCandlesticks,
        isShowSetLotSize,
        isShowSetLossProfit,
        isShowTimeFrames,

        activeTab,
        prevTab,

        onChangeTab,
        onCloseTab,
        onChangeActiveTab,
        onChangePrevTab,
    };
};

interface IHightLightButtons {
    isHighlightTradeButtons: boolean;
    setIsHighlightTradeButtons: (value: boolean) => void;
}

export const useHighLightButtons = (): IHightLightButtons => {
    const [isHighlightTradeButtons, setIsHighlightTradeButtons] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsHighlightTradeButtons(false);
        }, 300);
    }, [isHighlightTradeButtons]);

    return {
        isHighlightTradeButtons,
        setIsHighlightTradeButtons,
    };
};
