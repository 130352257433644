import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setIsOpenTradesOpen, setIsPendingOrdersOpen } from "@/redux/actions/ui";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";

import { OrderTableType, TradesTable } from "../TradesTable";

export const DataTables = (): JSX.Element => {
    const dispatch = useDispatch();
    const { isFooterExpanded, isOpenTradesOpen, isPendingOrdersOpen } = useUI();
    const { openTrades } = useTradeOrders();
    const introStep = useIntroStep();
    const isOpenTradesIntro = introStep === 5;

    useEffect(() => {
        if (openTrades.length && isOpenTradesIntro) {
            dispatch(setIsPendingOrdersOpen(false));
            dispatch(setIsOpenTradesOpen(true));
        }
    }, [dispatch, openTrades.length, isOpenTradesIntro]);

    if (!isFooterExpanded) {
        return null;
    }

    return (
        <>
            {isOpenTradesOpen && (
                <TradesTable type={OrderTableType.OPEN_TRADES} />
            )}
            {isPendingOrdersOpen && (
                <TradesTable type={OrderTableType.PENDING_ORDERS} />
            )}
        </>
    );
};
