import clsx from "clsx";
import parentFrameAPI from "@/services/parent";
import { useTranslation } from "next-i18next";
import styles from "./styles.module.scss";

export const LockedView = () => {
    const login = () => parentFrameAPI.login();
    const signup = () => parentFrameAPI.signup();
    const { t } = useTranslation("common");

    return (
        <div className={clsx(styles.popup_wrapper, styles.scrollBar_override)}>
            <div className={styles.lockedWrapper}>
                <div className={styles.lockedContent}>
                    <div className={styles.lockIcon}></div>
                    <div className={styles.lockTitle}>{t("market_alerts_platform_popup")}</div>
                    <div className={styles.lockSubtitle}>
                        {t("market_alerts_available_for_registrated_users_only")}
                    </div>
                    <div className={styles.lockButtonsContainer}>
                        <button onClick={signup} className={clsx(styles.lockButton, styles.signup)}>
                            {t("sign_up")}
                        </button>
                        <button onClick={login} className={clsx(styles.lockButton, styles.login)}>
                            {t("login")}
                        </button>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.dummyBackground, styles.blured)}></div>
        </div>
    );
};
