import React, { useRef } from "react";
import { useTranslation } from "next-i18next";
import { getBrandName } from "@/utils/helpers";
import { useKey } from "@/utils/hooks/keyboard/useKey";
import { KeyboardKeys } from "@/utils/hooks/keyboard/keyboardEvents";

import styles from "./FirstStep.module.scss";

interface IProps {
    moveForward: (value: number) => void;
    closeModal: () => void;
    children: JSX.Element;
}

export const FirstStep: React.FC<IProps> = ({ moveForward, closeModal, children }) => {
    const { t } = useTranslation("common");

    const moveForwardRef = useRef(() => moveForward(1));
    useKey([KeyboardKeys.Enter], moveForwardRef);

    return (
        <div className={styles.container}>
            <div onClick={closeModal} className={styles.close}>
                <div className={styles.closeIcon}></div>
            </div>
            <div className={styles.title}>
                {t("guidance.step01.title").replace("${brandName}", getBrandName())}
            </div>
            <div className={styles.subtitle}>
                {t("guidance.step01.content")}
            </div>
            <button className={styles.button} onClick={() => moveForward(1)}>
                {t("guidance.step01.button")}
            </button>
            <div onClick={closeModal} className={styles.hint}>
                {t("guidance.no_thanx")}
            </div>
            {children}
        </div>
    );
};
