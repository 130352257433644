import React from 'react';
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import PaymentSystemsView from "@/components/SystemFeedbackMobile/common/PaymentSystemsView";
import { useTouchState } from "@/hooks/common/useTouchState";
import AudioPlayer, { AudioType } from '@/components/AudioPlayer';

import styles from "./SystemFeedback.module.scss";

interface IProps {
    onClose: () => void;
    onDeposit: () => void;
    playAudioEffect?: boolean;
}

const MarginCallFeedback = ({ onClose, onDeposit, playAudioEffect = true }: IProps): JSX.Element => {
    const { isTouched, onTouchStart, onTouchEnd } = useTouchState();
    const { t } = useTranslation("common");
    const msg = t("marginCallMessage1");
    return (
        <>
            {playAudioEffect  && <AudioPlayer type={AudioType.ERROR} />}
            <div className={clsx(styles.container, styles.marginCall)}>
                <div className={styles.closeIcon} onClick={onClose} />
                <div className={clsx(styles.icon, styles.dangerIcon)} />

                <div className={styles.title}>{t("marginCall")}</div>

                <div className={styles.description}>
                    <span className={styles.attentionText}>{t("attention")}&nbsp;</span>
                    {msg}
                </div>

                <PaymentSystemsView />

                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.touched]: isTouched
                        })}
                        onClick={onDeposit}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                    >
                        {t("deposit")}
                    </button>
                </div>
            </div>

        </>
    );
};

export default React.memo(MarginCallFeedback);
