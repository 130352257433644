import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useTouchState } from "@/hooks/common/useTouchState";
import AudioPlayer, { AudioType } from "../AudioPlayer";

import styles from "./SystemFeedback.module.scss";

interface IProps {
    onClose: () => void;
    isReadOnly?: boolean;
    isCloseOnly?: boolean;
    playAudioEffect?: boolean;
}

const TradeDisabledFeedback = ({
    // eslint-disable-next-line
    isReadOnly,
    onClose,
    isCloseOnly,
    playAudioEffect = true,
}: IProps): JSX.Element => {
    const { isTouched, onTouchStart, onTouchEnd } = useTouchState();
    const { t } = useTranslation("common");
    const message = isCloseOnly
        ? "modals.error.tradeDisabled.description.close.only"
        : "modals.error.tradeDisabled.description.read.only";

    return (
        <>
            {playAudioEffect && <AudioPlayer type={AudioType.ERROR} />}
            <div className={clsx(styles.container, styles.tradeDisabled)}>
                <div className={styles.closeIcon} onClick={onClose} />
                <div className={clsx(styles.icon, styles.dangerIcon)} />

                <div className={styles.title}>{t("messages.133")}</div>

                <div className={styles.description}>{t(message)}</div>

                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.touched]: isTouched,
                        })}
                        onClick={onClose}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}>
                        {t("close")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default React.memo(TradeDisabledFeedback);
