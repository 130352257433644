// WE NEED THIS FIREBASE ONLY FOR THIS ALERTS
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, where, query, Timestamp } from "firebase/firestore/lite";
import { ITradeNotification } from "./interfaces";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { filterByLatestTriggerTime, filterOutHidden } from "./helpers";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASURMENT_ID,
};

// Initialize Firebase
let app = null;
if (!app) {
    app = initializeApp(firebaseConfig);
}

const db = getFirestore(app);

const auth = getAuth(app);
//signOut(auth);
onAuthStateChanged(auth, async user => {
    if (!user) await signInAnonymously(auth);
});
export const fetchAlertsDataFromFirebase = async (symbolsInfoById): Promise<ITradeNotification[]> => {
    try {
        const alerts = collection(db, "trade.notifications");

        const cutoffDate = new Date();
        cutoffDate.setHours(cutoffDate.getHours() - 48);

        const q = query(alerts, where("creationTime", ">", Timestamp.fromDate(cutoffDate)));

        const alertsSnapshot = await getDocs(q);

        const result: ITradeNotification[] = alertsSnapshot.docs.map(doc => {
            const data = doc.data();
            const milliseconds =
                data.creationTime.seconds * 1000 + data.creationTime.nanoseconds / 1000000;
            const _date = new Date(milliseconds);

            const ifRetail = symbolsInfoById[`${data.assetName}!`];

            return {
                id: doc.id,
                assetName: ifRetail ? `${data.assetName}!` : data.assetName,
                displayName: data.displayName,
                category: data.category,
                basePrice: data.basePrice,
                baseTime: data.baseTime,
                triggerPrice: data.triggerPrice,
                triggerTime: data.triggerTime,
                triggerDeviation: data.triggerDeviation,
                configuredDeviation: data.configuredDeviation,
                creationTime: data.creationTime,
                triggerTimeDiv: data.triggerTimeDiv,
                digits: data.digits,
                text: data.text,
                date: _date,
            } as ITradeNotification;
        });

        return filterByLatestTriggerTime(filterOutHidden(result, symbolsInfoById));
    } catch (error) {
        throw new Error(error);
    }
};
