import React, { useRef } from "react";
import { useTranslation } from "next-i18next";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { getBrandName } from "@/utils/helpers";
import { useKey } from "@/utils/hooks/keyboard/useKey";
import { KeyboardKeys } from "@/utils/hooks/keyboard/keyboardEvents";

import styles from "./Seventh.module.scss";


interface IProps {
    openDeposit: () => void;
    closeModal: () => void;
}

export const SeventhStep: React.FC<IProps> = ({ openDeposit, closeModal }) => {
    const { t } = useTranslation("common");
    const { isGuest, isLazyMode } = useAccountInfo();
    const host = getBrandName();

    const openDepositRef = useRef(openDeposit);
    useKey([KeyboardKeys.Enter], openDepositRef);

    const closeModalRef = useRef(closeModal);
    useKey([KeyboardKeys.Escape], closeModalRef, { eventTypes: ["keyup"] });

    return (
        <div className={styles.container}>
            <div onClick={closeModal} className={styles.close}>
                <div className={styles.closeIcon}></div>
            </div>
            <div className={styles.title}>
                {t("guidance.step07.title")}
            </div>
            <div className={styles.subtitle}>
                {t("guidance.step07.content").replace("${brandName}", host)}
            </div>
            <button className={styles.button} onClick={openDeposit}>
                {t(isGuest && !isLazyMode ? t("login") : t("deposit"))}
            </button>
            <div onClick={closeModal} className={styles.hint}>
                {t("guidance.no_thanx")}
            </div>
        </div>
    );
};
