import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { trimNumberToStepValue } from "@/utils/numbers";

import styles from "./NumberInputMobile.module.scss";

interface IProps {
    value: number | string;
    step: number;
    onChange: (number) => void;
    onClick?: () => void;
    disabled?: boolean;
    fullWidth?: boolean;
    allowZero?: boolean;
    hasError?: boolean;
    className?: string;
    minValue?: number;
    maxValue?: number;
    handleError?: (value: "min" | "max", withClear?: boolean) => void;
    trim?: boolean;
    allowDisplayOutOfRange?: boolean;
}

const NumberInputMobile = (props: IProps): JSX.Element => {
    const { palette } = useTheme();

    const {
        value,
        step,
        onChange,
        onClick,
        disabled = false,
        fullWidth = false,
        hasError = false,
        allowZero = false,
        className,
        minValue,
        maxValue,
        handleError = () => { },
        trim = true,
        allowDisplayOutOfRange = true,
    } = props;

    const [userInput, setUserInput] = useState(String(value));
    const [isManualMode, setIsManualMode] = useState(false);
    const decimalPartMaxLength = step >= 1 ? 0 : String(step).length - 2;

    let displayValue = "";

    if (isManualMode) {
        displayValue = userInput;
    } else if (trim) {
        displayValue = String(trimNumberToStepValue(Number(value), step));
    } else {
        displayValue = String(value);
    }

    const handleValueChange = (newValue: number, operation) => {
        if (!isManualMode) {
            if (minValue && newValue < minValue) {
                handleError("min", true);
                return;
            }
            if (maxValue && newValue > maxValue) {
                handleError("max", true);
                return;
            }
        }

        if (disabled || (!allowZero && newValue === 0)) {
            return;
        }

        if (isManualMode) {
            setIsManualMode(false);
            handleError(null);
            const customValue = operation === "inc" ? +userInput + step : +userInput - step;
            if (customValue >= minValue && customValue <= maxValue) {
                onChange(+trimNumberToStepValue(customValue, step));
            } else {
                onChange(+trimNumberToStepValue(operation === "inc" ? maxValue : maxValue - step, step));
            }
            return;
        }

        onChange(+trimNumberToStepValue(newValue, step));
    };

    const allowedInput = /^\d*[.,]?\d*$/;

    const handleUserInput = (newValue: string) => {
        if (disabled) {
            return;
        }

        if (!allowedInput.test(newValue)) {
            return;
        }

        let normalizedUserInput = newValue;

        normalizedUserInput = normalizedUserInput.replace(",", ".");

        const decimalPart = normalizedUserInput.split(".")[1];

        if (decimalPart && decimalPart.length > decimalPartMaxLength) {
            return;
        }

        if (!allowDisplayOutOfRange) {
            const isValueOutOfRange = (
                minValue !== undefined && +normalizedUserInput < minValue ||
                maxValue !== undefined && +normalizedUserInput > maxValue
            );

            if (
                isValueOutOfRange &&
                !(
                    normalizedUserInput === "" ||
                    `${minValue}`.startsWith(normalizedUserInput) ||
                    `${maxValue}`.startsWith(normalizedUserInput)
                )
            ) {
                if (+normalizedUserInput < minValue) {
                    handleError("min");
                } else if (+normalizedUserInput > maxValue) {
                    handleError("max");
                }
                return;
            }
        }

        setUserInput(normalizedUserInput);
        setIsManualMode(true);

        const numberValue = Number(normalizedUserInput);

        if (minValue && numberValue < minValue) {
            handleError("min");
            return;
        }
        if (maxValue && numberValue > maxValue) {
            handleError("max");
            return;
        }

        if (!Number.isNaN(numberValue) && (numberValue !== 0 || allowZero)) {
            handleError(null);
            onChange(numberValue);
        } else if (normalizedUserInput === "") {
            handleError("min");
            onChange(null);
        }
    };

    const handleClick = useCallback(() => {
        if (disabled || !onClick) return;

        onClick();
    }, [disabled, onClick]);

    const handleBlur = () => {
        if (isManualMode && +userInput === 0) {
            setIsManualMode(false);
            handleError(null);
            onChange(minValue);
        }
    };

    return (
        <div
            className={clsx(styles.container, {
                [styles.disabled]: disabled,
                [styles.fullWidth]: fullWidth,
                [styles.hasError]: hasError,
                [className]: !!className,
            })}>
            <div
                className={palette.mode === "dark" ? styles.decrease : styles.decrease_light}
                onClick={() => {
                    handleValueChange(Number(value) - step, "dec");
                }}
            />

            <input
                className={styles.value}
                type="text"
                value={String(displayValue)}
                onChange={e => handleUserInput(e.target.value)}
                onClick={handleClick}
                onBlur={handleBlur}
                disabled={disabled}
            />

            <div
                className={palette.mode === "dark" ? styles.increase : styles.increase_light}
                onClick={() => handleValueChange(Number(value) + step, "inc")}
            />
        </div>
    );
};

export default React.memo(NumberInputMobile);
