import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { quoteSocketManager } from "@/hooks/trade/tradeSocket";
import { OrderTableType } from "@/components/TradesTable";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { useTradeColumnSorter } from "@/hooks/trade/useTradeColumnSorter";
import { TableItem } from "./TableItem";
import { ITradeOrder } from "@/services/trade/order";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";

import styles from "../TradesTable.module.scss";

const getTableColumns = (tableType: string): string[] =>
    [
        tableType === "pending" ? "orderId" : "positionId",
        "openTime",
        "asset",
        "direction",
        "amount",
        "distance",
        "openPrice",
        "closePrice",
        "stopLoss",
        "takeProfit",
        tableType === "pending" ? "expirationDate" : "",
        "swap",
        "profit",
        tableType === "open" ? "close" : "delete",
    ].filter(item => item);

interface ITableHeaderProps {
    type: OrderTableType;
    sortHandler: (sortColumn: string) => void;
}

const TableHeader = ({ type, sortHandler }: ITableHeaderProps) => {
    const { t } = useTranslation("common");

    const executeSort = (column: string) => {
        return () => {
            if (column === "Close" || column === "Delete") {
                return;
            }
            sortHandler(column);
        };
    };

    return (
        <div className={clsx(styles.table_header)}>
            <div
                className={clsx(
                    clsx(styles.table_header_row, {
                        [styles.pending]: type === "pending",
                    })
                )}>
                {getTableColumns(type).map((column, id) => {
                    return (
                        <div
                            className={clsx(styles.table_cell_wrp, styles.table_header_cell_wrp)}
                            key={`${column}-${id}`}>
                            <div className={clsx(styles.table_header_cell)}>
                                <span className={styles.header_label} onClick={executeSort(column)}>
                                    {t(column)}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export interface ICalculatedValuesObject {
    [orderId: string]: {
        profit?: number;
        distance?: number;
        currentPrice?: number;
    };
}

interface IProps {
    type: OrderTableType;
    handleOrderEditClick: (order: ITradeOrder) => void;
}

export const TableRenderer = ({ type, handleOrderEditClick }: IProps): JSX.Element => {
    const { pendingOrders, openTrades } = useTradeOrders();
    const renderedOrders = type === OrderTableType.OPEN_TRADES ? openTrades : pendingOrders;
    const { symbolsInfoById } = useTradeInfo();
    const [closedOrderTicket, setClosedOrderTicket] = useState(null);
    const ordersToShow = orders => {
        return orders
            .map(order => {
                const symbolId = order.symbol;
                const { ask, bid } = quoteSocketManager.getValue(symbolId);

                const symbolInfo = symbolsInfoById[symbolId];
                const isBuy = order.type === "Buy";
                const marketPrice =
                    type !== OrderTableType.OPEN_TRADES ? (isBuy ? ask : bid) : isBuy ? bid : ask;

                if (!marketPrice) {
                    return {
                        ...order,
                        distance: 0,
                    };
                }

                const isCurrencyGroup = symbolInfo?.group?.name === "Currencies";

                let diff = isBuy ? marketPrice - order.openPrice : order.openPrice - marketPrice;

                if (isCurrencyGroup) {
                    diff = diff / 10;
                }

                const distanceInPips = diff / symbolInfo?.point;

                return {
                    ...order,
                    distance: distanceInPips,
                };
            })
            .filter(o => o);
    };

    // eslint-disable-next-line prefer-const
    let { sortedOrders, setSortColumn } = useTradeColumnSorter(
        //@ts-ignore
        ordersToShow(renderedOrders),
        OrderTableType.OPEN_TRADES
    );

    return (
        <>
            <TableHeader type={type} sortHandler={setSortColumn} />

            <div className={clsx(styles.table_body)}>
                <PerfectScrollbar>
                    {sortedOrders.map(order => {
                        return (
                            <TableItem
                                closedOrderTicket={closedOrderTicket}
                                setClosedOrderTicket={setClosedOrderTicket}
                                key={order.ticket}
                                type={type}
                                order={order}
                                handleOrderEditClick={handleOrderEditClick}
                            />
                        );
                    })}
                </PerfectScrollbar>
            </div>
        </>
    );
};
