export enum Pages {
    /**
     * Phase 1
     */
    Home = "home",
    Promotions = "promotions",
    Registration = "registration",
    Login = "login",

    /**
     * Phase 2
     */
    Game = "game",
    Deposit = "deposit",
    Withdraw = "withdraw",
    ForgotPassword = "forgotPassword",

    /**
     * Phase 3
     */
    ProfileAccount = "profileAccount",
    ProfileAccountValidation = "profileAccountValidation",
    ProfileDocuments = "profileDocuments",
    ProfileChangePassword = "profileChangePassword",
    ProfileActivity = "profileActivity",
    ProfileClose = "profileClose",
    ProfileCampaignPromotions = "profileCampaignPromotions",
    ProfileBalance = "profileBalance",

    // Profile limits
    ProfileLimitsPayments = "profileLimitsPayments",
    ProfileLimitsTimeout = "profileLimitsTimeout",
    ProfileLimitsSelfExecutions = "profileLimitsSelfExecutions",
    ProfileLimitsResponsibleGambling = "profileLimitsResponsibleGambling",
    ProfileLimitsQuestionnaire = "profileLimitsQuestionnaire",

    // Profile transactions
    ProfileTransactionGame = "profileTransactionGame",
    ProfileTransactionSport = "profileTransactionSport",
    ProfileTransactionDeposit = "profileTransactionDeposit",
    ProfileTransactionWithdrawal = "profileTransactionWithdrawal",
    ProfileTransactionPromo = "profileTransactionPromo",

    /**
     * Phase 4
     */
    AboutUs = "aboutUs",
    PrivacyPolicy = "privacyPolicy",
    ResponsibleGambling = "responsibleGambling",
    Faq = "faq",
    Support = "support",
    TermsConditions = "termsConditions",
}

export const ROUTES = {
    [Pages.Home]: "/",
    [Pages.Promotions]: "/promotions",
};
