import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import { ITradeOrder } from "@/services/trade/order";
import { useTheme } from "@mui/material";
import { useAccountInfo } from "@/redux/selectors/accountSelector";

import styles from "./Controls.module.scss";

interface ITooltipedButtonProps {
    isActive: boolean;
    setShowIsActive: (isOpen: boolean) => void;
    title?: string;
    imgUrl: string;
    imgUrlHover: string;
    orders?: ITradeOrder[] | any[];
    isNoWrapText?: boolean;
    id?: string;
    noDisplayingAmount?: boolean;
    isLockedAmount?: boolean;
}

const TooltipedButton: React.FC<ITooltipedButtonProps> = ({
    isActive,
    setShowIsActive,
    title,
    imgUrl,
    imgUrlHover,
    orders = null,
    isNoWrapText = true,
    noDisplayingAmount = false,
    isLockedAmount = false,
    id,
}) => {
    const { t } = useTranslation("common");
    const [tooltipId, setTooltipId] = useState(null);

    const displayAmount = orders?.length > 99 ? "99+" : `${orders?.length || "0"}`;

    return (
        <div
            id={title}
            className={clsx(styles.item, {
                [styles.listItem]: orders,
                [styles.active]: isActive,
            })}
            onMouseLeave={() => setTooltipId(null)}
            onMouseOver={() => title && setTooltipId(title)}
            onFocus={() => void 0}
            onClick={() => setShowIsActive(!isActive)}>
            <div
                style={{
                    bottom: orders ? 66 : 75,
                }}
                className={clsx(styles.tooltip, {
                    [styles.visible]: tooltipId === title,
                    [styles.noWrapText]: !isNoWrapText,
                })}>
                <div
                    className={clsx(styles.text, {
                        [styles.noWrapText]: !isNoWrapText,
                    })}>
                    {t(title)}
                    <div className={styles.triangle} />
                </div>
            </div>
            {orders && !isLockedAmount && (
                <div className={styles.listCount}>
                    <span>{displayAmount}</span>
                </div>
            )}

            {!isLockedAmount && noDisplayingAmount && (
                <div className={clsx(styles.listCount, styles.reload)}>
                    <span></span>
                </div>
            )}

            {isLockedAmount && (
                <div className={clsx(styles.listCount, styles.lock)}>
                    <span></span>
                </div>
            )}
            <div>
                <img className={styles.img} src={imgUrl} alt="intro" />
                <img id={id || null} className={styles.imgHover} src={imgUrlHover} alt="intro" />
            </div>
        </div>
    );
};

interface IControlProps {
    showIntro: boolean;
    showNotifications: boolean;
    setShowIntro: (isOpen: boolean) => void;
    handleToggleNotifications: (isOpen: boolean) => void;
    showCalculator: boolean;
    handleToggleCalculator: (isOpen: boolean) => void;
    openTradesToggle: (value: boolean) => void;
    tradingHistoryToggle: (value: boolean) => void;
    pendingOrdersToggle: (value: boolean) => void;
}

type IGetImagePathProps = {
    key: string;
    format?: string;
};

export const Controls: React.FC<IControlProps> = ({
    showIntro,
    setShowIntro,
    showCalculator,
    handleToggleCalculator,
    openTradesToggle,
    tradingHistoryToggle,
    pendingOrdersToggle,
    showNotifications,
    handleToggleNotifications,
}) => {
    const { pendingOrders, openTrades } = useTradeOrders();
    const { tradeAlerts = [], isGuest, isLazyMode } = useAccountInfo();
     const { t } = useTranslation("common");

    const noTradeAlertsNotification = (tradeAlerts && !tradeAlerts?.length) || !tradeAlerts;

    const lockedAmount = isGuest && !isLazyMode;

    const {
        isOpenTradesOpen,
        isPendingOrdersOpen,
        isTradingHistoryOpen,
        isNotificationsPopupVisible,
    } = useUI();
    const { palette } = useTheme();

    const getImagePath = ({ key, format = "png" }: IGetImagePathProps): string => {
        const isDarkMode = palette.mode === "dark";
        const relativePath = `/images/footer/${key}`;

        return isDarkMode ? `${relativePath}.${format}` : `${relativePath}-light-mode.${format}`;
    };

    return (
        <div className={styles.container}>
            <TooltipedButton
                isActive={showIntro}
                setShowIsActive={setShowIntro}
                title={"platform_introduction"}
                imgUrl={getImagePath({ key: "intro", format: "svg" })}
                imgUrlHover={getImagePath({ key: "intro-hover", format: "svg" })}
            />
            <TooltipedButton
                id={`calculation${showCalculator ? "_" : ""}`}
                isActive={showCalculator}
                setShowIsActive={handleToggleCalculator}
                title={"how_to_calculate"}
                imgUrl={getImagePath({ key: "calculate", format: "svg" })}
                imgUrlHover={getImagePath({ key: "calculate-hover", format: "svg" })}
            />

            <TooltipedButton
                id={`notification${isNotificationsPopupVisible ? "_" : ""}`}
                isActive={showNotifications}
                setShowIsActive={handleToggleNotifications}
                imgUrl={getImagePath({ key: "notification-bell", format: "svg" })}
                imgUrlHover={getImagePath({ key: "notification-bell-hover", format: "svg" })}
                orders={tradeAlerts}
                noDisplayingAmount={noTradeAlertsNotification}
                isLockedAmount={lockedAmount}
                title={t("tradingAlerts")}
            />

            <TooltipedButton
                isActive={isOpenTradesOpen}
                setShowIsActive={openTradesToggle}
                title={"openTrades"}
                imgUrl={getImagePath({ key: "open-trades", format: "svg" })}
                imgUrlHover={getImagePath({ key: "open-trades-hover", format: "svg" })}
                orders={openTrades}
            />
            <TooltipedButton
                isActive={isPendingOrdersOpen}
                setShowIsActive={pendingOrdersToggle}
                title={"pendingTrades"}
                imgUrl={getImagePath({ key: "pending-orders", format: "svg" })}
                imgUrlHover={getImagePath({ key: "pending-orders-hover", format: "svg" })}
                orders={pendingOrders}
            />
            <TooltipedButton
                isActive={isTradingHistoryOpen}
                setShowIsActive={tradingHistoryToggle}
                title={"tradeHistory"}
                imgUrl={getImagePath({ key: "history", format: "svg" })}
                imgUrlHover={getImagePath({ key: "history-hover", format: "svg" })}
            />
        </div>
    );
};
