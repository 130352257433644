import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import AudioPlayer, { AudioType } from "@/components/AudioPlayer";
import { useTouchState } from "@/hooks/common/useTouchState";
import { getErrorMessageTranslation } from "@/utils/helpers";

import styles from "../SystemFeedback.module.scss";

interface IProps {
    onClose: () => void;
    className?: string;
    title?: string;
    description?: string;
    children?: any;
    playAudioEffect?: boolean;
    placed?: boolean;
}

const WarningFeedback: React.FC<IProps> = ({
    children,
    onClose,
    className,
    title,
    description,
    playAudioEffect = true,
    placed,
}) => {
    const { t } = useTranslation("common");
    const { isTouched, onTouchStart, onTouchEnd } = useTouchState();
    if (placed) {
        title = t("your_request_submitted");
    }
    let details = getErrorMessageTranslation(description, t);
    details = title === details ? "" : details;

    return (
        <>
            {playAudioEffect && <AudioPlayer type={AudioType.ERROR} />}
            <div className={clsx(styles.container, styles.defaultWarning, className)}>
                <div
                    className={clsx(styles.icon, {
                        [styles.warningIcon]: !placed,
                        [styles.successIcon]: placed,
                    })}
                />
                <div className={styles.title}>{title}</div>

                {details && <div className={styles.description}>{details}</div>}
                {children && children}
                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.touched]: isTouched,
                        })}
                        onClick={onClose}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}>
                        {t("close")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default React.memo(WarningFeedback);
