import { useContext } from "react";
import { useTranslation } from "next-i18next";

import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { daysOfWeek, DaysOfWeek, useMarketSchedule } from "@/hooks/trade/schedule";
import { useActiveSymbolDynamicFields } from "@/hooks/symbols";
import { IContent as IInfoListContent } from "@/components/Core/InfoList/InfoList";
import { padDate } from "@/utils/format";
import { TradeOrderStateContext } from "@/contexts/TradeOrder";

interface IInfoPageData {
    symbolData: IInfoListContent[];
    tradingHoursData: IInfoListContent[];
    pendingOrdersData: IInfoListContent[];
}

const useInfoPageData = (): IInfoPageData => {
    const { t } = useTranslation("common");
    const context = useContext(TradeOrderStateContext);
    const { symbolsInfoById, activeSymbolId, internalSymbolId } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { lotSize } = context || { lotSize: symbolInfo?.group?.lotMin || 1 };
    const { schedule } = useMarketSchedule(activeSymbolId);

    const {
        lotDisplayValue,
        isAccountCurrencySymbol,
        marginDisplayValueInSymbolCurrency,
        leverageDisplayValue,
        requiredBuyMarginDisplayValue,
        requiredSellMarginDisplayValue,
        swapBuyDisplayValue,
        swapSellDisplayValue,
    } = useActiveSymbolDynamicFields({ lotSize });

    const symbolData: IInfoListContent[] = [
        {
            label: t("base_asset"),
            value: t(symbolInfo?.currency),
        },
        {
            label: t("quote_asset"),
            value: internalSymbolId ? internalSymbolId : symbolInfo?.ex?.displayName || symbolInfo?.id,
        },
        {
            label: t("leverage"),
            value: `${leverageDisplayValue}`,
        },
        {
            label: t("spread"),
            value: t(symbolInfo?.ex?.spreadType?.toLowerCase()),
        },
        {
            label: t("digits"),
            value: String(symbolInfo?.digits),
        },
        {
            label: t("lot_value"),
            value: `${lotDisplayValue}`,
        },
        {
            label: t("min_max_volume"),
            value: `${symbolInfo?.group?.lotMin} - ${symbolInfo?.group?.lotMax}`,
        },
        {
            label: t("volume_step"),
            value: `${symbolInfo?.group?.lotStep}`,
        },
        {
            label: t("required_margin_buy", { lots: lotSize }),
            value: isAccountCurrencySymbol
                ? requiredBuyMarginDisplayValue
                : `${marginDisplayValueInSymbolCurrency} (${requiredBuyMarginDisplayValue})`,
            valueType: "price",
        },
        {
            label: t("required_margin_sell", { lots: lotSize }),
            value: isAccountCurrencySymbol
                ? requiredSellMarginDisplayValue
                : `${marginDisplayValueInSymbolCurrency} (${requiredSellMarginDisplayValue})`,
            valueType: "price",
        },
        {
            label: t("swap_buy"),
            value: swapBuyDisplayValue,
            valueType: "price",
        },
        {
            label: t("swap_sell"),
            value: swapSellDisplayValue,
            valueType: "price",
        },
        {
            label: "",
            value: t("on_wednesday_3_swaps_are_changed"),
        },
    ];

    if (symbolInfo?.ex?.rolloverDate) {
        const rolloverDate = new Date(symbolInfo?.ex?.rolloverDate);
        const rolloverDateInfo = {
            label: t("rolloverDate"),
            value: `${padDate(rolloverDate.getDate())}.${padDate(
                rolloverDate.getMonth() + 1
            )}.${rolloverDate.getFullYear()}`,
        };

        // after Leverage
        symbolData.splice(3, 0, rolloverDateInfo);
    }

    const dayLabels: Record<DaysOfWeek, string> = {
        mon: "Mon",
        tue: "Tue",
        wed: "Wed",
        thu: "Thu",
        fri: "Fri",
        sat: "Sat",
        sun: "Sun",
    };

    const tradingHoursData: IInfoListContent[] = daysOfWeek.map(day => {
        if (schedule === "OPEN247") {
            return {
                label: t(`daysOfWeek.${dayLabels[day]}`),
                value: "00:00-24:00",
            };
        }

        const daySchedule = schedule[day];

        if (!daySchedule || !daySchedule.length) {
            return {
                label: t(`daysOfWeek.${dayLabels[day]}`),
                value: t("closed"),
            };
        }

        const scheduleLines = daySchedule.map(slot => {
            const [from, to] = slot;
            const hhFrom = padDate(from.getHours());
            const mmFrom = padDate(from.getMinutes());
            const hhTo = padDate(to.getHours());
            const mmTo = padDate(to.getMinutes());

            if (hhTo === "23" && mmTo === "59") {
                return `${hhFrom}:${mmFrom}-24:00`;
            }

            return `${hhFrom}:${mmFrom}-${hhTo}:${mmTo}`;
        });

        return {
            label: t(`daysOfWeek.${dayLabels[day]}`),
            value: scheduleLines.join(", "),
        };
    });

    const pendingOrdersData: IInfoListContent[] = [
        {
            label: t("pendingTrades"),
            value: t("pending_order_description"),
        },
        {
            label: t("actionsId.buy_stop"),
            value: t("buy_stop_description"),
        },
        {
            label: t("actionsId.sell_stop"),
            value: t("sell_stop_description"),
        },
        {
            label: t("actionsId.buy_limit"),
            value: t("buy_limit_description"),
        },
        {
            label: t("actionsId.sell_limit"),
            value: t("sell_limit_description"),
        },
    ];

    return {
        symbolData,
        tradingHoursData,
        pendingOrdersData,
    };
};

export default useInfoPageData;
