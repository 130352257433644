import React from "react";
import clsx from "clsx";
import { ITradeOrder } from "@/services/trade/order";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { TRANSACTIONS_TYPES } from "@/constants/index";
import { useTranslation } from "next-i18next";
import { getDirectionsTranslationKey } from "@/utils/helpers";
import { useTheme } from "@mui/material";
import styles from "./TradeHeader.module.scss";

interface IProps {
    tradeData: ITradeOrder;
}

const iconsMap = {
    regular_deposit: "/images/deposit_operation_*_theme.svg",
    deposit_cc: "/images/deposit_operation_*_theme.svg",

    adjustment: "/images/adjustment_operation_*_theme.svg",

    regular_withdrawal: "/images/withdrawal_operation_*_theme.svg",
    cancel_withdrawal: "/images/cancel_withdrawal_operation_*_theme.svg",
    cancel_withdrawal_request: "/images/cancel_withdrawal_operation_*_theme.svg",

    balance_correction: "/images/adjustment_operation_*_theme.svg",
    bo_correction: "/images/adjustment_operation_*_theme.svg",

    chargeback: "/images/chargeback_operation_*_theme.svg",
    cancel_chargeback: "/images/cancel_chargeback_operation_*_theme.svg",

    // potentially rare operations
    rollover_correction: null, //"Rollover Correction",
    psp_recall: null, //"PSP Recall",
    refer_a_friend: null, //"Refer a Friend",
    rebate: null, //"Rebate",
    agreement: null, //"Agreement",
    dealing_correction: null, //"Dealing Correction",
    fee: null, //"Fee",
    fee_reimbursement: null, //"Fee Reimbursement",
    fraud_correction: null, //"Fraud correction",
    reset_balance: null, //"Reset Balance",
    technical_error: null, //"Technical Error",
    verification_bonus: null, //"Verification Bonus",
    binaryclosure: null, //"BinaryClosure",
    competition_winner: null, //"Competition Winner",
    swap_corrections: null, //"Swap Corrections",
};

const balanceOperationTypes = ["credit", "balance", "deposit", "regular_deposit"];

const OperationIcon = ({ flag, mode }) => {
    const isTradeOperation = flag === "buy" || flag === "sell";

    return isTradeOperation ? (
        <div className={clsx(styles.directionIcon)} />
    ) : (
        <div
            style={{
                backgroundImage: flag,
            }}
            className={clsx(styles.directionIcon, styles[mode])}
        />
    );
};

const TradeHeader = ({ tradeData }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[tradeData.symbol];
    const isBalanceOperation = balanceOperationTypes.includes(tradeData.type.toLowerCase());
    const comment = tradeData?.ex?.comment?.toLowerCase() || tradeData?.comment?.toLowerCase();

    const getIcon = () => {
        const normalizedCommentKey = comment.toLowerCase().replace(/ /g, "_");
        const icon = iconsMap[normalizedCommentKey];
        return icon?.replace("*", palette.mode) || "";
    };

    const getOperationType = () => {
        const type = tradeData.type.toLowerCase();
        const isBalanceOperation = balanceOperationTypes.includes(type);
        if (isBalanceOperation) return `url(${getIcon()})`;
        return type.includes("buy") ? "buy" : "sell";
    };

    const getCommentValue = () => {
        if (isBalanceOperation && comment) {
            const normalizedCommentKey = comment.toLowerCase().replace(/ /g, "_");
            return TRANSACTIONS_TYPES[normalizedCommentKey] || comment;
        }

        return t(getDirectionsTranslationKey(tradeData.type)) || "";
    };

    return (
        <div className={styles.container}>
            <div
                className={clsx(styles.directionIconContainer, {
                    [styles.buy]: getOperationType() === "buy",
                    [styles.sell]: getOperationType() === "sell",
                })}>
                <OperationIcon flag={getOperationType()} mode={palette.mode} />
            </div>

            <div className={styles.headerMainInfo}>
                <div className={styles.headerMainInfo__symbol}>
                    {symbolInfo?.ex?.displayName || tradeData.symbol || getCommentValue().toUpperCase()}
                </div>
                <div className={styles.headerMainInfo__direction}>
                    {tradeData.ticket && `ID #${tradeData.ticket}`}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TradeHeader);
