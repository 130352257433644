import React, { useContext, useEffect } from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import clsx from "clsx";

import NumberInput from "@/components/Core/Form/NumberInput/NumberInput";
import TextInput from "@/components/Core/Form/TextInput/TextInput";
import Checkbox from "@/components/Core/Form/Checkbox/Checkbox";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { ITradeOrderOperationCategory } from "@/contexts/TradeOrder/interfaces";
import { formatAmount } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { TradeChartGlobalAPIContext } from "@/contexts/ChartAPI";
import { useEditOrder } from "@/hooks/trade/useEditOrder";

import styles from "./StopLossTakeProfit.module.scss";


const StopLossTakeProfit = (): JSX.Element => {
    const router = useRouter();
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { currency: accountCurrency } = useAccountInfo();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const tradeOrderContext = useContext(TradeOrderStateContext);
    const { addTakeProfitLine, removeTakeProfitLine, addStopLossLine, removeStopLossLine } = useContext(
        TradeChartGlobalAPIContext
    );
    const isLongTitleTranslation = ["ru", "es", "pt", "cn", "el", "it", "ge", "ms"].includes(
        router.locale
    );

    const {
        handleStopLossToggle,
        handleTakeProfitToggle,
        handleStopLossPipsChange,
        handleTakeProfitPipsChange,
        handleStopLossPriceChange,
        handleTakeProfitPriceChange,

        activeOperationCategory,
        symbolInfo,
        isForex,

        isStopLoss,
        stopLossPrice,
        stopLossPriceDisplayValue,
        stopLossPriceFormInputValue,
        stopLossPipsFormInputValue,
        stopLossValue,

        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        takeProfitPriceFormInputValue,
        takeProfitPipsFormInputValue,
        takeProfitValue,
    } = useEditOrder({
        order: null,
        tradeOrderDispatch,
        tradeOrder: tradeOrderContext,
    });

    const isOperationSelected = (["Buy", "Sell"] as ITradeOrderOperationCategory[]).includes(
        activeOperationCategory
    );

    useEffect(() => {
        if (isStopLoss && stopLossPrice !== null) {
            addStopLossLine(stopLossPriceDisplayValue);
        } else {
            removeStopLossLine();
        }
    }, [isStopLoss, stopLossPrice, stopLossPriceDisplayValue, addStopLossLine, removeStopLossLine]);

    useEffect(() => {
        if (isTakeProfit && takeProfitPrice !== null) {
            addTakeProfitLine(takeProfitPriceDisplayValue);
        } else {
            removeTakeProfitLine();
        }
    }, [
        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        addTakeProfitLine,
        removeTakeProfitLine,
    ]);

    useEffect(() => {
        return () => {
            removeStopLossLine();
            removeTakeProfitLine();
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div
                    className={clsx(styles.row, {
                        [styles.long_title]: isLongTitleTranslation,
                    })}>
                    <div className={styles.checkbox_block}>
                        <Checkbox
                            inputId="stop_loss_toggle"
                            checked={isStopLoss}
                            onChange={handleStopLossToggle}
                            disabled={!isOperationSelected}
                            classes={styles.sl_tp_toggle}
                        />
                        <label
                            htmlFor="stop_loss_toggle"
                            className={!isOperationSelected ? styles.label_disabled : ""}>
                            {t("stopLoss")}
                        </label>
                    </div>
                    <div></div>
                    <div className={styles.checkbox_block}>
                        <Checkbox
                            inputId="take_profit_toggle"
                            checked={isTakeProfit}
                            onChange={handleTakeProfitToggle}
                            disabled={!isOperationSelected}
                            classes={styles.sl_tp_toggle}
                        />
                        <label
                            htmlFor="take_profit_toggle"
                            className={!isOperationSelected ? styles.label_disabled : ""}>
                            {t("takeProfit")}
                        </label>
                    </div>
                </div>
                <div
                    className={clsx(styles.row, {
                        [styles.long_title]: isLongTitleTranslation,
                    })}>
                    <NumberInput
                        value={stopLossPipsFormInputValue}
                        step={1}
                        onChange={handleStopLossPipsChange}
                        disabled={!isStopLoss}
                        fullWidth
                        allowNull
                        className={clsx(styles.amountInput, {
                            [styles.disabled]: !isOperationSelected,
                        })}
                    />
                    <div className="row__title">{t(isForex ? "pips" : "points")}</div>
                    <NumberInput
                        value={takeProfitPipsFormInputValue}
                        step={1}
                        onChange={handleTakeProfitPipsChange}
                        disabled={!isTakeProfit}
                        fullWidth
                        allowNull
                        className={clsx(styles.amountInput, {
                            [styles.disabled]: !isOperationSelected,
                        })}
                    />
                </div>
                <div
                    className={clsx(styles.row, {
                        [styles.long_title]: isLongTitleTranslation,
                    })}>
                    <NumberInput
                        className={clsx(styles.amountInput, {
                            [styles.disabled]: !isOperationSelected,
                        })}
                        value={stopLossPriceFormInputValue}
                        step={isStopLoss ? symbolInfo?.point : 1}
                        onChange={handleStopLossPriceChange}
                        disabled={!isStopLoss}
                        allowNull
                        fullWidth
                    />
                    <div className={styles.row__title}>{t("price")}</div>
                    <NumberInput
                        value={takeProfitPriceFormInputValue}
                        step={isTakeProfit ? symbolInfo?.point : 1}
                        onChange={handleTakeProfitPriceChange}
                        disabled={!isTakeProfit}
                        allowNull
                        fullWidth
                        className={clsx(styles.amountInput, {
                            [styles.disabled]: !isOperationSelected,
                        })}
                    />
                </div>
                <div
                    className={clsx(styles.row, {
                        [styles.long_title]: isLongTitleTranslation,
                    })}>
                    <TextInput
                        className={clsx(styles[`calculateProfitInput_${palette.mode}`], {
                            [styles.disabled]: !isOperationSelected,
                        })}
                        value={
                            isStopLoss && stopLossPriceFormInputValue !== null
                                ? formatAmount(stopLossValue, accountCurrency)
                                : ""
                        }
                        fullWidth
                        disabled
                    />
                    <div className={styles.row__title}>{t("profit")}</div>
                    <TextInput
                        className={clsx(styles[`calculateProfitInput_${palette.mode}`], {
                            [styles.disabled]: !isOperationSelected,
                        })}
                        value={
                            isTakeProfit && takeProfitPriceFormInputValue !== null
                                ? formatAmount(takeProfitValue, accountCurrency)
                                : ""
                        }
                        fullWidth
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

export default StopLossTakeProfit;
