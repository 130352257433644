import React, { useState, useEffect } from "react";
import { useDebounce } from "@/utils/hooks/useDebounce";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import { useUI } from "@/redux/selectors/uiSelector";
import { closeTradeOrderModal } from "@/redux/actions/ui";

import styles from "./ThirdStep.module.scss";

interface IProps {
    closeModal: () => void;
    children: JSX.Element;
}

export const ThirdStep: React.FC<IProps> = ({ closeModal, children }) => {
    const { t, i18n } = useTranslation("common");
    const dispatch = useDispatch();
    const { tradeOrderModal, isTradeOrderViewOpen } = useUI();
    const [leftPosition, setLeftPosition] = useState(0);
    const debounce = useDebounce();

    useEffect(() => {
        const el = window?.document.getElementById("trade_order_section");
        if (el) {
            const rect = el.getBoundingClientRect();
            let position = rect ? rect?.left + rect?.width + rect?.width * 0.1 : null;
            
            if (screen.width < 1376 && screen.width > 1260) {
                position = rect ? rect?.left + screen.width * 0.16 : null;
            }
    
            if (screen.width < 1260) {
                position = rect ? rect?.left + screen.width * 0.13 : null;
            }
            position === 0 ? closeModal() : setLeftPosition(position);
        }
    }, [debounce, isTradeOrderViewOpen]);

    useEffect(() => {
        if (tradeOrderModal) {
            dispatch(closeTradeOrderModal());
        }
    }, []);

    return (
        <div
            className={styles.container}
            style={{
                left: leftPosition,
            }}>
            <div onClick={closeModal} className={styles.closeIcon}></div>
            <div className={styles.content}>
                <div
                    className={styles.title}
                    style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
                >
                    {t("guidance.step03.title")}
                </div>
                <div
                    className={styles.subtitle}
                    style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
                >
                    {[1, 2, 3, 4].map((point) => (
                        <div key={point} className={styles.row}>
                            <span className={styles.point}>{`${point}.`}</span>
                            <span className={styles.info}>{t(`guidance.step03.content0${point}`)}</span>                            
                        </div>
                    ))}
                </div>
            </div>
            {children}
        </div>
    );
};
