import { useCallback, useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { togglePendingOrder, togglePendingOrderExpiration } from "@/contexts/TradeOrder/actions";
import { toggleMobilePendingOrders, setAssetsTabActive, setToggleMobileFooter } from "@/redux/actions/ui";
import { TradeOrderDispatchContext } from "@/contexts/TradeOrder";
import { useUI } from "@/redux/selectors/uiSelector";

interface IPendingOrdersActions {
    onGetBack: () => void;
    openPendingOrder: () => void;
    closePendingOrder: () => void;
    closeCalendar: () => void;
    onDateSet: (value: string) => void;
    isCalendarOpen: boolean;
    date: string;
    isDateSet: boolean;
    toggleCalendar: () => void;
    togglePendingOrdersButton: () => void;
}

export const usePendingOrdersActions = (): IPendingOrdersActions => {
    const dispatch = useDispatch();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const initDateRef = useRef<string>(new Date().toISOString());
    const [date, setDate] = useState(initDateRef.current);
    const { isMobilePendingOrdersOpen } = useUI();

    const openPendingOrder = useCallback(() => {
        tradeOrderDispatch(togglePendingOrder());
        dispatch(toggleMobilePendingOrders(true));
    }, []);

    const closePendingOrder = useCallback(() => {
        tradeOrderDispatch(togglePendingOrder());
        dispatch(toggleMobilePendingOrders(false));
    }, []);

    const onGetBack = useCallback(() => {
        dispatch(setAssetsTabActive());
        dispatch(setToggleMobileFooter(true));
    }, []);

    const closeCalendar = useCallback(() => {
        setCalendarOpen(false);
        dispatch(toggleMobilePendingOrders(true));
    }, []);

    const onDateSet = value => {
        setCalendarOpen(false);
        setDate(value);

        tradeOrderDispatch(togglePendingOrderExpiration());
        dispatch(toggleMobilePendingOrders(true));
    };

    const toggleCalendar = () => setCalendarOpen(!isCalendarOpen);

    const togglePendingOrdersButton = () => {
        if (!isMobilePendingOrdersOpen) {
            openPendingOrder();
        } else {
            closePendingOrder();
        }
    };

    const isDateSet = initDateRef.current !== date;

    return {
        onGetBack,
        openPendingOrder,
        closePendingOrder,
        closeCalendar,
        onDateSet,
        isCalendarOpen,
        date,
        isDateSet,
        toggleCalendar,
        togglePendingOrdersButton,
    };
};