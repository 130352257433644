import React from "react";
import clsx from "clsx";
import moment from "moment";
import YearMonthPicker from "../YearMonthPicker/YearMonthPicker";
import { useTranslation } from "next-i18next";

import styles from "./DatePickerHeader.module.scss";

interface IProps {
    minDate: string | Date;
    maxDate?: Date;
    date: Date;
    changeYear: (year: string) => void;
    changeMonth: () => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    timeStampId: number;
    onShowYear: () => void;
    showYear: boolean;
    showMonthsPicker: boolean;
    setShowMonthsPicker: () => void;
}

const DatePickerHeader: React.FC<IProps> = ({
    minDate,
    maxDate,
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    timeStampId,
    onShowYear,
    showYear,
    showMonthsPicker,
    setShowMonthsPicker,
}) => {
    const { t } = useTranslation("common");

    const month = t(`months.${moment(date).format("MMMM").toLowerCase()}`).slice(0, 3);
    const year = moment(date).format("YYYY");

    return (
        <div className={styles.header_container} id={`${timeStampId}`}>
            {showYear ? (
                <YearMonthPicker
                    minDate={minDate}
                    maxDate={maxDate}
                    date={date}
                    changeYear={changeYear}
                    changeMonth={changeMonth}
                    timeStampId={timeStampId}
                    onShowYear={onShowYear}
                    showMonthsPicker={showMonthsPicker}
                    setShowMonthsPicker={setShowMonthsPicker}
                />
            ) : (
                <>
                    <button
                        disabled={prevMonthButtonDisabled}
                        onClick={decreaseMonth}
                        className={clsx(styles.header_button, styles.left)}
                    />
                    <div onClick={onShowYear} className={styles.header_date_wrapper}>
                        <div className={styles.header_month}>{month}</div>
                        <div className={styles.header_year}>{year}</div>
                    </div>
                    <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        className={clsx(styles.header_button, styles.right)}
                    />
                </>
            )}
            {!showYear && <div className={styles.divider}></div>}
        </div>
    );
};

export default React.memo(DatePickerHeader);
