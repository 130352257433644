import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import AudioPlayer, { AudioType } from "@/components/AudioPlayer";
import { useTouchState } from "@/hooks/common/useTouchState";

import styles from "../SystemFeedback.module.scss";

interface IProps {
    onClose: () => void;
    className?: string;
    title?: string;
    description?: string;
    children?: any;
    playAudioEffect?: boolean;
}

const DangerFeedback = ({
    onClose,
    className,
    title,
    description,
    children,
    playAudioEffect = true
}: IProps): JSX.Element => {
    const { t } = useTranslation();
    const { isTouched, onTouchStart, onTouchEnd } = useTouchState();

    return (
        <>
            {playAudioEffect  && <AudioPlayer type={AudioType.ERROR} />}
            <div className={clsx(styles.container, styles.defaultDanger, className)}>
                <div className={clsx(styles.icon, styles.dangerIcon)} />

                <div className={styles.title}>{title}</div>

                {description &&
                    <div className={styles.description}>{description}</div>
                }

                {children && children}

                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.touched]: isTouched
                        })}
                        onClick={onClose}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                    >
                        {t("close")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default React.memo(DangerFeedback);
