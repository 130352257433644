import React from "react";
import clsx from "clsx";

import { useUI } from "@/redux/selectors/uiSelector";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import TradingAssetsSection from "./TradingAssetsSection/TradingAssetsSection";
import TradingOrderSection from "./TradingOrderSection/TradingOrderSection";
import TradingChartInfoSection from "./TradingChartInfoSection/TradingChartInfoSection";
import { HomeMobile } from "@/components/Home/HomeMobile";
import { LoadingScreen } from "@/components/LoadingScreen";
import { TradeOrderDispatchContext, TradeOrderStateContext, useTradeOrder } from "@/contexts/TradeOrder";
import { useAccountDynamicInfo } from "@/redux/selectors/accountSelector";

import styles from "./Home.module.scss";


const TradingHomeDesktop = () => {
    const { isTradeOrderViewOpen, isFooterExpanded, isFooterExpandedFullScreen } = useUI();
    const { isQuoteDataLoaded, isAccountSocketDataLoaded } = useTradeInfo();
    const { activeSymbolId } = useTradeInfo();
    const { isSetDynamicAccountInfoCalculated } = useAccountDynamicInfo();
    const [tradeOrder, tradeOrderDispatch] = useTradeOrder({ activeSymbolId });

    const isDataLoaded = (
        isQuoteDataLoaded &&
        isAccountSocketDataLoaded &&
        isSetDynamicAccountInfoCalculated
    );

    return (
        <div
            className={clsx(styles.container, {
                [styles.footerExpandedFullScreen]: isFooterExpandedFullScreen,
                [styles.collapsed]: isFooterExpanded,
            })}
        >
            {isDataLoaded ? (
                <>
                    <TradeOrderDispatchContext.Provider value={tradeOrderDispatch}>
                        <TradeOrderStateContext.Provider value={tradeOrder}>
                            <TradingAssetsSection />
                            {isTradeOrderViewOpen && <TradingOrderSection />}
                            <TradingChartInfoSection />
                        </TradeOrderStateContext.Provider>
                    </TradeOrderDispatchContext.Provider>
                </>
            ) : (
                <LoadingScreen />
            )}
        </div>
    );
};

export default React.memo(TradingHomeDesktop);

export const TradingHomeTablet = React.memo(HomeMobile);

export const TradingHomeMobile = React.memo(HomeMobile);
