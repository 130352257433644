import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";

import { useUI } from "@/redux/selectors/uiSelector";
import { setAssetsTabActive, setTradesTabActive, setWalletTabActive } from "@/redux/actions/ui";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

import styles from "./FooterMobile.module.scss";

const FooterMobile = () => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { isAssetsTabActive, isTradesTabActive, isWalletTabActive } = useUI();
    const { platform } = useDevice();

    return (
        <div className={clsx(styles.footer_mbl, {
            [styles.tablet]: platform === DevicePlatform.Tablet,
        })}>
            <div
                onClick={() => {
                    dispatch(setAssetsTabActive());
                }}
                className={clsx(styles.footer_mbl_btn, styles.markets_icon, {
                    [styles.active]: isAssetsTabActive,
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}>
                <span>{t("markets")}</span>
            </div>
            <div
                onClick={() => {
                    dispatch(setTradesTabActive());
                }}
                className={clsx(styles.footer_mbl_btn, styles.trades_icon, {
                    [styles.active]: isTradesTabActive,
                })}>
                <span>{t("my_trade")}</span>
            </div>
            <div
                onClick={() => {
                    dispatch(setWalletTabActive());
                }}
                className={clsx(styles.footer_mbl_btn, styles.wallet_icon, {
                    [styles.active]: isWalletTabActive,
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}>
                <span>{t("wallet")}</span>
            </div>
        </div>
    );
};

export default React.memo(FooterMobile);
