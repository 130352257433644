import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    minWidth: 200,
    width: "100%",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#2CC47E",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#F25555",
    },
}));

interface IProps {
    value: number;
    minWidth?: string;
}

const ProgressBar = ({ value, minWidth }: IProps) => {
    return <BorderLinearProgress variant="determinate" value={value} style={{ minWidth }} />;
};

export default React.memo(ProgressBar);
