import React from "react";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useRouter } from "next/router";

import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { formatAmount } from "@/utils/format";
import { useDevice } from "@/redux/selectors/deviceSelector";

import styles from "./MarginInfoBlock.module.scss";


const MarginInfoBlock = () => {
    const { t } = useTranslation("common");
    const { isMobile, isTablet } = useDevice();
    const { locale } = useRouter();
    const { currency: accountCurrency } = useAccountInfo();
    
    const {
        equity = 0,
        margin = 0,
        freeMargin = 0,
        profit = 0,
        balance,
    } = useAccountDynamicInfo();

    const marginLevel = (equity / margin) * 100;
    const marginLevelDisplayValue = margin > 0 ? `${marginLevel.toFixed(2)}%` : "---";

    return (
        <div className={styles.container}>
            <div className={clsx(styles.leftInfo, {
                [styles.tablet]: isTablet
            })}>
                <div className={styles.titles}>
                    <span className={styles.title}>{t("pl")}:</span>
                    <span className={styles.title}>{t("freeMargin")}:</span>
                    <span className={styles.title}>{t("balance")}:</span>
                </div>
                <div className={clsx(styles.values, {
                    [styles.long_value]: isMobile && locale === "el",
                })}>
                    <span>{formatAmount(profit, accountCurrency)}</span>
                    <span>{formatAmount(freeMargin, accountCurrency)}</span>
                    <span className={styles.title}>{formatAmount(balance, accountCurrency)}</span>
                </div>
            </div>
            <div className={clsx(styles.rightInfo, {
                [styles.tablet]: isTablet
            })}>
                <div className={styles.titles}>
                    <span className={styles.title}>{t("used_funds")}:</span>
                    <span className={styles.title}>{t("equity")}:</span>
                    <span className={styles.title}>{t("marginLevel")}:</span>
                </div>
                <div className={clsx(styles.values, {
                    [styles.long_value]: isMobile && locale === "el",
                })}>
                    <span>{formatAmount(margin, accountCurrency)}</span>
                    <span>{formatAmount(equity, accountCurrency)}</span>
                    <span>{marginLevelDisplayValue}</span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(MarginInfoBlock);
