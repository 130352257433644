import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { dayNames, monthNames } from "./helpers";
import MobileMonthYearPicker from "./MobileMonthYearPicker";
import ArrowIcon from "../ArrowIcon/ArrowIcon";
import moment from "moment";
import styles from "./Calendar.module.scss";

const currentData = new Date();

const isEqualDate = (d1?: Date, d2?: Date) => {
    if (!d1 || !d2) return false;

    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    );
};

const isIntermediateDate = (current: Date, from?: Date, to?: Date) => {
    if (!from || !to) return false;

    if (isEqualDate(current, from) || isEqualDate(current, to)) return false;

    return current.getTime() > from.getTime() && current.getTime() < to.getTime();
};

interface IProps {
    from?: Date;
    to?: Date;
    onChange: (range: { from: Date; to: Date }) => void;
    isYearModalOpen: boolean;
    openYearModal: () => void;
    minDate?: Date;
    maxDate?: Date;
}

export const Calendar: React.FC<IProps> = ({
    from,
    to,
    onChange,
    isYearModalOpen,
    openYearModal,
    minDate,
    maxDate,
}) => {
    const { t } = useTranslation("common");
    const [year, setYear] = useState(from ? from.getFullYear() : currentData.getFullYear());
    const [month, setMonth] = useState(from ? from.getMonth() : currentData.getMonth());
    const [isSelectInProcess, setIsSelectInProcess] = useState(false);

    const handleChangeMonth = useCallback(
        (number: number, skipChange?: boolean) => {
            return () => {
                if (skipChange) {
                    return;
                }
                if (month + number > 11) {
                    setYear(year + 1);
                    setMonth(0);
                } else if (month + number < 0) {
                    setYear(year - 1);
                    setMonth(11);
                } else {
                    setMonth(month + number);
                }
            };
        },
        [year, month]
    );

    const handleSelectDay = useCallback(
        date => {
            let newFrom = from;
            let newTo;

            if (isSelectInProcess && from.getTime() > date.getTime()) {
                setIsSelectInProcess(false);
                newTo = from;
                newFrom = date;
            } else if (isSelectInProcess && from.getTime() <= date.getTime()) {
                setIsSelectInProcess(false);
                newTo = date;
            } else {
                setIsSelectInProcess(true);
                newFrom = date;
                newTo = date;
            }

            onChange({
                from: new Date(newFrom.getFullYear(), newFrom.getMonth(), newFrom.getDate(), 0, 0, 0, 0),
                to: new Date(newTo.getFullYear(), newTo.getMonth(), newTo.getDate(), 23, 59, 59, 0),
            });
        },
        [isSelectInProcess, from, onChange]
    );

    const calendarGridData = useMemo(() => {
        const firstMonthDay = new Date(year, month, 1).getDay();
        const daysInMonth = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();

        const days = [];

        for (let i = 1; i <= daysInMonth; i++) {
            days.push({
                date: new Date(year, month, i),
            });
        }

        const pushbackDaysArray = [];
        for (let i = 0; i < firstMonthDay; i++) {
            pushbackDaysArray.push(i);
        }

        return {
            days,
            pushbackDaysArray,
        };
    }, [year, month]);

    const date = new Date();
    date.setFullYear(year);
    date.setMonth(month);

    const isLeftSwitchActive = minDate ? +minDate <= +date : true;
    const isRightSwitchActive = maxDate ? +maxDate >= +date : true;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.label_container} onClick={openYearModal}>
                    <div className={styles.current_month}>
                        {monthNames[month] && t(`months.${monthNames[month].toLowerCase()}`)}
                    </div>
                    <div className={styles.current_year}>{year}</div>
                    <ArrowIcon isOpen={isYearModalOpen} />
                </div>
                <div className={styles.switcher_container}>
                    <div
                        onClick={handleChangeMonth(-1, !isLeftSwitchActive)}
                        className={clsx(styles.switcher_control, styles.switcher_left, {
                            [styles.active]: isLeftSwitchActive,
                        })}></div>
                    <div
                        onClick={handleChangeMonth(1, !isRightSwitchActive)}
                        className={clsx(styles.switcher_control, styles.switcher_right, {
                            [styles.active]: isRightSwitchActive,
                        })}></div>
                </div>
            </div>

            {isYearModalOpen && (
                <MobileMonthYearPicker
                    date={date}
                    onClose={openYearModal}
                    setYear={setYear}
                    setMonth={setMonth}
                    maxDate={new Date()}
                />
            )}

            {!isYearModalOpen && (
                <div className={styles.daysGrid}>
                    <div className={styles.daysGrid__header}>
                        {dayNames.map(day => {
                            return (
                                <div key={day} className={styles.dayName}>
                                    {t(`daysOfWeek.${day}`)}
                                </div>
                            );
                        })}
                    </div>

                    <div className={clsx(styles.daysGrid__days, styles.daysContainer)}>
                        {calendarGridData.pushbackDaysArray.map(i => (
                            <div key={i} />
                        ))}

                        {calendarGridData.days.map(item => {
                            return (
                                <div
                                    onClick={() => handleSelectDay(item.date)}
                                    key={item.date.getDate()}
                                    className={clsx(styles.dayNumber, {
                                        [styles.firstSelected]: isEqualDate(item.date, from),
                                        [styles.lastSelected]: isEqualDate(item.date, to),
                                        [styles.intermediate]: isIntermediateDate(item.date, from, to),
                                    })}>
                                    <div className={styles.value}>{item.date.getDate()}</div>

                                    {!isEqualDate(from, to) && <div className={styles.dockingMarking} />}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
