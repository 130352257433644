import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { roundNumber } from "@/utils/numbers";

import styles from "./PendingOrders.module.scss";

interface IPriceInputMobile {
    value?: string;
    initialValue?: string;
    step?: number;
    onChange: (value: string) => void;
    disabled?: boolean;
    //trim?: boolean;
    allowZero?: boolean;
    decimalDigitsCount?: number;
}

const PriceInputMobile: React.FC<IPriceInputMobile> = ({
    initialValue,
    value,
    step,
    onChange,
    disabled = false,
    //trim = false,
    allowZero = false,
    decimalDigitsCount = 5,
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const [innerValue, setInnerValue] = useState(value);

    useEffect(() => {
        onChange(Number(innerValue).toFixed(decimalDigitsCount));
    }, [innerValue]);

    const handleValueChange = (newValue: number) => {
        if (disabled || (!allowZero && newValue === 0)) {
            return;
        }
        setInnerValue(Number(newValue).toFixed(decimalDigitsCount));
        onChange(Number(newValue).toFixed(decimalDigitsCount));
    };

    const handleValueIncrease = () =>
        handleValueChange(roundNumber(Number(value) + step, step, "Point"));

    const handleValueDecrease = () =>
        handleValueChange(roundNumber(Number(value) - step, step, "Point"));

    const handleValueManualChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }

        const value = e.target.value;
        setInnerValue(value);
        if (value) {
            onChange(Number(value).toFixed(decimalDigitsCount));
        }
    };

    const handleBlur = () => {
        onChange(Number(innerValue || initialValue).toFixed(decimalDigitsCount));
        if (!innerValue) {
            setInnerValue(initialValue);
        }
    };

    let displayValue = innerValue;
    if (
        `${displayValue}`.includes(".") &&
        `${displayValue}`.split(".")[1]?.length >= decimalDigitsCount
    ) {
        displayValue = Number(innerValue).toFixed(decimalDigitsCount);
    }

    return (
        <div
            className={clsx(styles.priceSetter, {
                [styles.disabled]: disabled,
            })}>
            <div
                onClick={handleValueDecrease}
                className={clsx(styles.setter, styles.minus, {
                    [styles.disabled]: disabled,
                })}></div>
            <div className={styles.dataContainer}>
                <span className={styles[`title_${palette.mode}`]}>{t("at_price")}</span>
                <input
                    type="number"
                    className={styles[`amount_${palette.mode}`]}
                    value={displayValue}
                    pattern="\d*"
                    onChange={handleValueManualChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                />
                <span className={styles.actions}>
                    <span className={styles.increase} onClick={handleValueIncrease} />
                    <span className={styles.decrease} onClick={handleValueDecrease} />
                </span>
            </div>
            <div
                onClick={handleValueIncrease}
                className={clsx(styles.setter, styles.plus, {
                    [styles.disabled]: disabled,
                })}></div>
        </div>
    );
};

export default PriceInputMobile;
