import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import { PriceChangeType, QuoteData } from "@/hooks/trade/tradeSocket";
import { getAssetsPriceChangePercentage, getFormattedPrice } from "@/utils/symbols";
import { addToFavourites, removeFromFavourites } from "@/redux/actions/account";
import { AnimationClasses, useAnimation } from "@/hooks/common/useAnimation";
import { setAssetsTabActive, setToggleMobileFooter } from "@/redux/actions/ui";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useFavourites } from "@/redux/selectors/accountSelector";
import { useMarketWaitingTimeMessage } from "@/hooks/trade/schedule";
import AlertMessage from "../AlertMessage/AlertMessage";
import { useSystemFeedback } from "@/redux/selectors/systemFeedbackSelector";
import { setFavoritesAlertMessage } from "@/redux/actions/systemFeedback";

import styles from "./Header.module.scss";


export const Header: React.FC = () => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { setAnimation } = useAnimation();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { waitingTimeMessage, isMarketOpen } = useMarketWaitingTimeMessage(activeSymbolId);
    const symbol = symbolsInfoById[activeSymbolId];
    const favourites = useFavourites();
    const { favoritesAlerts } = useSystemFeedback();
    const isInFavourites = favourites?.length ? favourites.includes(activeSymbolId) : false;

    const onBackClickHandler = () => {
        setAnimation({ class: AnimationClasses.fadeInLeft });
        dispatch(setAssetsTabActive());
        dispatch(setToggleMobileFooter(true));

        if (favoritesAlerts.length) {
            dispatch(setFavoritesAlertMessage(null));
        }
    };

    return (
        <>
            {!isMarketOpen && (
                <AlertMessage
                    title={t("messages.marketIsClosed")}
                    description={waitingTimeMessage}
                />
            )}
            <div className={styles.header}>
                <div
                    className={styles.backIcon}
                    onClick={onBackClickHandler}
                />
                <div className={styles.infoBlock}>
                    <div className={styles.symbolName}>
                        {symbolsInfoById[activeSymbolId]?.ex?.displayName || ""}
                    </div>
                    <div className={styles.details}>
                        <span className={styles.priceValue}>
                            <QuoteData
                                symbolId={symbol.id}
                                priceChangeTTL={450}
                                render={({ bid, changeType }) => (
                                    <span
                                        className={clsx({
                                            [styles.priceChangeUp]: changeType === PriceChangeType.Up,
                                            [styles.priceChangeDown]:
                                                changeType === PriceChangeType.Down,
                                        })}>
                                        {getFormattedPrice(bid, symbol.point)}
                                    </span>
                                )}
                            />
                        </span>
                        <QuoteData
                            symbolId={symbol.id}
                            render={({ bid }) => {
                                const percentage = getAssetsPriceChangePercentage(
                                    bid,
                                    symbol.quote.midnightPrice
                                );
                                let displayValue: string;

                                if (isMarketOpen && percentage) {
                                    displayValue = `${percentage.toFixed(2)}%`;
                                } else {
                                    displayValue = "0.00%";
                                }

                                return (
                                    <span className={clsx(styles.changeValue, {
                                        [styles.negativeValue]: +percentage?.toFixed(2) < 0
                                    })}>
                                        {displayValue}
                                    </span>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className={styles.favContainer} onClick={e => e.stopPropagation()}>
                    {isInFavourites ?
                        <span
                            className={clsx(styles.favoritesIcon, styles.favoritesIconChecked)}
                            onClick={() => dispatch(removeFromFavourites(activeSymbolId))}
                        /> :
                        <span
                            className={styles.favoritesIcon}
                            onClick={() => dispatch(addToFavourites(activeSymbolId))}
                        />
                    }
                </div>
            </div>
        </>

    );
};

export default Header;
