import React from "react";
import { useDispatch } from "react-redux";
import AppModal from "@/components/Core/Modal/AppModal";
import { closeTradeOrderModal } from "@/redux/actions/ui";
import SelectedOrder from "@/components/OrderMobile/SelectedOrder";

import styles from "./EditOrderModal.module.scss";


const EditOrderModal = ({ order }): JSX.Element => {
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(closeTradeOrderModal());

    return (
        <AppModal
            onClose={handleCloseModal}
            className={styles.container}
        >
            <SelectedOrder
                order={order}
                onCancel={handleCloseModal}
            />
        </AppModal>
    );
};

export default React.memo(EditOrderModal);
