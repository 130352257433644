import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import styles from "./styles.module.scss";

export const Loader = ({ withTitle }): JSX.Element => {
    const { t, ready } = useTranslation("common");
    const { palette } = useTheme();

    return (
        <div className={styles.wrapper}>
            <div className={styles[`loading_bar_${palette.mode}`]} />
            {withTitle && ready && (
                <div className={styles[`text_${palette.mode}`]}>{t("loading.screen.text")}</div>
            )}
        </div>
    );
};
