import { useSelector } from "react-redux";

import { IModalState, PopupType } from "../interfaces/IModal";
import { IStore } from "../interfaces/IStore";
import { useEffect, useState } from "react";

export const useModal = (): IModalState => useSelector((state: IStore) => state.modal);

export const useIntroStep = (): number | null => {
    const [introStep, setIntroStep] = useState<number>(null);
    const modal = useModal();

    useEffect(() => {
        if (modal?.category) {
            if (modal.category.includes(PopupType.None) && introStep !== null) {
                setIntroStep(null);
            } else if (modal.category.includes("Intro")) {
                const step = +modal.category.split("_")[1];
                setIntroStep(step);
            }
        }
    }, [modal?.category]);
    
    return introStep;
};
