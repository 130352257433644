import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import styles from "./SixthStep.module.scss";

const iconUrls = {
    calculator: "/images/intro/mode/calculate.svg",
    trades: "/images/intro/mode/trades.svg",
    alerts: "/images/intro/mode/notification-bell.svg",
    pending_orders: "/images/intro/mode/pending_orders.svg",
    history: "/images/intro/mode/history.svg",
};

interface IProps {
    closeModal: () => void;
    children: JSX.Element;
}

export const SixthStep: React.FC<IProps> = ({ closeModal, children }) => {
    const { t } = useTranslation("common");
    const theme = useTheme();

    const icons = type => iconUrls[type].replace("mode", theme.palette.mode);

    return (
        <div className={styles.container}>
            <div onClick={closeModal} className={styles.close}>
                <div className={styles.closeIcon}></div>
            </div>
            <div className={styles.title}>{t("guidance.step06.title")}</div>
            <div className={styles.subtitle}>{t("guidance.step06.content")}</div>
            <div className={styles.icons_container}>
                <div
                    style={{ backgroundImage: `url(${icons("calculator")}` }}
                    className={clsx(styles.icon)}></div>
                <div
                    style={{ backgroundImage: `url(${icons("alerts")}` }}
                    className={clsx(styles.icon)}></div>
                <div
                    style={{ backgroundImage: `url(${icons("trades")}` }}
                    className={clsx(styles.icon)}></div>
                <div
                    style={{ backgroundImage: `url(${icons("pending_orders")}` }}
                    className={clsx(styles.icon)}></div>
                <div
                    style={{ backgroundImage: `url(${icons("history")}` }}
                    className={clsx(styles.icon)}></div>
            </div>
            {children}
        </div>
    );
};
