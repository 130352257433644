import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import styles from "./PendingOrders.module.scss";


interface IPendingOrderButton {
    isExpandedButton?: boolean;
    isDateSet?: boolean;
    toggleButton: () => void;
    toggleCalendar: () => void;
}

const PendingOrdersButton: React.FC<IPendingOrderButton> = ({
    isExpandedButton = false,
    isDateSet = false,
    toggleButton,
    toggleCalendar,
}) => {
    const { t } = useTranslation("common");

    return (
        <div
            onClick={toggleButton}
            className={clsx(styles.tab, {
                [styles.expanded]: isExpandedButton,
            })}
        >
            <div className={styles.watchIcon}></div>

            {isExpandedButton && (
                <>
                    <div className={styles.label}>{t("pendingOrder")}</div>
                    <div
                        onClick={toggleCalendar}
                        className={clsx(styles.calendarIcon, {
                            [styles.active]: isDateSet,
                        })}
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(PendingOrdersButton);
