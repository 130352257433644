import React, { useCallback, useState } from "react";
import Calendar from "react-calendar";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import moment from "moment";
import clsx from "clsx";
import TimePickerSwiper from "../TimePickerSwiper";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { BackdropWrapper } from "@/components/Core/Backdrop/Backdrop";
import { AnimationClasses } from "@/hooks/common/useAnimation";
import ArrowIcon from "@/components/Core/ArrowIcon/ArrowIcon";
import MobileMonthYearPicker from "@/components/Core/DateRangePickerMobile/MobileMonthYearPicker";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";

import styles from "./CalendarDrawer.module.scss";


const Header = ({ date, incrementMonth, decrementMonth, isYearMonthPickerOpen, openMobileMonthYearPicker }) => {
    const { t } = useTranslation("common");
    const isActive = moment(date).subtract(1, 'month').isSameOrAfter(moment().subtract(1, "day"));

    const month = t(`months.${moment(date).format("MMMM").toLowerCase()}`).slice(0, 3);
    const year = moment(date).format("YYYY");

    return (
        <div className={styles.date_container}>
            <div className={styles.currentDate} onClick={() => openMobileMonthYearPicker(!isYearMonthPickerOpen)}>
                <div className={clsx(styles.dateTitle, {
                    [styles.year_picker_open]: isYearMonthPickerOpen,
                })}>
                    {`${month} ${year}`}
                </div>
                <ArrowIcon isOpen={isYearMonthPickerOpen} />
            </div>
            <div className={styles.switcher_container}>
                <div
                    onClick={() => {
                        isActive && decrementMonth(date);
                    }}
                    className={clsx(styles.switcher_control, styles.switcher_left, {
                        [styles.active]: isActive,
                    })}></div>
                <div
                    onClick={() => {
                        incrementMonth(date);
                    }}
                    className={clsx(styles.switcher_control, styles.switcher_right, {
                        [styles.active]: true,
                    })}></div>
            </div>
        </div>
    );
};

interface IProps {
    onSet: (date: string) => void;
    onClose: () => void;
    value: string;
}

export const CaledarDrawer: React.FC<IProps> = ({ onSet, onClose, value }) => {
    const { t } = useTranslation("common");
    const { platform } = useDevice();
    const [date, setDate] = useState(new Date(value));
    const { palette } = useTheme();
    const { isTouched, buttonName, onTouchEnd, onTouchStart } = useTouchState();
    const [time, setTime] = useState("");
    const [isYearMonthPickerOpen, setYearMonthPickerOpen] = useState(false);

    const [hour, minute] = time.split(":");
    const isDateExpired = moment(date).set({ hour: +hour, minute: +minute })
        .isSameOrBefore(moment());

    const incrementMonth = value => {
        setDate(moment(value).add(1, "months").toDate());
    };

    const decrementMonth = value => {
        const isAfter = moment(value).isSameOrAfter(moment());

        return isAfter && setDate(moment(value).subtract(1, "months").toDate());
    };

    const changeMonth = value => {
        return setDate(moment(date).set("month", value).toDate());
    };

    const changeYear = value => {
        return setDate(moment(date).set("year", value).toDate());
    };

    const handleSet = () => {
        if (isYearMonthPickerOpen || isDateExpired) {
            return;
        }
        const formatedDateTime = `${moment(date).format("YYYY-MM-DD")} ${time}`;
        const formated = moment(formatedDateTime).format("YYYY-MM-DD[T]HH:mm:ss.SSS");

        onSet(formated);
    };

    const getWeekDayFormat = useCallback((locale, date) => {
        return t(`daysOfWeek.${moment(date).format("dddd").slice(0, 3)}`);
    }, []);

    return (
        <BackdropWrapper themeMode={palette.mode} onClose={onClose}>
            <div
                className={clsx(styles.drawerWrp, AnimationClasses.fadeInUp)}
                style={{ animationDuration: "0.3s" }}
            >
                <div className={clsx(styles.overlay, {
                    [styles.overlay_tablet]: platform === DevicePlatform.Tablet,
                })}>
                    <div className={styles.drawerInnerWrp}>
                        <Header
                            incrementMonth={incrementMonth}
                            decrementMonth={decrementMonth}
                            date={date}
                            isYearMonthPickerOpen={isYearMonthPickerOpen}
                            openMobileMonthYearPicker={setYearMonthPickerOpen}
                        />

                        {isYearMonthPickerOpen && (
                            <MobileMonthYearPicker
                                setMonth={changeMonth}
                                setYear={changeYear}
                                onClose={() => setYearMonthPickerOpen(false)}
                                minDate={new Date()}
                                date={date}
                            />
                        )}

                        {!isYearMonthPickerOpen && (
                            <>
                                <Calendar
                                    minDate={new Date()}
                                    value={date}
                                    activeStartDate={date}
                                    className={styles.calendar_container}
                                    locale={"en-EN"}
                                    showNavigation={false}
                                    formatShortWeekday={getWeekDayFormat}
                                    onChange={value => {
                                        setDate(moment(value).toDate());
                                    }}
                                />
                                <div className={styles.divider}></div>
                                <div className={styles.timePicker_wrp}>
                                    <TimePickerSwiper onChange={setTime} date={date} />
                                </div>
                            </>
                        )}
                        <div className={clsx(styles.controls_container, {
                            [styles.tablet]: platform === DevicePlatform.Tablet,
                            [styles.mobile]: platform === DevicePlatform.Mobile,
                        })}>
                            <div
                                className={clsx(styles.btn_cancel, {
                                    [styles.touched]: isTouched && buttonName === PressedButtons.CANCEL,
                                })}
                                onClick={onClose}
                                onTouchStart={() => onTouchStart(PressedButtons.CANCEL)}
                                onTouchEnd={onTouchEnd}
                            >
                                {t("cancel")}
                            </div>
                            <div
                                className={clsx(styles.btn_set, {
                                    [styles.disabled]: isYearMonthPickerOpen || isDateExpired,
                                    [styles.touched]: isTouched && buttonName === PressedButtons.APPLY,
                                })}
                                onClick={handleSet}
                                onTouchStart={() => onTouchStart(PressedButtons.APPLY)}
                                onTouchEnd={onTouchEnd}
                            >
                                {t("set")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BackdropWrapper>
    );
};
