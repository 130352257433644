import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import TradingOrderFormMobile from "../TradingOrderSection/TradingOrderFormMobile";
import { MarginTabletInfo } from "@/components/tablet";
import {
    CalculatorSection,
    InfoSection,
    SentimentsSection,
    TimeFramesSection,
    HowCalculateInfoSection,
    AssetPanelSection,
    CandlesticksSection,
    StopLossTakeProfitSection,
    Loader,
    Header,
    ProfitLossInfo,
} from "./components";

import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import useTradeChart, { initTradeChartTimeframe } from "@/hooks/chart";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { setLotSize } from "@/contexts/TradeOrder/actions";
import { AssetPageTabs, noAnimationsTabs } from "./constants";
import { AccountInfo } from "./components/AccountInfo";
import { useUI } from "@/redux/selectors/uiSelector";
import { CaledarDrawer } from "./components/CalendarDrawer";
import { PendingOrders } from "./components/PendingOrders";
import { toggleMobilePendingOrders } from "@/redux/actions/ui";
import { usePendingOrdersActions } from "./components/PendingOrders/components/PendingOrdersActions";
import { useHighLightButtons, useMobileActionTabsState } from "./hooks";
import { useSystemFeedback } from "@/redux/selectors/systemFeedbackSelector";
import { setFavoritesAlertMessage } from "@/redux/actions/systemFeedback";
import clsx from "clsx";

import styles from "./TradingAssetSection.module.scss";


const Chart: React.FC<{ isLoaded: boolean }> = ({ isLoaded }) => {
    return (
        <div className={clsx(null, {
            [styles.defaultMaxHeight]: !isLoaded
        })}>
            <div className={styles.graph_container}>
                <div className={styles.chart}
                    id="trading_chart_mobile"
                />
            </div>

            {!isLoaded && (
                <div className={styles.loaderWrp}>
                    <Loader />
                </div>
            )}
        </div>
    );
};

const calculateLotMax = symbolInfo => {
    const brand = window?.location.host?.split(".")[1];
    const assetName = symbolInfo?.id?.toLowerCase();
    const associatedBrands = ["investmarkets", "invesacapital"];
    const dangerouslySetLotMax = associatedBrands.includes(brand) && assetName === "salik";

    let lotMax = symbolInfo?.group?.lotMax || 100;

    if (dangerouslySetLotMax) {
        lotMax = 1000;
    }

    return lotMax;
};

const TradingAssetSection = (): JSX.Element => {
    const dispatch = useDispatch();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { lotSize } = useContext(TradeOrderStateContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const { isMobileAccountInfoOpen, isMobilePendingOrdersOpen } = useUI();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { isHighlightTradeButtons, setIsHighlightTradeButtons } = useHighLightButtons();
    const { favoritesAlerts } = useSystemFeedback();

    const [isChartLoaded, setIsChartLoaded] = useState(false);
    const [timeFrameValue, setTimeFrameValue] = useState(initTradeChartTimeframe);

    const symbol = symbolsInfoById[activeSymbolId];

    useEffect(() => {
        if (favoritesAlerts.length) {
            dispatch(setFavoritesAlertMessage(null));
        }
    }, []);

    const {
        isShowCalculateInfo,
        isShowCandlesticks,
        isShowInfo,
        isShowSentiments,
        isShowSetLossProfit,
        isShowSetLotSize,
        isShowTimeFrames,

        activeTab,
        prevTab,

        onCloseTab,
        onChangeTab,
    } = useMobileActionTabsState();

    const {
        closeCalendar,
        closePendingOrder,
        isCalendarOpen,
        onDateSet,
        date,
        isDateSet,
        toggleCalendar,
        togglePendingOrdersButton,
    } = usePendingOrdersActions();

    const pendingOrderUtils = useMemo(
        () => ({
            isDateSet,
            toggleCalendar,
            togglePendingOrdersButton,
            closePendingOrder,
        }),
        [isDateSet, isMobilePendingOrdersOpen]
    );

    const {
        changeTimeframe: changeChartTimeframe,
        changePriceType: changeChartPriceType,
    } = useTradeChart({ isChartLoaded: value => setIsChartLoaded(value) });

    const changeTimeFrame = useCallback(
        value => {
            setTimeFrameValue(value);
            changeChartTimeframe(value);
        },
        [changeChartTimeframe]
    );

    function showActiveTab(): JSX.Element {
        switch (true) {
            case isMobilePendingOrdersOpen || isCalendarOpen:
                return (
                    <>
                        {isCalendarOpen && (
                            <CaledarDrawer onSet={onDateSet} onClose={closeCalendar} value={date} />
                        )}
                        <PendingOrders
                            isCalendarOpen={isCalendarOpen}
                            date={date}
                            symbol={symbol}
                            toggleDrawer={() => {
                                dispatch(toggleMobilePendingOrders(false));
                            }}
                            isOpenDuration={!noAnimationsTabs.includes(prevTab)}
                        />
                    </>
                );
            case isMobileAccountInfoOpen:
                return <AccountInfo />;
            case isShowSentiments:
                return (
                    <SentimentsSection
                        onClose={() => onCloseTab(AssetPageTabs.SET_SENTIMENTS)}
                        isOpenDuration={!noAnimationsTabs.includes(prevTab)}
                    />
                );
            case isShowTimeFrames:
                return (
                    <TimeFramesSection
                        onClose={() => onCloseTab(AssetPageTabs.SET_SENTIMENTS)}
                        onChange={changeTimeFrame}
                        selectedValue={timeFrameValue}
                        isOpenDuration={!noAnimationsTabs.includes(prevTab)}
                    />
                );
            case isShowCandlesticks:
                return (
                    <CandlesticksSection
                        changeChartPriceType={changeChartPriceType}
                        isOpenDuration={!noAnimationsTabs.includes(prevTab)}
                    />
                );
            case isShowSetLossProfit:
                return (
                    <StopLossTakeProfitSection
                        onClose={() => onCloseTab(AssetPageTabs.SET_LOSS_PROFIT)}
                        setHighlightTradeButtons={setIsHighlightTradeButtons}
                    />
                );
            case isShowInfo:
                return (
                    <InfoSection onClose={() => onCloseTab(AssetPageTabs.SET_INFO)} symbol={symbol} />
                );
            case isShowCalculateInfo:
                return (
                    <HowCalculateInfoSection onClose={() => onCloseTab(AssetPageTabs.SET_CALCULATOR)} />
                );
            case isShowSetLotSize:
                return (
                    <CalculatorSection
                        className={styles.calculatorContainer}
                        onClose={() => onCloseTab(AssetPageTabs.SET_LOT)}
                        lotSize={lotSize}
                        lotMin={symbol?.group?.lotMin || 0.01}
                        lotMax={calculateLotMax(symbol)}
                        onChange={newLotSize => tradeOrderDispatch(setLotSize(newLotSize))}
                    />
                );
            default:
                return null;
        }
    }

    return (
        <div className={styles.container} ref={containerRef}>
            <Header />
            <Chart isLoaded={isChartLoaded} />

            <div className={styles.actionsPanel}>
                {isShowSetLossProfit ? (
                    <ProfitLossInfo isHighlightTradeButtons={isHighlightTradeButtons} />
                ) : (
                    <MarginTabletInfo lotSize={lotSize} />
                )}

                <AssetPanelSection
                    activeTab={activeTab}
                    onChangeTab={onChangeTab}
                    timeFrameValue={timeFrameValue}
                    pendingOrderUtils={pendingOrderUtils}
                />

                <TradingOrderFormMobile
                    isHighlighted={isHighlightTradeButtons}
                    className={styles.tradingOrderFormMobile}
                />

                {showActiveTab()}
            </div>
        </div>
    );
};

export default React.memo(TradingAssetSection);
