import React from "react";
import clsx from "clsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useTranslation } from "next-i18next";

import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { favouritesCategory } from "@/components/Home/TradingAssetsSection/TradingAssets";
//import { ModalTestingComponent } from "../../../../utils/ModalsTestComponent";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { useDevice } from "@/redux/selectors/deviceSelector";

import styles from "./TradingAssetsSectionMobile.module.scss";

const AssetsCategoryList = ({ activeCategory, setActiveCategory }) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { platform } = useDevice();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveCategory(newValue);
    };

    const { groups: assetCategories, search } = useTradeInfo();
    const categories = [favouritesCategory, ...assetCategories];

    return (
        <>
            {/* <ModalTestingComponent /> */}
            {categories.length ? (
                <Tabs
                    value={activeCategory || false}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    className={clsx(styles.categoryList)}>
                    {categories.map(({ name }) => {
                        const isActive = activeCategory === name;
                        const nameLowerCase = name == "BITA" ? name : name.toLocaleLowerCase();
                        let translated =
                            nameLowerCase === "cryptocurrencies"
                                ? t("crypto")
                                : t(name.toLocaleLowerCase());
                        if (translated == "bita") {
                            translated = name;
                        }

                        return (
                            <Tab
                                className={clsx(styles[`categoryListItem_${palette.mode}`], {
                                    [styles.categoryListItemActive]: isActive,
                                    [styles.tablet]: platform === DevicePlatform.Tablet,
                                })}
                                key={name}
                                label={translated}
                                value={name}
                                disabled={Boolean(search)}
                                disableRipple
                            />
                        );
                    })}
                </Tabs>
            ) : (
                <div style={{ width: "100%", padding: "0 0.5rem" }}>
                    <Skeleton height="3.8rem" animation="wave" />
                </div>
            )}
        </>
    );
};

export default React.memo(AssetsCategoryList);
