import React from "react";
import clsx from "clsx";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import TradeCalculatorMobile
    from "@/components/Home/TradingAssetSectionMobile/components/Calculator/TradeCalculatorMobile";

import styles from "./TradeValueCalculatorModal.module.scss";

interface IProps {
    value?: number;
    onSubmit: (value?: number) => void;
    isOpen: boolean;
    onClose: () => void;
    label: string;
    step: number;
}

const PriceCalculatorModal: React.FC<IProps> = ({ value, onSubmit, isOpen, onClose, label, step }) => {
    const theme = useTheme();

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
                <Box
                    id="app-theme-root"
                    className={clsx(styles.menuCalculator, `theme--${theme?.palette?.mode}` )}
                >
                    <div className={styles.closeIcon} onClick={onClose}/>

                    <TradeCalculatorMobile
                        className={styles.calculatorContainer}
                        onSubmit={onSubmit}
                        label={label}
                        value={value}
                        step={step}
                    />
                </Box>
            </Slide>
        </Modal>
    );
};

export default PriceCalculatorModal;
