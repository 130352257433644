import React, { useState } from "react";

import PendingPriceViewBlock
    from "@/components/Core/TakeProfitStopLoss/PendingPriceViewBlock";
import PriceCalculatorModal
    from "@/components/Home/TradingAssetSectionMobile/components/Calculator/PriceCalculatorModal";

interface IProps {
    value: number;
    onChange: (value) => void;
    label: string;
    step?: number;
    checked: boolean;
    onChangeChecked: () => void;
    disabled?: boolean;
}

const PendingPriceWithModalBlock: React.FC<IProps> = ({
    value,
    onChange,
    label,
    step,
    checked,
    disabled,
    onChangeChecked
}) => {
    const [isShowCalculator, setShowCalculator] = useState(false);

    return (
        <>
            <PendingPriceViewBlock
                onClick={() => setShowCalculator(true)}
                value={value}
                label={label}
                step={step}
                onChange={onChange}
                checked={checked}
                onChangeChecked={onChangeChecked}
                disabled={disabled}
            />

            <PriceCalculatorModal
                onSubmit={(value) => {
                    onChange(value);
                    setShowCalculator(false);
                }}
                step={step}
                label={label}
                value={value}
                onClose={() => setShowCalculator(false)}
                isOpen={isShowCalculator}
            />
        </>
    );
};

export default React.memo(PendingPriceWithModalBlock);
