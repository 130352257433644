import React from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import OrderInfoView from "@/components/SystemFeedbackMobile/common/OrderInfoView";
import { useUI } from "@/redux/selectors/uiSelector";
import { deleteOrderConfirm } from "@/redux/actions/orders";
import { closeTradeOrderModal } from "@/redux/actions/ui";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { assignPriceParamsToOrder } from "@/utils/helpers";
import { useQuoteData } from "@/hooks/trade/tradeSocket";

import styles from "./SystemFeedback.module.scss";

const DeleteOrderFeedback = (): JSX.Element => {
    const dispatch = useDispatch();
    const { tradeOrderModalParams } = useUI();
    const { t } = useTranslation();
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();

    // @ts-ignore
    const order = tradeOrderModalParams?.order;
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { ask, bid } = useQuoteData(activeSymbolId);
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const args = assignPriceParamsToOrder({
        ask,
        bid,
        symbolInfo,
        originalObject: {},
    });
    const handleDelete = () => dispatch(deleteOrderConfirm({ order, args }));

    const handleClose = () => dispatch(closeTradeOrderModal());

    return (
        <div className={clsx(styles.container, styles.deleteOrder)}>
            <div className={clsx(styles.icon, styles.dangerIcon)} />
            <div className={styles.title}>{t("about_delete_pending_order")}</div>

            <OrderInfoView order={order} operation="delete-confirm" />

            <div className={clsx(styles.btnContainer, styles.btnContainerTwoButtons)}>
                <button
                    className={clsx(styles.btn, styles.btnTransparent, {
                        [styles.touched]: isTouched && buttonName === PressedButtons.CANCEL,
                    })}
                    onClick={handleClose}
                    onTouchStart={() => onTouchStart(PressedButtons.CANCEL)}
                    onTouchEnd={onTouchEnd}>
                    {t("cancel")}
                </button>

                <button
                    className={clsx(styles.btn, {
                        [styles.touched]: isTouched && buttonName === PressedButtons.DELETE,
                    })}
                    onClick={handleDelete}
                    onTouchStart={() => onTouchStart(PressedButtons.DELETE)}
                    onTouchEnd={onTouchEnd}>
                    {t("delete")}
                </button>
            </div>
        </div>
    );
};

export default DeleteOrderFeedback;
