import clsx from "clsx";
import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";

import Footer, { FooterMobile,FooterTablet } from "@/components/Footer/footer";
import { PageMeta } from "@/components/pageMeta";
import { useBalanceCalculations } from "@/hooks/balanceCalculation";
import { setDynamicAccountInfoCalculated } from "@/redux/actions/account";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import { init } from "@/utils/mock-data";

import styles from "./layout.module.scss";

interface ILayout {
    children: ReactNode;
}

const LayoutDesktop: FunctionComponent<ILayout> = ({ children }) => {
    return (
        <>
            <div className={clsx(styles.container)}>{children}</div>

            <Footer />
        </>
    );
};

const LayoutTablet: FunctionComponent<ILayout> = ({ children }) => {
    const { showMobileFooter } = useUI();

    return (
        <>
            <div className={clsx(styles.containerTablet)}>{children}</div>
            {showMobileFooter && <FooterTablet />}
        </>
    );
};

const LayoutMobile: FunctionComponent<ILayout> = ({ children }) => {
    const { showMobileFooter } = useUI();

    return (
        <>
            <div className={clsx(styles.containerMobile)}>{children}</div>
            {showMobileFooter && <FooterMobile />}
        </>
    );
};

const Layout: FunctionComponent<ILayout> = ({ children }: ILayout) => {
    const dispatch = useDispatch();
    const { isDesktop, isTablet } = useDevice();
    const { defaultPageMetaProps } = init(); // static info which will be in CMS later

    useEffect(() => {
        dispatch(setDynamicAccountInfoCalculated(false));
    }, []);

    useBalanceCalculations();

    return (
        <div id="app-theme-root">
            <PageMeta payload={defaultPageMetaProps} />

            {isDesktop ? (
                <LayoutDesktop>{children}</LayoutDesktop>
            ) : isTablet ? (
                <LayoutTablet>{children}</LayoutTablet>
            ) : (
                <LayoutMobile>{children}</LayoutMobile>
            )}
        </div>
    );
};

export default React.memo(Layout);
