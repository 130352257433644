import React, { useCallback, useState } from "react";
import TradeCalculatorMobile
    from "@/components/Home/TradingAssetSectionMobile/components/Calculator/TradeCalculatorMobile";
import { trimNumberToStepValue } from "@/utils/numbers";
import PendingPriceViewBlock
    from "@/components/Core/TakeProfitStopLoss/PendingPriceViewBlock";

import styles from "./PendingPriceBlock.module.scss";


interface IPendingPriceBlockProps {
    value: number;
    onChange: (value) => void;
    label: string;
    step?: number;
    checked: boolean;
    onChangeChecked: () => void;
}

const PendingPriceBlock: React.FC<IPendingPriceBlockProps> = ({
    value,
    onChange,
    label,
    step,
    checked,
    onChangeChecked
}) => {
    const [isShowCalculator, setShowCalculator] = useState(false);

    const handleSubmitCalculator = useCallback((newValue) => {
        onChange(newValue);
        setShowCalculator(false);
    },[onChange]);

    const handleCancelCalculator = useCallback(() => setShowCalculator(false), []);
    const handleOpenCalculator = useCallback(() => setShowCalculator(true), []);

    if (isShowCalculator) {
        return (
            <TradeCalculatorMobile
                className={styles.calculatorContainer}
                value={+trimNumberToStepValue(value, step)}
                step={step}
                onSubmit={handleSubmitCalculator}
                onCancel={handleCancelCalculator}
                label={label}
            />
        );
    }

    return (
        <PendingPriceViewBlock
            onClick={handleOpenCalculator}
            value={value}
            label={label}
            step={step}
            onChange={onChange}
            checked={checked}
            onChangeChecked={onChangeChecked}
        />
    );
};

export default React.memo(PendingPriceBlock);
