import React from "react";
import clsx from "clsx";

import styles from "./AlertMessage.module.scss";


interface IAlertMessageProps {
    title: string;
    description: string;
    classes?: {
        common?: string
        icon?: string
    };
}

const AlertMessage: React.FC<IAlertMessageProps> = ({ title, description, classes }) => {
    return (
        <div className={clsx(styles.container, styles?.common)}>
            <div className={clsx(styles.icon, classes?.icon)}></div>
            <div className={clsx(styles.info, {
                [styles.no_open_time]: !description,
            })}>
                <div className={styles.title}>{title}</div>
                {description  && <div className={styles.description}>{description}</div>}
            </div>
        </div>
    );
};

export default React.memo(AlertMessage);
