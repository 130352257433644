import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { processedQuedOrdersString } from "@/utils/helpers";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { openTradeOrderModal } from "@/redux/actions/ui";
import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { CPluginErrorCodes } from "@/services/index";
import { openOrderErrorModal } from "@/components/Home/TradingOrderSection/Modal";
import {
    ISendOrderQueryParams,
    ITradeOrder,
    sendOrder,
    TradeOrderOperationType,
} from "@/services/trade/order";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import { trimNumberToStepValue } from "@/utils/numbers";
import { getStopLossPriceFromPips, getTakeProfitPriceFromPips } from "@/utils/symbols";
import { resetOrder, setLotSize } from "@/contexts/TradeOrder/actions";
import { ICommonAPIErrorResponse } from "@/services/common/api";
import { setSuccessfullyOpenedOrder } from "@/redux/actions/systemFeedback";
import { isForexSymbol } from "@/hooks/symbols";
import { setOrderAction } from "@/redux/actions/orders";
import { ITradeOrderOperationCategory } from "@/contexts/TradeOrder/interfaces";
import { useUI } from "@/redux/selectors/uiSelector";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { getSeenPrice } from "@/utils/getSeenPrice";

interface IPlaceOrderParams {
    actionType?: ITradeOrderOperationCategory;
    expirationDate?: string | Date;
}
interface IUseTradeOrderFormData {
    placeOrder: (params?: IPlaceOrderParams) => void;
    isRequestPending: boolean;
}

const useTradeOrderFormData = (): IUseTradeOrderFormData => {
    const dispatch = useDispatch();
    const { isDesktop } = useDevice();
    const { isMobilePendingOrdersOpen } = useUI();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const {
        lotSize,
        activeOperationCategory,
        isPendingOrder,
        pendingOrderPrice,
        isPendingOrderExpiration,
        pendingOrderExpiration,
        pendingOrderType,
        isStopLoss,
        stopLossPrice,
        stopLossPips,
        stopLossPriceCalcMode,
        isTakeProfit,
        takeProfitPrice,
        takeProfitPips,
        takeProfitPriceCalcMode,
        activeSymbolPoint,
    } = useContext(TradeOrderStateContext);

    const [isRequestPending, setIsRequestPending] = useState(false);
    const symbolInfo = symbolsInfoById[activeSymbolId];
    //const { ask, bid } = useQuoteData(symbolInfo.id, 0);

    const handleOrderSuccess = order => {
        if (isDesktop) {
            dispatch(
                openTradeOrderModal(ITradeOrderModalTypes.OPEN_ORDER_SUCCESS, {
                    order: order || {},
                })
            );
            return;
        }
        dispatch(setSuccessfullyOpenedOrder(order));
    };

    const handleOrderError = (code: CPluginErrorCodes, message: string) => {
        // REQUEST_PLACED
        if (code === "REQUEST_PLACED") {
            processedQuedOrdersString.put();

            setTimeout(() => {
                if (processedQuedOrdersString.getLast()) {
                    openOrderErrorModal({
                        dispatch,
                        code,
                        message,
                    });
                }
            }, 10000);
        } else {
            openOrderErrorModal({
                dispatch,
                code,
                message,
            });
        }
    };

    const placeOrder = async (params?: IPlaceOrderParams) => {
        const _isPendingOrder = isDesktop ? isPendingOrder : isMobilePendingOrdersOpen;

        if (isRequestPending || (_isPendingOrder && pendingOrderType === null)) {
            return;
        }

        setIsRequestPending(true);

        let orderOperation: TradeOrderOperationType = params?.actionType || activeOperationCategory;

        if (_isPendingOrder && pendingOrderPrice) {
            orderOperation = `${activeOperationCategory === TRADE_OPERATION_TYPE.BUY ? "Buy" : "Sell"}${
                pendingOrderType === "Stop" ? "Stop" : "Limit"
            }`;
        }

        const additionalArgs = {
            seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
            seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
        };

        const orderArgs: ISendOrderQueryParams = {
            symbol: activeSymbolId,
            operation: orderOperation,
            volume: Number(lotSize),
            ...additionalArgs,
        };

        if ((_isPendingOrder || isMobilePendingOrdersOpen) && pendingOrderPrice) {
            orderArgs.price = Number(trimNumberToStepValue(pendingOrderPrice, symbolInfo.point));
        }

        if (
            (isPendingOrderExpiration && !isDesktop && params.expirationDate) ||
            pendingOrderExpiration
        ) {
            orderArgs.expiration = new Date(params.expirationDate || pendingOrderExpiration)
                .toISOString()
                .replace(/\.000Z$/, "");
        }

        const isForex = isForexSymbol(symbolInfo);

        const sltpPriceCalcStoreArgs = {
            activeOperationCategory,
            activeSymbolId,
            symbolsInfoById,
            isPendingOrder: _isPendingOrder,
            pendingOrderPrice,
            activeSymbolPoint,
            isForex,
        };

        if (isStopLoss) {
            const reqStopLossPrice =
                stopLossPriceCalcMode === "Pips"
                    ? getStopLossPriceFromPips(stopLossPips, sltpPriceCalcStoreArgs)
                    : stopLossPrice;

            orderArgs.stopLoss = Number(trimNumberToStepValue(reqStopLossPrice, symbolInfo.point));
        }

        if (isTakeProfit) {
            const reqTakeProfitPrice =
                takeProfitPriceCalcMode === "Pips"
                    ? getTakeProfitPriceFromPips(takeProfitPips, sltpPriceCalcStoreArgs)
                    : takeProfitPrice;

            orderArgs.takeProfit = Number(trimNumberToStepValue(reqTakeProfitPrice, symbolInfo.point));
        }

        const orderResult = await sendOrder(orderArgs);

        const { ticket } = orderResult as ITradeOrder;

        if (ticket) {
            dispatch(setOrderAction(orderResult as ITradeOrder));
            handleOrderSuccess(orderResult);

            tradeOrderDispatch(resetOrder());
            tradeOrderDispatch(setLotSize(symbolInfo?.group?.lotMin || 0.01));
        } else {
            // error
            const { code, message } = (orderResult as ICommonAPIErrorResponse) || {
                code: CPluginErrorCodes.Error,
                message: "Unknown error",
            };

            handleOrderError(code, message);
        }

        setIsRequestPending(false);
    };

    return {
        placeOrder,
        isRequestPending,
    };
};

export default useTradeOrderFormData;
