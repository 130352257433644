import { useState, useLayoutEffect } from "react";

export const useVhHandler = (): number => {
    const [vh, setVh] = useState<number>(0);

    const updateCssVhValue = () => {
        const newVh = window.innerHeight * 0.01;

        setVh(newVh);

        document.documentElement.style.setProperty("--vh", `${newVh}px`);
    };

    useLayoutEffect(() => {
        updateCssVhValue();

        window.addEventListener("orientationchange", updateCssVhValue);
        window.addEventListener("resize", updateCssVhValue);
        return () => {
            window.removeEventListener("orientationchange", updateCssVhValue);
            window.removeEventListener("resize", updateCssVhValue);
        };
    }, []);

    return vh;
};
