import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

export const MobileTotalPl = ({ totalPL }) => {
    const {
        palette: { mode },
    } = useTheme();
    const { t } = useTranslation("common");
    const value = parseFloat(totalPL.replace(/[^\d.-]/g, ""));

    return (
        <div className={styles.main}>
            <div
                className={clsx(styles.decoratorLine, styles.left, {
                    [styles.dark]: mode === "dark",
                    [styles.light]: mode === "light",
                })}></div>
            <div className={styles.wrapper}>
                <span
                    className={clsx(styles.title, {
                        [styles.dark]: mode === "dark",
                        [styles.light]: mode === "light",
                    })}>
                    {t("pl")}:
                </span>
                <span
                    className={clsx(styles.PL, {
                        [styles.isProfit]: value > 0,
                        [styles.isLoss]: value < 0,
                    })}>
                    {totalPL}
                </span>
            </div>
            <div
                className={clsx(styles.decoratorLine, styles.right, {
                    [styles.dark]: mode === "dark",
                    [styles.light]: mode === "light",
                })}></div>
        </div>
    );
};
