import React from "react";
import clsx from "clsx";
import { Box, Modal, Slide, useTheme } from "@mui/material";

import TradingInfoMobile from "@/components/Home/TradingChartInfoSection/TradingInfoMobile";
import { getSymbolAssets } from "@/utils/symbols";
import { ISymbolInfo } from "@/services/trade/symbols";
import SymbolInfoImages from "../SymbolInfoImages/SymbolInfoImages";

import styles from "./TradeInfoListModal.module.scss";


interface IProps {
    onClose: () => void;
    symbol: ISymbolInfo;
}

const TradeInfoListModal: React.FC<IProps> = ({ onClose, symbol }) => {
    const { palette } = useTheme();
    const isDefaultTheme = palette.mode === "dark";
    const [assetImage1, assetImage2] = getSymbolAssets(symbol.id, symbol.group.name);

    return (
        <Modal open={true} onClose={onClose}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box
                    id="app-theme-root"
                    className={clsx(
                        styles.container,
                        `theme--${palette?.mode}`,
                        styles[`scroll_${palette?.mode}`]
                    )}
                >
                    <div className={styles.closeIcon} onClick={onClose} />
                    <div className={styles.header}>
                        <div
                            className={clsx(null, {
                                [styles.infoIcon]: isDefaultTheme,
                                [styles.infoIcon__light]: !isDefaultTheme,
                            })}
                            onClick={() => {}}>
                            <img src="/images/info.svg" alt="info icon" />
                        </div>
                        <SymbolInfoImages
                            imageName1={assetImage1}
                            imageName2={assetImage2}
                            showSymbolMainInfoName={true}
                            symbol={symbol}
                        />
                    </div>
                    <TradingInfoMobile />
                </Box>
            </Slide>
        </Modal>
    );
};

export default React.memo(TradeInfoListModal);
