import React, { useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Skeleton } from "@mui/material";
import { useTheme } from "@mui/material";

import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import AssetsTable from "./AssetsTable";
import { setActiveGroupName } from "@/redux/actions/tradeInfo";
import { useUI } from "@/redux/selectors/uiSelector";

import styles from "./TradingAssetsSection.module.scss";

export const favouritesCategory = {
    name: "Favourites",
    description: "",
};

const TradingAssets = ({ isSearching }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const {
        isQuoteDataLoaded,
        groups: assetCategories,
        activeGroupName: activeCategory,
        search,
    } = useTradeInfo();
    const { isAssetsTableExpanded } = useUI();
    const categories = [favouritesCategory, ...assetCategories].filter(c => c);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        dispatch(setActiveGroupName(newValue));
    };

    useEffect(() => {
        if (isQuoteDataLoaded && !activeCategory && assetCategories.length) {
            dispatch(setActiveGroupName(assetCategories[0].name));
        }
    }, [isQuoteDataLoaded, assetCategories, activeCategory, dispatch]);

    return (
        <div className={clsx(styles.content)}>
            <TabContext value={activeCategory}>
                <Box className={clsx(styles.categoryListWrap)}>
                    {isSearching && (
                        <div className={styles.searchResultPlaceholder}>{t("search_results")}:</div>
                    )}
                    {!isSearching && assetCategories.length ? (
                        <Tabs
                            value={activeCategory || false}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            className={clsx(styles.categoryList)}>
                            {categories.map(({ name = "" }) => {
                                const isActive = activeCategory === name;
                                const nameLowerCase = name == "BITA" ? name : name.toLocaleLowerCase();
                                let translated =
                                    nameLowerCase === "cryptocurrencies"
                                        ? t("crypto")
                                        : t(name.toLocaleLowerCase());
                                if (translated == "bita") {
                                    translated = name;
                                }
                                return (
                                    <Tab
                                        className={clsx(styles.categoryListItem, {
                                            [styles.categoryListItemActive]: isActive,
                                            [styles.listItemExpanded]: isAssetsTableExpanded,
                                        })}
                                        key={name}
                                        label={translated}
                                        value={name}
                                        disabled={Boolean(search)}
                                        disableRipple
                                    />
                                );
                            })}
                        </Tabs>
                    ) : (
                        <div style={{ width: "100%", padding: "0 0.5rem" }}>
                            {!isSearching && <Skeleton height="3.8rem" animation="wave" />}
                        </div>
                    )}
                </Box>
                <Box
                    className={clsx(styles.tableWrap, styles[`tableWrap_${palette.mode}`], {
                        [styles.isSearchOpen]: isSearching,
                    })}>
                    {isQuoteDataLoaded ? (
                        categories.map(({ name }) => {
                            return (
                                <TabPanel
                                    key={name}
                                    value={name}
                                    style={{ padding: "0", margin: "0", height: "100%" }}>
                                    <AssetsTable
                                        isSearching={isSearching}
                                        activeCategory={activeCategory}
                                    />
                                </TabPanel>
                            );
                        })
                    ) : (
                        <div style={{ width: "100%", padding: "0 0.5rem" }}>
                            {[...Array(13).keys()].map(key => (
                                <Skeleton key={key} height="2.8rem" animation="wave" />
                            ))}
                        </div>
                    )}
                </Box>
            </TabContext>
        </div>
    );
};

export default React.memo(TradingAssets);
