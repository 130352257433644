import React, { useMemo, useState, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "next-i18next";
import { Portal } from "react-overlays";
import { Fade } from "@mui/material";
import { TimePicker, DatePickerHeader } from "./components";
import { useResize } from "@/hooks/common/useResize";
import { getCalendatPopupPosition } from "./components/helpers";

import styles from "./DatePicker.module.scss";


interface ICustomDatePicker {
    minDate?: Date;
    maxDate?: Date;
    initDate?: string;
    showTimeInput?: boolean;
    onSubmit: (date: string) => void;
    orientation?: string;
    dateFormat?: string;
    customDateFilter?: (date: Date | string) => void;
    useMopperModifiers?: boolean;
    isTableExpanded?: boolean;
    classes?: {
        calendar_icon?: string;
        common?: string;
        dateInput?: string;
    };
}

const PopperContainer = ({ children }) => {
    const container = document.getElementById("app-theme-root");
    return <Portal container={container}>{children}</Portal>;
};

const UTC_FORMAT = "YYYY-MM-DD[T]HH:mm:ss.SSS";


export const CustomDatePicker: React.FC<ICustomDatePicker> = ({
    minDate,
    maxDate,
    initDate,
    onSubmit,
    showTimeInput = false,
    orientation = "auto",
    classes,
    dateFormat = "dd.MM.yyyy",
    customDateFilter,
    useMopperModifiers = false,
    isTableExpanded = false,
}) => {
    const { t } = useTranslation("common");
    const { width, height } = useResize();
    const timeStampId = +new Date();
    const datePickerRef = useRef<DatePicker>();
    const [date, onChangeDate] = useState<null | Date>(null);

    const [showYear, setShowYear] = useState(false);
    const [showMonthsPicker, setShowMonthsPicker] = useState(false);
    const [time, setTime] = useState(moment().format("HH:mm"));
    const [focused, setFocused] = useState("h");
    const closeCounter = useRef(0);

    const openCalendar = () => {
        datePickerRef?.current.setOpen(true);
    };

    const closeCalendar = (flag?: boolean) => {
        if (!flag) {
            closeCounter.current = 0;
        }

        datePickerRef.current.setOpen(false);
        if (showYear || showMonthsPicker) {
            setShowYear(false);
            setShowMonthsPicker(false);
        }
    };

    const timeSubmit = time => {
        const formatted = moment(date).format("YYYY-MM-DD");
        const dateTime = moment(`${formatted} ${time}`, "YYYY-MM-DD HH:mm").format(UTC_FORMAT);

        if (dateTime !== "Invalid time") {
            setTime(moment(dateTime).format("HH:mm"));
            onChangeDate(new Date(dateTime));
            datePickerRef?.current?.setOpen(false);
            onSubmit && onSubmit(dateTime);
        }
    };

    const handleSubmit = () => {
        const newDate = date ? moment(date).format(UTC_FORMAT) : moment().format(UTC_FORMAT);
        datePickerRef?.current?.setOpen(false);

        date && onSubmit && onSubmit(newDate);
    };

    useMemo(() => {
        date && !showTimeInput && handleSubmit();
    }, [date]);

    useMemo(() => {
        if (initDate) {
            onChangeDate(new Date(initDate));
        }
    }, []);

    const getWeekDayFormat = useCallback(nameOfDay => t(`daysOfWeek.${nameOfDay.slice(0, 3)}`), []);

    const onDateFilter = useCallback(
        date => {
            return customDateFilter
                ? customDateFilter(date)
                : minDate
                    ? moment(minDate, "dd-MM-yyyy").startOf("day").isSameOrBefore(moment(date, "dd-MM-yyyy"))
                    : true;
        },
        [customDateFilter, minDate]
    );

    const CalendarContainer = useCallback(({ children }) => {

        return (
            <Fade in={true} timeout={800}>
                <div className={styles.calendarContainer}>
                    <div>{children}</div>
                </div>
            </Fade>
        );
    }, []);

    const popperModifiers = useMemo(
        () => [
            {
                name: "offset",
                options: {
                    offset: (props) => {
                        return getCalendatPopupPosition({ ...props, width, height, isTableExpanded });
                    },
                },
            },
        ],
        [width, isTableExpanded]
    );

    return (
        <div id="scale" className={clsx(styles.inputWrapper, classes?.common)}>
            <DatePicker
                onCalendarClose={closeCalendar}
                dateFormat={dateFormat}
                formatWeekDay={getWeekDayFormat}
                selected={date}
                minDate={minDate}
                maxDate={maxDate}
                className={clsx(styles.dateInput, classes?.dateInput)}
                placeholderText={dateFormat}
                ref={datePickerRef}
                shouldCloseOnSelect={showTimeInput ? false : !showYear}
                showPopperArrow={false}
                showYearPicker={showYear}
                popperContainer={PopperContainer}
                popperPlacement={orientation}
                popperModifiers={useMopperModifiers && popperModifiers}
                calendarContainer={CalendarContainer}
                onChange={onChangeDate}
                showTimeInput={showTimeInput}
                onKeyDown={(event) => event.preventDefault()}
                onInputClick={() => {
                    // need to close calendar when dubble input click
                    // not possibility to find out previous calendar state (always state is true)
                    closeCounter.current++;
                    if (closeCounter.current % 2 === 0) {
                        closeCalendar(true);
                    }
                }}
                peekNextMonth
                yearItemNumber={21}
                filterDate={onDateFilter}
                customTimeInput={
                    <TimePicker
                        minDate={minDate}
                        outerTime={time}
                        setOuterTime={time => {
                            setTime(time);
                        }}
                        //@ts-ignore
                        initDate={date}
                        focused={focused} // NEED this crutch cause of limitation of using library
                        setFocused={setFocused} // TODO find another more flexible 3d party library for this purposes
                        onSubmit={timeSubmit}
                        onClose={closeCalendar}
                        offsetActive={!showYear}
                    />
                }
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <DatePickerHeader
                        minDate={minDate}
                        maxDate={maxDate}
                        date={date}
                        timeStampId={timeStampId}
                        changeYear={changeYear}
                        changeMonth={changeMonth}
                        decreaseMonth={decreaseMonth}
                        increaseMonth={increaseMonth}
                        onShowYear={() => {
                            setShowYear(!showYear);
                            setShowMonthsPicker(false);
                        }}
                        showMonthsPicker={showMonthsPicker}
                        setShowMonthsPicker={() => setShowMonthsPicker(!showMonthsPicker)}
                        showYear={showYear}
                        prevMonthButtonDisabled={prevMonthButtonDisabled}
                        nextMonthButtonDisabled={nextMonthButtonDisabled}
                    />
                )}
            />
            <div
                onClick={openCalendar}
                className={clsx(styles.calendar_icon, classes?.calendar_icon)}></div>
        </div>
    );
};
