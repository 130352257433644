import React from "react";
import { useDispatch } from "react-redux";

import { closeTradeOrderModal } from "@/redux/actions/ui";
import AppModal from "@/components/Core/Modal/AppModal";
import TradingAssetSection from "./TradingAssetSectionMobile";

import styles from "./common.module.scss";

const TradingAssetSectionMobile = (): JSX.Element => {
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(closeTradeOrderModal());

    return (
        <AppModal onClose={handleCloseModal} className={styles.container}>
            <TradingAssetSection />
        </AppModal>
    );
};

export default React.memo(TradingAssetSectionMobile);
