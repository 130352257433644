import React, { useState } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import styles from "../TradeHistory.module.scss";
import { useTheme } from "@mui/material";
// component styles
import _styles from "./styles.module.scss";

const MAX_SYMBOLS = 12;

const Popup = ({ text, position }) => {
    const {
        palette: { mode },
    } = useTheme();
    const { top, left } = position;

    return ReactDOM.createPortal(
        <div
            className={clsx(_styles.popup_container, {
                [_styles.dark]: mode === "dark",
                [_styles.light]: mode === "light",
            })}
            style={{ top: `${top}px`, left: `${left}px` }}>
            {text}
        </div>,
        document.body
    );
};

export const TableCellPopup = ({ text }) => {
    const isTextVeryLong = text && text.length >= MAX_SYMBOLS;
    const displayedText = isTextVeryLong ? `${text.slice(0, MAX_SYMBOLS)}...` : text;

    const [isPopupAppear, setPopupAppear] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

    const handleMouseEnter = event => {
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({ top: rect.bottom - 84, left: rect.left - 10 });
        setPopupAppear(isTextVeryLong && true);
    };

    const handleMouseLeave = () => {
        setPopupAppear(false);
        setPopupPosition({ top: 0, left: 0 });
    };

    return (
        <div
            className={styles.table_body_cell}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {displayedText}
            {isPopupAppear && <Popup text={text} position={popupPosition} />}
        </div>
    );
};
