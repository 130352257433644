import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { OrderTableType } from "@/components/TradesTable";
import { useTheme } from "@mui/material";
import TradeHeader from "@/components/OrderMobile/TradeHeader";
import TradeInfoGrid from "@/components/OrderMobile/TradeInfoGrid";
import EditOrderModal from "@/components/OrderMobile/EditOrderModal";
import { useUI } from "@/redux/selectors/uiSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { ITradeOrder } from "@/services/trade/order";
import { closeDeleteOrderAction } from "@/redux/actions/orders";
import { useAnimation } from "@/hooks/common/useAnimation";
import { useTouchState } from "@/hooks/common/useTouchState";

import styles from "./TradesList.module.scss";

interface IProps {
    tradeData: ITradeOrder;
    type: OrderTableType;
    onEdit: () => void;
    onDelete: () => void;
    onClose: () => void;
    closingTickets?: any;
}

const TradesListItem: React.FC<IProps> = ({
    tradeData,
    type,
    onEdit,
    onDelete,
    onClose,
    closingTickets,
}) => {
    const { palette } = useTheme();
    const { isOrderModifyPopupOpen, animationParams, tradeOrderModal } = useUI();
    const { activeOrderId } = useTradeInfo();
    const [isShowFullInfo, setShowFullInfo] = useState(false);
    const dispatch = useDispatch();
    const { setAnimation } = useAnimation();
    const { isTouched, onTouchEnd, onTouchStart } = useTouchState();
    const tradesItemRef = useRef<HTMLDivElement>(null);
    const [collapse, setCollapse] = useState(false);
    const isDarkMode = palette.mode === "dark";

    useEffect(() => {
        if (
            tradesItemRef?.current &&
            animationParams?.class &&
            animationParams?.params?.ticket === tradeData.ticket
        ) {
            const callback = () => {
                setCollapse(true);
                setTimeout(() => {
                    setAnimation(null);
                    dispatch(closeDeleteOrderAction(tradeData));
                }, 500);
            };

            tradesItemRef?.current.addEventListener("animationend", callback);

            return () => {
                if (tradesItemRef?.current) {
                    tradesItemRef?.current.removeEventListener("animationend", callback);
                }
            };
        }
    }, [animationParams]);

    return (
        <div
            className={clsx(styles.tradesItem, {
                [styles.collapse_item]: collapse && animationParams?.params?.ticket === tradeData.ticket,
                [styles.tradesItem_notExpanded]: !isShowFullInfo,
                [animationParams?.class]:
                    animationParams?.class &&
                    animationParams?.params?.ticket === tradeData.ticket &&
                    !tradeOrderModal,
            })}
            ref={tradesItemRef}>
            <div className={styles.tradesItem__header}>
                <TradeHeader tradeData={tradeData} />

                <div className={styles.actionContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.active]: isShowFullInfo,
                            [styles.touched]: isTouched,
                        })}
                        onClick={() => setShowFullInfo(prevState => !prevState)}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}>
                        <div
                            className={clsx(null, {
                                [styles.iconOpen]: !isShowFullInfo && isDarkMode,
                                [styles.iconClose]: isShowFullInfo && isDarkMode,
                                [styles.iconOpen_light]: !isShowFullInfo && !isDarkMode,
                                [styles.iconClose_light]: isShowFullInfo && !isDarkMode,
                                [styles.iconOpen_touched]: isTouched && !isShowFullInfo,
                                [styles.iconClose_touched]: isTouched && isShowFullInfo,
                            })}
                        />
                    </button>

                    {type !== OrderTableType.CLOSED && (
                        <button
                            disabled={closingTickets?.some(t => t === tradeData?.ticket)}
                            className={clsx(styles.btn)}
                            onClick={onEdit}>
                            <div
                                className={clsx(styles.iconEdit, {
                                    [styles.iconEdit_light]: !isDarkMode,
                                })}
                            />
                        </button>
                    )}

                    {type === OrderTableType.PENDING_ORDERS && (
                        <button
                            disabled={closingTickets?.some(t => t === tradeData?.ticket)}
                            className={clsx(styles.btn)}
                            onClick={onDelete}>
                            <div
                                className={clsx(styles.iconDelete, {
                                    [styles.iconDelete_light]: !isDarkMode,
                                })}
                            />
                        </button>
                    )}

                    {type === OrderTableType.OPEN_TRADES && (
                        <button
                            disabled={closingTickets?.some(t => t === tradeData?.ticket)}
                            className={clsx(styles.btn)}
                            onClick={onClose}>
                            <div
                                className={clsx(styles.iconCross, {
                                    [styles.iconCross_light]: !isDarkMode,
                                })}
                            />
                        </button>
                    )}
                </div>
            </div>

            <TradeInfoGrid order={tradeData} type={type} isShowFullInfo={isShowFullInfo} />

            {isOrderModifyPopupOpen && activeOrderId === tradeData.ticket && (
                <EditOrderModal order={tradeData} />
            )}
        </div>
    );
};

export default React.memo(TradesListItem);
