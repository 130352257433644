import React, { useState, RefObject, useContext } from "react";
import clsx from "clsx";

import useTradeChart from "@/hooks/chart";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useActiveSymbolDynamicFields } from "@/hooks/symbols";
import { TradeOrderStateContext } from "@/contexts/TradeOrder";

import styles from "./TradingChartInfoSection.module.scss";


const ChartContainerEl = () => {
    return <div className={clsx(styles.chart)} id="trading_chart" />;
};

const ChartContainer = React.memo(ChartContainerEl);

interface IProps {
    wrapRef: RefObject<HTMLDivElement>;
}

const TradingChart: React.FC<IProps> = props => {
    const [isLoading, setIsLoading] = useState(true);
    const { wrapRef } = props;
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    useTradeChart({
        wrapRef,
        // NOTE: triggering any re-render of the container causes chart canvas bugs
        showLoader: () => setIsLoading(true),
        hideLoader: () => setIsLoading(false),
    });

    const { lotSize = symbolInfo?.group?.lotMin || 0.01 } = useContext(TradeOrderStateContext);
    const { image1: assetImage1, image2: assetImage2 } = useActiveSymbolDynamicFields({ lotSize });

    const getAssetName = () => {
        const displayName = symbolInfo?.ex?.displayName || "";

        if (symbolInfo.group.name === "Currencies" && displayName) {
            return `${displayName.slice(0, 3)}/${displayName.slice(3, 6)}`;
        }
        if (symbolInfo.group.name === "Cryptocurrencies" && displayName.endsWith(symbolInfo.currency)) {
            return displayName.replace(symbolInfo.currency, `/${symbolInfo.currency}`);
        }
        return displayName || activeSymbolId;
    };

    return (
        <div className={styles.chartWrap}>
            <div className={styles.assetTopInfo}>
                <div
                    className={clsx(styles.assetIcon, {
                        [styles.singleAssetIcon]: !assetImage1 || !assetImage2,
                    })}>
                    <img src={assetImage1} alt="" />
                    <img src={assetImage2} alt="" />
                </div>
                <div className={styles.assetName}>
                    {getAssetName()}
                </div>
            </div>
            <ChartContainer />
            {isLoading && (
                <div className={styles.chartLoader}>
                    <div className={styles.loadingBox}>
                        <div className={styles.loader} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(TradingChart);
