import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { initPriceChartType, TradeChartPriceType } from "@/hooks/chart";
import { AnimationClasses } from "@/hooks/common/useAnimation";

import styles from "./CandlesticksBlock.module.scss";


interface IProps {
    changeChartPriceType: (priceChartType) => void;
    isOpenDuration?: boolean;
}

const CandlesticksBlock: React.FC<IProps> = ({
    changeChartPriceType,
    isOpenDuration = true,
}) => {
    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(initPriceChartType);

    const options = useMemo(() => {
        return [
            { key: TradeChartPriceType.Line, iconClassName: styles.lineIcon },
            { key: TradeChartPriceType.Area, iconClassName: styles.areaIcon },
            { key: TradeChartPriceType.Candlesticks, iconClassName: styles.candlesticksIcon },
            { key: TradeChartPriceType.HLC, iconClassName: styles.hlcIcon },
            { key: TradeChartPriceType.Dots, iconClassName: styles.dotsIcon },
            { key: TradeChartPriceType.Heikinashi, iconClassName: styles.heikinashiIcon },
            {
                key: TradeChartPriceType.HollowCandlesticks,
                iconClassName: styles.hollowСandlesticksIcon,
            },
            { key: TradeChartPriceType.OHLC, iconClassName: styles.ohlcIcon },
            { key: TradeChartPriceType.Histogram, iconClassName: styles.histogramIcon },
        ];
    }, []);

    const handleChangePriceChartType = useCallback(key => {
            setActiveTab(key);
            changeChartPriceType(key);
        },
        [changeChartPriceType]
    );

    return (
        <div
            style={{ animationDuration: isOpenDuration ? "0.3s" : "0s" }}
            className={clsx(styles.container, AnimationClasses.fadeInUp)}
        >
            <ul className={styles.chartTypeList}>
                {options.map(item => {
                    return (
                        <li
                            key={item.key}
                            onClick={() => handleChangePriceChartType(item.key)}
                            className={clsx(null, {
                                [styles.active]: item.key === activeTab,
                            })}>
                            <div className={styles.title}>
                                {t(`tradeChartPriceType.${item.key}`)}
                            </div>
                            <div className={clsx(styles.icon, item.iconClassName)} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default React.memo(CandlesticksBlock);
