import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import clsx from "clsx";
import {useTheme} from "@mui/material";

import styles from "./Modal.module.scss";

interface IProps {
    onClose: () => void;
    className?: string;
    children: JSX.Element;
    backdropColor?: string;
}

const AppModal = ({ onClose, className, children, backdropColor }: IProps): JSX.Element => {
    const theme = useTheme();

    return (
        <Modal
            open={true}
            onClose={onClose}
            BackdropProps={{ style: { backgroundColor: backdropColor } }}
            disableAutoFocus
        >
            <Box
                id="app-theme-root"
                className={clsx(styles.container, className, `theme--${theme?.palette?.mode}` )}
            >
                {children}
            </Box>
        </Modal>
    );
};

export default React.memo(AppModal);
