import { IPageMetaProps } from "@/components/pageMeta";
import { Pages, ROUTES } from "@/utils/routes";

export function init() {
    const defaultPageMetaProps: IPageMetaProps = {
        pageTitle: "Trading Online",
        metaMobileTitle: "Trading Online",
        metaTitle: "Trading Online",
        metaDescription: "Trading Online",
        metaUrl: ROUTES[Pages.Home],
        metaImage: "",
        css: "",
        gtm: "",
    };

    return { defaultPageMetaProps };
}
