import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";

import { TradeOrderStateContext, TradeOrderDispatchContext } from "@/contexts/TradeOrder";
import { setLotSize } from "@/contexts/TradeOrder/actions";
import { getValueInRange, STEPS, validateLotSizeValue } from "./helpers";
import { removeNonWhitelistedChars } from "@/utils/index";

import styles from "./LotSize.module.scss";

const getIncreasedSize = (size: number | string): number => {
    return STEPS.find(step => +size < step) || Number(size) + 100;
};

const getDecreasedSize = (size: number | string): number => {
    const reverseSteps = [...STEPS].reverse();
    const maxStep = reverseSteps[0];

    if (Number(size) - maxStep > maxStep) {
        return Number(size) - maxStep;
    }

    return reverseSteps.find(step => +size > step) || Number(size) - 0.01;
};

const LOT_SIZE_CHARS_WHITELIST = "0123456789.,";

interface ILotSizeProps {
    defaultLotSize?: number | string;
    min: number;
    max: number;
    disabled?: boolean;
    fullWidth?: boolean;
    setIsValid: (value: boolean) => void;
    showErrorMessage?: boolean;
}

const LotSize = (props: ILotSizeProps): JSX.Element => {
    const { min, max, disabled = false, fullWidth = false, setIsValid } = props;
    const { palette } = useTheme();
    const { t } = useTranslation("common");

    const {
        register,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { lotSize } = useContext(TradeOrderStateContext);

    const _rmvZeros = val => {
        if (val[0] === "0" && val[1] && val[1] !== ".") {
            return val.substring(1);
        }
        return val;
    };

    const handleValueEdit = (val: string): void => {
        if (disabled) {
            return;
        }

        // need this to dispatch empty value and not broke other logic
        const enteredValue = val ? val : "";

        if (val === null) {
            tradeOrderDispatch(setLotSize(null));
        }

        const value = removeNonWhitelistedChars(enteredValue, LOT_SIZE_CHARS_WHITELIST);

        if (value && value.split(".")[1] && value.split(".")[1].length > 2) {
            return;
        }

        const isValid = validateLotSizeValue(value, clearErrors, setError, min, max, t, setIsValid);

        setIsValid(isValid);

        if (isValid && Number(value) <= max) {
            tradeOrderDispatch(setLotSize(value));
        }
    };

    const handleValueStepChange = (newValue: number): void => {
        if (disabled) {
            return;
        }

        clearErrors();
        setIsValid(true);
        tradeOrderDispatch(setLotSize(getValueInRange(newValue, min, max)));
    };

    const lotInput = register("lotInput");

    return (
        <form onSubmit={e => e.preventDefault()}>
            <div
                className={clsx(styles.container, {
                    [styles.disabled]: disabled,
                    [styles.fullWidth]: fullWidth,
                })}>
                <div className={clsx(styles.label, styles[`label_${palette.mode}`])}>
                    {t("volumeInLot")}
                </div>
                <input
                    className={clsx(styles.input, styles[`input_${palette.mode}`], {
                        [styles.error]: errors?.lotInput,
                    })}
                    placeholder={t("volumeALot")}
                    value={removeNonWhitelistedChars(`${lotSize}`, LOT_SIZE_CHARS_WHITELIST)}
                    onChange={e => {
                        const val = _rmvZeros(e.target.value);

                        lotInput.onChange(e);
                        handleValueEdit(val || null);
                    }}
                    disabled={false}
                />
                <div className={styles.error_container}>
                    {errors?.lotInput?.message && <span>{errors.lotInput.message as string}</span>}
                </div>
                <span className={styles.actions}>
                    <span
                        className={styles[`increase_${palette.mode}`]}
                        onClick={() => handleValueStepChange(getIncreasedSize(lotSize))}>
                        <img src="/images/lot-size-arrow-up.svg" alt="Increase lot" />
                    </span>
                    <span
                        className={styles[`decrease_${palette.mode}`]}
                        onClick={() => handleValueStepChange(getDecreasedSize(lotSize))}>
                        <img src="/images/lot-size-arrow-up.svg" alt="Decrease lot" />
                    </span>
                </span>
            </div>
        </form>
    );
};

export default React.memo(LotSize);
