import { TradeChartTimeframe } from "@/hooks/chart";
import { AssetPageTabs } from "../../constants";
import { getIconPath } from "@/utils/helpers";


export function getTimeFrames(timeFrameValue: TradeChartTimeframe, t: (string) => string): string {
    switch (timeFrameValue) {
        case TradeChartTimeframe.Min1:
            return `1 ${t("dateRange.min")}`;
        case TradeChartTimeframe.Min5:
            return `5 ${t("dateRange.min")}`;
        case TradeChartTimeframe.Min15:
            return `15 ${t("dateRange.min")}`;
        case TradeChartTimeframe.Min30:
            return `30 ${t("dateRange.min")}`;
        case TradeChartTimeframe.Hour:
            return `1 ${t("dateRange.hour").slice(0, 1)}`;
        case TradeChartTimeframe.Hour4:
            return `4 ${t("dateRange.hour").slice(0, 1)}`;
        case TradeChartTimeframe.Day:
            return `1 ${t("dateRange.day").slice(0, 1)}`;
        case TradeChartTimeframe.Week:
            return `1 ${t("dateRange.week").slice(0, 1)}`;
        case TradeChartTimeframe.Month:
            return `1 ${t("dateRange.month").slice(0, 1)}`;
        default:
            return "";
    }
}

interface IGetPanelProps {
    onChangeTab: (value: AssetPageTabs) => void;
    t: (string) => string;
    tabsParams: {
        lotSize: number | string;
        timeFrameValue: TradeChartTimeframe;
    };
}

interface IGetPanelReturn {
    tabId: AssetPageTabs;
    title: string;
    iconPath: string;
    actionHandler: () => void;
}

export function getPanelTabs({ onChangeTab, tabsParams, t }: IGetPanelProps): IGetPanelReturn[] {
    const { lotSize, timeFrameValue } = tabsParams;
    return [
        {
            tabId: AssetPageTabs.ACCOUNT_INFO,
            title: "",
            iconPath: getIconPath({ name: "user" }),
            actionHandler: () => onChangeTab(AssetPageTabs.ACCOUNT_INFO),
        },
        {
            tabId: AssetPageTabs.SET_LOT,
            title: `${lotSize} ${t("lot")}`,
            iconPath: "",
            actionHandler: () => onChangeTab(AssetPageTabs.SET_LOT),
        },
        {
            tabId: AssetPageTabs.SET_LOSS_PROFIT,
            title: "",
            iconPath: getIconPath({ name: "shield" }),
            actionHandler: () => onChangeTab(AssetPageTabs.SET_LOSS_PROFIT),
        },
        {
            tabId: AssetPageTabs.PENDING_ORDER,
            title: "",
            iconPath: getIconPath({ name: "alarm" }),
            actionHandler: () => onChangeTab(AssetPageTabs.PENDING_ORDER),
        },
        {
            tabId: AssetPageTabs.CANDLESTICKS,
            title: "",
            iconPath: getIconPath({ name: "sticks" }),
            actionHandler: () => onChangeTab(AssetPageTabs.CANDLESTICKS),
        },
        {
            tabId: AssetPageTabs.SET_TIME_FRAMES,
            title: getTimeFrames(timeFrameValue, t),
            iconPath: getIconPath({ name: "clock2" }),
            actionHandler: () => onChangeTab(AssetPageTabs.SET_TIME_FRAMES),
        },
        {
            tabId: AssetPageTabs.SET_INFO,
            title: "",
            iconPath: getIconPath({ name: "info" }),
            actionHandler: () => onChangeTab(AssetPageTabs.SET_INFO),
        },
        {
            tabId: AssetPageTabs.SET_SENTIMENTS,
            title: "",
            iconPath: getIconPath({ name: "bull-bear" }),
            actionHandler: () => onChangeTab(AssetPageTabs.SET_SENTIMENTS),
        },
        {
            tabId: AssetPageTabs.SET_CALCULATOR,
            title: "",
            iconPath: getIconPath({ name: "calculator" }),
            actionHandler: () => onChangeTab(AssetPageTabs.SET_CALCULATOR),
        },
    ];
}

export const handleScroll = (tab: AssetPageTabs, ref: React.MutableRefObject<HTMLDivElement>): void => {
    if (tab === AssetPageTabs.ACCOUNT_INFO && ref.current.scrollLeft > 15) {
        ref.current.scrollLeft = 0;
    } else if (tab === AssetPageTabs.SET_LOT && ref.current.scrollLeft > 67) {
        ref.current.scrollLeft = 59;
    } else if (tab === AssetPageTabs.SET_LOSS_PROFIT && ref.current.scrollLeft > 150) {
        ref.current.scrollLeft = 143;
    } else if (
        tab === AssetPageTabs.PENDING_ORDER &&
        (ref.current.scrollLeft > 203 || ref.current.scrollLeft < 20)
    ) {
        ref.current.scrollLeft = 105;
    } else if (tab === AssetPageTabs.SET_SENTIMENTS && ref.current.scrollLeft < 144) {
        ref.current.scrollLeft = 150;
    } else if (tab === AssetPageTabs.SET_TIME_FRAMES && ref.current.scrollLeft < 29) {
        ref.current.scrollLeft = 36;
    } else if (tab === AssetPageTabs.SET_INFO && ref.current.scrollLeft < 80) {
        ref.current.scrollLeft = 89;
    } else if (tab === AssetPageTabs.SET_CALCULATOR && ref.current.scrollLeft < 197) {
        ref.current.scrollLeft = 210;
    }
};
