import {
    ITradeOrderOperationCategory,
    IResetOrderAction,
    ISetActiveOperationCategoryAction,
    ISetActiveSymbolAction,
    ISetLotSizeAction,
    ISetPendingOrderExpirationAction,
    ISetPendingOrderPriceAction,
    ISetStopLossPipsAction,
    ISetStopLossPriceAction,
    ISetTakeProfitPipsAction,
    ISetTakeProfitPriceAction,
    ITogglePendingOrderAction,
    ITogglePendingOrderExpirationAction,
    TradeOrderActionTypes,
    ISetIsStopLossAction,
    ISetIsTakeProfitAction,
} from "./interfaces";

export const setActiveSymbol = (
    id: string,
    point: number,
    isForex: boolean
): ISetActiveSymbolAction => ({
    type: TradeOrderActionTypes.SET_ACTIVE_SYMBOL,
    id,
    point,
    isForex,
});

export const setActiveOperationCategory = (
    category?: ITradeOrderOperationCategory,
    ask?: number,
    bid?: number
): ISetActiveOperationCategoryAction => ({
    type: TradeOrderActionTypes.SET_ACTIVE_OPERATION_CATEGORY,
    category,
    ask,
    bid,
});

export const setLotSize = (size: number | string): ISetLotSizeAction => {
    return {
        type: TradeOrderActionTypes.SET_LOT_SIZE,
        size,
    };
};

export const resetOrder = (): IResetOrderAction => ({
    type: TradeOrderActionTypes.RESET_ORDER,
});

export const togglePendingOrder = (): ITogglePendingOrderAction => ({
    type: TradeOrderActionTypes.TOGGLE_PENDING_ORDER,
});

export const togglePendingOrderExpiration = (): ITogglePendingOrderExpirationAction => ({
    type: TradeOrderActionTypes.TOGGLE_PENDING_ORDER_EXPIRATION,
});

export const setIsStopLoss = (isActive: boolean, price?: number): ISetIsStopLossAction => ({
    type: TradeOrderActionTypes.SET_IS_STOP_LOSS,
    isActive,
    price,
});

export const setIsTakeProfit = (isActive: boolean, price?: number): ISetIsTakeProfitAction => ({
    type: TradeOrderActionTypes.SET_IS_TAKE_PROFIT,
    isActive,
    price,
});

export const setPendingOrderPrice = (
    price: number,
    marketPrice: number
): ISetPendingOrderPriceAction => ({
    type: TradeOrderActionTypes.SET_PENDING_ORDER_PRICE,
    price,
    marketPrice,
});

export const setPendingOrderExpiration = (expiration: string): ISetPendingOrderExpirationAction => ({
    type: TradeOrderActionTypes.SET_PENDING_ORDER_EXPIRATION,
    expiration,
});

export const setStopLossPrice = (price: number): ISetStopLossPriceAction => ({
    type: TradeOrderActionTypes.SET_STOP_LOSS_PRICE,
    price,
});

export const setStopLossPips = (pips: number): ISetStopLossPipsAction => ({
    type: TradeOrderActionTypes.SET_STOP_LOSS_PIPS,
    pips,
});

export const setTakeProfitPrice = (price: number): ISetTakeProfitPriceAction => ({
    type: TradeOrderActionTypes.SET_TAKE_PROFIT_PRICE,
    price,
});

export const setTakeProfitPips = (pips: number): ISetTakeProfitPipsAction => ({
    type: TradeOrderActionTypes.SET_TAKE_PROFIT_PIPS,
    pips,
});
