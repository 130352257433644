const getXAxis = (width, reference, placement, isTableExpanded) => {
    if (width <= 1366 && width > 1260) return -reference.x + 458 + (isTableExpanded ? 270 : 0);
    if (width <= 1260 && width > 1200) return -reference.x + 388 + (isTableExpanded ? 255 : 0);
    if (width <= 1200 && width > 1130) return -reference.x + 384 + (isTableExpanded ? 225 : 0);
    if (width <= 1130 && width > 920) return -192 + (isTableExpanded ? -113 : 0);
    if (width > 1366) return 0;
    return undefined;
};

const getYAxis = (width, height, reference, placement) => {
    let res = 0;
    if (height >= 769) {
        if (width <= 1366 && width > 1260) res = -102;
        if (width <= 1260 && width > 1200) res = -reference.y + 330;
        if (width <= 1200 && width > 1130) res = -reference.y + 345;
        if (width <= 1130 && width > 920) res = -190;
        if (width > 1366 && placement === "top-start") res = 100;
    } else {
        if (width <= 1366 && width > 1260) res = -461;
        if (width <= 1260 && width > 1200) res = -505;
        if (width <= 1200 && width > 1130) res = -512;
        if (width <= 1130 && width > 920) res = -503;
    }

    return res;
};

export const getCalendatPopupPosition = ({ width, height, reference, placement, isTableExpanded }) => {
    return [
        getXAxis(width, reference, placement, isTableExpanded),
        getYAxis(width, height, reference, placement),
    ];
};

export const generateYears = (startYear = 2010, count = 21): Array<number[]> => {
    const years = [];
    let temp = [];
    for (let i = 0; i < count; i++) {
        temp.push(startYear + i);
        if (temp.length === 3) {
            years.push(temp);
            temp = [];
        }
    }
    return years;
};

export const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
