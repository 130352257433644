import React, { useContext, useState, useRef, useEffect } from "react";
import { processedQuedOrdersString } from "@/utils/helpers";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Box, Button, Divider, Stack, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "next-i18next";
import { useWindowResize } from "@/utils/hooks/Device";
import { TradeOrderStateContext, TradeOrderDispatchContext } from "@/contexts/TradeOrder";
import TradeButton, { TradeButtonOperationType } from "@/components/Core/Button/TradeButton/TradeButton";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { setActiveOperationCategory } from "@/contexts/TradeOrder/actions";
import { setTradeOrderView } from "@/redux/actions/ui";
import { useActiveSymbolDynamicFields } from "@/hooks/symbols";
import PendingOrder from "./PendingOrder/PendingOrder";
import StopLossTakeProfit from "./StopLossTakeProfit/StopLossTakeProfit";
import LotSize from "./LotSize/LotSize";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import useTradeOrderFormData from "@/components/Home/TradingOrderSection/hooks";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { isInvalidPendingOrderPrice, isInvalidSLTP } from "@/utils/trade";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import parentFrameAPI from "@/services/parent";

import { useResize } from "@/hooks/common/useResize";
import { hasElementScroll } from "@/utils/helpers";
import { normalyzeDecimalLength } from "@/utils/format";

import styles from "./TradingOrderSection.module.scss";

interface IProps {
    isAdvanced: boolean;
    tradeBtnWidth: number;
}

interface IDynamicFormFields {
    isAdvanced: boolean;
    showFlashButton: boolean;
    setIsLotSizeValid: (value: boolean) => void;
}

const calculateLotMax = symbolInfo => {
    const brand = window?.location.host?.split(".")[1];
    const assetName = symbolInfo?.id?.toLowerCase();
    const associatedBrands = ["investmarkets", "invesacapital"];
    const dangerouslySetLotMax = associatedBrands.includes(brand) && assetName === "salik";

    let lotMax = symbolInfo?.group?.lotMax || 100;

    if (dangerouslySetLotMax) {
        lotMax = 1000;
    }

    return lotMax;
};

const DynamicFormFields: React.FC<IDynamicFormFields> = props => {
    const { isAdvanced, showFlashButton, setIsLotSizeValid } = props;
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const introStep = useIntroStep();
    const isCurrentIntroStep = introStep === 3;
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { lotSize = symbolInfo?.group?.lotMin || 0.01, activeOperationCategory } = useContext(
        TradeOrderStateContext
    );

    const lotMin = symbolInfo?.group?.lotMin || 0.01;
    const lotMax = calculateLotMax(symbolInfo);

    const [displayLot, setDisplayLot] = useState(+lotSize > lotMax ? lotMin : lotSize);
    useEffect(() => {
        setDisplayLot(lotSize || 0.01);
    }, [lotSize]);

    const { ask, bid } = useQuoteData(activeSymbolId);

    const {
        isForex: isForexSymbol,
        leverageDisplayValue,
        lotDisplayValue,
        pipDisplayValue,
        pointDisplayValue,
        spreadInPipsDisplayValue,
        spreadInPointsDisplayValue,
        lotBuyDisplayValueInAccountCurrency,
        lotSellDisplayValueInAccountCurrency,
        requiredBuyMarginDisplayValue,
        requiredSellMarginDisplayValue,
    } = useActiveSymbolDynamicFields({ lotSize, operationType: activeOperationCategory });
    const lotDisplayValueInAccountCurrency =
        activeOperationCategory === TRADE_OPERATION_TYPE.SELL
            ? lotSellDisplayValueInAccountCurrency
            : lotBuyDisplayValueInAccountCurrency;

    const requiredMarginDisplayValue =
        activeOperationCategory === TRADE_OPERATION_TYPE.SELL
            ? requiredSellMarginDisplayValue
            : requiredBuyMarginDisplayValue;

    return (
        <div className={clsx(styles.orderFormWrap, { [styles.overlayed]: isCurrentIntroStep })}>
            <Box>
                <LotSize
                    defaultLotSize={displayLot}
                    min={lotMin}
                    max={lotMax}
                    setIsValid={value => {
                        setIsLotSizeValid(value);
                    }}
                    showErrorMessage={false}
                />
            </Box>
            <div className={styles.orderInfoList}>
                <div className={styles.orderInfoListRow}>
                    <div className={styles[`orderInfoListLabel_${palette.mode}`]}>
                        {displayLot} {t("lot")}:
                    </div>
                    <div className={styles[`orderInfoListValue_${palette.mode}`]}>
                        {lotDisplayValue}
                        {lotDisplayValueInAccountCurrency ? (
                            <>
                                <br />({lotDisplayValueInAccountCurrency})
                            </>
                        ) : null}
                    </div>
                </div>
                <div className={styles.orderInfoListRow}>
                    <div className={styles.orderInfoListLabel}>{t("leverage")}:</div>
                    <div className={styles.orderInfoListValue}>{leverageDisplayValue}</div>
                </div>
                <div className={styles.orderInfoListRow}>
                    <div className={styles.orderInfoListLabel}>
                        {t(isForexSymbol ? "pips_value" : "pointValue.label")}:
                    </div>
                    <div className={styles.orderInfoListValue}>
                        {isForexSymbol ? normalyzeDecimalLength(pipDisplayValue, 2) : pointDisplayValue}
                    </div>
                </div>
                <div className={styles.orderInfoListRow}>
                    <div className={styles.orderInfoListLabel}>{t("requiredMargin")}:</div>
                    <div className={styles.orderInfoListValue}>{requiredMarginDisplayValue}</div>
                </div>
                <div className={styles.orderInfoListRow}>
                    <div className={styles.orderInfoListLabel}>{t("spread")}:</div>
                    <div className={styles.orderInfoListValue}>
                        {isForexSymbol ? spreadInPipsDisplayValue : spreadInPointsDisplayValue}
                    </div>
                </div>
            </div>

            <Divider />

            <div className={styles.buySellButtons}>
                <TradeButton
                    symbolId={activeSymbolId}
                    operation={TRADE_OPERATION_TYPE.SELL}
                    active={activeOperationCategory === TRADE_OPERATION_TYPE.SELL}
                    showFlashButton={isAdvanced && showFlashButton && !activeOperationCategory}
                    onClick={() => {
                        tradeOrderDispatch(
                            setActiveOperationCategory(TRADE_OPERATION_TYPE.SELL, ask, bid)
                        );
                    }}
                    classes={{ buy: styles.buy, sell: styles.sell }}
                />
                <TradeButton
                    symbolId={activeSymbolId}
                    operation={TRADE_OPERATION_TYPE.BUY}
                    active={activeOperationCategory === TRADE_OPERATION_TYPE.BUY}
                    showFlashButton={isAdvanced && showFlashButton && !activeOperationCategory}
                    onClick={() => {
                        tradeOrderDispatch(
                            setActiveOperationCategory(TRADE_OPERATION_TYPE.BUY, ask, bid)
                        );
                    }}
                    classes={{ buy: styles.buy, sell: styles.sell }}
                />
            </div>
        </div>
    );
};

interface IDynamicTradeButtonProps {
    isLotSizeValid: boolean;
    tradeBtnWidth: number;
    isFixed: boolean;
}

const DynamicTradeButton: React.FC<IDynamicTradeButtonProps> = props => {
    const { tradeBtnWidth, isLotSizeValid, isFixed = false } = props;
    const { t } = useTranslation("common");
    const theme = useTheme();
    const { placeOrder, isRequestPending } = useTradeOrderFormData();
    const { activeSymbolId } = useTradeInfo();
    const { isLoggedInUser } = useLoginInfo();

    const {
        lotSize,
        activeOperationCategory,
        isPendingOrder,
        isStopLoss,
        stopLossPrice,
        isTakeProfit,
        takeProfitPrice,
        pendingOrderType,
        pendingOrderPrice,
    } = useContext(TradeOrderStateContext);

    const { tradeOrderModal } = useUI();
    const { ask, bid } = useQuoteData(activeSymbolId);
    const isInvalidPendingOrderParams = isPendingOrder && pendingOrderType === null;
    const marketPrice = activeOperationCategory === "Buy" ? ask : bid;

    const handlePlaceOrderClick = (action?: TradeButtonOperationType) => {
        return isLoggedInUser ? placeOrder({ actionType: action }) : parentFrameAPI.login();
    };

    const isInvalidPendingOrderPriceCheck =
        isPendingOrder &&
        isInvalidPendingOrderPrice(
            activeOperationCategory,
            pendingOrderType,
            pendingOrderPrice,
            marketPrice
        );

    const isWithinTenSeconds = () => {
        const time = processedQuedOrdersString.getLast();
        if (!time) return false;
        const timestamp = Number(time);
        const currentTime = Date.now();
        const elapsedTime = currentTime - timestamp;

        return elapsedTime <= 10000;
    };

    // REQUEST_PLACED
    const isPlaceOrderDisabled =
        !activeOperationCategory ||
        +lotSize <= 0 ||
        isInvalidSLTP(
            activeOperationCategory,
            isStopLoss ? stopLossPrice : 0,
            isTakeProfit ? takeProfitPrice : 0,
            isPendingOrder ? pendingOrderPrice : marketPrice
        ) ||
        isInvalidPendingOrderParams ||
        isInvalidPendingOrderPriceCheck ||
        isRequestPending ||
        !isLotSizeValid ||
        isWithinTenSeconds();

    const btnWidth = isFixed ? `${tradeBtnWidth}px` : "auto";

    return (
        <div
            style={{ width: btnWidth }}
            className={clsx(styles.backbroundDynamicButton, {
                [styles.tradeButton_fixed]: isFixed,
            })}>
            <div style={{ flex: 1, width: btnWidth, display: tradeOrderModal && "none" }}>
                <Button
                    className={clsx(styles.tradeButton, styles[`tradeButton_${theme.palette.mode}`])}
                    disabled={isPlaceOrderDisabled}
                    variant="contained"
                    onClick={() => handlePlaceOrderClick()}
                    fullWidth>
                    {isPendingOrder ? t("place_order") : t("trade")}
                </Button>
            </div>
        </div>
    );
};

export const DynamicFormHeader: React.FC = () => {
    const dispatch = useDispatch();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { lotSize } = useContext(TradeOrderStateContext);
    const { image1: assetImage1, image2: assetImage2 } = useActiveSymbolDynamicFields({ lotSize });
    const symbolInfo = symbolsInfoById[activeSymbolId];

    return (
        <div className={styles.orderFormHeader}>
            <div className={styles.assetTopInfo}>
                <div
                    className={clsx(styles.assetIcon, {
                        [styles.singleAssetIcon]: !assetImage1 || !assetImage2,
                    })}>
                    <img src={assetImage1} alt="" />
                    <img src={assetImage2} alt="" />
                </div>
                <div className={styles.assetName}>{symbolInfo?.ex?.displayName || activeSymbolId}</div>
            </div>
            <CloseIcon className={styles.closeIcon} onClick={() => dispatch(setTradeOrderView(false))} />
        </div>
    );
};

function elementsOverlap(el1, el2) {
    if (el1 && el2) {
        const domRect1 = el1.getBoundingClientRect();
        const domRect2 = el2.getBoundingClientRect();

        return !(
            domRect1.top > domRect2.bottom + 45 ||
            domRect1.right < domRect2.left ||
            domRect1.bottom < domRect2.top ||
            domRect1.left > domRect2.right
        );
    }
    return null;
}

const TradingOrderForm: React.FC<IProps> = props => {
    const { isPendingOrder } = useContext(TradeOrderStateContext);
    const [hasScroll, setHasScroll] = useState(false);
    const { isResized } = useResize();
    const [isResizing, setIsResizing] = useState(0);
    const [isOverlaping, setIsOverlaping] = useState(false);
    const ref = useRef<HTMLDivElement>();

    const [isLotSizeValid, setIsLotSizeValid] = useState(true);
    const [showFlashButton, setShowFlashButton] = useState(false);
    const introStep = useIntroStep();
    const isCurrentIntroStep = introStep === 3;
    const { activeOperationCategory } = useContext(TradeOrderStateContext);
    const { isAdvanced = false } = props;
    const { isFooterExpanded, isOpenTradesOpen, isPendingOrdersOpen, tradeOrderModal } = useUI();
    useEffect(() => {
        const table = document.getElementById("open_trades_container");
        const form = document.getElementById("trade_order_form");
        const overlap = elementsOverlap(table, form);
        overlap !== null && setIsOverlaping(overlap);
    }, [
        isResizing,
        isOpenTradesOpen,
        isPendingOrdersOpen,
        isFooterExpanded,
        isAdvanced,
        hasScroll,
        isResized,
    ]);

    useWindowResize({
        onResize: () => setIsResizing(+new Date()),
    });

    const handleFlashBuySellButtons = () => {
        setShowFlashButton(true);

        setTimeout(() => {
            setShowFlashButton(false);
        }, 500);
    };
    useEffect(() => {
        setHasScroll(hasElementScroll(ref));
    }, [isOpenTradesOpen, isPendingOrdersOpen, isAdvanced, isPendingOrder, isResized]);

    return (
        <div
            id="trade_order_form"
            className={clsx(styles.orderFormWrap, { [styles.overlayed]: isCurrentIntroStep })}>
            <div
                className={clsx(styles.formsWrapper, {
                    [styles.collapsed]: isFooterExpanded,
                })}
                ref={ref}>
                <DynamicFormFields
                    isAdvanced={isAdvanced}
                    showFlashButton={showFlashButton}
                    setIsLotSizeValid={setIsLotSizeValid}
                />

                {isAdvanced ? (
                    <div onClick={!activeOperationCategory ? handleFlashBuySellButtons : () => {}}>
                        <Stack>
                            <PendingOrder />
                        </Stack>
                        <Stack>
                            <StopLossTakeProfit />
                        </Stack>
                    </div>
                ) : null}
            </div>

            {(isOpenTradesOpen || isPendingOrdersOpen) && !tradeOrderModal && isOverlaping && (
                <div style={{ height: 60 }}></div>
            )}

            <DynamicTradeButton
                isFixed={(isOpenTradesOpen || isPendingOrdersOpen) && !tradeOrderModal && isOverlaping}
                tradeBtnWidth={ref.current?.clientWidth}
                isLotSizeValid={isLotSizeValid}
            />
        </div>
    );
};

export default React.memo(TradingOrderForm);
