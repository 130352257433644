import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { Skeleton, useTheme } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import AssetsListMobile, {
    AssetViewType,
} from "@/components/Home/TradingAssetsSection/mobile/AssetsListMobile";
import MarginInfoBlock from "@/components/Home/TradingAssetsSection/mobile/MarginInfoBlock";
import AssetsCategoryList from "@/components/Home/TradingAssetsSection/mobile/AssetsCategoryList";
import { favouritesCategory } from "@/components/Home/TradingAssetsSection/TradingAssets";
import SearchInput from "@/components/Core/SearchInputMobile/SearchInputMobile";
import { setActiveSymbolFromURL, setSearch } from "@/redux/actions/tradeInfo";

import styles from "./TradingAssetsSectionMobile.module.scss";

const TradingAssetsSectionMobile: React.FC = () => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const dispatch = useDispatch();

    const {
        isActiveSymbolFromURL,
        isQuoteDataLoaded,
        search,
        groups: assetCategories,
        activeGroupName: initialActiveCategory,
    } = useTradeInfo();
    const isDarkMode = palette.mode === "dark";

    const [assetsViewType, setAssetsViewType] = useState(AssetViewType.LIST_TYPE_1);
    const [isShowMarginBlock, setShowMarginBlock] = useState(false);
    const [activeCategory, setActiveCategory] = useState(initialActiveCategory);
    const [isSearchActive, setSearchActive] = useState(false);

    const categories = [favouritesCategory, ...assetCategories];

    useEffect(() => {
        if (isQuoteDataLoaded && !activeCategory && assetCategories.length) {
            setActiveCategory(assetCategories[0].name);
        }
    }, [isQuoteDataLoaded, assetCategories, activeCategory]);

    useEffect(() => {
        if (
            initialActiveCategory &&
            activeCategory &&
            activeCategory !== initialActiveCategory &&
            isActiveSymbolFromURL
        ) {
            dispatch(setActiveSymbolFromURL(false));
        }
    }, [activeCategory, initialActiveCategory, isActiveSymbolFromURL]);

    const handleListViewClick = useCallback(() => {
        setAssetsViewType(
            assetsViewType === AssetViewType.LIST_TYPE_1
                ? AssetViewType.LIST_TYPE_2
                : AssetViewType.LIST_TYPE_1
        );

        if (isActiveSymbolFromURL) {
            dispatch(setActiveSymbolFromURL(false));
        }
    }, [isActiveSymbolFromURL, assetsViewType]);

    return (
        <>
            <div
                className={clsx(styles.container, {
                    [styles.containerWithMarginBlock]: isShowMarginBlock,
                })}>
                {isShowMarginBlock && <MarginInfoBlock />}

                <div
                    className={clsx(styles.toolbar_mbl, styles.searchInput, {
                        [styles.searchActive]: isSearchActive && search,
                    })}>
                    <SearchInput
                        onChange={value => dispatch(setSearch(value))}
                        searchText={search}
                        isDisabled={!isQuoteDataLoaded}
                        onClearSearch={() => setSearchActive(false)}
                        onFocus={() => setSearchActive(true)}
                        className={isSearchActive && search ? styles.activeSearch : ""}
                        placeholder={t("filter")}
                    />

                    {!(isSearchActive && search) && (
                        <>
                            <div className={styles.toolbar__btnContainer} onClick={handleListViewClick}>
                                <div
                                    className={clsx(null, {
                                        [styles.active]: assetsViewType === AssetViewType.LIST_TYPE_1,
                                    })}>
                                    {isDarkMode ? (
                                        <div
                                            className={clsx(
                                                assetsViewType !== AssetViewType.LIST_TYPE_1
                                                    ? styles.list1_inactiveIcon
                                                    : styles.list1_icon
                                            )}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                assetsViewType !== AssetViewType.LIST_TYPE_1
                                                    ? styles.list1_inactiveIcon
                                                    : styles.list1_icon_light
                                            }
                                        />
                                    )}
                                </div>

                                <div
                                    className={clsx(null, {
                                        [styles.active]: assetsViewType === AssetViewType.LIST_TYPE_2,
                                    })}>
                                    {isDarkMode ? (
                                        <div
                                            className={clsx(
                                                assetsViewType === AssetViewType.LIST_TYPE_2
                                                    ? styles.list2_icon_active
                                                    : styles.list2_icon
                                            )}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                assetsViewType === AssetViewType.LIST_TYPE_2
                                                    ? styles.list2_icon_active_light
                                                    : styles.list2_icon_light
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            <div
                                className={clsx(styles.toolbar__btnContainer, {
                                    [styles[
                                        `toolbar__chartBtnContainer_${palette.mode}`
                                    ]]: isShowMarginBlock,
                                })}>
                                <div
                                    onClick={() => setShowMarginBlock(prevState => !prevState)}
                                    className={clsx(null, {
                                        [styles[`activeChartTab_${palette.mode}`]]: isShowMarginBlock,
                                    })}>
                                    {isDarkMode ? (
                                        <div className={clsx(styles.chart_icon, styles.icon)} />
                                    ) : (
                                        <div className={clsx(styles.chart_icon_light, styles.icon)} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className={clsx(styles.content_mbl)}>
                    <TabContext value={activeCategory}>
                        {!search && (
                            <Box className={clsx(styles.categoryListWrap)}>
                                <AssetsCategoryList
                                    activeCategory={activeCategory}
                                    setActiveCategory={setActiveCategory}
                                />
                            </Box>
                        )}

                        {isQuoteDataLoaded ? (
                            categories.map(({ name }, ind) => {
                                return (
                                    <TabPanel
                                        key={`${ind}_${name}`}
                                        value={name}
                                        style={{ padding: "0", margin: "0", height: "100%" }}>
                                        <div
                                            className={clsx(
                                                styles[`assetsListContainer_mbl_${palette.mode}`],
                                                {
                                                    [styles.listContainerWithMarginBlock]:
                                                        isShowMarginBlock && !search,
                                                    [styles.listContainerWithSearch]:
                                                        !!search && !isShowMarginBlock,
                                                    [styles.listContainerWithMarginBlockAndSearch]:
                                                        !!search && isShowMarginBlock,
                                                }
                                            )}>
                                            <AssetsListMobile
                                                key={`${name}_${ind}`}
                                                activeCategory={activeCategory}
                                                assetsViewType={assetsViewType}
                                                isShowMarginBlock={isShowMarginBlock}
                                            />
                                        </div>
                                    </TabPanel>
                                );
                            })
                        ) : (
                            <div style={{ width: "100%", padding: "0 0.5rem" }}>
                                {[...Array(13).keys()].map(key => (
                                    <Skeleton key={key} height="2.8rem" animation="wave" />
                                ))}
                            </div>
                        )}
                    </TabContext>
                </div>
            </div>
        </>
    );
};

export default React.memo(TradingAssetsSectionMobile);
