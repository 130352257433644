export enum PeriodTypes {
    TODAY = 'Today',
    LAST_3_DAYS = 'Last 3 days',
    LAST_WEEK = 'Last week',
    LAST_MONTH = 'Last month',
    LAST_3_MONTHS = 'Last 3 months',
    ALL_PERIOD = 'All period',
    DATE_RANGE = 'Date Range',
}

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const getDateFromPeriod = (period: PeriodTypes): Date => {
    const currentDate = new Date();

    switch (period) {
        case PeriodTypes.TODAY:
            return new Date(currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                23, 59, 59, 0
            );
        case PeriodTypes.LAST_3_DAYS:
            return new Date(currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - 3,
                0, 0, 0, 0
            );
        case PeriodTypes.LAST_WEEK:
            return new Date(currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - 7,
                0, 0, 0, 0
            );
        case PeriodTypes.LAST_MONTH:
            return new Date(currentDate.getFullYear(),
                currentDate.getMonth() - 1,
                currentDate.getDate(),
                0, 0, 0, 0
            );
        case PeriodTypes.LAST_3_MONTHS:
            return new Date(currentDate.getFullYear(),
                currentDate.getMonth() - 3,
                currentDate.getDate(),
                0, 0, 0, 0
            );
        case PeriodTypes.ALL_PERIOD:
            return new Date(currentDate.getFullYear() - 50,
                currentDate.getMonth() - 3,
                currentDate.getDate(),
                0, 0, 0, 0
            );
        default:
            return new Date();
    }
};

interface IYearsParams {
    startYear?: number;
    count?: number;
    rowLength?: number;
    direction?: "up" | "down",
}

export const generateYears = ({
    startYear = 2023,
    count = 25,
    rowLength = 5,
    direction = "up"
}: IYearsParams): Array<number[]> => {
    const years = [];
    let temp = [];
    for (let i = 0; i < count; i++) {
        const yearCandidate = direction === "up" ? startYear + i : startYear - i;
        temp.push(yearCandidate);
        if (temp.length === rowLength) {
            if (direction !== "up") {
                temp.reverse();
            }
            years.push(temp);
            temp = [];
        }
    }
    return direction === "up" ? years : years.reverse();
};
