import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { Calendar } from "@/components/Core/DateRangePickerMobile/Calendar";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";

import styles from "./DateRangePickerMobile.module.scss";


interface IProps {
    from?: Date;
    to?: Date;
    maxDate?: Date;
    onChange: (range: { from: Date, to: Date }) => void;
    onCancel: () => void;
}

const SelectCustomRange: React.FC<IProps> = ({ from, to, maxDate, onChange, onCancel }) => {
    const { t } = useTranslation("common");
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();

    const [range, setRange] = useState({ from, to });
    const [isYearModalOpen, setYearModalOpen] = useState(false);

    const handleSetRange = useCallback(() => {
        onChange(range);
    }, [range, onChange]);

    return (
        <div className={styles.selectCustomRange}>
            <Calendar
                from={range.from}
                to={range.to}
                maxDate={maxDate}
                onChange={setRange}
                isYearModalOpen={isYearModalOpen}
                openYearModal={() => setYearModalOpen(!isYearModalOpen)}
            />
            {!isYearModalOpen && (
                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, styles.btnTransparent, {
                            [styles.touched]: isTouched && buttonName === PressedButtons.CANCEL
                        })}
                        onClick={onCancel}
                        onTouchStart={() => onTouchStart(PressedButtons.CANCEL)}
                        onTouchEnd={onTouchEnd}
                    >
                        {t("cancel")}
                    </button>
                    <button
                        className={clsx(styles.btn, styles.btnApply, {
                            [styles.touched]: isTouched && buttonName === PressedButtons.APPLY,
                        })}
                        onClick={handleSetRange}
                        onTouchStart={() => onTouchStart(PressedButtons.APPLY)}
                        onTouchEnd={onTouchEnd}
                        disabled={from === range.from && to === range.to}
                    >
                        {t("set")}
                    </button>
                </div>

            )}
        </div>
    );
};

export default React.memo(SelectCustomRange);
