import React, { useCallback, useRef, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import TradeCalculatorMobile from "./TradeCalculatorMobile";
import { isForexSymbol, useActiveSymbolDynamicFields } from "@/hooks/symbols";
import { normalyzeDecimalLength } from "@/utils/format";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";

import styles from "./TradeValueCalculatorModal.module.scss";

interface IProps {
    lotSize?: number | string;
    lotMax?: number;
    lotMin?: number;
    onChange: (value?: number) => void;
    onClose: () => void;
    className: string;
}

const TradeValueCalculatorModal: React.FC<IProps> = ({
    lotSize,
    lotMax,
    lotMin,
    onChange,
    onClose,
    className,
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const initialLotSize = useRef(`${lotSize}`);
    const [labelValue, setLabelValue] = useState(initialLotSize.current);

    const {
        leverageDisplayValue,
        getLotDisplayValue,
        pipDisplayValue,
        pointDisplayValue,
        requiredBuyMarginDisplayValue,
    } = useActiveSymbolDynamicFields({ lotSize: `${labelValue}` });

    const handleChange = useCallback(value => onChange(Number(value)), [onChange]);

    const handleCalculatorChange = useCallback((value: number, labelValue: string) => {
        if (labelValue !== undefined) {
            setLabelValue(labelValue);
        }
    }, []);

    const handleSubmit = () => {
        handleChange(labelValue);
        onClose();
    };

    return (
        <Modal open={true} onClose={onClose} disableAutoFocus>
            <Backdrop
                id="backdrop"
                sx={{
                    background: palette.mode === "dark" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.5)",
                }}
                open={true}
                onClick={({ target }) => {
                    // @ts-ignore
                    const shouldClose = target.id === "backdrop";
                    shouldClose && onClose();
                }}>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Box id="app-theme-root" className={clsx(styles.menu, `theme--${palette?.mode}`)}>
                        <div className={styles.closeIcon} onClick={onClose} />

                        <div className={styles.orderInfoList}>
                            <div className={styles.orderInfoListRow}>
                                <div className={styles.orderInfoListLabel}>
                                    {`${labelValue} ${t("lot")}:`}
                                </div>
                                <div className={styles.orderInfoListValue}>
                                    {labelValue && getLotDisplayValue(labelValue)}
                                </div>
                            </div>
                            <div className={styles.orderInfoListRow}>
                                <div className={styles.orderInfoListLabel}>{t("leverage")}:</div>
                                <div className={styles.orderInfoListValue}>{leverageDisplayValue}</div>
                            </div>
                            <div className={styles.orderInfoListRow}>
                                <div className={styles.orderInfoListLabel}>
                                    {t(isForexSymbol(symbolInfo) ? "pips_value" : "pointValue.label")}:
                                </div>
                                <div className={styles.orderInfoListValue}>
                                    {isForexSymbol(symbolInfo) ?
                                        normalyzeDecimalLength(pipDisplayValue, 2) :
                                        pointDisplayValue}
                                </div>
                            </div>
                            <div className={styles.orderInfoListRow}>
                                <div className={styles.orderInfoListLabel}>{t("requiredMargin")}:</div>
                                <div className={styles.orderInfoListValue}>
                                    {requiredBuyMarginDisplayValue}
                                </div>
                            </div>
                        </div>

                        <TradeCalculatorMobile
                            className={className}
                            onSubmit={handleSubmit}
                            label={t("volumeInLot")}
                            value={labelValue}
                            lotMax={lotMax}
                            lotMin={lotMin}
                            step={0.01}
                            onChange={handleCalculatorChange}
                            isSubmitDisabled={initialLotSize.current === labelValue}
                        />
                    </Box>
                </Slide>
            </Backdrop>
        </Modal>
    );
};

export default React.memo(TradeValueCalculatorModal);
