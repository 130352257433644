export const gaps = {
    de: {
        top: "-36px",
        bottom: "-6px",
    },
    cs: {
        top: "-11px",
        bottom: "-32px",
    },
    el: {
        paddingTop: 10,
        top: "-35px",
        bottom: "-32px",
        width: 178,
    },
    fr: {
        top: null,
        bottom: null,
        width: null,
    },
    ge: {
        top: "-37px",
        bottom: null,
        width: 174,
    },
    hu: {
        top: "-17px",
        bottom: "-44px",
        width: 178,
    },
    it: {
        top: null,
        bottom: null,
        width: null,
    },
    ja: {
        top: "-38px",
        bottom: "-9px",
        width: null,
    },
    kr: {
        top: null,
        bottom: null,
        width: null,
    },
    ms: {
        top: null,
        bottom: null,
        width: null,
    },
    nl: {
        top: null,
        bottom: null,
        width: null,
    },
    no: {
        top: null,
        bottom: null,
        width: null,
    },
    pl: {
        top: null,
        bottom: null,
        width: null,
    },
    ro: {
        top: "-23px",
        bottom: "-48px",
        width: 186,
    },
    ru: {
        top: "-35px",
        bottom: "-36px",
        width: 186,
    },
    sv: {
        top: null,
        bottom: null,
        width: null,
    },
    th: {
        top: "4px",
        bottom: "4px",
        width: 110,
    },
    tr: {
        top: "-16px",
        bottom: "-8px",
        width: 140,
    },
    pt: {
        top: "-28px",
        bottom: "-16px",
        width: 160,
    },
};
