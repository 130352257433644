import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";

import MarketClosedFeedback from "@/components/SystemFeedbackMobile/MarketClosedFeedback";
import InsufficientFundsFeedback from "@/components/SystemFeedbackMobile/InsufficientFundsFeedback";
import DeleteOrderFeedback from "@/components/SystemFeedbackMobile/DeleteOrderFeedback";
import MarginCallFeedback from "@/components/SystemFeedbackMobile/MarginCallFeedback";
import SuccessFeedback from "@/components/SystemFeedbackMobile/common/SuccessFeedback";
import TradeDisabledFeedback from "@/components/SystemFeedbackMobile/TradeDisabledFeedback";
import OrderInfoView from "@/components/SystemFeedbackMobile/common/OrderInfoView";
import DangerFeedback from "@/components/SystemFeedbackMobile/common/DangerFeedback";
import WarningFeedback from "@/components/SystemFeedbackMobile/common/WarningFeedback";
import { useSystemFeedback } from "@/redux/selectors/systemFeedbackSelector";
import { resetFeedbackModals } from "@/redux/actions/systemFeedback";
import { useUI } from "@/redux/selectors/uiSelector";
import { IOrderErrorModalParams, ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { closeTradeOrderModal } from "@/redux/actions/ui";
import CloseOrderModal from "@/components/SystemFeedbackMobile/CloseOrderModal";
import parentFrameAPI from "@/services/parent";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { FavoritesAlert } from "./FavoritesAlert";
import { useMarketSchedule } from "@/hooks/trade/schedule";

import styles from "./SystemFeedback.module.scss";

const SystemFeedbackMobileWrapper = ({ children }): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    let { tradeOrderModal } = useUI();
    const { tradeOrderModalParams } = useUI();
    const { isGuest, isLazyMode } = useAccountInfo();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const { isMarketOpen } = useMarketSchedule(activeSymbolId);
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const {
        successfullyClosedOrder,
        successfullyModifiedOrder,
        successfullyDeletedOrder,
        successfullyOpenedOrder,
        isShowOpenPositionWarning,
        generalErrorMessage,
        isShowSecureConnection,
        isShowMarginCall,
        isShowNoQuotes,
        isShowOrderFailed,
        isShowOrderHasWrongPrice,
        favoritesAlerts,
    } = useSystemFeedback();

    const handleClose = () => {
        if (tradeOrderModal) {
            dispatch(closeTradeOrderModal());
        }
        dispatch(resetFeedbackModals());
    };

    useEffect(handleClose, []);

    if (!tradeOrderModal && !!successfullyOpenedOrder) {
        tradeOrderModal = ITradeOrderModalTypes.OPEN_ORDER_SUCCESS;
    }

    if (!tradeOrderModal && !!successfullyModifiedOrder) {
        tradeOrderModal = ITradeOrderModalTypes.ORDER_MODIFIED_SUCCESS;
    }

    if (!tradeOrderModal && !!successfullyModifiedOrder) {
        tradeOrderModal = ITradeOrderModalTypes.ORDER_MODIFIED_SUCCESS;
    }

    if (!tradeOrderModal && !!successfullyClosedOrder) {
        tradeOrderModal = ITradeOrderModalTypes.CLOSE_TRADE_SUCCESS;
    }

    if (!tradeOrderModal && !!successfullyDeletedOrder) {
        tradeOrderModal = ITradeOrderModalTypes.ORDER_DELETE_SUCCESS;
    }

    const renderFeedbackModal = () => {
        switch (true) {
            case tradeOrderModal === ITradeOrderModalTypes.REQUEST_PLACED:
                return <WarningFeedback placed onClose={handleClose} />;

            case tradeOrderModal === ITradeOrderModalTypes.OPEN_ORDER_SUCCESS:
                return (
                    <SuccessFeedback
                        onClose={handleClose}
                        title={t("successfuly.opened")}
                        className={styles.orderFeedback}>
                        <OrderInfoView
                            //@ts-ignore
                            order={successfullyOpenedOrder || tradeOrderModalParams?.order}
                            operation="open"
                        />
                    </SuccessFeedback>
                );
            case tradeOrderModal === ITradeOrderModalTypes.ORDER_MODIFIED_SUCCESS:
                return (
                    <SuccessFeedback
                        onClose={handleClose}
                        title={t("successfuly.modified")}
                        className={styles.orderFeedback}>
                        <OrderInfoView order={successfullyModifiedOrder} operation="open" />
                    </SuccessFeedback>
                );
            case tradeOrderModal === ITradeOrderModalTypes.CLOSE_TRADE_SUCCESS:
                return (
                    <SuccessFeedback
                        onClose={handleClose}
                        title={t("successfuly.closed")}
                        className={styles.orderFeedback}>
                        <OrderInfoView
                            //@ts-ignore
                            order={successfullyClosedOrder || tradeOrderModalParams?.order}
                            operation="close"
                        />
                    </SuccessFeedback>
                );
            case tradeOrderModal === ITradeOrderModalTypes.ORDER_DELETE_SUCCESS:
                return (
                    <SuccessFeedback
                        onClose={handleClose}
                        title={t("successfuly.deleted")}
                        className={styles.orderFeedback}>
                        <OrderInfoView order={successfullyDeletedOrder} operation="delete" />
                    </SuccessFeedback>
                );

            case tradeOrderModal === ITradeOrderModalTypes.MARKET_CLOSED:
                return <MarketClosedFeedback onClose={handleClose} />;
            case tradeOrderModal === ITradeOrderModalTypes.TRADE_DISABLED_USER_READ_ONLY:
                return <TradeDisabledFeedback isReadOnly onClose={handleClose} />;
            case tradeOrderModal === ITradeOrderModalTypes.TRADE_DISABLED_ASSET_CLOSE_ONLY:
                return <TradeDisabledFeedback isCloseOnly onClose={handleClose} />;
            case tradeOrderModal === ITradeOrderModalTypes.INSUFFICIENT_FUNDS:
                return (
                    <InsufficientFundsFeedback
                        onDeposit={() => {
                            if (isGuest && !isLazyMode) {
                                parentFrameAPI.login();
                            } else {
                                parentFrameAPI.deposit();
                            }
                        }}
                        onClose={handleClose}
                    />
                );
            case tradeOrderModal === ITradeOrderModalTypes.ORDER_DELETE_CONFIRM:
                return <DeleteOrderFeedback />;
            case tradeOrderModal === ITradeOrderModalTypes.INVALID_PARAMS:
                return (
                    <DangerFeedback
                        title={t("messages.3")}
                        onClose={handleClose}
                        description={t("modals.error.invalidParams.description", {
                            points: symbolInfo?.ex?.stopsLevel || 50,
                        })}
                    />
                );
            case tradeOrderModal === ITradeOrderModalTypes.NO_CONNECT:
                return (
                    <DangerFeedback title={t("no.connection")} description="" onClose={handleClose} />
                );
            case tradeOrderModal === ITradeOrderModalTypes.INVALID_SL_TP:
                return (
                    <WarningFeedback
                        onClose={handleClose}
                        title={t("messages.130")}
                        description={t("modals.error.invalidParams.description", {
                            points: symbolInfo?.ex?.stopsLevel || 50,
                        })}
                    />
                );
            case isShowOpenPositionWarning:
                return (
                    <WarningFeedback
                        onClose={handleClose}
                        title={t("guidance.step03.title")}
                        description={t("modals.error.open.position.description")}
                    />
                );
            case tradeOrderModal === ITradeOrderModalTypes.ORDER_ERROR:
                return (
                    <WarningFeedback
                        onClose={handleClose}
                        title={t("modals.error.title")}
                        description={
                            generalErrorMessage ||
                            (tradeOrderModalParams as IOrderErrorModalParams)?.error
                        }
                    />
                );
            case tradeOrderModal === ITradeOrderModalTypes.INVALID_PARAMETERS_WRONG_EXPIRE_DATE:
                return (
                    <DangerFeedback
                        title={t("messages.3")}
                        onClose={handleClose}
                        description={t("modals.error.tradeDisabled.invalid.expiration.date")}
                    />
                );
            case isShowSecureConnection:
                return (
                    <WarningFeedback
                        onClose={handleClose}
                        title={t("modals.notification.secure.connection")}
                    />
                );
            case isShowNoQuotes:
                return <WarningFeedback onClose={handleClose} title={t("messages.136")} />;
            case isShowOrderFailed:
                return <WarningFeedback onClose={handleClose} title={t("messages.orderFailed")} />;
            case isShowOrderHasWrongPrice:
                return <WarningFeedback onClose={handleClose} title={t("messages.129")} />;
            case isShowMarginCall || tradeOrderModal === ITradeOrderModalTypes.MARGIN_CALL:
                return (
                    <MarginCallFeedback
                        onClose={handleClose}
                        onDeposit={() => {
                            if (isGuest && !isLazyMode) {
                                parentFrameAPI.login();
                            } else {
                                parentFrameAPI.deposit();
                            }
                            dispatch(closeTradeOrderModal());
                        }}
                    />
                );
            case tradeOrderModal === ITradeOrderModalTypes.CLOSE_TRADE:
                return <CloseOrderModal />;

            case !!favoritesAlerts.length:
                return (
                    <FavoritesAlert
                        messageData={favoritesAlerts[0]}
                        count={favoritesAlerts.length}
                        isMarketOpen={isMarketOpen}
                    />
                );

            default:
                return tradeOrderModal || generalErrorMessage ? (
                    <WarningFeedback
                        onClose={handleClose}
                        title={t("modals.error.title")}
                        description={
                            generalErrorMessage ||
                            (tradeOrderModalParams as IOrderErrorModalParams)?.error
                        }
                    />
                ) : null;
        }
    };

    return (
        <>
            {children}
            {renderFeedbackModal()}
        </>
    );
};

export default SystemFeedbackMobileWrapper;
