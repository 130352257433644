import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { formatAmount } from "@/utils/format";
import { useTranslation } from "next-i18next";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import TradeHeader from "@/components/OrderMobile/TradeHeader";
import PendingPriceBlock from "@/components/Core/TakeProfitStopLoss/PendingPriceBlock";
import { useTradeOrder } from "@/contexts/TradeOrder";
import { ITradeOrderState } from "@/contexts/TradeOrder/interfaces";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { modifyOrderCancel } from "@/redux/actions/orders";
import { useEditOrder } from "@/hooks/trade/useEditOrder";
import { ITradeOrder } from "@/services/trade/order";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import { setOperationTimestamp } from "@/utils/helpers";
import useTradeChart, { TradeChartType } from "@/hooks/chart";
import { isPendingOrder } from "@/utils/trade";

import styles from "./SelectedOrder.module.scss";

interface IProps {
    order: ITradeOrder;
    onCancel: () => void;
}

const SelectedOrder = ({ order, onCancel }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const commonT = useTranslation("common");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById } = useTradeInfo();

    // TODO: maybe use TradeOrder Context as in other modules
    const tradeOrderProps: Partial<ITradeOrderState> = {
        activeSymbolId: order.symbol,
        activeSymbolPoint: symbolsInfoById[order.symbol]?.point,
        activeOperationCategory: order.type.toLowerCase().includes("buy")
            ? TRADE_OPERATION_TYPE.BUY
            : TRADE_OPERATION_TYPE.SELL,
        lotSize: order.lots,
    };

    if (order.stopLoss) {
        tradeOrderProps.isStopLoss = true;
        tradeOrderProps.stopLossPrice = order.stopLoss;
        tradeOrderProps.stopLossPips = 50;
        tradeOrderProps.stopLossPriceCalcMode = "Price";
    }

    if (order.takeProfit) {
        tradeOrderProps.isTakeProfit = true;
        tradeOrderProps.takeProfitPrice = order.takeProfit;
        tradeOrderProps.takeProfitPips = 50;
        tradeOrderProps.takeProfitPriceCalcMode = "Price";
    }

    const [tradeOrder, tradeOrderDispatch] = useTradeOrder(tradeOrderProps);

    const {
        handleModifyOrderSubmit,
        handleStopLossToggle,
        handleTakeProfitToggle,
        handleStopLossPriceChange,
        handleTakeProfitPriceChange,
        takeProfitPriceDisplayValue,
        stopLossPriceDisplayValue,
        symbolInfo,
        isStopLoss,
        isTakeProfit,
        stopLossPLValue,
        takeProfitPLValue,
        stopLossPriceFormInputValue,
        takeProfitPriceFormInputValue,
        isValidOrder,
    } = useEditOrder({
        order,
        tradeOrderDispatch,
        tradeOrder,
    });
    const {
        addStopLossLine,
        removeStopLossLine,
        addTakeProfitLine,
        removeTakeProfitLine,
    } = useTradeChart({
        chartType: TradeChartType.PendingOrder,
        symbolInfo: symbolInfo,
    });

    const handleClose = () => {
        setOperationTimestamp();
        dispatch(modifyOrderCancel());
        onCancel();
    };

    useEffect(() => {
        if (isStopLoss) {
            addStopLossLine(stopLossPriceDisplayValue);
        } else {
            removeStopLossLine();
        }
    }, [isStopLoss, stopLossPriceDisplayValue, addStopLossLine, removeStopLossLine]);

    useEffect(() => {
        if (isTakeProfit) {
            addTakeProfitLine(takeProfitPriceDisplayValue);
        } else {
            removeTakeProfitLine();
        }
    }, [isTakeProfit, takeProfitPriceDisplayValue, addTakeProfitLine, removeTakeProfitLine]);

    const initialLossProfit = useRef({
        isSL: isStopLoss,
        isTP: isTakeProfit,
        sl: stopLossPriceFormInputValue,
        tp: takeProfitPriceFormInputValue,
    });

    const isSubmitDisabled =
        !isValidOrder ||
        (!initialLossProfit.current.isSL &&
            !isStopLoss &&
            !initialLossProfit.current.isTP &&
            !isTakeProfit) ||
        (initialLossProfit.current.sl === stopLossPriceFormInputValue &&
            initialLossProfit.current.tp === takeProfitPriceFormInputValue);

    return (
        <div className={clsx(styles.container)}>
            <div className={styles.header} onClick={handleClose}>
                <div className={palette.mode === "dark" ? styles.iconBack : styles.iconBack_light} />
                {commonT.t(`select.order.modify.${isPendingOrder(order) ? "pending" : "open"}.trades`)} #{order.ticket}
            </div>

            <div className={styles.infoContainer}>
                <TradeHeader tradeData={order} />
            </div>
            <main className={styles.graph_container}>
                <div className={clsx(styles.chart)} id="trading_chart_pending_orders_mobile" />
            </main>
            <div className={styles.p_l_container}>
                <div className={styles.p_l_item}>
                    <span className={styles.p_l_title}>{t("profit")}:</span>
                    <span className={clsx(styles.p_l_value, { [styles.profited]: isTakeProfit })}>
                        {isTakeProfit ? formatAmount(takeProfitPLValue, accountCurrency) : "-"}
                    </span>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.p_l_item}>
                    <span className={styles.p_l_title}>{t("loss")}:</span>
                    <span className={clsx(styles.p_l_value, { [styles.lossing]: isStopLoss })}>
                        {isStopLoss ? formatAmount(stopLossPLValue, accountCurrency) : "-"}
                    </span>
                </div>
            </div>
            <div className={styles.profitLossContainer}>
                <PendingPriceBlock
                    value={takeProfitPriceDisplayValue}
                    onChange={handleTakeProfitPriceChange}
                    label={t("takeProfit")}
                    step={symbolInfo?.point}
                    checked={isTakeProfit}
                    onChangeChecked={handleTakeProfitToggle}
                />

                <PendingPriceBlock
                    value={stopLossPriceDisplayValue}
                    onChange={handleStopLossPriceChange}
                    label={t("stopLoss")}
                    step={symbolInfo?.point}
                    checked={isStopLoss}
                    onChangeChecked={handleStopLossToggle}
                />
            </div>

            <div className={styles.actionContainer}>
                <button className={clsx(styles.btn, styles.btnTransparent)} onClick={handleClose}>
                    {commonT.t("cancel")}
                </button>
                <button
                    className={clsx(styles.btn, styles.btnApply, {
                        [styles.btnApply_disabled]: isSubmitDisabled,
                    })}
                    onClick={() => handleModifyOrderSubmit()}
                    disabled={isSubmitDisabled}>
                    {commonT.t("apply")}
                </button>
            </div>
        </div>
    );
};

export default React.memo(SelectedOrder);
