import React from "react";
import { useTheme } from "@mui/material";

import styles from "./styles.module.scss";

export const Loader = (): JSX.Element => {
    const { palette } = useTheme();

    return (
        <div className={styles.wrapper}>
            <div className={styles[`loading_bar_${palette.mode}`]} />
        </div>
    );
};
