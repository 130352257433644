import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "next-i18next";

import styles from "./SearchInputMobile.module.scss";

interface IProps {
    isDisabled?: boolean;
    onChange: (value?: string) => void;
    placeholder?: string;
    searchText?: string;
    onFocus?: () => void;
    onClearSearch?: () => void;
    className?: string;
    classes?: {
        closeIcon?: string
    }
}

const SearchInputMobile = ({
    isDisabled = false,
    onChange,
    placeholder,
    searchText,
    onFocus,
    onClearSearch,
    className,
    classes,
}: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const searchRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        setValue(searchText);
    }, [searchText]);

    const clearSearch = useCallback(() => {
        setValue('');
        onChange('');

        if (onClearSearch) {
            onClearSearch();
        }
    }, [onChange, onClearSearch]);

    const handleChange = useCallback(e => {
        const value = e.target.value;

        setValue(value);
        onChange(value);
    }, [onChange]);

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }
    }, [onFocus]);

    const handleSearchIconClick = () => {
        if (searchRef.current) {
            const searchInput = document.querySelector("input");
            searchInput && searchInput.focus();
        }
    };

    return (
        <div className={clsx(styles.container, className, {
            [styles.hasValue]: value
        })}>
            <InputBase
                onFocus={handleFocus}
                className={clsx(styles.input)}
                ref={searchRef}
                placeholder={placeholder ? placeholder : t('search')}
                onChange={handleChange}
                value={value}
                disabled={isDisabled}
            />
            <div className={styles.iconWrapper} onClick={e => {
                e.stopPropagation();
            }}>
                {value ?
                    <IconButton
                        className={clsx(styles.closeIcon, classes?.closeIcon)}
                        component="span"
                        onClick={clearSearch}
                    >
                        <CloseIcon
                            sx={{ fontSize: "1.2rem", color: palette.mode === 'dark' ? '#ffffff' : '#000000' }}
                            onClick={clearSearch}
                        />
                    </IconButton> :
                    <div
                        className={clsx(styles[`search_icon${value ? '' : '_dimmed'}`])}
                        onClick={handleSearchIconClick}
                    />
                }
            </div>
        </div>
    );
};

export default SearchInputMobile;
