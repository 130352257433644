export type ITradeOrderOperationCategory = "Buy" | "Sell";

export type PendingOrderType = "Stop" | "Limit" | null;

export type SLTPPriceCalcMode = "Price" | "Pips";

export interface ITradeOrderState {
    activeSymbolId: string;
    isForex: boolean;
    activeSymbolPoint: number;
    activeOperationCategory: ITradeOrderOperationCategory;
    lotSize: number | string;
    isPendingOrder: boolean;
    pendingOrderPrice: number;
    pendingOrderType: PendingOrderType;
    isPendingOrderExpiration: boolean;
    pendingOrderExpiration: string;
    isStopLoss: boolean;
    stopLossPrice: number;
    stopLossPips: number;
    stopLossPriceCalcMode: SLTPPriceCalcMode;
    isTakeProfit: boolean;
    takeProfitPrice: number;
    takeProfitPips: number;
    takeProfitPriceCalcMode: SLTPPriceCalcMode;
    lastOrderResetTime: number;
}

export enum TradeOrderActionTypes {
    RESET_ORDER = "tradeOrder/RESET_ORDER",
    SET_ACTIVE_SYMBOL = "tradeOrder/SET_ACTIVE_SYMBOL",
    SET_ACTIVE_OPERATION_CATEGORY = "tradeOrder/SET_ACTIVE_OPERATION_CATEGORY",
    SET_LOT_SIZE = "tradeOrder/SET_LOT_SIZE",
    TOGGLE_PENDING_ORDER = "tradeOrder/TOGGLE_PENDING_ORDER",
    SET_IS_STOP_LOSS = "tradeOrder/SET_IS_STOP_LOSS",
    SET_IS_TAKE_PROFIT = "tradeOrder/SET_IS_TAKE_PROFIT",
    TOGGLE_PENDING_ORDER_EXPIRATION = "tradeOrder/TOGGLE_PENDING_ORDER_EXPIRATION",
    SET_PENDING_ORDER_PRICE = "tradeOrder/SET_PENDING_ORDER_PRICE",
    SET_PENDING_ORDER_EXPIRATION = "tradeOrder/SET_PENDING_ORDER_EXPIRATION",
    SET_STOP_LOSS_PRICE = "tradeOrder/SET_STOP_LOSS_PRICE",
    SET_STOP_LOSS_PIPS = "tradeOrder/SET_STOP_LOSS_PIPS",
    SET_TAKE_PROFIT_PRICE = "tradeOrder/SET_TAKE_PROFIT_PRICE",
    SET_TAKE_PROFIT_PIPS = "tradeOrder/SET_TAKE_PROFIT_PIPS",
}

export interface IResetOrderAction {
    type: typeof TradeOrderActionTypes.RESET_ORDER;
}

export interface ISetActiveSymbolAction {
    type: typeof TradeOrderActionTypes.SET_ACTIVE_SYMBOL;
    id: string;
    point: number;
    isForex: boolean;
}

export interface ISetActiveOperationCategoryAction {
    type: typeof TradeOrderActionTypes.SET_ACTIVE_OPERATION_CATEGORY;
    category: ITradeOrderOperationCategory;
    ask: number;
    bid: number;
}

export interface ISetLotSizeAction {
    type: typeof TradeOrderActionTypes.SET_LOT_SIZE;
    size: number | string;
}

export interface ITogglePendingOrderAction {
    type: typeof TradeOrderActionTypes.TOGGLE_PENDING_ORDER;
}

export interface ITogglePendingOrderExpirationAction {
    type: typeof TradeOrderActionTypes.TOGGLE_PENDING_ORDER_EXPIRATION;
}

export interface ISetIsStopLossAction {
    type: typeof TradeOrderActionTypes.SET_IS_STOP_LOSS;
    isActive: boolean;
    price?: number;
}

export interface ISetIsTakeProfitAction {
    type: typeof TradeOrderActionTypes.SET_IS_TAKE_PROFIT;
    isActive: boolean;
    price?: number;
}

export interface ISetPendingOrderPriceAction {
    type: typeof TradeOrderActionTypes.SET_PENDING_ORDER_PRICE;
    price: number;
    marketPrice: number;
}

export interface ISetPendingOrderExpirationAction {
    type: typeof TradeOrderActionTypes.SET_PENDING_ORDER_EXPIRATION;
    expiration: string;
}

export interface ISetStopLossPriceAction {
    type: typeof TradeOrderActionTypes.SET_STOP_LOSS_PRICE;
    price: number;
}

export interface ISetStopLossPipsAction {
    type: typeof TradeOrderActionTypes.SET_STOP_LOSS_PIPS;
    pips: number;
}

export interface ISetTakeProfitPriceAction {
    type: typeof TradeOrderActionTypes.SET_TAKE_PROFIT_PRICE;
    price: number;
}

export interface ISetTakeProfitPipsAction {
    type: typeof TradeOrderActionTypes.SET_TAKE_PROFIT_PIPS;
    pips: number;
}

export type TradeOrderActions =
    | IResetOrderAction
    | ISetActiveSymbolAction
    | ISetActiveOperationCategoryAction
    | ISetLotSizeAction
    | ITogglePendingOrderAction
    | ITogglePendingOrderExpirationAction
    | ISetPendingOrderPriceAction
    | ISetPendingOrderExpirationAction
    | ISetIsStopLossAction
    | ISetIsTakeProfitAction
    | ISetStopLossPriceAction
    | ISetStopLossPipsAction
    | ISetTakeProfitPriceAction
    | ISetTakeProfitPipsAction;
