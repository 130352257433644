import clsx from "clsx";
import styles from "./styles.module.scss";

const SkeletonItem = () => {
    return (
        <div className={styles.skeletonContainer}>
            <div className={styles.skeletonRow}>
                <div className={styles.skeletonRound}></div>
            </div>
            <div className={clsx(styles.skeletonRow, styles.twoRows)}>
                <div className={clsx(styles.skeletonStripe, styles.middle)}></div>
                <div className={clsx(styles.skeletonStripe, styles.short)}></div>
                <div className={clsx(styles.skeletonStripe, styles.long)}></div>
            </div>
        </div>
    );
};

export const Skeleton = () => {
    const numbers = Array.from({ length: 6 }, (_, index) => index + 1);
    return (
        <>
            {numbers.map(i => {
                return <SkeletonItem key={i} />;
            })}
            ;
        </>
    );
};
