import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getSymbolQuoteValue, QuoteData, PriceChangeType } from "@/hooks/trade/tradeSocket";
import { setActiveSymbol, setActiveSymbolFromURL, setSearch } from "@/redux/actions/tradeInfo";
import {
    getAssetsPriceChangeType,
    getAssetsPriceChangePercentage,
    getFormattedPrice,
    getSentimentsInfo,
    getSymbolAssets,
    getDefaultAssetsSort,
    applySymbolFilters,
    getSymbolNameTranslation,
} from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { useMarketWaitingTimeMessage, useMarketSchedule } from "@/hooks/trade/schedule";
import { useAccountInfo, useFavourites } from "@/redux/selectors/accountSelector";
import { addToFavourites, removeFromFavourites } from "@/redux/actions/account";
import ProgressBar from "./ProgressBar";
import {
    setIsAssetsTableExpanded,
    setLockExpandButton,
    setSingleAssetViewActive,
} from "@/redux/actions/ui";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { ISymbolInfo } from "@/services/trade/symbols";
import NoResultsComponent from "../NoResultsComponent/NoResultsComponent";
import { formatAmount, normalyzeDecimalLength } from "@/utils/format";
import { multiply } from "@/utils/mathUtils";

import styles from "./AssetsTable.module.scss";

interface IProps {
    activeCategory: string;
    isSearching: boolean;
}

type SortingMode = "NONE" | "UP" | "DOWN";

interface SortingOptions {
    sortColumn: string;
    sortDirection: SortingMode;
}

interface IHeaderProps {
    isFooterExpanded: boolean;
    isOverlayed: boolean;
    handleChangeColumnSort: (column: string) => void;
    sortingOptions: SortingOptions;
    sortingIconMap: Record<SortingMode, string>;
    isAssetsTableExpanded: boolean;
}

const ResetFavoritesComponent = () => {
    const { isAssetsTableExpanded, isOpenTradesOpen, isPendingOrdersOpen } = useUI();
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    useEffect(() => {
        if (isAssetsTableExpanded) {
            dispatch(setIsAssetsTableExpanded(false));
        }
    }, []);

    return (
        <div
            className={clsx(styles.emptyList, {
                [styles.tradesTablesOpened]: isOpenTradesOpen || isPendingOrdersOpen,
            })}>
            {t("placeholder.empty")}
            <button
                className={styles.button}
                onClick={() => {
                    dispatch(addToFavourites());
                }}>
                <div className={styles.contentBtn}>
                    <div className={styles.resetIcon} />
                    <span className={styles.titleBtn}>{t("btn.empty")}</span>
                </div>
            </button>
        </div>
    );
};

const SentimentsTooltip = () => {
    const { t } = useTranslation("common");

    return (
        <div className={styles.sentimentsTooltip}>
            <h3>{t("traders_choice")}</h3>
            <div className={styles.sentimentsTooltipBuy}>{t("open_positions_long")}</div>
            <div className={styles.sentimentsTooltipSell}>{t("open_positions_short")}</div>
            <div className={styles.sentimentsTooltipDisclaimer}>{t("choice_objectives")}</div>
        </div>
    );
};

interface IRowProps {
    isAssetsTableExpanded: boolean;
    symbol: ISymbolInfo;
    assetImage1: any;
    assetImage2: any;
    isActive: boolean;
    isExpanded: boolean;
    isInFavourites: boolean;
    handleRowClick: (value: string) => void;
    sentimentsSell: number;
    sentimentsBuy: number;
    accountCurrency: string;
    rowIndex?: number;
}

const TableRowComponent: React.FC<IRowProps> = ({
    isAssetsTableExpanded,
    symbol,
    assetImage1,
    assetImage2,
    isActive,
    isExpanded,
    isInFavourites,
    handleRowClick,
    sentimentsSell,
    sentimentsBuy,
    rowIndex,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMarketOpen } = useMarketSchedule(symbol.id);

    if (assetImage1 && !assetImage1.includes("http")) {
        assetImage1 = `/images/symbols/${assetImage1}`;
    }

    if (assetImage2 && !assetImage2.includes("http")) {
        assetImage2 = `/images/symbols/${assetImage2}`;
    }

    return (
        <div
            className={clsx(styles.table_row, styles.withBorder, styles.withHighlight, {
                [styles.expanded]: isAssetsTableExpanded,
                [styles.activeRow]: isActive,
            })}
            style={{ animationDelay: `${multiply(rowIndex, 0.02)}s` }}
            key={symbol.id}
            id={symbol.id}>
            <div
                onClick={() => handleRowClick(symbol.id)}
                className={clsx(styles.tableHeader_column, styles.cell_name, {
                    [styles.activeRow]: isActive,
                })}
                title={getSymbolNameTranslation(symbol, t)}>
                <div className={styles.symbolMainInfoCell}>
                    <div
                        className={clsx(styles.symbolMainInfoImages, {
                            [styles.symbolMainInfoSingleImage]: !assetImage1 || !assetImage2,
                        })}>
                        <object data={assetImage1}>
                            <img src={assetImage1} alt="" />
                        </object>
                        {assetImage2 && (
                            <object data={assetImage2}>
                                <img src={assetImage2} alt="" />
                            </object>
                        )}
                    </div>
                    <div className={styles.symbolMainInfoName}>{symbol.ex.displayName || symbol.id}</div>
                </div>
                {isActive && <SymbolAvailabilityInfo />}
            </div>
            <div
                onClick={() => handleRowClick(symbol.id)}
                className={clsx(styles.tableHeader_column, styles.cell_price)}>
                <QuoteData
                    symbolId={symbol.id}
                    priceChangeTTL={450}
                    render={({ bid, changeType }) => {
                        const price = getFormattedPrice(bid, symbol.point);
                        const displayValue = normalyzeDecimalLength(
                            formatAmount(Number(price), "", symbol.digits),
                            symbol.digits
                        );
                        return (
                            <span
                                className={clsx({
                                    [styles.priceChangeUp]: changeType === PriceChangeType.Up,
                                    [styles.priceChangeDown]: changeType === PriceChangeType.Down,
                                    [styles.priceFreeze]: !isMarketOpen,
                                })}>
                                {displayValue}
                            </span>
                        );
                    }}
                />
            </div>
            <div
                onClick={() => handleRowClick(symbol.id)}
                className={clsx(styles.tableHeader_column, styles.cell_change)}>
                <QuoteData
                    symbolId={symbol.id}
                    render={({ bid }) => {
                        const percentage = getAssetsPriceChangePercentage(
                            bid,
                            symbol.quote.midnightPrice
                        );
                        const changeType = getAssetsPriceChangeType(bid, symbol.quote.midnightPrice);

                        return (
                            <span
                                style={{
                                    color: isMarketOpen
                                        ? changeType === PriceChangeType.Down
                                            ? "#F25555"
                                            : changeType === PriceChangeType.Up
                                            ? "#2CC47E"
                                            : "inherit"
                                        : "inherit",
                                }}>
                                {isMarketOpen && percentage ? `${percentage.toFixed(2)}%` : "0.00%"}
                            </span>
                        );
                    }}
                />
            </div>
            <div
                onClick={() => handleRowClick(symbol.id)}
                className={clsx(styles.tableHeader_column, styles.cell_hight_low)}>
                <QuoteData
                    symbolId={symbol.id}
                    render={({ high, low }) => (
                        <>
                            {getFormattedPrice(high, symbol.point)}/
                            {getFormattedPrice(low, symbol.point)}
                        </>
                    )}
                />
            </div>
            <div className={clsx(styles.tableHeader_column, styles.cell_favorite)}>
                <Checkbox
                    style={isExpanded ? { padding: 0 } : { paddingTop: 0, paddingBottom: 0 }}
                    icon={<div className={styles.favoritesIcon} />}
                    checked={isInFavourites}
                    onChange={() => {
                        return isInFavourites
                            ? dispatch(removeFromFavourites(symbol.id))
                            : dispatch(addToFavourites(symbol.id));
                    }}
                    checkedIcon={
                        <div className={clsx(styles.favoritesIcon, styles.favoritesIconChecked)} />
                    }
                />
            </div>
            {isAssetsTableExpanded && (
                <>
                    <div
                        onClick={() => handleRowClick(symbol.id)}
                        className={clsx(styles.tableHeader_column, styles.cell_bear)}>
                        {sentimentsSell.toFixed(0)}%
                    </div>
                    <div
                        onClick={() => handleRowClick(symbol.id)}
                        className={clsx(styles.tableHeader_column)}>
                        <ProgressBar value={sentimentsSell} minWidth="40px" />
                    </div>
                    <div
                        onClick={() => handleRowClick(symbol.id)}
                        className={clsx(styles.tableHeader_column, styles.cell_bull)}>
                        {sentimentsBuy.toFixed(0)}%
                    </div>
                </>
            )}
        </div>
    );
};

const TableHeader: React.FC<IHeaderProps> = ({
    isFooterExpanded = false,
    isOverlayed = false,
    handleChangeColumnSort,
    sortingOptions: { sortColumn, sortDirection },
    sortingIconMap,
    isAssetsTableExpanded,
}) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { isFirstRenderAnimationFinished } = useUI();
    const imgThemePostfix = palette.mode === "light" ? "-light" : "";

    return (
        <div
            className={clsx(styles.tableHeader_container, {
                [styles.collapsed]: isFooterExpanded,
                [styles.overlayed]: isOverlayed,
                [styles.noAnimation]: isFirstRenderAnimationFinished,
            })}>
            <div
                className={clsx(styles.table_row, {
                    [styles.expanded]: isAssetsTableExpanded,
                })}>
                <div
                    className={clsx(styles.tableHeader_cell, styles.cell_name)}
                    onClick={() => handleChangeColumnSort("assetName")}>
                    {t("assetName")}
                    <span className={styles.columnSortIcon}>
                        <img
                            src={`/images/${
                                sortingIconMap[sortColumn === "assetName" ? sortDirection : "NONE"]
                            }`}
                            alt="sort icon"
                        />
                    </span>
                </div>
                <div className={clsx(styles.tableHeader_cell, styles.cell_price)}>{t("price")}</div>
                <div
                    className={clsx(styles.tableHeader_cell, styles.cell_change)}
                    onClick={() => handleChangeColumnSort("change")}>
                    {t("change")}
                    <span className={styles.columnSortIcon}>
                        <img
                            src={`/images/${
                                sortingIconMap[sortColumn === "change" ? sortDirection : "NONE"]
                            }`}
                            alt="sort icon"
                        />
                    </span>
                </div>
                <div className={clsx(styles.tableHeader_cell, styles.cell_hight_low)}>
                    {t("highlow")}
                </div>
                <div className={clsx(styles.tableHeader_cell)} />
                {isAssetsTableExpanded && (
                    <>
                        <div className={clsx(styles.tableHeader_cell, styles.cell_bear)}>
                            <img
                                src={`images/bear${imgThemePostfix}.svg`}
                                alt="bear market"
                                width="20"
                                height="20"
                            />
                        </div>
                        <div className={clsx(styles.tableHeader_cell, styles.sentimentsHeadCell)}>
                            <img
                                src={`images/info-filled${imgThemePostfix}.svg`}
                                alt="info"
                                width="12"
                                height="12"
                            />
                            {t("sentiments")}
                            <SentimentsTooltip />
                        </div>
                        <div className={clsx(styles.tableHeader_cell, styles.cell_bull)}>
                            <img
                                src={`images/bull${imgThemePostfix}.svg`}
                                alt="bull market"
                                width="20"
                                height="20"
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const AssetsTable: React.FC<IProps> = ({ isSearching, activeCategory }) => {
    const [sortingOptions, setSortingOptions] = useState<SortingOptions>({
        sortColumn: "",
        sortDirection: "NONE",
    });
    const { isAssetsTableExpanded, isFooterExpanded, isExpandButtonLocked } = useUI();
    const introStep = useIntroStep();
    const isOverlayed = introStep === 2;

    const sortingIconMap: Record<SortingMode, string> = {
        NONE: "sort-default.svg",
        UP: "sort-up.svg",
        DOWN: "sort-down.svg",
    };

    const handleChangeColumnSort = (sortColumn: string) => {
        let newSort: SortingMode = "NONE";

        if (sortingOptions.sortColumn === sortColumn) {
            if (sortingOptions.sortDirection === "NONE") {
                newSort = "UP";
            } else if (sortingOptions.sortDirection === "UP") {
                newSort = "DOWN";
            }
        } else {
            newSort = "UP";
        }

        setSortingOptions({ sortColumn, sortDirection: newSort });
    };

    return (
        <>
            {!isSearching && (
                <TableHeader
                    isFooterExpanded={isFooterExpanded}
                    isOverlayed={isOverlayed}
                    handleChangeColumnSort={handleChangeColumnSort}
                    sortingOptions={sortingOptions}
                    sortingIconMap={sortingIconMap}
                    isAssetsTableExpanded={isAssetsTableExpanded}
                />
            )}
            <div
                className={clsx(styles.tableContainer, {
                    [styles.tableExpanded]: isAssetsTableExpanded,
                    [styles.collapsed]: isFooterExpanded,
                    [styles.overlayed]: isOverlayed,
                    [styles.noSearchResultsMode]: isExpandButtonLocked,
                })}>
                <div>
                    <AssetsTableContent
                        activeCategory={activeCategory}
                        sortingOptions={sortingOptions}
                        isExpanded={isAssetsTableExpanded}
                        isSearching={isSearching}
                    />
                </div>
            </div>
        </>
    );
};

const SymbolAvailabilityInfo = (): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { activeSymbolId } = useTradeInfo();
    const { waitingTimeMessage, isMarketOpen } = useMarketWaitingTimeMessage(activeSymbolId);

    const getAlertMessage = () => {
        if (isMarketOpen) {
            return t("asset_available");
        }

        if (waitingTimeMessage) {
            return (
                <span style={{ color: palette.mode === "dark" ? "#a7aebb" : "#89909c" }}>
                    {waitingTimeMessage}
                </span>
            );
        }

        return t("openTradingTimes.marketClosed");
    };

    return (
        <div className={styles.symbolAvailabilityInfo}>
            <img
                src={`/images/assets-table/market-${isMarketOpen ? "open" : "closed"}.svg`}
                className={styles.symbolAvailabilityIcon}
                alt="Market availability icon"
            />
            {getAlertMessage()}
        </div>
    );
};

interface IAssetsTableContentProps {
    activeCategory: string;
    sortingOptions: SortingOptions;
    isExpanded: boolean;
    isSearching: boolean;
}

const AssetsTableContent: React.FC<IAssetsTableContentProps> = ({
    activeCategory,
    sortingOptions: { sortColumn, sortDirection },
    isExpanded,
    isSearching,
}) => {
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const introStep = useIntroStep();
    const {
        symbolsInfo,
        symbolsInfoById,
        activeSymbolId,
        search,
        groups: assetCategories,
        isActiveSymbolFromURL,
    } = useTradeInfo();
    const {
        isAssetsTableExpanded,
        isPendingOrdersOpen,
        isOpenTradesOpen,
        isExpandButtonLocked,
        assetToScrollOn,
    } = useUI();
    const { platform: devicePlatform } = useDevice();
    const favourites = useFavourites();
    const { currency: accountCurrency } = useAccountInfo();

    const categoriesNames = assetCategories.map(({ name }) => name);

    let tradeData = applySymbolFilters(symbolsInfo, {
        search,
        categoriesNames,
        activeCategory,
        favourites,
    });

    useEffect(() => {
        if (!tradeData.length) {
            dispatch(setLockExpandButton(true));
        } else if (tradeData.length && isExpandButtonLocked) {
            dispatch(setLockExpandButton(false));
        }
    }, [tradeData.length]);

    useEffect(() => {
        if (tradeData?.length) {
            if (isActiveSymbolFromURL) {
                dispatch(setActiveSymbolFromURL(false));
            } else {
                dispatch(setActiveSymbol(tradeData[0].id, symbolsInfoById[tradeData[0].id].point));
            }
        }
    }, []);

    const quoteData = tradeData.reduce((all, { id }) => {
        const { bid } = getSymbolQuoteValue(id);

        return {
            ...all,
            [id]: bid,
        };
    }, {});

    if (["DOWN", "UP"].includes(sortDirection)) {
        tradeData.sort((a, b) => {
            if (sortColumn === "change") {
                const changeA = getAssetsPriceChangePercentage(quoteData[a.id], a.quote.midnightPrice);
                const changeB = getAssetsPriceChangePercentage(quoteData[b.id], b.quote.midnightPrice);

                if (a.quote.midnightPrice === null) {
                    return 1;
                } else if (b.quote.midnightPrice === null) {
                    return -1;
                }
                return sortDirection === "UP" ? changeB - changeA : changeA - changeB;
            } else if (sortColumn === "assetName") {
                const changeA = a.ex.displayName.toLowerCase();
                const changeB = b.ex.displayName.toLowerCase();
                return sortDirection === "UP"
                    ? changeA.toLowerCase().localeCompare(changeB)
                    : changeB.toLowerCase().localeCompare(changeA);
            }
        });
    } else {
        tradeData = getDefaultAssetsSort(tradeData);
    }

    const handleRowClick = useCallback(
        symbolId => {
            dispatch(setActiveSymbol(symbolId, symbolsInfoById[symbolId].point));

            if (devicePlatform === DevicePlatform.Mobile) {
                dispatch(setSingleAssetViewActive());
            }
        },
        [devicePlatform]
    );

    const displayResetDefaultAssets =
        !isSearching && !tradeData.length && activeCategory === "Favourites";
    const displayNoResults = isSearching && !tradeData.length;

    if (displayNoResults && introStep === 2) {
        dispatch(setSearch(""));
    }

    const ps = useRef();
    const scrollToCoords = coords => {
        const curr = ps.current;
        if (curr) {
            //@ts-ignore
            curr.scrollTop = coords;
        }
    };
    useEffect(() => {
        if (assetToScrollOn) {
            let index = tradeData.findIndex(a => a.id.toLowerCase() == assetToScrollOn.toLowerCase());

            if (index === -1) {
                index = tradeData.findIndex(
                    a =>
                        a.id.replace("!", "").toLowerCase() ==
                        assetToScrollOn.replace("!", "").toLowerCase()
                );
                scrollToCoords(index * 44);
            } else {
                scrollToCoords(index * 44);
            }
        }
    }, [assetToScrollOn]);
    return (
        <div
            className={clsx(styles.tableBody, {
                [styles.tableBody__light]: palette.mode === "light",
                [styles.trades_table_opened]: isOpenTradesOpen || isPendingOrdersOpen,
                [styles.noResults]:
                    (displayResetDefaultAssets || displayNoResults) &&
                    (isOpenTradesOpen || isPendingOrdersOpen),
            })}>
            <PerfectScrollbar
                //@ts-ignore
                containerRef={el => (ps.current = el)}
                options={{ suppressScrollX: true }}>
                {displayResetDefaultAssets && <ResetFavoritesComponent />}
                {displayNoResults && <NoResultsComponent />}
                {tradeData.map((symbol, index) => {
                    const [assetImage1, assetImage2] = getSymbolAssets(
                        symbol.id,
                        symbol?.group?.name || activeCategory
                    );
                    const isInFavourites =
                        favourites && favourites.length ? favourites?.includes(symbol.id) : false;
                    const { buy: sentimentsBuy, sell: sentimentsSell } = getSentimentsInfo(symbol);
                    const isActive = activeSymbolId === symbol.id;

                    return (
                        <TableRowComponent
                            key={symbol.id}
                            isAssetsTableExpanded={isAssetsTableExpanded}
                            symbol={symbol}
                            assetImage1={assetImage1}
                            assetImage2={assetImage2}
                            isActive={isActive}
                            isExpanded={isExpanded}
                            isInFavourites={isInFavourites}
                            handleRowClick={handleRowClick}
                            sentimentsSell={sentimentsSell}
                            sentimentsBuy={sentimentsBuy}
                            accountCurrency={accountCurrency}
                            rowIndex={index + 1}
                        />
                    );
                })}
            </PerfectScrollbar>
        </div>
    );
};

export default React.memo(AssetsTable);
