import React, { useReducer, createContext, useEffect } from "react";

import { useTradeInfo } from "redux/selectors/tradeInfoSelector";

import { ITradeOrderState, TradeOrderActions } from "./interfaces";
import tradeOrderReducer from "./reducer";
import { setActiveSymbol, setLotSize } from "./actions";
import { isForexSymbol } from "@/hooks/symbols";
import { createTimestamp } from "@/utils/dateTime";

const tradeOrderDefaultState: ITradeOrderState = {
    activeSymbolId: null,
    isForex: false,
    activeSymbolPoint: 0.001,
    activeOperationCategory: null,
    lotSize: 0.01,
    isPendingOrder: false,
    pendingOrderPrice: 0,
    pendingOrderType: null,
    isPendingOrderExpiration: false,
    pendingOrderExpiration: "",
    isStopLoss: false,
    stopLossPrice: 0,
    stopLossPips: 0,
    stopLossPriceCalcMode: "Pips",
    isTakeProfit: false,
    takeProfitPrice: 0,
    takeProfitPips: 0,
    takeProfitPriceCalcMode: "Pips",
    lastOrderResetTime: createTimestamp(),
};

/**
 * Used only to initialize the TradeOrder Context.
 * useContext(TradeOrderStateContext) should be used inside the component.
 *
 * Stores only the core order parameters / logic, dynamic values are calculated in
 * useEditOrder.
 */
export const useTradeOrder = (
    defaults: Partial<ITradeOrderState>
): [ITradeOrderState, React.Dispatch<TradeOrderActions>] => {
    defaults = defaults || {};

    const [state, dispatch] = useReducer(tradeOrderReducer, {
        ...tradeOrderDefaultState,
        ...defaults,
    });
    const { activeSymbolId } = defaults;
    const { symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    useEffect(() => {
        dispatch(setActiveSymbol(activeSymbolId, symbolInfo?.point, isForexSymbol(symbolInfo)));
        dispatch(setLotSize(symbolInfo?.group?.lotMin || 0.01));
    }, [dispatch, activeSymbolId, symbolInfo, symbolInfo?.point]);

    return [state, dispatch];
};

export const TradeOrderStateContext = createContext<ITradeOrderState>(null);

TradeOrderStateContext.displayName = "TradeOrderStateContext";

export const TradeOrderDispatchContext = createContext<React.Dispatch<TradeOrderActions>>(null);

TradeOrderDispatchContext.displayName = "TradeOrderDispatchContext";
