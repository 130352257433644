import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ITradeOrder } from "@/services/trade/order";
import { getFormattedPrice } from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { QuoteData } from "@/hooks/trade/tradeSocket";
import { getDirectionsTranslationKey } from "@/utils/helpers";

import styles from "../InfoPopup.module.scss";


interface IContentHeaderProps {
    order: ITradeOrder;
}

const ContentHeader: React.FC<IContentHeaderProps> = ({
    order: {
        symbol: symbolId,
        type,
        lots,
        openPrice
    }
})=> {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[symbolId];

    return (
        <div className={styles.content_header}>
            <div className={styles.content_header_column}>
                <span className={styles[`content_header_title_${palette.mode}`]}>
                    {t("assetName")}
                </span>
                <span className={styles.content_header_subtitle}>
                    {symbolInfo?.ex?.displayName || symbolId}
                </span>
            </div>
            <div className={styles.content_header_column}>
                <span className={styles[`content_header_title_${palette.mode}`]}>
                    {t("direction")}
                </span>
                <span className={styles.content_header_subtitle}>{t(getDirectionsTranslationKey(type))}</span>
            </div>
            <div className={styles.content_header_column}>
                <span className={styles[`content_header_title_${palette.mode}`]}>
                    {t("highlow")}
                </span>
                <span className={styles.content_header_subtitle}>
                    <QuoteData
                        symbolId={symbolId}
                        render={({ high, low }) => (
                            <>
                                {getFormattedPrice(high, symbolInfo?.point)}&nbsp;/&nbsp;
                                {getFormattedPrice(low, symbolInfo?.point)}
                            </>
                        )}
                    />
                </span>
            </div>
            <div className={styles.content_header_column}>
                <span className={styles[`content_header_title_${palette.mode}`]}>{t("lots")}</span>
                <span className={styles.content_header_subtitle}>{lots}</span>
            </div>
            <div className={styles.content_header_column}>
                <span className={styles[`content_header_title_${palette.mode}`]}>
                    {t("openPrice")}
                </span>
                <span className={styles.content_header_subtitle}>{openPrice}</span>
            </div>
        </div>
    );
};

export default React.memo(ContentHeader);
