import React, { useMemo, useState } from "react";
import clsx from "clsx";
import chunk from "lodash/chunk";
import { useTranslation } from "next-i18next";
import { generateYears, months } from "../helpers";

import styles from "./YearMonthPicker.module.scss";

interface IMonthYearPickerProps {
    date: Date;
    minDate?: Date | string;
    maxDate?: Date;
    changeMonth?: (month: number) => void;
    changeYear: (value: number | string) => void;
    onShowYear: () => void;
    showMonthsPicker?: boolean;
    setShowMonthsPicker?: () => void;
    timeStampId?: number;
}

const MonthPicker: React.FC<IMonthYearPickerProps> = ({
    date,
    minDate,
    maxDate,
    changeMonth,
    changeYear,
    onShowYear,
}) => {
    const { t } = useTranslation("common");
    const now = new Date();
    const selectedYear = new Date(date).getFullYear();
    const [activeMonth, setActiveMonth] = useState(date.getMonth());

    const decreaseYear = () => changeYear(selectedYear - 1);
    const increaseYear = () => changeYear(selectedYear + 1);

    const handleChangeMonth = (month: string) => {
        return () => {
            setActiveMonth(months.indexOf(month));
            changeMonth(months.indexOf(month));
            onShowYear();
        };
    };

    const isMonthDisabled = (monthName: string) => (
        (minDate &&
            now.getFullYear() === selectedYear &&
            months.indexOf(monthName) < now.getMonth()) ||
        (maxDate &&
            (
                maxDate.getFullYear() < selectedYear ||
                maxDate.getFullYear() === selectedYear && months.indexOf(monthName) > now.getMonth()
            ))
    );

    return (
        <div className={styles.months_picker}>
            <div className={styles.months_picker__header}>
                <button
                    onClick={minDate && now.getFullYear() === selectedYear ? () => { } : decreaseYear}
                    className={clsx(styles.years_switcher, styles.left, {
                        [styles.disabled]: minDate && now.getFullYear() === selectedYear,
                    })}
                />
                <div className={styles.months_picker_header_year} onClick={onShowYear}>
                    {selectedYear}
                    <div className={styles.backArrowIcon}></div>
                </div>
                <button
                    onClick={increaseYear}
                    className={clsx(styles.years_switcher, styles.right)}
                />
            </div>
            <div className={styles.months_picker__content}>
                {chunk(months, 3).map((monthsRow, mIndex) => {
                    return (
                        <div className={styles.row} key={mIndex}>
                            {monthsRow.map((monthName: string, rmIndex: number) => {
                                return (
                                    <div
                                        key={`${monthName}-${rmIndex}`}
                                        className={clsx(styles.column, {
                                            [styles.currentMonth]: activeMonth === months.indexOf(monthName) &&
                                                (now.getFullYear() === selectedYear ? months.indexOf(monthName) >= now.getMonth() : false),
                                            [styles.disableMonth]: isMonthDisabled(monthName)
                                        })}
                                        onClick={!isMonthDisabled(monthName) ? handleChangeMonth(monthName) : () => {}}
                                    >
                                        {monthName && t(`months.${monthName.toLowerCase()}`).slice(0, 3)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const YearPicker: React.FC<IMonthYearPickerProps> = ({
    date,
    minDate,
    maxDate,
    onShowYear,
    changeYear,
    setShowMonthsPicker,
}) => {
    const selectedYear = new Date(date).getFullYear();
    const [startYear, setStartYear] = useState(minDate ? new Date(minDate).getFullYear() : 2010);
    const pageYears = useMemo(() => generateYears(startYear), [startYear]);

    const isValidYear = (year: number): boolean => {
        if (!minDate && !maxDate) {
            return true;
        }

        if (minDate && maxDate) {
            return (
                year >= new Date(minDate).getFullYear() &&
                year <= new Date(maxDate).getFullYear()
            );
        }

        return (
            minDate && year >= new Date(minDate).getFullYear() ||
            maxDate && year <= new Date(maxDate).getFullYear()
        );
    };

    const isSwitcherActive = (direction: "left" | "right"): boolean => {
        if (!minDate && !maxDate) {
            return true;
        }

        if (direction === "left" && minDate) {
            return !pageYears.flat().includes(new Date(minDate).getFullYear());
        }

        if (direction === "right" && maxDate) {
            return !pageYears.flat().includes(new Date(maxDate).getFullYear());
        }
        return true;
    };

    const handleChangeYear = (year: number) => {
        return () => {
            if (isValidYear(year)) {
                changeYear(year);
                setShowMonthsPicker();
            }
        };
    };

    return (
        <div className={styles.years_picker}>
            <div onClick={onShowYear} className={styles.years_picker__header}>
                <div className={styles.header__currentYear}>
                    {selectedYear}
                    <div className={styles.backArrowIcon}></div>
                </div>
                <div className={styles.header__yearsPeriod}>{`${startYear}~${startYear + 20}`}</div>
            </div>
            <div className={styles.years_picker__content}>
                <button
                    className={clsx(styles.years_switcher, styles.left, {
                        [styles.disabled]: !isSwitcherActive("left"),
                    })}
                    onClick={() => {
                        if (isSwitcherActive("left")) {
                            setStartYear(prev => prev - 21);
                        }
                    }}
                />
                <div className={styles.years_picker__years}>
                    {pageYears.map((rowYears, index) => {
                        return (
                            <div className={styles.row} key={index}>
                                {rowYears.map((year, index2) => {
                                    return (
                                        <div
                                            key={index2}
                                            className={clsx(styles.column, {
                                                [styles.activeYear]: year === selectedYear,
                                                [styles.disabled]: !isValidYear(year),
                                            })}
                                            onClick={handleChangeYear(year)}>
                                            {year}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <button
                    className={clsx(styles.years_switcher, styles.right, {
                        [styles.disabled]: !isSwitcherActive("right"),
                    })}
                    onClick={() => {
                        if (isSwitcherActive("right")) {
                            setStartYear(prev => prev + 21);
                        }
                    }}
                />
            </div>
        </div>
    );
};

const MonthYearPicker: React.FC<IMonthYearPickerProps> = (props) => (
    <>
        {props.showMonthsPicker ?
            <MonthPicker {...props} /> :
            <YearPicker {...props} />
        }
    </>
);


export default React.memo(MonthYearPicker);
