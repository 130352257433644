import { ITradeNotification } from "./interfaces";

export function filterByLatestTriggerTime(tradeAlerts: ITradeNotification[]): ITradeNotification[] {
    const groupedByAssetName: { [key: string]: ITradeNotification } = {};

    tradeAlerts.forEach(alert => {
        const { assetName, date } = alert;

        if (groupedByAssetName[assetName]) {
            const { date: existingDate } = groupedByAssetName[assetName];
            const isLatest =
                date > existingDate ||
                (date.getTime() === existingDate.getTime() &&
                    date.getMilliseconds() > existingDate.getMilliseconds());

            if (isLatest) {
                groupedByAssetName[assetName] = alert;
            }
        } else {
            groupedByAssetName[assetName] = alert;
        }
    });

    const filteredAlerts: ITradeNotification[] = Object.values(groupedByAssetName);

    return filteredAlerts.sort((a, b) => {
        if (b.date.getTime() === a.date.getTime()) {
            return b.date.getMilliseconds() - a.date.getMilliseconds();
        }
        return b.date.getTime() - a.date.getTime();
    });
}

export const filterOutHidden = (tradeAlerts: ITradeNotification[], symbolsInfoById) => {
    return tradeAlerts
        .map(al => {
            const asset = symbolsInfoById[al.assetName];
            if (!asset) return null;
            //const isClosed = asset?.ex.tradeMode === "Close";
            const isHidden = asset?.isHidden;
            if (!isHidden) return al;
        })
        .filter(al => al);
};
