import { ITradeOrder } from "@/services/trade/order";

export const tableResponseParser = (response = []): ITradeOrder[] => {
    if (!response || !response.length) {
        return [];
    }
    return response;
    return response.sort((a, b) => {
        return a.openTime - b.openTime;
    });
};
