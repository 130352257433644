import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useUI } from "@/redux/selectors/uiSelector";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { getStopLossPriceFromPips, getTakeProfitPriceFromPips } from "@/utils/symbols";
import {
    convertAmountToCurrency,
    getPriceDiffInPipsOrPoints,
    getSymbolQuoteCurrency,
} from "@/utils/trade";
import {
    setIsStopLoss,
    setIsTakeProfit,
    setStopLossPips,
    setStopLossPrice,
    setTakeProfitPips,
    setTakeProfitPrice,
} from "@/contexts/TradeOrder/actions";
import { setOperationTimestamp } from "@/utils/helpers";
import { modifyOrderSubmit } from "@/redux/actions/orders";
import { trimNumberToStepValue } from "@/utils/numbers";
import { ITradeOrderState } from "@/contexts/TradeOrder/interfaces";
import { ISymbolInfo } from "@/services/trade/symbols";
import { ITradeOrder } from "@/services/trade/order";
import { isForexSymbol } from "@/hooks/symbols";
import { isPendingOrder as isPendingOrderCheck } from "@/utils/trade";
import { assignPriceParamsToOrder } from "@/utils/helpers";

interface IProps {
    tradeOrder: ITradeOrderState;
    tradeOrderDispatch: any;
    order: ITradeOrder | null;
}

interface IGetModifyOrderRequestParamsProps extends ITradeOrderState {
    ticket: number;
    point: number;
    symbolsInfoById: Record<string, ISymbolInfo>;
    basePrice: number;
    expiration: string;
}

const getModifyOrderRequestParams = (props: IGetModifyOrderRequestParamsProps) => {
    const {
        ticket,
        point,
        symbolsInfoById,
        activeOperationCategory,
        activeSymbolId,
        isPendingOrder,
        pendingOrderPrice,
        activeSymbolPoint,
        isStopLoss,
        isTakeProfit,
        stopLossPriceCalcMode,
        stopLossPips,
        takeProfitPips,
        stopLossPrice,
        takeProfitPriceCalcMode,
        takeProfitPrice,
        basePrice,
        expiration,
    } = props;

    const modifyOrderParams = {
        ticket,
        stopLoss: 0,
        takeProfit: 0,
        ...(expiration && { expiration }),
    };

    const symbolInfo = symbolsInfoById[activeSymbolId];
    const isForex = isForexSymbol(symbolInfo);

    const sltpPriceCalcStoreArgs = {
        activeOperationCategory,
        activeSymbolId,
        symbolsInfoById,
        isPendingOrder,
        pendingOrderPrice,
        activeSymbolPoint,
        isForex,
    };

    if (isStopLoss) {
        const reqStopLossPrice =
            stopLossPriceCalcMode === "Pips"
                ? getStopLossPriceFromPips(stopLossPips, sltpPriceCalcStoreArgs, basePrice)
                : stopLossPrice;

        modifyOrderParams.stopLoss = Number(trimNumberToStepValue(reqStopLossPrice, point));
    }

    if (isTakeProfit) {
        const reqTakeProfitPrice =
            takeProfitPriceCalcMode === "Pips"
                ? getTakeProfitPriceFromPips(takeProfitPips, sltpPriceCalcStoreArgs, basePrice)
                : takeProfitPrice;

        modifyOrderParams.takeProfit = Number(trimNumberToStepValue(reqTakeProfitPrice, point));
    }

    return modifyOrderParams;
};

type EditOrderTradeOrderProps =
    | "lotSize"
    | "activeOperationCategory"
    | "activeSymbolId"
    | "activeSymbolPoint"
    | "isForex"
    // SL
    | "isStopLoss"
    | "stopLossPriceCalcMode"
    | "stopLossPips"
    | "stopLossPrice"
    // TP
    | "isTakeProfit"
    | "takeProfitPriceCalcMode"
    | "takeProfitPips"
    | "takeProfitPrice";

export interface IEditOrderState extends Pick<ITradeOrderState, EditOrderTradeOrderProps> {
    symbolInfo: ISymbolInfo;
    accountCurrency: string;
    isValidOrder: boolean;
    handleModifyOrderSubmit: () => void;
    // SL props
    stopLossPriceDisplayValue: number;
    stopLossPipsDisplayValue: number;
    stopLossPriceFormInputValue: number | null;
    stopLossPipsFormInputValue: number | null;
    stopLossValue: number;
    stopLossAmount: number;
    stopLossPLValue: number; // Profit calculation, only for open orders
    // SL methods
    handleStopLossToggle: () => void;
    handleStopLossPipsChange: (pips: number) => void;
    handleStopLossPriceChange: (price: number) => void;
    // TP props
    takeProfitPriceDisplayValue: number;
    takeProfitPipsDisplayValue: number;
    takeProfitPriceFormInputValue: number | null;
    takeProfitPipsFormInputValue: number | null;
    takeProfitValue: number;
    takeProfitAmount: number;
    takeProfitPLValue: number; // Profit calculation, only for open orders
    // TP methods
    handleTakeProfitToggle: () => void;
    handleTakeProfitPipsChange: (pips: number) => void;
    handleTakeProfitPriceChange: (price: number) => void;
}

/**
 * Provides the UI state for order editing
 */
export const useEditOrder = ({ order, tradeOrderDispatch, tradeOrder }: IProps): IEditOrderState => {
    setOperationTimestamp();

    const dispatch = useDispatch();
    const { isOrderModifyPopupRequestPending } = useUI();
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById, symbols } = useTradeInfo();
    const isOpenOrder = order !== null;
    const isOpenOrderPending = isOpenOrder && isPendingOrderCheck(order);

    let orderId = 0;
    let openPrice = 0;

    // for create order form we don't have an order yet
    if (isOpenOrder) {
        orderId = order.ticket;
        openPrice = order.openPrice;
    }

    const {
        lotSize: lot,
        activeSymbolId,
        activeSymbolPoint,
        activeOperationCategory,
        isPendingOrder,
        pendingOrderPrice,
        isStopLoss,
        stopLossPrice,
        stopLossPips,
        stopLossPriceCalcMode,
        isTakeProfit,
        takeProfitPrice,
        takeProfitPips,
        takeProfitPriceCalcMode,
        pendingOrderExpiration,
    } = tradeOrder;

    const lotSize = order?.lots || lot;

    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { ask, bid } = useQuoteData(activeSymbolId);

    /**
     * For new orders we use the selected operation (Buy or Sell) price as base calc price
     * for regular orders and pending order price for pending orders.
     * For opened orders we use the current market price that position will close at.
     * For pending orders - pending order price, that the order will open at.
     */
    const basePrice = isOpenOrderPending
        ? order.openPrice
        : isOpenOrder
        ? activeOperationCategory === "Buy"
            ? bid
            : ask
        : isPendingOrder
        ? pendingOrderPrice
        : activeOperationCategory === "Buy"
        ? ask
        : bid;

    const isForex = isForexSymbol(symbolInfo);
    const sltpPriceCalcStoreArgs = {
        activeOperationCategory,
        activeSymbolId,
        activeSymbolPoint,
        isPendingOrder,
        pendingOrderPrice,
        isForex,
    };

    const symbolQuoteCurrency = getSymbolQuoteCurrency(symbolInfo);

    /**
     * SL/TP prices can be manual or calculated from market price and entered value in pips/points,
     * so the display value can be either static or dynamic.
     */
    const stopLossPriceDisplayValue =
        stopLossPriceCalcMode === "Pips"
            ? getStopLossPriceFromPips(stopLossPips, sltpPriceCalcStoreArgs, basePrice)
            : stopLossPrice;

    const stopLossPipsDisplayValue =
        stopLossPriceCalcMode === "Pips"
            ? stopLossPips
            : getPriceDiffInPipsOrPoints(
                  stopLossPriceDisplayValue,
                  basePrice,
                  symbolInfo?.point,
                  isForex
              );

    const isNullStopLoss =
        (stopLossPriceCalcMode === "Price" && stopLossPrice === null) ||
        (stopLossPriceCalcMode === "Pips" && stopLossPips === null);

    let stopLossPriceFormInputValue = 0;
    let stopLossPipsFormInputValue = 0;

    if (isStopLoss) {
        if (isNullStopLoss) {
            stopLossPriceFormInputValue = null;
            stopLossPipsFormInputValue = null;
        } else {
            stopLossPriceFormInputValue = stopLossPriceDisplayValue;
            stopLossPipsFormInputValue = stopLossPipsDisplayValue;
        }
    }

    const takeProfitPriceDisplayValue =
        takeProfitPriceCalcMode === "Pips"
            ? getTakeProfitPriceFromPips(takeProfitPips, sltpPriceCalcStoreArgs, basePrice)
            : takeProfitPrice;

    const takeProfitPipsDisplayValue =
        takeProfitPriceCalcMode === "Pips"
            ? takeProfitPips
            : getPriceDiffInPipsOrPoints(
                  takeProfitPriceDisplayValue,
                  basePrice,
                  symbolInfo?.point,
                  isForex
              );

    const isNullTakeProfit =
        (takeProfitPriceCalcMode === "Price" && takeProfitPrice === null) ||
        (takeProfitPriceCalcMode === "Pips" && takeProfitPips === null);

    let takeProfitPriceFormInputValue = 0;
    let takeProfitPipsFormInputValue = 0;

    if (isTakeProfit) {
        if (isNullTakeProfit) {
            takeProfitPipsFormInputValue = null;
            takeProfitPriceFormInputValue = null;
        } else {
            takeProfitPipsFormInputValue = takeProfitPipsDisplayValue;
            takeProfitPriceFormInputValue = takeProfitPriceDisplayValue;
        }
    }

    const pointValue = convertAmountToCurrency({
        amount: symbolInfo?.point * Number(lotSize) * symbolInfo?.contractSize,
        fromCurrency: symbolQuoteCurrency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: activeOperationCategory === "Sell" ? "Ask" : "Bid",
    });

    const pipValue = 10 * pointValue;

    const pointOrPipValue = isForex ? pipValue : pointValue;

    const stopLossAmount =
        stopLossPriceCalcMode === "Pips"
            ? pointOrPipValue * stopLossPips
            : (Math.abs(stopLossPrice - basePrice) / symbolInfo?.point) * pointValue;

    const takeProfitAmount =
        takeProfitPriceCalcMode === "Pips"
            ? pointOrPipValue * takeProfitPips
            : (Math.abs(takeProfitPrice - basePrice) / symbolInfo?.point) * pointValue;

    const contractSize = symbolInfo?.contractSize || 0;

    /**
     * For Stop Loss, the entered price will always be in the opposite direction from market
     * price for Buy/Sell, so the diff, amount and profit values will be negative.
     */
    const stopLossPriceDiff =
        activeOperationCategory === "Buy"
            ? stopLossPriceDisplayValue - basePrice
            : basePrice - stopLossPriceDisplayValue;

    const stopLossValue = useMemo(
        () =>
            convertAmountToCurrency({
                amount: stopLossPriceDiff * contractSize * Number(lotSize),
                fromCurrency: symbolQuoteCurrency,
                toCurrency: accountCurrency,
                symbols,
                conversionPriceType: activeOperationCategory === "Sell" ? "Ask" : "Bid",
            }),
        [
            stopLossPriceDiff,
            contractSize,
            lotSize,
            symbolQuoteCurrency,
            accountCurrency,
            symbols,
            activeOperationCategory,
        ]
    );

    /**
     * For Profit calculation of active orders we use the Open price instead of
     * current Market price
     */
    const stopLossProfitPriceDiff =
        activeOperationCategory === "Buy"
            ? stopLossPriceDisplayValue - openPrice
            : openPrice - stopLossPriceDisplayValue;

    const stopLossPLValue = useMemo(
        () =>
            convertAmountToCurrency({
                amount: stopLossProfitPriceDiff * contractSize * Number(lotSize),
                fromCurrency: symbolQuoteCurrency,
                toCurrency: accountCurrency,
                symbols,
                conversionPriceType: activeOperationCategory === "Sell" ? "Ask" : "Bid",
            }),
        [
            stopLossProfitPriceDiff,
            contractSize,
            lotSize,
            symbolQuoteCurrency,
            accountCurrency,
            symbols,
            activeOperationCategory,
        ]
    );

    const takeProfitPriceDiff =
        activeOperationCategory === "Buy"
            ? takeProfitPriceDisplayValue - basePrice
            : basePrice - takeProfitPriceDisplayValue;

    const takeProfitValue = useMemo(
        () =>
            convertAmountToCurrency({
                amount: takeProfitPriceDiff * contractSize * Number(lotSize),
                fromCurrency: symbolQuoteCurrency,
                toCurrency: accountCurrency,
                symbols,
                conversionPriceType: activeOperationCategory === "Sell" ? "Ask" : "Bid",
            }),
        [
            takeProfitPriceDiff,
            contractSize,
            lotSize,
            symbolQuoteCurrency,
            accountCurrency,
            symbols,
            activeOperationCategory,
        ]
    );

    const takeProfitProfitPriceDiff =
        activeOperationCategory === "Buy"
            ? takeProfitPriceDisplayValue - openPrice
            : openPrice - takeProfitPriceDisplayValue;

    const takeProfitPLValue = useMemo(
        () =>
            convertAmountToCurrency({
                amount: takeProfitProfitPriceDiff * contractSize * Number(lotSize),
                fromCurrency: symbolQuoteCurrency,
                toCurrency: accountCurrency,
                symbols,
                conversionPriceType: activeOperationCategory === "Sell" ? "Ask" : "Bid",
            }),
        [
            takeProfitProfitPriceDiff,
            contractSize,
            lotSize,
            symbolQuoteCurrency,
            accountCurrency,
            symbols,
            activeOperationCategory,
        ]
    );

    // TODO: maybe use single validation for create / modify / SLTP & pending submit
    const isValidOrder = !isNullStopLoss && !isNullTakeProfit;

    const handleStopLossToggle = useCallback(() => tradeOrderDispatch(setIsStopLoss(!isStopLoss)), [
        tradeOrderDispatch,
        isStopLoss,
    ]);

    const handleTakeProfitToggle = useCallback(
        () => tradeOrderDispatch(setIsTakeProfit(!isTakeProfit)),
        [tradeOrderDispatch, isTakeProfit]
    );

    const handleStopLossPipsChange = pips => tradeOrderDispatch(setStopLossPips(pips));
    const handleStopLossPriceChange = price => tradeOrderDispatch(setStopLossPrice(price));

    const handleTakeProfitPipsChange = pips => tradeOrderDispatch(setTakeProfitPips(pips));
    const handleTakeProfitPriceChange = price => tradeOrderDispatch(setTakeProfitPrice(price));

    // TODO: maybe add create order as well
    const handleModifyOrderSubmit = useCallback(() => {
        if (isOrderModifyPopupRequestPending) {
            return;
        }

        const basePrice = isOpenOrderPending
            ? order.openPrice
            : isOpenOrder
            ? activeOperationCategory === "Buy"
                ? bid
                : ask
            : isPendingOrder
            ? pendingOrderPrice
            : activeOperationCategory === "Buy"
            ? ask
            : bid;

        const expiration = isOpenOrderPending
            ? pendingOrderExpiration || "1970-01-01T00:00:00.000Z"
            : null;

        let modifyOrderParams = getModifyOrderRequestParams({
            ...tradeOrder,
            ticket: orderId,
            symbolsInfoById,
            point: symbolInfo?.point,
            basePrice,
            expiration,
        });
        // Assign also seenPrice here
        modifyOrderParams = assignPriceParamsToOrder({
            symbolInfo,
            originalObject: modifyOrderParams,
            ask,
            bid,
        });

        dispatch(modifyOrderSubmit(modifyOrderParams));
    }, [
        symbolsInfoById,
        orderId,
        symbolInfo?.point,
        ask,
        bid,
        tradeOrder,
        isOrderModifyPopupRequestPending,
        dispatch,
    ]);

    return {
        lotSize,
        activeSymbolId,
        activeOperationCategory,
        activeSymbolPoint,
        symbolInfo,
        isForex,
        accountCurrency,
        isValidOrder,
        handleModifyOrderSubmit,

        // SL
        isStopLoss,
        stopLossPriceCalcMode,
        stopLossPips,
        stopLossPrice,
        stopLossAmount,
        stopLossValue,
        stopLossPriceDisplayValue,
        stopLossPipsDisplayValue,
        stopLossPriceFormInputValue,
        stopLossPipsFormInputValue,
        stopLossPLValue,
        handleStopLossToggle,
        handleStopLossPipsChange,
        handleStopLossPriceChange,

        // TP
        isTakeProfit,
        takeProfitPriceCalcMode,
        takeProfitPips,
        takeProfitPrice,
        takeProfitAmount,
        takeProfitValue,
        takeProfitPriceDisplayValue,
        takeProfitPipsDisplayValue,
        takeProfitPriceFormInputValue,
        takeProfitPipsFormInputValue,
        takeProfitPLValue,
        handleTakeProfitToggle,
        handleTakeProfitPipsChange,
        handleTakeProfitPriceChange,
    };
};
