import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";

import { openTradeOrderModal } from "@/redux/actions/ui";
import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { getDecimalPrecision, trimNumberToStepValue } from "@/utils/numbers";
import { validateLotSizeValue } from "@/components/Home/TradingOrderSection/LotSize/helpers";

import styles from "./TradeCalculatorMobile.module.scss";

interface IProps {
    value?: number | string;
    step: number;
    onSubmit: (value?: number) => void;
    isSubmitDisabled?: boolean;
    onCancel?: () => void;
    onChange?: (value: number, labelValue?: string) => void;
    className?: string;
    label: string;
    lotMax?: number;
    lotMin?: number;
}

const TradeCalculatorMobile = ({
    value = 0,
    onCancel,
    onSubmit,
    className,
    step,
    label,
    onChange,
    lotMax,
    lotMin,
    isSubmitDisabled = false,
}: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const [displayValue, setDisplayValue] = useState(`${trimNumberToStepValue(Number(value), step)}`);
    const [lotError, setLotError] = useState("");
    const [isLotSizeValid, setIsLotSizeValid] = useState(true);
    const dispatch = useDispatch();

    const showErrorModal = (errorMessage: string) => {
        dispatch(openTradeOrderModal(ITradeOrderModalTypes.INVALID_PARAMS, { error: errorMessage }));
    };

    const handleChange = (value: string) => {
        if (onChange) {
            // only for lot size value typing
            const isValid = validateLotSizeValue(
                value,
                () => setLotError(""),
                (_, errorDetails) => setLotError(errorDetails?.message),
                lotMin,
                lotMax,
                t
            );

            if (isValid && Number(value) <= lotMax) {
                onChange(+value);
            }

            if (Number(value) <= lotMax) {
                setDisplayValue(value);
                onChange(null, value);
            }

            setIsLotSizeValid(isValid);
        } else {
            setDisplayValue(value);
        }
    };

    const handleSubmit = (displayValue: number) => {
        return () => {
            if (onChange && displayValue < lotMin) {
                showErrorModal(`Enter lot size between ${lotMin}-${lotMax}`);

                return;
            }

            onSubmit(displayValue);
        };
    };

    const handleAddNumber = (number: string) => {
        return () => {
            if (
                displayValue.includes(".") &&
                displayValue.split(".")[1]?.length >= getDecimalPrecision(step)
            ) {
                return;
            }
            if (displayValue === "0") {
                return handleChange(`${number}`);
            }

            handleChange(`${displayValue}${number}`);
        };
    };

    const handleBackspace = () => {
        if (displayValue.length === 1) {
            return handleChange("");
        }

        handleChange(displayValue.substring(0, displayValue.length - 1));
    };

    const handleDot = () => {
        if (displayValue.includes(".")) {
            return;
        }

        handleChange(`${displayValue}.`);
    };

    const handleChangeLot = (lotValue: string) => {
        return () => {
            const value = +displayValue;
            const lot = +lotValue;

            let precision = 0;

            if (displayValue.split(".").length === 2) {
                const arr = displayValue.split(".");

                precision = arr[1].length;
            }

            if (precision === 0 && `${value + lot}`.split(".").length === 2) {
                const arr = `${value + lot}`.split(".");

                precision = arr[1].length;
            }

            if (value + lot < 0) {
                return;
            }

            handleChange(`${(value + lot).toFixed(precision)}`);
        };
    };

    return (
        <div className={clsx(styles.container, className)}>
            <div className={styles.display}>
                <div className={styles.value}>{displayValue}</div>
                {
                    <div
                        className={clsx(styles.helpText, {
                            [styles.helpTextError]: lotError && displayValue !== "",
                        })}>
                        {lotError && displayValue !== ""
                            ? lotError
                            : displayValue !== ""
                            ? t("volumeALot")
                            : label}
                    </div>
                }
                {onCancel && (
                    <div
                        className={clsx(styles.cancelIcon, {
                            [styles.cancelIcon_light]: palette.mode === "light",
                        })}
                        onClick={onCancel}
                    />
                )}
            </div>
            <div className={styles.keyboard}>
                <button onClick={handleAddNumber("1")}>1</button>
                <button onClick={handleAddNumber("2")}>2</button>
                <button onClick={handleAddNumber("3")}>3</button>
                <button onClick={handleBackspace}>
                    <div
                        className={clsx(styles.backspaceIcon, {
                            [styles.backspaceIcon_light]: palette.mode === "light",
                        })}
                    />
                </button>

                <button onClick={handleAddNumber("4")}>4</button>
                <button onClick={handleAddNumber("5")}>5</button>
                <button onClick={handleAddNumber("6")}>6</button>
                <button onClick={handleChangeLot(`+${step * 50}`)}>
                    <div className={styles.volumeBlock}>+50</div>
                </button>

                <button onClick={handleAddNumber("7")}>7</button>
                <button onClick={handleAddNumber("8")}>8</button>
                <button onClick={handleAddNumber("9")}>9</button>
                <button onClick={handleChangeLot(`-${step * 50}`)}>
                    <div className={styles.volumeBlock}>-50</div>
                </button>

                <button style={{ pointerEvents: "none" }} />
                <button onClick={handleAddNumber("0")}>0</button>
                <button onClick={handleDot}>.</button>
                <button
                    disabled={
                        !isLotSizeValid ||
                        (isLotSizeValid && Number(displayValue) === 0) ||
                        isSubmitDisabled
                    }
                    onClick={handleSubmit(+displayValue)}>
                    {t("done")}
                </button>
            </div>
        </div>
    );
};

export default React.memo(TradeCalculatorMobile);
