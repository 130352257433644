import React from "react";
import NumberInputMobile from "@/components/Core/Form/NumberInputMobile/NumberInputMobile";

import styles from "./OrderVolumeBlock.module.scss";

interface IProps {
    onChange: (number) => void;
    errorMessage?: string;
    setErrorMessage?: (error: string, withClear?: boolean) => void;
    lotSize: number | string;
    minLotValue: number;
    maxLotValue: number;
    t: (key: string) => string;
}

const OrderVolumeBlock: React.FC<IProps> = ({
    onChange,
    lotSize,
    minLotValue,
    maxLotValue,
    errorMessage,
    setErrorMessage,
    t,
}) => {
    const handleError = (value?: "min" | "max", withClear?: boolean) => {
        if (!value && errorMessage) {
            setErrorMessage(null);
        } else {
            setErrorMessage(value, withClear);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>{t("amount")}</div>

            <NumberInputMobile
                value={lotSize}
                onChange={onChange}
                step={0.01}
                hasError={!!errorMessage}
                minValue={minLotValue || 0.01}
                maxValue={maxLotValue}
                handleError={handleError}
                allowDisplayOutOfRange={false}
            />

            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        </div>
    );
};

export default React.memo(OrderVolumeBlock);
