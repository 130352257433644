import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";
import { useDebounce } from "@/utils/hooks/useDebounce";
import { gaps } from "./languageDependentGaps";

import styles from "./FourthStep.module.scss";


interface IFourthStepProps {
    closeModal: () => void;
    children: JSX.Element;
}

export const FourthStep: React.FC<IFourthStepProps> = ({ closeModal, children }) => {
    const { t, i18n } = useTranslation("common");
    const { palette } = useTheme();

    const [leftPosition, setLeftPosition] = useState(0);
    const debounce = useDebounce();

    useEffect(() => {
        const el = window?.document.getElementById("trading_chart_section");
        const rect = el?.getBoundingClientRect();
        const position = rect ? rect?.width + 30 : 420;
        setLeftPosition(position);
    }, [debounce]);

    return (
        <div className={styles.container} style={{ right: leftPosition }}>
            <div onClick={closeModal} className={styles.close}>
                <div className={styles.closeIcon}></div>
            </div>
            <div
                className={styles.content}
                style={{ paddingTop: gaps[i18n.resolvedLanguage]?.paddingTop || null }}
            >
                <div
                    className={styles.title}
                    style={{ color: palette.mode === "dark" ? "#e6ecf1" : "#000" }}>
                    {t("guidance.step04.title")}
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: t("guidance.step04.content01") }}
                    className={styles.subtitle}
                />

                <div className={clsx(styles.subtitle, styles.mg_16)}>
                    {t("guidance.step04.content02")}
                </div>
            </div>

            <div className={styles.candles_diagram}>
                <div className={styles.top_title}>
                    {t("guidance.step04.high")}
                </div>
                <div className={styles.candles_container}>
                    <div className={styles.left_desc}>
                        <span>{t("close")}</span>
                        <span>{t("open")}</span>
                    </div>
                    <div className={styles.candles_image}>

                    </div>
                    <div className={styles.right_desc}>
                        <span>{t("open")}</span>
                        <span>{t("close")}</span>

                    </div>
                </div>
                <div className={styles.bottom_title}>
                    {t("guidance.step04.low")}
                </div>
            </div>
            <div style={{ marginTop: 28 }} className={styles.stepper_wrapper}>
                {children}
            </div>
        </div>
    );
};
