import React, { useEffect, useMemo, useState } from "react";

import { ITradeOrder } from "@/services/trade/order";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { OrderTableType } from "@/components/TradesTable";

enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

interface IUseTradeColumnSorter {
    sortedOrders: ITradeOrder[];
    sortColumn: string;
    sortDirection: SortDirection;
    setSortColumn: (column: string) => void;
}

const columnsMapping = {
    orderId: "ticket",
    direction: "type",
    volume: "lots",
    amount: "lots",
    closedPrice: "closePrice",
    createdTime: "openTime",
    asset: "symbol",
};

const utils = {
    isDate: (fieldName: string) => ["closeTime", "openTime"].includes(fieldName),
    isString: (fieldName: string) => ["type", "symbol"].includes(fieldName),
};

const executeSort = (
    orders: ITradeOrder[],
    fieldName: string,
    sortDirection: SortDirection
    //symbolsInfoById
) => {
    if (utils.isDate(fieldName)) {
        return [...orders].sort(
            sortDirection === SortDirection.ASC
                ? (a, b) => new Date(a[fieldName]).getTime() - new Date(b[fieldName]).getTime()
                : (a, b) => new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime()
        );
    }

    if (utils.isString(fieldName)) {
        const sortedItems = [...orders].sort((a, b) => {
            let firstItem = a[fieldName].toLowerCase();
            let secondItem = b[fieldName].toLowerCase();

            if (fieldName === "symbol") {
                const firstItemSymbolInfo = a.symbol;
                const secondItemSymbolInfo = b.symbol;

                if (firstItemSymbolInfo) {
                    firstItem = firstItemSymbolInfo;
                }

                if (secondItemSymbolInfo) {
                    secondItem = secondItemSymbolInfo;
                }
            }

            return firstItem.localeCompare(secondItem);
        });

        if (sortDirection === SortDirection.ASC) {
            return sortedItems;
        }

        return sortedItems.reverse();
    }

    const sorted = [...orders].sort(
        sortDirection === SortDirection.ASC
            ? (a, b) => a[fieldName] - b[fieldName]
            : (a, b) => b[fieldName] - a[fieldName]
    );

    return sorted;
};

export const useTradeColumnSorter = (
    orders: ITradeOrder[],
    tableType?: OrderTableType
): IUseTradeColumnSorter => {
    const [sortColumn, setSortColumn] = useState(
        tableType === OrderTableType.CLOSED ? "closeTime" : columnsMapping.createdTime
    );
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);
    //const { symbolsInfoById } = useTradeInfo();
    const sortedOrders = useMemo(() => {
        if (!sortColumn || !orders?.length) {
            return orders;
        }

        const fieldName = columnsMapping[sortColumn] || sortColumn;

        return executeSort(orders, fieldName, sortDirection);
    }, [orders, sortColumn, sortDirection]);

    return {
        sortedOrders: sortedOrders,
        sortColumn,
        sortDirection,
        setSortColumn: (header: string) => {
            if (header !== sortColumn) {
                setSortColumn(header);
                setSortDirection(SortDirection.ASC);
            } else {
                setSortDirection(
                    sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
                );
            }
        },
    };
};
