import React, { useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import { OrderTableType } from "@/components/TradesTable";
import { useTheme } from "@mui/material";
import { useRouter } from "next/router";

import styles from "./Notice.module.scss";


interface IProps {
    type: OrderTableType;
}

const NoOrdersNotice = ({ type }: IProps): JSX.Element => {
    const { palette } = useTheme();
    const { locale } = useRouter();
    const { t } = useTranslation("common");

    const getTranslatedTitle = useCallback((): string | JSX.Element => {
        if (locale === "en") {
            const [firstLine, secondLine] =
                t(`no_${type}_trades`, { separator: "<br>" }).split("<br>");
            return (
                <>
                    {firstLine}
                    <br />
                    {secondLine}
                </>
            );
        }
        return t(`no_${type}_trades`, { separator: "" });
    }, [locale, type]);

    return (
        <div className={clsx(styles.notice)}>
            <div className={styles.notice__iconContainer}>
                <div
                    className={clsx(null, {
                        [styles.iconMarket]: palette.mode === 'dark',
                        [styles.iconMarket_light]: palette.mode === 'light',
                    })}
                />
            </div>
            <div className={styles.title}>
                {getTranslatedTitle()}
            </div>
        </div>
    );
};

export default React.memo(NoOrdersNotice);
