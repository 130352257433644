import React from "react";
import clsx from "clsx";

import { useUI } from "@/redux/selectors/uiSelector";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import TradesSectionMobile from "@/components/Home/TradesSectionMobile/TradesSectionMobile";
import { LoadingScreen } from "@/components/LoadingScreen";
import SystemFeedbackMobileWrapper from "@/components/SystemFeedbackMobile/SystemFeedbackMobileWrapper";
import TradingAssetsSectionMobile from "@/components/Home/TradingAssetsSection/mobile/TradingAssetsSectionMobile";
import TradingAssetSectionMobile from "../TradingAssetSectionMobile";
import WalletSectionMobile from "@/components/Home/WalletSectionMobile/WalletSectionMobile";
import { TradeOrderDispatchContext, TradeOrderStateContext, useTradeOrder } from "@/contexts/TradeOrder";
import { AnimationClasses, useAnimation } from "@/hooks/common/useAnimation";
import useRedirectToAssetFromURL from "@/hooks/common/useRedirectToAssetFromURL";

import styles from "./HomeMobile.module.scss";


export const HomeMobile: React.FC = () => {
    const { isAssetsTabActive, isTradesTabActive, isSingleAssetViewActive, isWalletTabActive } = useUI();
    const { isQuoteDataLoaded, isAccountSocketDataLoaded, activeSymbolId } = useTradeInfo();
    const [tradeOrder, tradeOrderDispatch] = useTradeOrder({ activeSymbolId });
    const { animationParams } = useAnimation();
    useRedirectToAssetFromURL(activeSymbolId);

    return (
        <SystemFeedbackMobileWrapper>
            <div className={clsx(null, { [styles.container_mbl]: isSingleAssetViewActive })}>
                {isQuoteDataLoaded && isAccountSocketDataLoaded ? (
                    <>
                        {isAssetsTabActive &&
                            <div className={clsx(null, {
                                [animationParams?.class]: animationParams?.class === AnimationClasses.fadeInLeft,
                            })}>
                                <TradingAssetsSectionMobile />
                            </div>
                        }
                        {isSingleAssetViewActive && (
                            <TradeOrderDispatchContext.Provider value={tradeOrderDispatch}>
                                <TradeOrderStateContext.Provider value={tradeOrder}>
                                    <TradingAssetSectionMobile />
                                </TradeOrderStateContext.Provider>
                            </TradeOrderDispatchContext.Provider>
                        )}
                        {isTradesTabActive && <TradesSectionMobile />}
                        {isWalletTabActive && <WalletSectionMobile />}
                    </>
                ) : (
                    <LoadingScreen withTitle />
                )}
            </div>
        </SystemFeedbackMobileWrapper>
    );
};
