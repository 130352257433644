import React from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "next-i18next";

import { OrderTableType } from "@/components/TradesTable";
import { formatAmount, normalyzeDecimalLength } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { QuoteData, useQuoteData } from "@/hooks/trade/tradeSocket";
import { getCurrentPrice, getFormattedPrice } from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { getDistanceValue, getMarketPriceValue, getTradeProfit } from "@/utils/trade";
import { ITradeOrder } from "@/services/trade/order";
import { getDirectionsTranslationKey } from "@/utils/helpers";

import styles from "./TradeInfoGrid.module.scss";
import { isBuyTrade } from '@/components/TradesTable/components/TableItem';

interface ITradeDataMap {
    tradeData: ITradeOrder;
    tableType: OrderTableType;
    extraParams: {
        accountCurrency: string;
        marketPrice: string;
        distance: number;
        digits: number;
    };
}

const getTradeDataMap = ({ tradeData, tableType, extraParams }: ITradeDataMap) => {
    const { accountCurrency, marketPrice, distance, digits } = extraParams;

    let main = [];
    let additional = [];

    const openPrice = {
        labelKey: "openPrice",
        value: normalyzeDecimalLength(formatAmount(tradeData.openPrice, "", digits), digits) || "-",
    };
    const currentPrice = {
        labelKey: "closePrice",
        value: normalyzeDecimalLength(formatAmount(Number(marketPrice), "", digits), digits) || "-",
    };
    const closePrice = {
        labelKey: "closedPrice",
        value: normalyzeDecimalLength(formatAmount(tradeData.closePrice, "", digits), digits) || "-",
    };
    const volume = { labelKey: "amount", value: tradeData.lots };
    const stopLoss = {
        labelKey: "stopLoss",
        value: normalyzeDecimalLength(formatAmount(tradeData.stopLoss, "", digits), digits) || "-",
    };

    const takeProfit = {
        labelKey: "takeProfit",
        value: normalyzeDecimalLength(formatAmount(tradeData.takeProfit, "", digits), digits) || "-",
    };

    const swap = {
        labelKey: "swap",
        value: tradeData.swap || "-",
    };

    const dist = {
        labelKey: "distance",
        value: distance || "-",
    };

    const profitLoss = {
        labelKey: "totalPL",
        value: formatAmount(tradeData.profit, accountCurrency),
    };
    const tradeType = {
        labelKey: "type",
        value: tradeData.type || "-",
    };
    const openDate = {
        labelKey: "openDate",
        value: moment(tradeData.openTime).format("DD.MM.YYYY"),
    };
    const openTime = {
        labelKey: "openTime",
        value: moment(tradeData.openTime).format("HH:mm:ss"),
    };
    const createdDate = {
        labelKey: "createdDate",
        value: moment(tradeData.openTime).format("DD.MM.YYYY"),
    };
    const createdTime = {
        labelKey: "createdTime",
        value: moment(tradeData.openTime).format("HH:mm:ss"),
    };
    const closeDate = {
        labelKey: "closeDate",
        value: moment(tradeData.closeTime).format("DD.MM.YYYY"),
    };
    const closeTime = {
        labelKey: "closeTime",
        value: moment(tradeData.closeTime).format("HH:mm:ss"),
    };

    const empty = {
        labelKey: "",
        value: "",
    };

    if (tableType === OrderTableType.OPEN_TRADES) {
        main = [profitLoss, openPrice, currentPrice, volume];
        additional = [stopLoss, takeProfit, openDate, openTime, swap, empty, empty, empty];
    } else if (tableType === OrderTableType.PENDING_ORDERS) {
        main = [openPrice, currentPrice, tradeType, volume];
        additional = [dist, stopLoss, takeProfit, createdDate, createdTime, empty, empty, empty];
    } else if (tableType === OrderTableType.CLOSED) {
        main = [profitLoss, openPrice, closePrice, volume];
        additional = [stopLoss, takeProfit, openDate, openTime, closeDate, closeTime, swap, empty];
    }

    return {
        main,
        additional,
    };
};

const OpenTradeInfoGridMain = ({ order, tradeDataMap, type, t }) => {
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById, symbols } = useTradeInfo();
    const symbolInfo = symbolsInfoById[order.symbol];
    const { ask, bid } = useQuoteData(order.symbol);

    let calcProfit;

    if (type !== OrderTableType.PENDING_ORDERS) {
        calcProfit = getTradeProfit({
            openPrice: order.openPrice,
            ask,
            bid,
            tradeType: isBuyTrade(order) ? "Buy" : "Sell",
            lotSize: order.lots,
            accountCurrency,
            symbolInfo,
            symbols,
        });
    }

    let profit = type === OrderTableType.PENDING_ORDERS ? order.profit : calcProfit;
    if (!profit) {
        profit = order.ex.profit;
    }


    const colorIndicatorClass = profit >= 0 ? styles.profitColor : styles.lossColor;

    return (
        <>
            {tradeDataMap.main.map((item: any) => {
                return (
                    <div key={item.labelKey} className={clsx(styles.infoBlock)}>
                        <div>{t(item.labelKey)}</div>
                        <div
                            className={clsx(styles.infoBlock__value, {
                                [colorIndicatorClass]: ["totalPL", "type"].includes(item.labelKey),
                            })}
                        >
                            {item.labelKey === "closePrice" && (
                                <QuoteData
                                    symbolId={order.symbol}
                                    priceChangeTTL={450}
                                    render={({ ask, bid }) => {
                                        return (
                                            <span>
                                                {getCurrentPrice(
                                                    order,
                                                    symbolInfo,
                                                    ask,
                                                    bid,
                                                    type === 'pending',
                                                )}
                                            </span>
                                        );
                                    }}
                                />
                            )}

                            {item.labelKey === "totalPL" && (
                                <>{formatAmount(profit, accountCurrency)}</>
                            )}

                            {item.labelKey !== "closePrice" && item.labelKey !== "totalPL" && (
                                <>{item.value}</>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

interface IProps {
    order: ITradeOrder;
    type: OrderTableType;
    isShowFullInfo: boolean;
}

const TradeInfoGrid = ({ order, type, isShowFullInfo }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById } = useTradeInfo();
    const { ask, bid } = useQuoteData(order.symbol);

    const symbolInfo = symbolsInfoById[order.symbol];
    const marketPrice = getMarketPriceValue({ order, ask, bid, type });
    const currentPrice = getFormattedPrice(marketPrice, symbolInfo?.point);


    const distance = getDistanceValue({
        marketPrice,
        order,
        point: symbolsInfoById[order.symbol]?.point,
    });

    const tradeDataMap = getTradeDataMap({
        tradeData: order,
        tableType: type,
        extraParams: {
            accountCurrency,
            marketPrice: currentPrice,
            distance,
            digits: symbolsInfoById[order.symbol]?.digits,
        },
    });

    const colorIndicatorClass = order.profit >= 0 ? styles.profitColor : styles.lossColor;

    return (
        <div className={clsx(styles.container)}>
            {type === OrderTableType.OPEN_TRADES ? (
                <OpenTradeInfoGridMain order={order} tradeDataMap={tradeDataMap} type={type} t={t} />
            ) : (
                <>
                    {tradeDataMap.main.map((item: any) => {
                        return (
                            <div key={item.labelKey} className={clsx(styles.infoBlock)}>
                                <div>{t(item.labelKey)}</div>
                                <div
                                    className={clsx(styles.infoBlock__value, {
                                        [colorIndicatorClass]: ["totalPL", "type"].includes(item.labelKey),
                                    })}>
                                    {item.labelKey === "closePrice" && (
                                        <QuoteData
                                            symbolId={order.symbol}
                                            priceChangeTTL={450}
                                            render={({ ask, bid }) => {
                                                return (
                                                    <span>
                                                        {getCurrentPrice(
                                                            order,
                                                            symbolInfo,
                                                            ask,
                                                            bid,
                                                            type === 'pending',
                                                        )}
                                                    </span>
                                                );
                                            }}
                                        />
                                    )}

                                    {item.labelKey === "type" && (
                                        <>{t(getDirectionsTranslationKey(item.value))}</>
                                    )}

                                    {!["closePrice", "type"].includes(item.labelKey) &&
                                        <>{item.value}</>}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}

            {tradeDataMap.additional.map((item: any, index: number) => {
                return (
                    <div key={`${index}_${item.labelKey}`} className={clsx(styles.infoBlock, {
                        [styles.infoBlock_hidden]: !isShowFullInfo,
                    })}>
                        <div>{t(item.labelKey)}</div>
                        <div className={clsx(null, {
                            [colorIndicatorClass]: ["totalPL", "type"].includes(item.labelKey)
                        })}>
                            {item.value}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(TradeInfoGrid);
