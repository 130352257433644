import React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import styles from "./SecondStep.module.scss";

const chips = [
    {
        title: "guidance.step02.cryptocurrencies",
        img: "/images/intro/mode/bitcoin.svg",
    },
    {
        title: "guidance.step02.commodities",
        img: "/images/intro/mode/commodities.svg",
    },
    {
        title: "guidance.step02.forex",
        img: "/images/intro/mode/forex.svg",
    },
    {
        title: "guidance.step02.indices",
        img: "/images/intro/mode/indices.svg",
    },
    {
        title: "guidance.step02.stocks",
        img: "/images/intro/mode/stocks.svg",
    },
    {
        title: "guidance.step02.metal",
        img: "/images/intro/mode/precious_metals.svg",
    },
    {
        title: "guidance.step02.energies",
        img: "/images/intro/mode/energies.svg",
    },
];

interface IProps {
    closeModal: () => void;
    children: JSX.Element;
}

export const SecondStep: React.FC<IProps> = ({ closeModal, children }) => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    return (
        <div className={styles.container}>
            <div onClick={closeModal} className={styles.closeIcon}></div>
            <div className={styles.title}>{t("guidance.step02.title")}</div>
            <div className={styles.subtitle}>{t("guidance.step02.content")}</div>
            <div className={styles.chips_container}>
                {chips.map(chip => {
                    const imgUrl = chip.img.replace("mode", theme.palette.mode);
                    return (
                        <div className={styles.chips} key={chip.title}>
                            <img src={imgUrl} />
                            {t(chip.title)}
                        </div>
                    );
                })}
            </div>
            {children}
        </div>
    );
};
