export enum AssetPageTabs {
    SET_LOT = "SET_LOT",
    SET_CALCULATOR = "SET_CALCULATOR",
    SET_SENTIMENTS = "SET_SENTIMENTS",
    SET_LOSS_PROFIT = "SET_LOSS_PROFIT",
    SET_INFO = "SET_INFO",
    SET_TIME_FRAMES = "SET_TIME_FRAMES",
    CANDLESTICKS = "CANDLESTICKS",
    PENDING_ORDER = "PENDING_ORDER",
    ACCOUNT_INFO = "ACCOUNT_INFO",
};

export const noAnimationsTabs = [
    AssetPageTabs.PENDING_ORDER,
    AssetPageTabs.SET_SENTIMENTS,
    AssetPageTabs.SET_TIME_FRAMES,
    AssetPageTabs.CANDLESTICKS,
];
