import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MessageItem } from "./messageItem";
import { useDispatch } from "react-redux";
import Search from "@/components/Search";
import { toggleNotificationsPopup } from "@/redux/actions/ui";
import { useUI } from "@/redux/selectors/uiSelector";

import { listenForOutsideClicks } from "@/utils/helpers";
import { assetListScrollToCoordinate } from "@/redux/actions/ui";
import { NoResultsTradeAlerts } from "./noResults";
import { useTheme } from "@mui/material";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { LockedView } from "./lockedView";
import { Skeleton } from "./skeleton";

export const NotificationsPopup = () => {
    const dispatch = useDispatch();
    const {
        palette: { mode },
    } = useTheme();
    //const { symbolsInfoById, symbolsInfo } = useTradeInfo();
    const { isNotificationsPopupVisible, assetToScrollOn, isTradeAlertsLoading: isLoading } = useUI();
    const { tradeAlerts, isGuest, isLazyMode } = useAccountInfo();
    const [filteredTradeAlerts, setFilteredTradeAlerts] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    //const [expandedAlert, setExpandedAlert] = useState(null);
    const [listening, setIsListening] = useState(false);
    const menuRef = useRef();
    const elementId = "notification";
    const closeButtonId = "close_notification";

    const onChangeSearch = e => {
        setSearchValue(e);
        dispatch(assetListScrollToCoordinate(null));
        if (e) {
            const filtered = tradeAlerts.filter(ta =>
                ta.assetName.toLowerCase().includes(e.toLowerCase())
            );
            setFilteredTradeAlerts(filtered);
        } else {
            setFilteredTradeAlerts(tradeAlerts);
        }
    };

    useEffect(() => {
        if (tradeAlerts && tradeAlerts.length) {
            setFilteredTradeAlerts(tradeAlerts);
        }
    }, [tradeAlerts]);

    // Need this to always have latest state of tradeAlerts
    useEffect(() => {
        if (!isNotificationsPopupVisible) {
            setSearchValue("");
            setFilteredTradeAlerts(tradeAlerts);
        }
    }, [isNotificationsPopupVisible]);

    const handleToggleNotifications = value => {
        dispatch(toggleNotificationsPopup(value));
        if (!value) {
            dispatch(assetListScrollToCoordinate(null));
        }
    };

    useEffect(
        listenForOutsideClicks(
            listening,
            setIsListening,
            menuRef,
            handleToggleNotifications,
            elementId,
            closeButtonId
        )
    );

    return (
        <div ref={menuRef}>
            {isNotificationsPopupVisible && (
                <>
                    {isGuest && !isLazyMode ? (
                        <LockedView />
                    ) : (
                        <div
                            className={clsx(styles.popup_wrapper, styles.scrollBar_override, {
                                [styles.fadeOut]: mode !== "light" && assetToScrollOn,
                                [styles.fadeOut_light]: mode === "light" && assetToScrollOn,
                            })}>
                            {!isLoading && (
                                <div className={styles.popup_header}>
                                    {tradeAlerts?.length > 0 && (
                                        <div id="close_notification" className={styles.close_icon}></div>
                                    )}

                                    {tradeAlerts?.length ? (
                                        <Search
                                            value={searchValue}
                                            onChange={onChangeSearch}
                                            manuallyDefinedTheme={"light"}
                                            popupId="notification"
                                        />
                                    ) : (
                                        <div style={{ height: 34 }}></div>
                                    )}
                                </div>
                            )}
                            {!isLoading && tradeAlerts?.length === 0 && (
                                <NoResultsTradeAlerts type={"empty"} />
                            )}
                            {!isLoading && searchValue && !filteredTradeAlerts?.length && (
                                <NoResultsTradeAlerts type={"search"} />
                            )}
                            {isLoading && <Skeleton />}
                            {!isLoading && tradeAlerts?.length > 0 && filteredTradeAlerts?.length > 0 && (
                                <PerfectScrollbar>
                                    {filteredTradeAlerts?.map(
                                        ({
                                            id,
                                            text,
                                            date,
                                            category,
                                            displayName,
                                            assetName,
                                            digits = 1,
                                        }) => {
                                            return (
                                                <MessageItem
                                                    key={id}
                                                    symbol={assetName || displayName}
                                                    category={category}
                                                    date={date}
                                                    message={text}
                                                    digits={digits}
                                                />
                                            );
                                        }
                                    )}
                                </PerfectScrollbar>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
