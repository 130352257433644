import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";
import { toggleMobileAccountInfo, toggleMobilePendingOrders } from "@/redux/actions/ui";
import { formatAmount } from "@/utils/format";
import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import { getBrandIconName } from "@/utils/helpers";
import { isEURegulation } from "@/utils/trade";
import { useAccountName } from "@/hooks/trade";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { AnimationClasses } from "@/hooks/common/useAnimation";

import { useThemeSwitcher } from "@/components/Footer/components/ThemeSwitcher";

import styles from "./AccountInfo.module.scss";

interface IHeader {
    accountMisc?: string;
    onClose?: () => void;
}

export const Header: React.FC<IHeader> = ({ accountMisc = "CFD/FX", onClose }) => {
    const { accountId, tradingPlatformServerType } = useAccountInfo();
    const { isLoggedInUser } = useLoginInfo();
    const accountIdDisplayValue = !isLoggedInUser || !accountId ? "---" : accountId;

    return (
        <div className={styles.header}>
            <div className={styles.accountInfoWrp}>
                <div className={styles.accountIcon} />
                <div className={styles.accountInfoData}>
                    <div className={styles.accountInfoId}>{accountIdDisplayValue}</div>
                    <div
                        className={
                            styles.accountInfoType
                        }>{`${accountMisc} | ${tradingPlatformServerType}`}</div>
                </div>
            </div>
            {onClose && (
                <div className={styles.closeIconWrp}>
                    <div className={styles.closeIcon} onClick={onClose} />
                </div>
            )}
        </div>
    );
};

interface IBalance {
    currency: string;
    value: number;
}

export const Balance: React.FC<IBalance> = ({ currency, value }) => {
    const { t } = useTranslation("common");
    const { isLoggedInUser } = useLoginInfo();
    const { platform } = useDevice();
    const { palette } = useTheme();
    // only for development
    const switchColorTheme = useThemeSwitcher();

    return (
        <div
            className={clsx(styles.balanceWrp, {
                [styles.tablet]: platform === DevicePlatform.Tablet,
            })}>
            <div
                className={clsx(styles.balanceIcon, {
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}
                style={{ backgroundImage: `url(/images/brand-logos/${getBrandIconName(palette.mode)})` }}
                onClick={switchColorTheme}
            />
            <div className={styles.balanceTitle}>{t("your_balance")}</div>
            <div className={styles.balanceAmount}>
                {isLoggedInUser ? formatAmount(value, currency) : "---"}
            </div>
        </div>
    );
};

interface ITradeInfo {
    profit: number;
    credit: number;
    freeMargin: number;
    equity: number;
    marginLevel: number;
    margin: number;
    accountCurrency: string;
    className?: string;
}

export const TradeInfo: React.FC<ITradeInfo> = ({
    profit,
    credit = 0,
    freeMargin,
    equity,
    marginLevel,
    margin,
    accountCurrency,
    className = "",
}) => {
    const { t } = useTranslation("common");
    const EMPTY = "---";

    const { isLoggedInUser } = useLoginInfo();
    const isEU = useMemo(() => isEURegulation(), []);
    const { platform } = useDevice();

    let marginLevelDisplayValue = EMPTY;
    let totalPLDisplayValue = EMPTY;
    let freeMarginDisplayValue = EMPTY;
    let usedFundsDisplayValue = EMPTY;
    let equityDisplayValue = EMPTY;
    let creditDisplayValue = EMPTY;

    if (isLoggedInUser) {
        totalPLDisplayValue = formatAmount(profit, accountCurrency);
        marginLevelDisplayValue = margin > 0 ? `${marginLevel.toFixed(2)}%` : EMPTY;
        freeMarginDisplayValue = formatAmount(freeMargin, accountCurrency);
        usedFundsDisplayValue = formatAmount(margin, accountCurrency);
        equityDisplayValue = formatAmount(equity, accountCurrency);
        creditDisplayValue = formatAmount(credit, accountCurrency);
    }

    return (
        <div
            className={clsx(styles.tradeInfoWrp, className, {
                [styles.tablet]: platform === DevicePlatform.Tablet,
            })}>
            <div
                className={clsx(styles.tradeInfoTableColumn, {
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}>
                <div className={styles.tradeInfoTableCell}>
                    <span className={styles.tradeInfoTableCell_title}>{t("totalPL")}</span>
                    <span className={styles.tradeInfoTableCell_subtitle}>
                        {totalPLDisplayValue ? totalPLDisplayValue : EMPTY}
                    </span>
                </div>
                <div className={styles.tradeInfoTableCell}>
                    <span className={styles.tradeInfoTableCell_title}>{t("freeMargin")}</span>
                    <span className={styles.tradeInfoTableCell_subtitle}>{freeMarginDisplayValue}</span>
                </div>
                <div className={styles.tradeInfoTableCell}>
                    <span className={styles.tradeInfoTableCell_title}>{t("marginLevel")}</span>
                    <span className={styles.tradeInfoTableCell_subtitle}>{marginLevelDisplayValue}</span>
                </div>
            </div>
            <div
                className={clsx(styles.tradeInfoTableColumn, {
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}>
                <div className={styles.tradeInfoTableCell}>
                    <span className={styles.tradeInfoTableCell_title}>{t("used_funds")}</span>
                    <span className={styles.tradeInfoTableCell_subtitle}>{usedFundsDisplayValue}</span>
                </div>
                <div className={styles.tradeInfoTableCell}>
                    <span className={styles.tradeInfoTableCell_title}>{t("equity")}</span>
                    <span className={styles.tradeInfoTableCell_subtitle}>{equityDisplayValue}</span>
                </div>
                {!isEU || credit > 0 ? (
                    <div className={styles.tradeInfoTableCell}>
                        <span className={styles.tradeInfoTableCell_title}>{t("credit")}</span>
                        <span className={styles.tradeInfoTableCell_subtitle}>{creditDisplayValue}</span>
                    </div>
                ) : (
                    <div className={styles.tradeInfoTableCell}>
                        <span
                            className={styles.tradeInfoTableCell_title}
                            style={{ color: "transparent" }}>
                            empty
                        </span>
                        <span className={styles.tradeInfoTableCell_subtitle} />
                    </div>
                )}
            </div>
        </div>
    );
};

interface IAccountInfoButtonActions {
    isAccountInfoButtonExpanded: boolean;
    openAccountInfo: () => void;
    toggleAccountInfoButton: (value?: boolean) => void;
}

export const useAccountInfoButtonActions = (): IAccountInfoButtonActions => {
    const dispatch = useDispatch();
    const [isAccountInfoButtonExpanded, setIsAccountInfoButtonExpanded] = useState(false);

    function expandAccountInfo() {
        setIsAccountInfoButtonExpanded(true);
        dispatch(toggleMobilePendingOrders(false));
    }

    function collapseAccountInfo() {
        setIsAccountInfoButtonExpanded(false);
        dispatch(toggleMobileAccountInfo(false));
    }

    function toggleAccountInfoButton(value?: boolean) {
        if (value === undefined) {
            return isAccountInfoButtonExpanded ? collapseAccountInfo() : expandAccountInfo();
        } else if (value) {
            expandAccountInfo();
        } else {
            collapseAccountInfo();
        }
    }

    function openAccountInfo() {
        dispatch(toggleMobileAccountInfo(true));
    }

    return {
        isAccountInfoButtonExpanded,
        toggleAccountInfoButton,
        openAccountInfo,
    };
};

interface IAccountInfoButton {
    openDrawer?: () => void;
    accountMisc?: string;
    isExpanded?: boolean;
    toggleButton: () => void;
}

export const AccountInfoButton: React.FC<IAccountInfoButton> = ({
    openDrawer = () => {},
    accountMisc = "CFD/FX",
    isExpanded = false,
    toggleButton,
}) => {
    const { palette } = useTheme();
    const { isLoggedInUser } = useLoginInfo();
    const { tradingPlatformServerType } = useAccountInfo();
    const accountIdValue = useAccountName();
    const accountIdDisplayValue = !isLoggedInUser || !accountIdValue ? "---" : accountIdValue;

    return (
        <div
            onClick={() => !isExpanded && toggleButton()}
            className={clsx(styles.tradePanelItem, styles.svgIcon, {
                [styles[`tradePanelItem__expanded__${palette.mode}`]]: isExpanded,
                [styles[`svgIcon_blackIcon`]]: isExpanded && palette.mode === "light",
            })}
            style={
                accountIdValue.length > 9 && isExpanded
                    ? { minWidth: `${180 + (accountIdValue.length - 9) * 10}px` }
                    : null
            }>
            {isExpanded && (
                <>
                    <div onClick={toggleButton} className={styles.accountInfoButtonWrp}>
                        <span className={styles.accountInfoTitle}>{accountIdDisplayValue}</span>
                        <span className={styles.accountInfoSubtitle}>
                            {`${accountMisc} | ${tradingPlatformServerType}`}
                        </span>
                    </div>

                    <div onClick={openDrawer} className={styles.tradePanelItem_dots}></div>
                </>
            )}
        </div>
    );
};

export const AccountInfo: React.FC = () => {
    const { platform } = useDevice();
    const { currency: accountCurrency } = useAccountInfo();
    const {
        balance = 0,
        equity = 0,
        margin = 0,
        freeMargin = 0,
        profit = 0,
        credit = 0,
    } = useAccountDynamicInfo();
    const marginLevel = (equity / margin) * 100;
    const dispatch = useDispatch();
    const closeDrawer = () => dispatch(toggleMobileAccountInfo(false));

    return (
        <div
            className={clsx(styles.drawerWrp, AnimationClasses.fadeInUp)}
            style={{ animationDuration: "0.3s", zIndex: 1601 }}>
            <Header onClose={closeDrawer} />
            <div
                className={clsx(styles.infoContainer, {
                    [styles.tablet]: platform === DevicePlatform.Tablet,
                })}>
                <Balance currency={accountCurrency} value={balance} />
                <TradeInfo
                    profit={profit}
                    credit={credit}
                    freeMargin={freeMargin}
                    margin={margin}
                    equity={equity}
                    marginLevel={marginLevel}
                    accountCurrency={accountCurrency}
                />
            </div>
        </div>
    );
};
