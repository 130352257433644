import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { getTradeHistory, getTradeHistoryPDF } from "@/services/trade/order";
import { tableResponseParser } from "@/components/TradeHistory/helpers";
import { OrderTableType } from "@/components/TradesTable";
import parentFrameAPI from "@/services/parent";
import { getUserTimezoneOffsetInHours } from "@/utils/dateTime";


const last_3_months = moment().subtract(3, "months").toISOString();

const today = moment().toISOString();

export const updatePDFLink = async (dateFrom, dateTo) => {

    console.log("navigator", navigator);


    if (
        (window as any)?.ReactNativeWebView ||
        navigator.platform.toLowerCase().includes("mac") ||
        navigator.platform.toLowerCase().includes("iphone")
    ) {
        parentFrameAPI.runDownloadPDF({ dateFrom, dateTo });
    }

    const pdfBinaryData = await getTradeHistoryPDF({
        dateFrom,
        dateTo,
    });

    const getNiceDate = (date: string): string => date.replace(/\.000Z$/, "");

    try {
        // @ts-ignore
        const pdfBlob = new Blob([pdfBinaryData], { type: "application/pdf" });

        const ref = document.createElement("a");
        ref.href = window.URL.createObjectURL(pdfBlob);
        ref.download = `trade-history_${getNiceDate(dateFrom)}_-_${getNiceDate(dateTo)}.pdf`;
        ref.dispatchEvent(new MouseEvent("click"));
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};

export const useTradeHistory = (orderType?: OrderTableType) => {
    const [isLoading, setIsLoading] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [dateFrom, setDateFrom] = useState(last_3_months);
    const [dateTo, setDateTo] = useState(today);
    const maxDate = useRef(new Date());

    const formatDateTillTheEnd = date => {
        if (!date) {
            return moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS");
        }

        const currentDate = moment().format("YYYY MM DD");

        if (date) {
            const temp = moment(date).format("YYYY MM DD");
            const formatted = moment(`${temp} 23 59 59`, "YYYY MM DD HH mm ss").format(
                "YYYY-MM-DD[T]HH:mm:ss.SSS"
            );

            if (formatted === "Invalid date") {
                return moment(`${currentDate} 23 59 59`, "YYYY MM DD HH mm ss").format(
                    "YYYY-MM-DD[T]HH:mm:ss.SSS"
                );
            }
            return formatted;
        }
        return currentDate;
    };

    const getData = async () => {
        setIsLoading(true);
        const historyTableData = await getTradeHistory({
            dateFrom,
            dateTo: formatDateTillTheEnd(dateTo),
        });
        setHistoryData(tableResponseParser(historyTableData));
        setIsLoading(false);
    };

    useEffect(() => {
        if (!orderType || orderType === OrderTableType.CLOSED) {
            getData();
        }
    }, [orderType]);

    const handleSetDateFilters = useCallback(async dates => {
        const newDateFrom = moment(dates.dateFrom).format("YYYY-MM-DD[T]HH:mm:ss.SSS");
        const newDateTo = formatDateTillTheEnd(dates.dateTo);

        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setIsLoading(true);

        const historyTableData = await getTradeHistory({
            dateFrom: newDateFrom,
            dateTo: newDateTo,
        });

        setIsLoading(false);
        setHistoryData(tableResponseParser(historyTableData));
    }, []);

    return {
        historyData,
        handleSetDateFilters,
        dateFrom,
        dateTo,
        isLoading,
        maxDate: maxDate.current,
    };
};
