import React from 'react';

// use it before image declaration to avoid blinking on hover
const ImagePreloader = ({ imagePaths }: { imagePaths: string[] }) => {
    return (
        <>
            {imagePaths.map((path, index) => (
                <img
                    key={index}
                    src={path}
                    style={{ display: 'none' }}
                />
            ))}
        </>
    );
};

export default ImagePreloader;
