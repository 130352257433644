export enum KeyboardKeys {
    Escape = "Escape",
    Enter = "Enter",
    ArrowLeft = "ArrowLeft",
    ArrowRight = "ArrowRight",
}

export const isMatchKeyboardEvent = (e: KeyboardEvent, identifier: KeyboardKeys | number): boolean => {
    if (e.key === identifier || e.code === identifier) {
        return true;
    }

    return false;
};
