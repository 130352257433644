import React, { useCallback, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import SearchInput from "@/components/Core/SearchInputMobile/SearchInputMobile";
import TradesList from "./components/TradesList";
import { OrderTableType } from "@/components/TradesTable";
import NoOrdersNotice from "@/components/Home/TradesSectionMobile/components/NoOrdersNotice";
import NoSearchNotice from "@/components/Home/TradesSectionMobile/components/NoSearchNotice";
import TradesListTabs from "@/components/Home/TradesSectionMobile/components/TradesListTabs";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { DateRangePickerMobile } from "@/components/Core/DateRangePickerMobile";
import { PeriodTypes } from "@/components/Core/DateRangePickerMobile/helpers";
import { closeTradeRequest, deleteOrderRequest, modifyOrderRequest } from "@/redux/actions/orders";
import { useTradeHistory, updatePDFLink } from "@/hooks/trade/useTradeHistory";
import { setOperationTimestamp } from "@/utils/helpers";
import { setActiveSymbol } from "@/redux/actions/tradeInfo";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { ITradeOrder } from "@/services/trade/order";
import { Loader } from "@/components/TradeHistory/Loader";

import styles from "./TradesSectionMobile.module.scss";
import { MobileTotalPl } from "@/components/MobileTotalPL";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { formatAmount } from "@/utils/format";

const TradesSectionMobile: React.FC = () => {
    const dispatch = useDispatch();
    const tradeListRef = useRef<HTMLDivElement>();

    const [activeTab, setActiveTab] = useState(OrderTableType.OPEN_TRADES);
    const [searchText, setSearchText] = useState("");
    const [rangeType, setRangeType] = useState(PeriodTypes.LAST_3_MONTHS);

    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById } = useTradeInfo();
    const { pendingOrders, openTrades } = useTradeOrders();
    const { historyData, dateFrom, dateTo, isLoading, handleSetDateFilters, maxDate } = useTradeHistory(
        activeTab
    );

    const isOpenTrades = activeTab === OrderTableType.OPEN_TRADES;
    const isPendingTrades = activeTab === OrderTableType.PENDING_ORDERS;
    const isClosedTrades = activeTab === OrderTableType.CLOSED;

    const handleSetRange = useCallback(
        ({ from, to }) => handleSetDateFilters({ dateFrom: from, dateTo: to }),
        []
    );

    const handleChangeActiveTab = useCallback(newTab => {
        if (newTab !== OrderTableType.CLOSED) {
            setRangeType(PeriodTypes.LAST_3_MONTHS);
        }

        setActiveTab(newTab);
        setSearchText("");
    }, []);

    const handleEditOrder = useCallback(order => {
        dispatch(setActiveSymbol(order.symbol, symbolsInfoById[order.symbol].point));
        setOperationTimestamp();
        dispatch(modifyOrderRequest(order));
    }, []);

    const handleOrderClose = order => dispatch(closeTradeRequest(order));

    const handleOrderDelete = order => dispatch(deleteOrderRequest(order));

    const handleDownloadPDF = () => updatePDFLink(dateFrom, dateTo);

    const tradesData = useMemo(() => {
        let ordersToDisplay: ITradeOrder[];

        if (isOpenTrades) {
            ordersToDisplay = openTrades;
        } else if (isPendingTrades) {
            ordersToDisplay = pendingOrders;
        } else if (isClosedTrades) {
            ordersToDisplay = historyData;
        }

        if (searchText) {
            return ordersToDisplay.filter(({ symbol }) =>
                symbol.toUpperCase().includes(searchText.toUpperCase())
            );
        }

        return ordersToDisplay;
    }, [activeTab, openTrades, pendingOrders, searchText, historyData]);

    const isNoOrders = tradesData.length === 0 && !searchText;
    const isNoSearchResults = tradesData.length === 0 && searchText;
    const profitLossDisplayValue = useMemo(
        () =>
            tradesData.reduce((acc, order) => {
                if (["Balance", "Credit"].includes(order.type)) {
                    return acc;
                }

                return acc + order.profit + order.swap;
            }, 0),
        [historyData]
    );

    return (
        <div
            className={clsx(styles.container, {
                [styles.containerOpenTrades]: isOpenTrades,
                [styles.containerPendingOrders]: isPendingTrades,
                [styles.containerClosedTrades]: isClosedTrades,
                [styles.isNoOrders]: isNoOrders,
                [styles.isCustomDateRange]: isClosedTrades && rangeType === PeriodTypes.DATE_RANGE,
            })}>
            <div className={clsx(styles.toolbar)}>
                <TradesListTabs activeTab={activeTab} onChangeTab={handleChangeActiveTab} />

                {isClosedTrades && (
                    <div className={styles.dateRangeWrap}>
                        <DateRangePickerMobile
                            from={new Date(dateFrom)}
                            to={new Date(dateTo)}
                            maxDate={maxDate}
                            onSubmit={handleSetRange}
                            onRangeType={setRangeType}
                        />
                    </div>
                )}
                {!isNoOrders && (
                    <div
                        className={clsx(styles.toolbar__searchWrapper, {
                            [styles.withButtons]: isClosedTrades,
                        })}>
                        <SearchInput onChange={setSearchText} searchText={searchText} />

                        {isClosedTrades && (
                            <div
                                onClick={handleDownloadPDF}
                                className={clsx(styles.btn, styles.iconDownload)}
                            />
                        )}
                    </div>
                )}
            </div>

            {isLoading && <Loader />}
            {!isLoading && isNoOrders && <NoOrdersNotice type={activeTab} />}
            {isNoSearchResults && <NoSearchNotice />}
            <div
                className={clsx(styles.tradesListContainer, {
                    [styles.tradesListOpenTrades]: isOpenTrades,
                    [styles.tradesListPendingOrders]: isPendingTrades,
                    [styles.tradesListClosedTrades]: isClosedTrades,
                    [styles.tradesListIsNoOrders]: isNoOrders,
                    [styles.tradesListIsDateRange]:
                        isClosedTrades && rangeType === PeriodTypes.DATE_RANGE,
                    [styles.containerScrollActive]:
                        tradeListRef?.current?.scrollHeight > tradeListRef?.current?.clientHeight,
                })}>
                {activeTab === "closed" &&
                    tradesData &&
                    tradesData.length > 0 &&
                    !isNoSearchResults &&
                    !isNoOrders &&
                    !isLoading && (
                        <MobileTotalPl totalPL={formatAmount(profitLossDisplayValue, accountCurrency)} />
                    )}
                <TradesList
                    onEdit={handleEditOrder}
                    onDelete={handleOrderDelete}
                    onClose={handleOrderClose}
                    type={activeTab}
                    tradesData={tradesData}
                    ref={tradeListRef}
                />
            </div>
        </div>
    );
};

export default React.memo(TradesSectionMobile);
