import React from "react";
import clsx from "clsx";
import { TradeChartTimeframe } from "@/hooks/chart";
import { useTranslation } from "next-i18next";
import { AnimationClasses } from "@/hooks/common/useAnimation";

import styles from "./TimeFramesModal.module.scss";

interface ITimeFramesProps {
    onClose: () => void;
    onChange: (value) => void;
    selectedValue: TradeChartTimeframe;
    isOpenDuration: boolean;
}

const TimeFramesModal: React.FC<ITimeFramesProps> = ({
    // eslint-disable-next-line
    onClose,
    onChange,
    selectedValue,
    isOpenDuration = true,
}) => {
    const { t } = useTranslation("common");

    const options = [
        { value: TradeChartTimeframe.Min1, title: `1 ${t("dateRange.minute")}` },
        { value: TradeChartTimeframe.Min5, title: `5 ${t("dateRange.minutes")}` },
        { value: TradeChartTimeframe.Min15, title: `15 ${t("dateRange.minutes")}` },
        { value: TradeChartTimeframe.Min30, title: `30 ${t("dateRange.minutes")}` },
        { value: TradeChartTimeframe.Hour, title: `1 ${t("dateRange.hour")}` },
        { value: TradeChartTimeframe.Hour4, title: `4 ${t("dateRange.hours")}` },
        { value: TradeChartTimeframe.Day, title: `1 ${t("dateRange.day")}` },
        { value: TradeChartTimeframe.Week, title: `1 ${t("dateRange.week")}` },
        { value: TradeChartTimeframe.Month, title: `1 ${t("month")}` },
    ];

    return (
        <div
            style={{ animationDuration: isOpenDuration ? "0.3s" : "0s" }}
            className={clsx(styles.container, AnimationClasses.fadeInUp)}>
            <div className={styles.cards}>
                {options.map((cardData, index) => {
                    const [value, measure] = cardData.title.split(" ");
                    const isSelected = selectedValue === cardData.value;

                    return (
                        <div
                            key={`${value}-${measure}-${index}`}
                            className={clsx(styles.card, {
                                [styles.selected]: isSelected,
                            })}
                            onClick={() => {
                                if (!isSelected) {
                                    onChange(cardData.value);
                                }
                            }}>
                            <span className={styles.value}>{value}</span>
                            <span className={styles.value_measure}>{measure}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(TimeFramesModal);
