import React, { useRef } from "react";
import clsx from "clsx";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import { useKey } from "@/utils/hooks/keyboard/useKey";
import { KeyboardKeys } from "@/utils/hooks/keyboard/keyboardEvents";

import styles from "./Stepper.module.scss";


const MAX_STEPS = 7;

interface IStepperProps {
    isBulletsClickable?: boolean,
    currentStep: number,
    setStep: (count: number, prevCount?: number) => void,
    stepsCount?: number,
    onClose?: () => void;
}

export const Stepper: React.FC<IStepperProps> = ({
    isBulletsClickable = false,
    currentStep = 0,
    setStep,
    stepsCount = MAX_STEPS,
    onClose
}) => {
    const bullets = new Array(stepsCount).fill(".");
    const maxSteps = stepsCount - 1;

    const increaseStep = () =>
        currentStep >= maxSteps ? setStep(maxSteps) : setStep(currentStep + 1, currentStep);
    const decreaseStep = () =>
        currentStep <= 0 ? setStep(0, 0) : setStep(currentStep - 1, currentStep);

    const increaseStepRef = useRef(increaseStep);
    useKey([KeyboardKeys.ArrowRight], increaseStepRef);

    const decreaseStepRef = useRef(decreaseStep);
    useKey([KeyboardKeys.ArrowLeft], decreaseStepRef);

    const closeModalRef = useRef(onClose);
    useKey([KeyboardKeys.Escape], closeModalRef, { eventTypes: ["keyup"] });

    return (
        <div className={styles.container}>
            <div className={styles.bullet_wrapper}>
                {bullets.map((b, index) => (
                    <div
                        key={index}
                        onClick={() => isBulletsClickable && setStep(index)}
                        className={clsx(styles.bullet, {
                            [styles.active]: currentStep === index,
                            [styles.clickable]: isBulletsClickable,
                        })}
                    />
                ))}
            </div>
            <div className={styles.controls_wrapper}>
                <div
                    onClick={decreaseStep}
                    className={clsx(styles.control, styles.left, {
                        [styles.disabled]: currentStep === 0,
                    })}
                >
                    <ArrowBackSharpIcon className={styles.arrowIcon} />
                </div>
                <div
                    onClick={increaseStep}
                    className={clsx(styles.control, styles.right, {
                        [styles.disabled]: currentStep >= maxSteps,
                    })}
                >
                    <ArrowForwardSharpIcon className={styles.arrowIcon} />
                </div>
            </div>
        </div>
    );
};
