import React from "react";
import clsx from "clsx";
import { getBrandIconPath } from "@/utils/helpers";
import { Loader } from "./components/Loader";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { useTheme } from "@mui/material";
import styles from "./LoadingScreen.module.scss";

interface IProps {
    withTitle?: boolean;
}

export const LoadingScreen: React.FC<IProps> = props => {
    const { isMobile, isTablet } = useDevice();
    const { palette } = useTheme();

    return (
        <div className={styles.container}>
            <>
                <div
                    style={{ backgroundImage: `url(${getBrandIconPath(palette.mode)})` }}
                    className={clsx(styles.loaderIcon, {
                        [styles.mobile]: isMobile,
                        [styles.tablet]: isTablet,
                    })}
                />
                <Loader withTitle={props.withTitle} />
            </>
        </div>
    );
};
