import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";

import PaymentSystemsView from "@/components/SystemFeedbackMobile/common/PaymentSystemsView";
import { useTouchState } from "@/hooks/common/useTouchState";
import AudioPlayer, { AudioType } from "../AudioPlayer";

import styles from "./SystemFeedback.module.scss";

interface IProps {
    onDeposit: () => void;
    onClose: () => void;
    playAudioEffect?: boolean;
}

const InsufficientFundsFeedback: React.FC<IProps> = ({ onDeposit, onClose, playAudioEffect = true }) => {
    const { t } = useTranslation("common");
    const { isTouched, onTouchStart, onTouchEnd } = useTouchState();
    return (
        <>
            {playAudioEffect && <AudioPlayer type={AudioType.ERROR} />}
            <div className={clsx(styles.container, styles.insufficientFunds)}>
                <div className={styles.closeIcon} onClick={onClose}></div>
                <div className={clsx(styles.icon, styles.fundsIcon)}/>

                <div className={styles.title}>{t("insufficient_funds")}</div>

                <div className={styles.description}>
                    {t("modals.deposit.defaultMessage")}
                </div>

                <PaymentSystemsView/>

                <div className={styles.btnContainer}>
                    <button
                        className={clsx(styles.btn, {
                            [styles.touched]: isTouched
                        })}
                        onClick={onDeposit}
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                    >
                        {t("deposit")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default React.memo(InsufficientFundsFeedback);
