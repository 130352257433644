import React, { useRef, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTheme } from "@mui/material";
import { CustomDatePicker as DatePicker } from "../Core/DatePicker";
import { DATE_FORMAT } from "@/constants/index";
import { useTranslation } from "next-i18next";

import styles from "./TradeHistory.module.scss";

const TIME_PERIODS = {
    TODAY: "dateRange.today",
    LAST_3_DAYS: "dateRange.threedays",
    LAST_WEEK: "dateRange.last_week",
    LAST_MONTH: "dateRange.last_month",
    LAST_3_MONTHS: "dateRange.threemonths",
    ALL_PERIODS: "dateRange.all",
};

const getDateFromPeriod = period => {
    switch (period) {
        case TIME_PERIODS.TODAY:
            return moment().startOf("day").format(DATE_FORMAT);
        case TIME_PERIODS.LAST_3_DAYS:
            return moment().subtract(3, "days").startOf("day").format(DATE_FORMAT);
        case TIME_PERIODS.LAST_WEEK:
            return moment().subtract(7, "days").startOf("day").format(DATE_FORMAT);
        case TIME_PERIODS.LAST_MONTH:
            return moment().subtract(1, "months").startOf("day").format(DATE_FORMAT);
        case TIME_PERIODS.LAST_3_MONTHS:
            return moment().subtract(3, "months").startOf("day").format(DATE_FORMAT);
        default:
            return moment().subtract(50, "years").startOf("day").format(DATE_FORMAT);
    }
};

const getDateFromPeriodValue = (periodIndicator: number) => {
    const periodLabel = Object.values(TIME_PERIODS).find((label, index) => periodIndicator === index);
    const dateFrom = getDateFromPeriod(periodLabel);

    if (dateFrom) {
        return dateFrom.split('T')[0];
    }
    return '01-01-1972';
};

interface IFilterData {
    dateFrom: string;
    dateTo: string;
}

interface TradeHistoryFilterProps {
    setFilter: (arg: IFilterData) => void;
    isLoading: boolean;
}

const TradeHistoryFilter: React.FC<TradeHistoryFilterProps> = ({ setFilter, isLoading }) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();

    const [activePeriodFilter, setActivePeriodFilter] = useState<number>(4);
    const [dateFrom, setDateFrom] = useState(moment(getDateFromPeriodValue(activePeriodFilter)).format());
    const [dateTo, setDateTo] = useState(moment().format());
    const periods = Object.values(TIME_PERIODS);
    const maxDate = useRef(new Date);

    const setPeriod = (period, index) => {
        const from = getDateFromPeriod(period);
        const to = moment().endOf("day").format(DATE_FORMAT);

        setDateTo(to);
        setDateFrom(from);
        setActivePeriodFilter(index);

        setFilter({ dateFrom: from, dateTo: to });
    };

    const setCustomPeriod = () => {
        const from = moment(dateFrom).startOf("day").format(DATE_FORMAT);
        const to = moment(dateTo).endOf("day").format(DATE_FORMAT);
        setFilter({ dateFrom: from, dateTo: to });
    };

    const PeriodFilterList = () => {
        return (
            <div className={styles.periodFilterList}>
                {periods.map((period, index) => {
                    return (
                        <div
                            key={period}
                            className={clsx(styles[`periodFilterListItem_${palette.mode}`], {
                                [styles.periodFilterListItemActive]: activePeriodFilter === index,
                            })}
                            onClick={() => {
                                if (!isLoading) {
                                    setPeriod(period, index);
                                }
                            }}>
                            {t(period)}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.filters}>
            <PeriodFilterList />
            <div className={styles.customPeriodWrap}>
                <DatePicker
                    maxDate={maxDate.current}
                    initDate={dateFrom}
                    onSubmit={date => {
                        setDateFrom(date);
                    }}
                    classes={{
                        calendar_icon: styles.calendarIcon,
                        common: styles.inputWrapper,
                    }}
                />
                <DatePicker
                    maxDate={maxDate.current}
                    initDate={dateTo}
                    onSubmit={date => {
                        setDateTo(date);
                    }}
                    classes={{
                        calendar_icon: styles.calendarIcon,
                        common: styles.inputWrapper,
                    }}
                />
                <input
                    className={styles[`submit_button_${palette.mode}`]}
                    type="submit"
                    value={t("done")}
                    onClick={() => setCustomPeriod()}
                />
            </div>
        </div>
    );
};

export default React.memo(TradeHistoryFilter);
