import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { Box, Modal, Slide, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgressBar from "@/components/Home/TradingAssetsSection/ProgressBar";
import { getSentimentsInfo } from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { AnimationClasses } from "@/hooks/common/useAnimation";

import styles from "./SentimentsSectionModal.module.scss";


interface ISentimentsInfoProps {
    isInfoOpen: boolean;
    closeInfo: () => void;
    onClose: () => void;
}

const SentimentsInfo: React.FC<ISentimentsInfoProps> = ({ isInfoOpen, closeInfo, onClose }) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { platform } = useDevice();

    return (
        <Modal
            open={true}
            onClose={onClose}
            disableAutoFocus
            BackdropProps={{
                classes: {
                    root: styles.modalBackdrop,
                },
            }}
        >
            <Slide direction="up" in={isInfoOpen} mountOnEnter unmountOnExit>
                <Box
                    id="app-theme-root"
                    className={clsx(styles.infoContainer, `theme--${palette?.mode}`, {
                        [styles.tablet]: platform === DevicePlatform.Tablet,
                    })}
                >
                    <div className={styles.header}>
                        <h1 className={styles.header__title}>{t("traders_choice")}</h1>
                        <div
                            className={styles.closeIcon}
                            onClick={() => {
                                closeInfo();
                                onClose();
                            }}
                        />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <div className={styles.greenBlock}></div>
                                <div className={styles.text}>{t("open_positions_long")}</div>
                                <div className={styles.arrowUpIcon}>
                                    <ArrowForwardIcon fontSize="large" />
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.redBlock}></div>
                                <div className={styles.text}>{t("open_positions_short")}</div>
                                <div className={styles.arrowDownIcon}>
                                    <ArrowBackIcon fontSize="large" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.description}>
                            {t("choice_objectives")}
                        </div>
                    </div>
                </Box>
            </Slide>
        </Modal>
    );
};

interface ISentimentsProgressBarProps {
    isOpenDuration: boolean;
    openInfo: () => void;
}

const SentimentsProgressBar: React.FC<ISentimentsProgressBarProps> = ({ isOpenDuration, openInfo }) => {
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbol = symbolsInfoById[activeSymbolId];
    const { buy: sentimentsBuy, sell: sentimentsSell } = getSentimentsInfo(symbol);

    return (
        <div
            className={clsx(styles.progressBarContainer, AnimationClasses.fadeInUp)}
            style={{ animationDuration: isOpenDuration ? "0.3s" : "0s" }}
        >
            <div className={styles.progressBar}>
                <span>{`${sentimentsSell.toFixed(0)}%`}</span>
                <div className={styles.progressLine}>
                    <ProgressBar value={sentimentsSell} minWidth="160px" />
                </div>
                <span>{`${sentimentsBuy.toFixed(0)}%`}</span>
                <div className={styles.infoIcon} onClick={openInfo}></div>
            </div>
        </div>
    );
};


interface ISentimensProps {
    onClose: () => void;
    isOpenDuration?: boolean;
}

const SentimentsSection: React.FC<ISentimensProps> = ({ isOpenDuration = true, onClose }) => {
    const [isInfoOpen, setInfoOpen] = useState(false);

    return isInfoOpen ?
        <SentimentsInfo
            closeInfo={() => setInfoOpen(false)}
            isInfoOpen={isInfoOpen}
            onClose={onClose}
        /> :
        <SentimentsProgressBar
            isOpenDuration={isOpenDuration}
            openInfo={() => setInfoOpen(true)}
        />;
};

export default React.memo(SentimentsSection);

