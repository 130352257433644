import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import Checkbox from "@/components/Core/Form/Checkbox/Checkbox";
import checkboxStyles from "@/components/Core/Form/Checkbox/Checkbox.module.scss";
import NumberInputMobile from "@/components/Core/Form/NumberInputMobile/NumberInputMobile";
import { useDevice } from "@/redux/selectors/deviceSelector";

import styles from "./PendingPriceBlock.module.scss";

interface IProps {
    value: number;
    onChange: (value) => void;
    label: string;
    step?: number;
    checked: boolean;
    onChangeChecked: () => void;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
}

const PendingPriceViewBlock: React.FC<IProps> = ({
    value,
    onChange,
    label,
    step,
    checked,
    onChangeChecked,
    onClick,
    className,
    disabled = false,
}) => {
    const { palette } = useTheme();
    const { isTablet } = useDevice();

    return (
        <div className={clsx(styles.container, className, {
            [styles.disabled]: !checked,
            [styles.container_tablet]: isTablet
        })}>
            <Checkbox
                onChange={!disabled ? onChangeChecked : () => {}}
                checked={checked}
                classes={clsx(null, {
                    [checkboxStyles.checkboxDark]: palette.mode === "light",
                    [styles.checkbox]: true,
                    [styles.checkbox_disabled]: disabled,
                })}
            />
            <div className={styles.label} onClick={!disabled ? onChangeChecked : () => {}}>{label}</div>

            <NumberInputMobile
                className={clsx(styles.inputNumber, {
                    [styles.inputNumber_tablet]: isTablet
                })}
                value={value}
                onChange={!disabled ? onChange : () => {}}
                step={step}
                disabled={!checked || disabled}
                onClick={onClick}
            />
        </div>
    );
};

export default React.memo(PendingPriceViewBlock);
