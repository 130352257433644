import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { Button } from "@mui/material";

import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { setChartTab, setTradeOrderView } from "@/redux/actions/ui";

import TradingChart from "./TradingChart";
import TradingInfo from "./TradingInfo";
import { ChartTabs } from "@/redux/interfaces/IUI";

import styles from "./TradingChartInfoSection.module.scss";


const DateTimeString = () => {
    const getTimeString = () => new Date().toLocaleString("en-GB").replace(/\//g, ".");
    const [timeString, setTimeString] = useState(getTimeString());

    useEffect(() => {
        const dateTimer = setInterval(() => {
            setTimeString(getTimeString());
        }, 1000);

        return () => clearInterval(dateTimer);
    }, []);

    return <>{timeString}</>;
};

const TradingChartInfoSection: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { isTradeOrderViewOpen, isAssetsTableExpanded, chartTab, isOpenTradesOpen } = useUI();
    const [forceChartReload, setForceChartReload] = useState(false);
    const isNarrow = isTradeOrderViewOpen && isAssetsTableExpanded;
    const introStep = useIntroStep();

    const handleTabChange = (newTab: ChartTabs) => {
        dispatch(setChartTab(newTab));
    };
    
    const isOverlayed = introStep === 4;
    const isCurrentIntroStep = introStep === 3;

    const openTradeOrderView = () => dispatch(setTradeOrderView(true));

    useEffect(() => {
        if (isCurrentIntroStep) {
            openTradeOrderView();
        }
        if (introStep === 4 && chartTab === "INFO") {
            handleTabChange("CHART");
        }
    }, [isCurrentIntroStep]);

    useEffect(() => {
        if (ref.current && chartTab === "CHART") {
            // possible bug with hide-tlb-item class removing in chart source
            const element = document.querySelector('prochart .layout1 ul.toolbar-adv>li');
            if (element && element.classList.contains("hide-tlb-item")) {
                setForceChartReload(true);
                setTimeout(() => {
                    setForceChartReload(false);
                }, 100);
            }
        }
    }, [chartTab]);

    const tabs: ChartTabs[] = ["CHART", "INFO"];

    return (
        <div
            className={clsx(styles.section, {
                [styles.narrow]: isNarrow,
                [styles.overlayed]: isOverlayed,
            })}
            id="trading_chart_section"
            ref={ref}>
            <div
                className={clsx(styles.sectionHeader, {
                    [styles.overlayed]: isOverlayed,
                })}>
                <div className={styles[`chartNavTabs${isTradeOrderViewOpen ? "" : "_expanded"}`]}>
                    {!isTradeOrderViewOpen && (
                        <div className={styles.chartNavTab}>
                            <Button
                                className={styles.chartNavTabOpenTrade}
                                variant="contained"
                                color="primary"
                                onClick={() => openTradeOrderView()}
                                disableRipple>
                                <img src="/images/trade-btn-icon.svg" alt="Open trade" />
                                {t("open_trade")}
                            </Button>
                        </div>
                    )}
                    {tabs.map(tab => {
                        const isActive = tab === chartTab;

                        return (
                            <div
                                className={clsx(styles.chartNavTab, {
                                    [styles.chartNavTabChart]: tab === "CHART",
                                    [styles.chartNavTabInfo]: tab === "INFO",
                                })}
                                onClick={() => handleTabChange(tab)}
                                key={tab}>
                                <Button
                                    className={clsx(styles.chartNavTabButton, {
                                        [styles.chartNavTabButtonActive]: isActive,
                                    })}
                                    variant="contained"
                                    disableRipple>
                                    {tab === "CHART" ? t("chart") : t("information")}
                                </Button>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.dateTime}>
                    <DateTimeString />
                </div>
            </div>

            <div className={clsx(styles.content, {
                [styles.tradesTableOpen]: isOpenTradesOpen,
            })}>
                <div
                    className={clsx(styles.tabContent, {
                        [styles.hidden]: chartTab !== "CHART",
                    })}>
                    {!forceChartReload && <TradingChart wrapRef={ref} />}
                </div>
                <div
                    className={clsx(styles.tabContent, {
                        [styles.hidden]: chartTab !== "INFO",
                        [styles.scrollContent]: true,
                    })}>
                    <TradingInfo />
                </div>
            </div>
        </div>
    );
};

export default React.memo(TradingChartInfoSection);
