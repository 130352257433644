import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import moment from "moment";

import { CustomDatePicker } from "@/components/Core/DatePicker";
import NumberInput from "@/components/Core/Form/NumberInput/NumberInput";
import TextInput from "@/components/Core/Form/TextInput/TextInput";
import Toggle from "@/components/Core/Form/Toggle/Toggle";
import Checkbox from "@/components/Core/Form/Checkbox/Checkbox";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import {
    togglePendingOrder,
    setPendingOrderPrice,
    togglePendingOrderExpiration,
    setPendingOrderExpiration,
} from "@/contexts/TradeOrder/actions";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { useResize } from "@/hooks/common/useResize";
import { DATE_FORMAT } from "@/constants/index";
import { useUI } from "@/redux/selectors/uiSelector";
import { ITradeOrder } from "@/services/trade/order";
import { isPendingOrder } from "@/utils/trade";
import { getDirectionsTranslationKey } from "@/utils/helpers";

import styles from "./PendingOrder.module.scss";

interface IPicker {
    isPendingOrderExpiration: boolean;
    handleExpirySwitchChange: () => void;
    handleExpirationTimeChange: (value: string) => void;
    initValue?: string;
}

const Picker: FC<IPicker> = ({
    isPendingOrderExpiration,
    handleExpirySwitchChange,
    handleExpirationTimeChange,
    initValue,
}) => {
    const { t } = useTranslation("common");
    const expirationMinDate = useRef(new Date());
    const { height, width } = useResize();
    const { isAssetsTableExpanded } = useUI();
    const orientation = width > 1400 && height < 885 ? "top-start" : "bottom-start";

    return (
        <div className={styles.row}>
            <div>{t("expiry")}:</div>
            <div>
                <Checkbox
                    checked={isPendingOrderExpiration}
                    onChange={handleExpirySwitchChange}
                    classes={styles.expirationCheckbox}
                />

                {isPendingOrderExpiration && (
                    <div id="datePickerWrapper" className={styles.datePickerWrapper}>
                        <CustomDatePicker
                            initDate={initValue || moment().format(DATE_FORMAT)}
                            minDate={expirationMinDate.current}
                            onSubmit={handleExpirationTimeChange}
                            classes={{ common: styles.expirationDateInput }}
                            orientation={orientation}
                            useMopperModifiers={true}
                            dateFormat="dd.MM.yyyy HH:mm"
                            showTimeInput
                            isTableExpanded={isAssetsTableExpanded}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const DynamicFormFields = ({ order }: { order?: ITradeOrder }) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { symbolsInfoById } = useTradeInfo();
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const {
        activeSymbolId,
        activeOperationCategory,
        pendingOrderPrice = 0,
        pendingOrderType,
    } = useContext(TradeOrderStateContext);

    const symbolInfo = symbolsInfoById[activeSymbolId];
    const { ask, bid } = useQuoteData(activeSymbolId);
    const marketPrice = activeOperationCategory === "Buy" ? ask : bid;

    const handlePriceChange = useCallback(
        price => {
            tradeOrderDispatch(setPendingOrderPrice(Number(price), marketPrice));
        },
        [marketPrice, tradeOrderDispatch]
    );

    const prefix = pendingOrderType ? "actionsId." : "";
    const category = activeOperationCategory.toLowerCase();
    const type = pendingOrderType ? `_${pendingOrderType.toLowerCase()}` : "";

    const pendingOperationTypeLabel = t(`${prefix}${category}${type}`);

    return (
        <>
            <div className={styles.row}>
                <div>{t("at_price")}:</div>
                <NumberInput
                    value={order ? order.openPrice : pendingOrderPrice}
                    step={symbolInfo.point}
                    onChange={handlePriceChange}
                    fullWidth
                    className={styles.pendingOrderPriceInput}
                    disabled={!!order}
                />
            </div>
            <div className={styles.row}>
                <div>{t("type")}:</div>
                <div>
                    <TextInput
                        value={
                            order
                                ? t(getDirectionsTranslationKey(order.type))
                                : pendingOperationTypeLabel
                        }
                        fullWidth
                        disabled
                        className={styles[`operationCategoryTextInput_${palette.mode}`]}
                    />
                </div>
            </div>
        </>
    );
};
const DynamicHeader = () => {
    const { t } = useTranslation("common");
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { activeOperationCategory, isPendingOrder } = useContext(TradeOrderStateContext);

    const handlePendingOrderToggle = () => tradeOrderDispatch(togglePendingOrder());

    return (
        <div className={styles.header}>
            <span className={styles.pendingOrderToggleLabel}>{t("setPendingOrder")}</span>
            <Toggle
                on={isPendingOrder}
                onChange={handlePendingOrderToggle}
                disabled={!activeOperationCategory}
            />
        </div>
    );
};

interface IPendingOrder {
    order?: ITradeOrder;
}
const PendingOrder: React.FC<IPendingOrder> = ({ order }) => {
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const tradeOrderStateContext = useContext(TradeOrderStateContext);

    const handleExpirySwitchChange = () => tradeOrderDispatch(togglePendingOrderExpiration());
    const handleExpirationTimeChange = data => {
        tradeOrderDispatch(setPendingOrderExpiration(new Date(data).toISOString()));
    };

    useEffect(() => {
        if (order && isPendingOrder(order)) {
            if (order.expiration && moment(order.expiration).isAfter(moment())) {
                handleExpirySwitchChange();
                handleExpirationTimeChange(order.expiration);
            }
        }
    }, []);

    const _isPendingOrder = order ? isPendingOrder(order) : tradeOrderStateContext.isPendingOrder;

    return (
        <div className={styles.container}>
            {!order && <DynamicHeader />}
            {_isPendingOrder && (
                <div className={styles.content}>
                    <DynamicFormFields order={order} />
                    <Picker
                        isPendingOrderExpiration={tradeOrderStateContext.isPendingOrderExpiration}
                        handleExpirySwitchChange={handleExpirySwitchChange}
                        handleExpirationTimeChange={handleExpirationTimeChange}
                        initValue={
                            order?.expiration && moment(order.expiration).isAfter(moment())
                                ? moment(order.expiration).format(DATE_FORMAT)
                                : ""
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(PendingOrder);
