import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "next-i18next";

export const NoResultsTradeAlerts = ({ type }) => {
    const { t } = useTranslation("common");
    if (type === "empty") {
        return (
            <div className={styles.no_results_container}>
                <div className={styles.rectangle_border}></div>
                <span className={styles.text}>{t("no_alerts")}</span>
            </div>
        );
    }
    if (type === "search") {
        return (
            <div className={styles.no_results_container}>
                <div className={styles.rectangle}></div>
                <span className={styles.text}>{t("no_results")}</span>
            </div>
        );
    }
    return <div className={styles.no_results_container}></div>;
};
