import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";

import Toggle from "@/components/Core/Form/Toggle/Toggle";
import OrderValueBlock from "@/components/Home/TradesSectionMobile/components/OrderVolumeBlock";
import { getCurrentPrice } from "@/utils/symbols";
import { QuoteData } from "@/hooks/trade/tradeSocket";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { formatAmount } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { ICloseOrderQueryParams } from "@/services/trade/order";
import { useLotSize } from "@/hooks/trade/lotSize";
import { closeTradeSubmit } from "@/redux/actions/orders";
import { IOrderCloseConfirmModalParams } from "@/redux/interfaces/IUI";
import { useUI } from "@/redux/selectors/uiSelector";
import { closeTradeOrderModal } from "@/redux/actions/ui";
import AppModal from "@/components/Core/Modal/AppModal";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { assignPriceParamsToOrder } from "@/utils/helpers";
import { getTradeProfit } from "@/utils/trade";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";
import useAutoClearValueState from "@/hooks/common/useAutoClearValueState";
import { getSeenPrice } from "@/utils/getSeenPrice";
import styles from "./CloseOrderModal.module.scss";

const CloseOrderModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();
    const { tradeOrderModalParams } = useUI();
    const { order } = tradeOrderModalParams as IOrderCloseConfirmModalParams;
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById, symbols } = useTradeInfo();
    const { ask, bid } = useQuoteData(order.symbol);
    const { isMarketOpen } = useMarketSchedule(order.symbol);

    const [isPartialClose, setPartialClose] = useState(false);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const { lots } = order;
    const [errorMessage, setErrorMessage] = useAutoClearValueState<"min" | "max">(null);
    const symbolInfo = symbolsInfoById[order.symbol];
    const lotMin = symbolInfo?.group?.lotMin;

    const { lotSize, handleValueChange } = useLotSize({
        max: lots,
        initialValue: lots,
        min: lotMin,
    });
    const handleCloseModal = () => dispatch(closeTradeOrderModal());

    const handleCloseOrder = () => {
        setCloseDisabled(true);
        setTimeout(() => {
            setCloseDisabled(false);
        }, 8000);

        let request: Partial<ICloseOrderQueryParams> = {
            ticket: order.ticket,
            volume: isPartialClose ? lotSize : null,
        };

        request = assignPriceParamsToOrder({
            ask,
            bid,
            symbolInfo,
            originalObject: request,
        });

        dispatch(
            closeTradeSubmit({
                ...request,
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            })
        );
    };

    const alertMessage = useMemo(() => {
        return errorMessage
            ? errorMessage === "min"
                ? `${t("min_volume")} ${lotMin || 0.01}`
                : `${t("max_volume")} ${lots}`
            : "";
    }, [errorMessage, lots, lotMin]);

    const calcProfit = getTradeProfit({
        openPrice: order.openPrice,
        ask,
        bid,
        tradeType: order.type.toLowerCase().includes("buy") ? "Buy" : "Sell",
        lotSize: order.lots,
        accountCurrency,
        symbolInfo,
        symbols,
    });

    const profit = isMarketOpen ? calcProfit : order.profit;
    const title = t("closeTradeModalTitle", { currency: symbolInfo.ex.displayName || order.symbol });

    return (
        <AppModal
            onClose={handleCloseModal}
            className={clsx(styles.container, {
                [styles.partialCloseOpen]: isPartialClose,
            })}
            backdropColor={"rgba(0, 0, 0, 0.8)"}>
            <>
                <div className={styles.title}>{t("closeTrade")}</div>
                <div className={styles.question}>{title}</div>

                <div className={styles.infoContainer}>
                    <div className={styles.infoItem}>
                        <div
                            className={clsx(styles.infoItem__value, {
                                [styles.profit]: order.profit >= 0,
                                [styles.loss]: order.profit < 0,
                            })}>
                            <QuoteData
                                symbolId={order.symbol}
                                priceChangeTTL={450}
                                render={({ ask, bid }) => {
                                    return <span>{getCurrentPrice(order, symbolInfo, ask, bid)}</span>;
                                }}
                            />
                        </div>
                        {t("closePrice")}
                    </div>
                    <div className={styles.infoItem}>
                        <div
                            className={clsx(styles.infoItem__value, {
                                [styles.profit]: profit >= 0,
                                [styles.loss]: profit < 0,
                            })}>
                            {formatAmount(profit, accountCurrency)}
                        </div>
                        {t("profit")}
                    </div>
                </div>

                <div className={styles.toggleContainer}>
                    <div>{t("partial_close")}</div>
                    <Toggle
                        on={isPartialClose}
                        onChange={() => setPartialClose(prev => !prev)}
                        className={clsx(null, {
                            [styles.customToggle]: isPartialClose,
                        })}
                    />
                </div>

                {isPartialClose && (
                    <OrderValueBlock
                        lotSize={lotSize}
                        onChange={handleValueChange}
                        maxLotValue={lots}
                        minLotValue={lotMin}
                        errorMessage={alertMessage}
                        setErrorMessage={setErrorMessage}
                        t={t}
                    />
                )}

                <div className={styles.buttonsContainer}>
                    <button
                        className={clsx(styles.btn, styles.btnTransparent, {
                            [styles.touched_no]: isTouched && buttonName === PressedButtons.NO,
                        })}
                        onClick={handleCloseModal}
                        onTouchStart={() => onTouchStart(PressedButtons.NO)}
                        onTouchEnd={onTouchEnd}>
                        {t("no")}
                    </button>
                    <button
                        className={clsx(styles.btn, styles.btnInfo, {
                            [styles.touched_ok]: isTouched && buttonName === PressedButtons.YES,
                            [styles.disabled]:
                                (!!errorMessage && lotSize !== lotMin && lotSize !== lots) ||
                                closeDisabled,
                        })}
                        onClick={handleCloseOrder}
                        onTouchStart={() => onTouchStart(PressedButtons.YES)}
                        onTouchEnd={onTouchEnd}
                        disabled={!!errorMessage || closeDisabled}>
                        {t("yes")}
                    </button>
                </div>
            </>
        </AppModal>
    );
};

export default React.memo(CloseOrderModal);
