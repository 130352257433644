import { getStopLossPriceFromPips, getTakeProfitPriceFromPips } from "@/utils/symbols";
import { getSymbolQuoteValue } from "@/hooks/trade/tradeSocket";

import {
    ISetActiveOperationCategoryAction,
    ISetActiveSymbolAction,
    ISetLotSizeAction,
    ISetPendingOrderExpirationAction,
    ISetPendingOrderPriceAction,
    ISetIsStopLossAction,
    ISetStopLossPipsAction,
    ISetStopLossPriceAction,
    ISetIsTakeProfitAction,
    ISetTakeProfitPipsAction,
    ISetTakeProfitPriceAction,
    ITradeOrderState,
    PendingOrderType,
    SLTPPriceCalcMode,
    TradeOrderActions,
    TradeOrderActionTypes,
} from "./interfaces";
import { createTimestamp } from "@/utils/dateTime";

const getResetOrderStateProps = (): Partial<ITradeOrderState> => {
    return {
        activeOperationCategory: null,
        lotSize: 0.01,
        isPendingOrder: false,
        isPendingOrderExpiration: false,
        pendingOrderExpiration: "",
        pendingOrderPrice: 0,
        pendingOrderType: null,
        isStopLoss: false,
        stopLossPriceCalcMode: "Pips",
        stopLossPips: 0,
        stopLossPrice: 0,
        isTakeProfit: false,
        takeProfitPriceCalcMode: "Pips",
        takeProfitPips: 0,
        takeProfitPrice: 0,
        lastOrderResetTime: createTimestamp(),
    };
};

export default function tradeOrderReducer(
    state: ITradeOrderState,
    action: TradeOrderActions
): ITradeOrderState {
    const { type } = action;

    switch (type) {
        case TradeOrderActionTypes.SET_ACTIVE_SYMBOL: {
            const { id, point, isForex } = action as ISetActiveSymbolAction;

            if (id === state.activeSymbolId) {
                return state;
            }

            return {
                ...state,
                activeSymbolId: id,
                activeSymbolPoint: point,
                isForex,
                ...getResetOrderStateProps(),
            };
        }

        case TradeOrderActionTypes.SET_ACTIVE_OPERATION_CATEGORY: {
            const { category, ask, bid } = action as ISetActiveOperationCategoryAction;
            const { isPendingOrder, pendingOrderPrice } = state;

            let pendingOrderNextType = null;

            if (isPendingOrder && pendingOrderPrice && category) {
                const marketPrice = category === "Buy" ? ask : bid;

                if (pendingOrderPrice === marketPrice) {
                    pendingOrderNextType = null;
                } else if (category === "Buy") {
                    pendingOrderNextType = pendingOrderPrice > marketPrice ? "Stop" : "Limit";
                } else {
                    pendingOrderNextType = pendingOrderPrice > marketPrice ? "Limit" : "Stop";
                }
            }

            return {
                ...state,
                activeOperationCategory: category,
                pendingOrderType: pendingOrderNextType,
            };
        }

        case TradeOrderActionTypes.SET_LOT_SIZE: {
            const { size } = action as ISetLotSizeAction;

            return {
                ...state,
                lotSize: size,
            };
        }

        case TradeOrderActionTypes.SET_PENDING_ORDER_PRICE: {
            const { price, marketPrice } = action as ISetPendingOrderPriceAction;

            if (price <= 0) {
                return state;
            }

            let pendingOrderType: PendingOrderType = null;

            if (price === marketPrice) {
                pendingOrderType = null;
            } else if (state.activeOperationCategory === "Buy") {
                pendingOrderType = price > marketPrice ? "Stop" : "Limit";
            } else {
                pendingOrderType = price > marketPrice ? "Limit" : "Stop";
            }

            return {
                ...state,
                pendingOrderPrice: price,
                pendingOrderType,
                stopLossPrice: state.isStopLoss
                    ? getStopLossPriceFromPips(state.stopLossPips, state, price)
                    : state.stopLossPrice,
                takeProfitPrice: state.isTakeProfit
                    ? getTakeProfitPriceFromPips(state.takeProfitPips, state, price)
                    : state.takeProfitPrice,
            };
        }

        case TradeOrderActionTypes.SET_STOP_LOSS_PRICE: {
            const { price } = action as ISetStopLossPriceAction;

            if (price < 0) {
                return state;
            }

            return {
                ...state,
                stopLossPriceCalcMode: "Price",
                stopLossPrice: price,
            };
        }

        case TradeOrderActionTypes.SET_STOP_LOSS_PIPS: {
            const { pips } = action as ISetStopLossPipsAction;

            return {
                ...state,
                stopLossPriceCalcMode: "Pips",
                stopLossPips: pips,
                stopLossPrice: getStopLossPriceFromPips(pips, state),
            };
        }

        case TradeOrderActionTypes.SET_TAKE_PROFIT_PRICE: {
            const { price } = action as ISetTakeProfitPriceAction;

            if (price < 0) {
                return state;
            }

            return {
                ...state,
                takeProfitPriceCalcMode: "Price",
                takeProfitPrice: price,
            };
        }

        case TradeOrderActionTypes.SET_TAKE_PROFIT_PIPS: {
            const { pips } = action as ISetTakeProfitPipsAction;

            return {
                ...state,
                takeProfitPriceCalcMode: "Pips",
                takeProfitPips: pips,
                takeProfitPrice: getTakeProfitPriceFromPips(pips, state),
            };
        }

        case TradeOrderActionTypes.RESET_ORDER: {
            return {
                ...state,
                ...getResetOrderStateProps(),
            };
        }

        case TradeOrderActionTypes.TOGGLE_PENDING_ORDER: {
            const isPendingOrder = !state.isPendingOrder;

            if (isPendingOrder && !state.activeOperationCategory) {
                return {
                    ...state,
                    isPendingOrder,
                };
            }

            let price = 0;

            if (isPendingOrder) {
                // TODO: move this to action parameter
                const { ask, bid } = getSymbolQuoteValue(state.activeSymbolId);

                price = state.activeOperationCategory === "Buy" ? ask : bid;
            }

            return {
                ...state,
                isPendingOrder,
                pendingOrderPrice: price,
                pendingOrderType: null,
                isPendingOrderExpiration: isPendingOrder ? state.isPendingOrderExpiration : false,
                pendingOrderExpiration: isPendingOrder ? state.pendingOrderExpiration : "",
                stopLossPrice:
                    isPendingOrder && state.isStopLoss
                        ? getStopLossPriceFromPips(state.stopLossPips, state, price)
                        : state.stopLossPrice,
                takeProfitPrice:
                    isPendingOrder && state.isTakeProfit
                        ? getTakeProfitPriceFromPips(state.takeProfitPips, state, price)
                        : state.takeProfitPrice,
            };
        }

        case TradeOrderActionTypes.TOGGLE_PENDING_ORDER_EXPIRATION: {
            const isPendingOrderExpiration = !state.isPendingOrderExpiration;

            return {
                ...state,
                isPendingOrderExpiration,
                pendingOrderExpiration: !isPendingOrderExpiration
                    ? ""
                    : // TODO: proper formatter
                      // TODO: local time
                      new Date().toISOString().substr(0, 16),
            };
        }

        case TradeOrderActionTypes.SET_IS_STOP_LOSS: {
            const { isActive: isStopLoss, price } = action as ISetIsStopLossAction;

            let stopLossPriceCalcMode: SLTPPriceCalcMode = "Pips";
            let stopLossPrice = 0;

            if (isStopLoss) {
                if (state.activeOperationCategory) {
                    stopLossPrice = getStopLossPriceFromPips(
                        50,
                        state,
                        state.isPendingOrder ? state.pendingOrderPrice : null
                    );
                } else {
                    // on mobile we don't have the operation category when editing SL/TP,
                    // so it's always in Price mode
                    stopLossPriceCalcMode = "Price";
                    stopLossPrice = price;
                }
            } else {
                stopLossPrice = 0;
            }

            return {
                ...state,
                isStopLoss,
                stopLossPriceCalcMode,
                stopLossPrice,
                stopLossPips: isStopLoss ? 50 : state.stopLossPips,
            };
        }

        case TradeOrderActionTypes.SET_IS_TAKE_PROFIT: {
            const { isActive: isTakeProfit, price } = action as ISetIsTakeProfitAction;

            let takeProfitPriceCalcMode: SLTPPriceCalcMode = "Pips";
            let takeProfitPrice = 0;

            if (isTakeProfit) {
                if (state.activeOperationCategory) {
                    takeProfitPrice = getTakeProfitPriceFromPips(
                        50,
                        state,
                        state.isPendingOrder ? state.pendingOrderPrice : null
                    );
                } else {
                    // on mobile we don't have the operation category when editing SL/TP,
                    // so it's always in Price mode
                    takeProfitPriceCalcMode = "Price";
                    takeProfitPrice = price;
                }
            } else {
                takeProfitPrice = 0;
            }

            return {
                ...state,
                isTakeProfit,
                takeProfitPriceCalcMode,
                takeProfitPrice,
                takeProfitPips: isTakeProfit ? 50 : state.takeProfitPips,
            };
        }

        case TradeOrderActionTypes.SET_PENDING_ORDER_EXPIRATION: {
            const { expiration } = action as ISetPendingOrderExpirationAction;

            return {
                ...state,
                pendingOrderExpiration: expiration,
            };
        }

        default:
            return state;
    }
}
