import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { listenForOutsideClicks } from "@/utils/helpers";

import styles from "./Calculate.module.scss";

interface ICalculateItem {
    title: string;
    description: string;
}

interface IProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export const calculateData: ICalculateItem[] = [
    {
        title: "balance",
        description: "help_balance",
    },
    {
        title: "pnl",
        description: "help_pnl",
    },
    {
        title: "equity",
        description: "help_equity",
    },
    {
        title: "used_funds",
        description: "help_used_margin",
    },
    {
        title: "freeMargin",
        description: "help_free_margin",
    },
    {
        title: "marginLevel",
        description: "help_margin_level",
    },
];

export const Calculate: React.FC<IProps> = ({ open, setOpen }) => {
    const { t } = useTranslation("common");
    const [listening, setIsListening] = useState(false);
    const menuRef = useRef();
    const elementId = "calculation";
    const closeButtonId = "close_calculation";

    useEffect(
        listenForOutsideClicks(listening, setIsListening, menuRef, setOpen, elementId, closeButtonId)
    );

    return (
        <div ref={menuRef}>
            {open && (
                <div className={styles.container}>
                    <div className={styles.popup_header}>
                        <div
                            id="close_calculation"
                            className={styles.close_icon}
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    {calculateData?.map(({ title, description }) => {
                        return (
                            <div key={title} className={styles.item_wrapper}>
                                <span className={styles.title}>{t(title)}</span>
                                <span className={styles.description}>{t(description)}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
