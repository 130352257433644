import React from "react";
import clsx from "clsx";
import styles from "./TextInput.module.scss";
//import { useTheme } from "@mui/material";

interface IProps {
    value: string;
    onChange?: (string) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    className?: string;
    classes?: { input: string };
}

const TextInput = (props: IProps): JSX.Element => {
    const {
        value,
        onChange = () => {},
        disabled = false,
        fullWidth = false,
        className,
        classes = { input: "" },
    } = props;

    return (
        <div
            className={clsx(styles.container, className, {
                [styles.disabled]: disabled,
                [styles.fullWidth]: fullWidth,
            })}>
            <input
                type="text"
                className={clsx({ [classes?.input]: classes?.input })}
                value={value}
                onChange={e => onChange(e.target.value)}
                disabled={disabled}
            />
        </div>
    );
};

export default TextInput;
