import React, { useRef, useState } from "react";
import clsx from "clsx";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import styles from "./styles.module.scss";

interface IProps {
    isDisabled?: boolean;
    className?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (value: string) => void;
    value?: string;
    manuallyDefinedTheme?: "dark" | "light";
    popupId?: string;
}

const Search = ({
    value,
    onChange,
    isDisabled = false,
    className,
    onFocus,
    onBlur,
    manuallyDefinedTheme,
    popupId,
}: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    let {
        palette: { mode },
    } = useTheme();

    const [isSearchIconHover, setSearchIconHover] = useState(false);
    const searchRef = useRef<HTMLDivElement>(null);

    const clearSearch = () => {
        onChange("");
        searchRef?.current.querySelectorAll("input")[0].focus();
    };

    const handleFocus = () => onFocus && onFocus();

    const handleBlur = () => onBlur && onBlur();

    const handleSearchIcon = () => {
        const searchInput = searchRef?.current.querySelectorAll("input")[0];
        searchInput && searchInput.focus();
    };

    if (manuallyDefinedTheme) {
        mode = manuallyDefinedTheme;
    }

    return (
        <div id={popupId || null} className={clsx(styles.container, className, styles[`_${mode}`])}>
            <InputBase
                id={popupId || null}
                className={clsx(styles.searchInput, styles[`_${mode}`], {
                    [styles.searchIconHoverActive]: isSearchIconHover,
                })}
                inputProps={{
                    className: clsx(styles[`_${mode}`]),
                    autoComplete: "off",
                }}
                ref={searchRef}
                onChange={e => onChange(e.target.value)}
                value={value}
                placeholder={t("filter")}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isDisabled}
            />
            <div id={popupId || null} className={clsx(styles.iconWrapper, styles[`_${mode}`])}>
                {value ? (
                    <button
                        className={clsx(styles.closeButton, styles[`_${mode}`])}
                        id={popupId || null}
                        onClick={clearSearch}></button>
                ) : (
                    <div
                        id={popupId || null}
                        className={clsx(styles.search_icon, styles[`_${mode}`])}
                        onClick={handleSearchIcon}
                        onMouseMove={() => setSearchIconHover(true)}
                        onMouseOut={() => setSearchIconHover(false)}
                        onBlur={() => setSearchIconHover(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default Search;
