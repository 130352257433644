import React, { ReactElement, useEffect } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Layout from "@/components/layout";
import TradingHomeDesktop, { TradingHomeMobile, TradingHomeTablet } from "@/components/Home/Home";
import { DataTables } from "@/components/DataTables/DataTables";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { useVhHandler } from "@/hooks/trade/useVhHandler";
import { useFontSizeChange } from "@/hooks/common/useFontSizeChange";
import { SYMBOLS_IMAGES_MAP_STORAGE_KEY } from "@/constants/index";
import { NotificationsPopup } from "../components/TradeAlerts";

const HomeDesktop = () => {
    useEffect(() => {
        if (navigator.userAgent.indexOf("Chrome") !== -1) {
            document.body.classList.add("chrome");
            document.body.classList.remove("not-chrome");
        } else {
            document.body.classList.add("not-chrome");
            document.body.classList.remove("chrome");
        }

        return () => {
            document.body.classList.remove("chrome");
            document.body.classList.remove("not-chrome");
        };
    }, []);
    return (
        <>
            <TradingHomeDesktop />
            <DataTables />
            <NotificationsPopup />
        </>
    );
};

// TODO: tablet home components
const HomeTablet = () => {
    return <TradingHomeTablet />;
};

const HomeMobile = () => {
    return (
        <>
            <TradingHomeMobile />
        </>
    );
};

const Home = (props): ReactElement => {
    const { assetsMap } = props;
    const { platform: devicePlatform } = useDevice();

    useEffect(() => {
        if (assetsMap) {
            localStorage.setItem(SYMBOLS_IMAGES_MAP_STORAGE_KEY, JSON.stringify(assetsMap));
        }
    }, []);

    useVhHandler();
    useFontSizeChange();

    return (
        <Layout>
            {devicePlatform === DevicePlatform.Desktop ? (
                <HomeDesktop />
            ) : devicePlatform === DevicePlatform.Tablet ? (
                <HomeTablet />
            ) : devicePlatform === DevicePlatform.Mobile ? (
                <HomeMobile />
            ) : null}
        </Layout>
    );
};

export async function getServerSideProps({ locale }) {
    let assetsMap = await new Promise<any>(resolve => {
        fetch("https://webtrader-cdn.patronfx.com/symbols/assets.json", {
            method: "GET",
            cache: "no-cache",
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(() => {
                resolve(undefined);
            });
    });

    if (!assetsMap) {
        assetsMap = {};
    }

    return {
        props: {
            assetsMap,
            ...(await serverSideTranslations(locale, ["common"])),
            revalidate: 10,
        },
    };
}

export default React.memo(Home);
