import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { formatAmount } from "@/utils/format";
import { useEditOrder } from "@/hooks/trade/useEditOrder";
import { setActiveOperationCategory } from "@/contexts/TradeOrder/actions";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { TradeChartGlobalAPIContext } from "@/contexts/ChartAPI";
import { useDevice } from "@/redux/selectors/deviceSelector";

import styles from "./ProfitLossInfo.module.scss";

interface IProps {
    isHighlightTradeButtons?: boolean;
}

export const ProfitLossInfo: React.FC<IProps> = ({ isHighlightTradeButtons }) => {
    const { t } = useTranslation("common");
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const tradeOrderContext = useContext(TradeOrderStateContext);
    const { isTablet } = useDevice();

    const { activeSymbolId } = useTradeInfo();
    const { ask, bid } = useQuoteData(activeSymbolId);
    const { activeOperationCategory } = useContext(TradeOrderStateContext);

    const { addTakeProfitLine, removeTakeProfitLine, addStopLossLine, removeStopLossLine } = useContext(
        TradeChartGlobalAPIContext
    );

    const {
        accountCurrency,
        isStopLoss,
        stopLossPrice,
        stopLossPriceDisplayValue,
        stopLossPriceFormInputValue,
        stopLossValue,

        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        takeProfitPriceFormInputValue,
        takeProfitValue,
    } = useEditOrder({
        order: null,
        tradeOrderDispatch,
        tradeOrder: tradeOrderContext,
    });

    useEffect(() => {
        if (isStopLoss && stopLossPrice !== null) {
            addStopLossLine(stopLossPriceDisplayValue);
        } else {
            removeStopLossLine();
        }
    }, [isStopLoss, stopLossPrice, stopLossPriceDisplayValue, addStopLossLine, removeStopLossLine]);

    useEffect(() => {
        if (isTakeProfit && takeProfitPrice !== null) {
            addTakeProfitLine(takeProfitPriceDisplayValue);
        } else {
            removeTakeProfitLine();
        }
    }, [
        isTakeProfit,
        takeProfitPrice,
        takeProfitPriceDisplayValue,
        addTakeProfitLine,
        removeTakeProfitLine,
    ]);

    const handleCategoryChange = (operationType?: TRADE_OPERATION_TYPE) => {
        return () => {
            if (activeOperationCategory) {
                tradeOrderDispatch(
                    setActiveOperationCategory(
                        activeOperationCategory === TRADE_OPERATION_TYPE.SELL
                            ? TRADE_OPERATION_TYPE.BUY
                            : TRADE_OPERATION_TYPE.SELL,
                        ask,
                        bid
                    )
                );
            } else {
                tradeOrderDispatch(
                    setActiveOperationCategory(
                        operationType === TRADE_OPERATION_TYPE.SELL
                            ? TRADE_OPERATION_TYPE.SELL
                            : TRADE_OPERATION_TYPE.BUY,
                        ask,
                        bid
                    )
                );
            }
        };
    };

    return (
        <div className={clsx(styles.pl_container, {
            [styles.tablet]: isTablet,
        })}>
            <div className={clsx(styles.info_block, {
                [styles.tablet]: isTablet,
            })}>
                <span className={styles.title}>{t("profit")}:</span>
                <span className={clsx(styles.profit_value)}>
                    {isTakeProfit && stopLossPriceFormInputValue !== null
                        ? formatAmount(takeProfitValue, accountCurrency)
                        : "-"}
                </span>
            </div>
            <div className={styles.directionsPanel} onClick={activeOperationCategory ? handleCategoryChange() : () => {}}>
                <div
                    className={clsx(styles.directionDown, {
                        [styles.active_sell]: activeOperationCategory === TRADE_OPERATION_TYPE.SELL,
                        [styles.highlightSell]: isHighlightTradeButtons,
                    })}
                    onClick={!activeOperationCategory ? handleCategoryChange(TRADE_OPERATION_TYPE.SELL) : () => {}}
                />
                <div
                    className={clsx(styles.directionUp, {
                        [styles.active_buy]: activeOperationCategory === TRADE_OPERATION_TYPE.BUY,
                        [styles.highlightBuy]: isHighlightTradeButtons,
                    })}
                    onClick={!activeOperationCategory ? handleCategoryChange(TRADE_OPERATION_TYPE.BUY) : () => {}}
                />
            </div>
            <div className={clsx(styles.info_block, styles.loss_block, {
                [styles.tablet]: isTablet,
            })}>
                <span className={styles.title}>{t("loss")}:</span>
                <span className={clsx(styles.loss_value)}>
                    {isStopLoss && takeProfitPriceFormInputValue !== null
                        ? formatAmount(stopLossValue, accountCurrency)
                        : "-"}
                </span>
            </div>
        </div>
    );
};
