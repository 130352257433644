import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "next-i18next";

import styles from "./TimePicker.module.scss";

interface IProps {
    onClose: () => void;
    onSubmit: (time: string) => void;
    initDate?: Date;
    outerTime?: string | null;
    setOuterTime?: (time: string) => void;
    setFocused: (time: string) => void;
    focused: string;
    offsetActive: boolean;
    minDate?: Date;
}

enum ActionTypes {
    INC = "inc",
    DEC = "dec",
}

enum MeasureTypes {
    HOUR = "hours",
    MIN = "minutes",
}

const TIME_FORMAT = "HH:mm";

const TimePicker: React.FC<IProps> = ({
    outerTime = null,
    onSubmit,
    onClose,
    focused,
    offsetActive = true,
    initDate,
}) => {
    const { t } = useTranslation("common");
    const now = moment().format(TIME_FORMAT);
    const [time, onChangeTime] = useState<string>(now);
    const [timeEditable, onChangeTimeEditable] = useState<string>(":");

    const [hour, minute] = timeEditable.split(":");
    const isTimeExpired = moment(initDate).set({ hour: +hour, minute: +minute })
        .isSameOrBefore(moment());

    useEffect(() => {
        timeEditable === ":" && onChangeTimeEditable(outerTime);
    }, []);

    useEffect(() => {
        outerTime !== time && onChangeTime(outerTime);
    }, [outerTime]);

    const handleTimeChange = (type: ActionTypes, measure: MeasureTypes) => {

        if (!hour || !minute) {
            onChangeTimeEditable(`${hour || "00"}:${minute || "00"}`);
            return;
        }

        const newTime =
            type === ActionTypes.INC
                ? moment(timeEditable, TIME_FORMAT).add(1, measure).format(TIME_FORMAT)
                : moment(timeEditable, TIME_FORMAT).subtract(1, measure).format(TIME_FORMAT);

        onChangeTimeEditable(newTime);
    };

    const onEdit = (value: string, measure: MeasureTypes) => {
        if (
            value.length > 2 ||
            !/^[0-9]*$/.test(value) ||
            measure === MeasureTypes.HOUR && (+value > 23) ||
            measure === MeasureTypes.MIN && (+value > 59)
        ) {
            return;
        }

        const val =
            measure === MeasureTypes.HOUR
                ? `${value}:${timeEditable.split(":")[1]}`
                : `${timeEditable.split(":")[0]}:${value}`;

        onChangeTimeEditable(val);

        const formatted = moment(val, "HH:mm").format("HH:mm");
        const isValid = formatted !== "Invalid date";

        if (isValid) {
            onChangeTime(formatted);
        }
    };

    const handleSubmit = () => {
        if (isTimeExpired) {
            return;
        }
        const formatted = moment(timeEditable, "HH:mm").format("HH:mm");
        const isValid = formatted !== "Invalid date";

        if (isValid) {
            onSubmit(timeEditable);
        }
    };

    return (
        <div className={clsx(styles.timePicker, { [styles.offsetActive]: offsetActive })}>
            <div className={styles.time_wrp}>
                <div className={styles.time_control}>
                    <div
                        className={clsx(styles.btn, styles.up)}
                        onClick={() => handleTimeChange(ActionTypes.INC, MeasureTypes.HOUR)}></div>
                    <div className={styles.time_container}>
                        <input
                            autoFocus={focused === "h"}
                            className={styles.timeInput}
                            value={timeEditable ? timeEditable.split(":")[0] : ""}
                            onChange={e => {
                                e.preventDefault();
                                onEdit(e.target.value, MeasureTypes.HOUR);
                            }}
                        />
                    </div>
                    <div
                        className={clsx(styles.btn, styles.down)}
                        onClick={() => handleTimeChange(ActionTypes.DEC, MeasureTypes.HOUR)}></div>
                </div>
                <div className={styles.dots}></div>
                <div className={styles.time_control}>
                    <div
                        className={clsx(styles.btn, styles.up)}
                        onClick={() => handleTimeChange(ActionTypes.INC, MeasureTypes.MIN)}></div>
                    <div className={styles.time_container}>
                        <input
                            autoFocus={focused === "m"}
                            className={styles.timeInput}
                            value={timeEditable ? timeEditable.split(":")[1] : ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e.preventDefault();
                                onEdit(e.target.value, MeasureTypes.MIN);
                            }}
                        />
                    </div>
                    <div
                        className={clsx(styles.btn, styles.down)}
                        onClick={() => handleTimeChange(ActionTypes.DEC, MeasureTypes.MIN)}></div>
                </div>
            </div>
            <div className={styles.btns_container}>
                <div className={clsx(styles.action_btn, styles.cancel_btn)} onClick={onClose}>
                    {t("cancel")}
                </div>
                <div
                    className={clsx(styles.action_btn, styles.set_btn, {
                        [styles.disabled]: isTimeExpired,
                    })}
                    onClick={handleSubmit}
                >
                    {t("set")}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TimePicker);
