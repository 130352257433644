import React, { useContext } from "react";
import { ColorModeContext } from "pages/_app";
import Toggle from "../Toggle/Toggle";

import styles from "./ThemeSwitcher.module.scss";


export const ThemeSwitcher = (): JSX.Element => {
    const colorMode = useContext(ColorModeContext);
    const isLightMode = colorMode.mode === "light";
    const isDarkMode = !isLightMode;

    return (
        <div className={styles.container}>
            <img src={`images/footer/theme-light${isLightMode ? "-active" : ""}.svg`}
                alt="light theme switch" />
            <Toggle
                on={isDarkMode}
                onChange={colorMode.toggleColorMode}
                className={styles.customToggle}
            />
            <img src={`images/footer/theme-dark${isDarkMode ? "-active" : ""}.png`}
                alt="dark theme switch" />
        </div>
    );
};

export const useThemeSwitcher = (isForceAction?: boolean): () => void => {
    const colorMode = useContext(ColorModeContext);

    const switchColorTheme = () => {
        if (window.location.host?.includes("localhost") || isForceAction) {
            colorMode.toggleColorMode();
        }
    };

    return switchColorTheme;
};
