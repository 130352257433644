import React from "react";
import { Backdrop } from "@mui/material";

interface IProps {
    children: JSX.Element,
    onClose: () => void;
    themeMode?: "dark" | "light",
    backgroundColor?: string;
}

export const BackdropWrapper: React.FC<IProps> = ({
    children,
    onClose,
    themeMode,
    backgroundColor,
}) => {
    return (
        <Backdrop
            id="backdrop"
            sx={{
                background: backgroundColor || themeMode === "dark" ?
                    "rgba(0, 0, 0, 0.8)" :
                    "rgba(0, 0, 0, 0.5)",
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
            onClick={({ target }) => {
                // @ts-ignore
                if (target.id === "backdrop") {
                    onClose();
                }
            }}
        >
            {children}
        </Backdrop>
    );
};
