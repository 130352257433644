import React, { useContext, useRef } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { TradeChartTimeframe } from "@/hooks/chart";
import { useUI } from "@/redux/selectors/uiSelector";
import { AccountInfoButton, useAccountInfoButtonActions } from "../AccountInfo";
import { PendingOrdersButton } from "../PendingOrders";
import { AssetPageTabs } from "../../constants";
import { TradeOrderStateContext } from "@/contexts/TradeOrder";
import { getPanelTabs, handleScroll } from "./utils";
import { getIconPath } from "@/utils/helpers";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

import styles from "../../TradingAssetSection.module.scss";


interface IProps {
    activeTab: AssetPageTabs;
    onChangeTab: (tabId: AssetPageTabs) => void;
    timeFrameValue: TradeChartTimeframe;
    pendingOrderUtils: {
        togglePendingOrdersButton: () => void;
        toggleCalendar: () => void;
        isDateSet: boolean,
        closePendingOrder: () => void;
    }
}

const AssetPanel: React.FC<IProps> = ({
    timeFrameValue,
    activeTab,
    onChangeTab,
    pendingOrderUtils,
}) => {
    const { t } = useTranslation("common");
    const { locale } = useRouter();
    const { platform } = useDevice();
    const { palette } = useTheme();
    const panelRef = useRef<HTMLDivElement>(null);
    const { isMobilePendingOrdersOpen } = useUI();
    const { lotSize, isStopLoss, isTakeProfit } = useContext(TradeOrderStateContext);

    const isMobile = platform === DevicePlatform.Mobile;

    const {
        closePendingOrder,
        isDateSet,
        toggleCalendar,
        togglePendingOrdersButton
    } = pendingOrderUtils;

    const {
        isAccountInfoButtonExpanded,
        openAccountInfo,
        toggleAccountInfoButton
    } = useAccountInfoButtonActions();

    const handleChange = (tab: AssetPageTabs) => {
        onChangeTab(tab);

        if (tab !== activeTab) {
            handleScroll(tab, panelRef);
        }
    };


    const tabsParams = {
        timeFrameValue,
        lotSize,
    };

    return (
        <div className={styles.tabs_panel} ref={panelRef}>
            {getPanelTabs({ onChangeTab: handleChange, tabsParams, t }).map((tab, index) => {
                const { tabId, title, iconPath, actionHandler } = tab;

                switch (iconPath) {
                    case getIconPath({ name: "user" }):
                        return (
                            <AccountInfoButton
                                key={`${tabId}-${index}`}
                                isExpanded={isAccountInfoButtonExpanded}
                                toggleButton={() => {
                                    onChangeTab(activeTab);
                                    actionHandler();
                                    toggleAccountInfoButton();
                                }}
                                openDrawer={openAccountInfo}
                            />
                        );
                    case getIconPath({ name: "alarm" }):
                        return (
                            <PendingOrdersButton
                                key={`${tabId}-${index}`}
                                isExpandedButton={isMobilePendingOrdersOpen}
                                toggleButton={() => {
                                    if (isAccountInfoButtonExpanded) {
                                        toggleAccountInfoButton();
                                    }
                                    onChangeTab(activeTab);
                                    actionHandler();
                                    togglePendingOrdersButton();
                                }}
                                toggleCalendar={toggleCalendar}
                                isDateSet={isDateSet}
                            />
                        );
                    default:
                        return (
                            <div
                                key={`${tabId}-${index}`}
                                className={clsx(styles.tab, {
                                    [styles.active]: tabId === activeTab,
                                    [styles[`sl_tp_active_${palette.mode}`]]: tabId === AssetPageTabs.SET_LOSS_PROFIT &&
                                        (isStopLoss || isTakeProfit),
                                    [styles[`clock_icon_${palette.mode}`]]: title && iconPath,
                                    [styles.text_icon]: title && !iconPath,
                                    [styles.long_text_icon]: title && !iconPath && isMobile && ["ge", "ja", "cn", "kr"].includes(locale),
                                    [styles.bull_bear_icon]: iconPath.includes("bull-bear"),
                                })}
                                onClick={() => {
                                    if (isMobilePendingOrdersOpen) {
                                        closePendingOrder();
                                    }

                                    if (isAccountInfoButtonExpanded) {
                                        toggleAccountInfoButton(false);
                                    }

                                    if (activeTab) {
                                        onChangeTab(activeTab);
                                    }

                                    actionHandler();
                                }}
                            >
                                {iconPath && (
                                    <img
                                        src={iconPath}
                                        className={clsx(null, {
                                            [styles[`svg_icon_${palette.mode}`]]: !title
                                        })}
                                    />
                                )}
                                {title && <span>{title}</span>}
                            </div>
                        );
                }
            }
            )}
        </div>
    );
};

export default React.memo(AssetPanel);
