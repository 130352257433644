import React from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { OrderTableType } from "@/components/TradesTable";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import { useAnimation } from '@/hooks/common/useAnimation';
import { useUI } from '@/redux/selectors/uiSelector';

import styles from "./TradesListTabs.module.scss";

interface IProps {
    activeTab: OrderTableType;
    onChangeTab: (tab) => void;
}

const TradesListTabs = ({ onChangeTab, activeTab }: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { animationParams } = useUI();
    const { setAnimation } = useAnimation();
    const { platform } = useDevice();

    const handleChangeTab = tab => {
        return () => {
            if (animationParams.class) {
                setAnimation(null);
            }
            onChangeTab(tab);
        };
    };

    return (
        <div className={clsx(styles.tabContainer, {
            [styles.tablet]: platform === DevicePlatform.Tablet,
        })}>
            <div
                className={clsx(styles.tabBtn, {
                    [styles.active]: activeTab === OrderTableType.OPEN_TRADES,
                })}
                onClick={handleChangeTab(OrderTableType.OPEN_TRADES)}>
                {t("openTrades")}
            </div>
            <div
                className={clsx(styles.tabBtn, {
                    [styles.active]: activeTab === OrderTableType.PENDING_ORDERS,
                })}
                onClick={handleChangeTab(OrderTableType.PENDING_ORDERS)}>
                {t("pendingTrades")}
            </div>
            <div
                className={clsx(styles.tabBtn, {
                    [styles.active]: activeTab === OrderTableType.CLOSED,
                })}
                onClick={handleChangeTab(OrderTableType.CLOSED)}>
                {t("closedTrades")}
            </div>
        </div>
    );
};

export default React.memo(TradesListTabs);
