import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { TableRenderer } from "./components/TableRenderer";
import { Header } from "./components/Header";
import { InfoPopup } from "@/components/TradesTable/components/InfoPopup";
import { setOperationTimestamp } from "@/utils/helpers";
import { modifyOrderRequest } from "@/redux/actions/orders";

import styles from "./TradesTable.module.scss";

export enum OrderTableType {
    OPEN_TRADES = "open",
    PENDING_ORDERS = "pending",
    CLOSED = "closed",
}

interface IProps {
    type: OrderTableType;
}

export const TradesTable: React.FC<IProps> = ({ type }) => {
    const dispatch = useDispatch();
    const [editedOrder, setEditedOrder] = useState(null);
    const { isOrderModifyPopupOpen, isFooterExpandedFullScreen } = useUI();
    const introStep = useIntroStep();
    const handleOrderEditClick = useCallback(
        order => {
            setEditedOrder(order);
            setOperationTimestamp();
            dispatch(modifyOrderRequest(order));
        },
        [setEditedOrder, dispatch]
    );

    return (
        <>
            <div
                id="open_trades_container"
                className={clsx(styles.table_wrapper, {
                    [styles.overlayed]: introStep === 5,
                    [styles.fullScreen]: isFooterExpandedFullScreen,
                })}>
                <Header type={type} />
                <div className={styles.table_body_wrap}>
                    <TableRenderer type={type} handleOrderEditClick={handleOrderEditClick} />
                </div>
            </div>

            {isOrderModifyPopupOpen && <InfoPopup order={editedOrder} />}
        </>
    );
};
