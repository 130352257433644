import React from "react";
import styles from "./PaymentSystemsView.module.scss";


const PaymentSystemsView = (): JSX.Element => {
    return (
        <div className={styles.paymentSystems}>
            <div className={styles.visaIcon}/>
            <div className={styles.mastercardIcon}/>
            <div className={styles.bankIcon}/>
        </div>
    );
};

export default PaymentSystemsView;
