import React, { RefObject } from "react";
import clsx from "clsx";

import { OrderTableType } from "@/components/TradesTable";
import TradesListItem from "@/components/Home/TradesSectionMobile/components/TradesListItem";
import { useTradeColumnSorter } from "@/hooks/trade/useTradeColumnSorter";

import styles from "./TradesList.module.scss";
//import { useTradeOrder } from "@/contexts/TradeOrder";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";

interface IProps {
    tradesData: any[];
    type: OrderTableType;
    onEdit: (order: any) => void;
    onDelete: (order: any) => void;
    onClose: (order: any) => void;
}

// eslint-disable-next-line react/display-name
const TradesList = React.forwardRef(
    (
        { tradesData = [], type, onEdit, onDelete, onClose }: IProps,
        ref: RefObject<HTMLDivElement>
    ): JSX.Element => {
        const { sortedOrders } = useTradeColumnSorter(tradesData);
        // REQUEST_PLACED
        const { closingTickets } = useTradeOrders();

        return (
            <div
                className={clsx(styles.tradesList, {
                    [styles.isClosedTrades]: type === OrderTableType.CLOSED,
                    [styles.containerScrollActive]:
                        ref?.current?.scrollHeight > ref?.current?.clientHeight,
                })}
                ref={ref}>
                {sortedOrders?.map(item => {
                    return (
                        <TradesListItem
                            closingTickets={closingTickets}
                            key={item.ticket}
                            tradeData={item}
                            type={type}
                            onEdit={() => onEdit(item)}
                            onDelete={() => onDelete(item)}
                            onClose={() => onClose(item)}
                        />
                    );
                })}
            </div>
        );
    }
);

export default React.memo(TradesList);
