import React from "react";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";

import { Header } from "./components/Header";
import ContentHeader from "./components/ContentHeader";
import { PopupForm } from "./components/PopupForm";
import { ITradeOrderState } from "@/contexts/TradeOrder/interfaces";
import {
    TradeOrderDispatchContext,
    TradeOrderStateContext,
    useTradeOrder
} from "@/contexts/TradeOrder";
import { ITradeOrder } from "@/services/trade/order";
import { modifyOrderCancel } from "@/redux/actions/orders";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { setOperationTimestamp } from "@/utils/helpers";

import styles from "./InfoPopup.module.scss";

interface IProps {
    order: ITradeOrder;
}

export const InfoPopup = (props: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const { order } = props;
    const { symbolsInfoById } = useTradeInfo();
    const dispatch = useDispatch();
    const symbolId = order.symbol;

    const tradeOrderProps: Partial<ITradeOrderState> = {
        activeSymbolId: symbolId,
        activeSymbolPoint: symbolsInfoById[order.symbol]?.point,
        activeOperationCategory: order.type.toLowerCase().includes("buy") ? "Buy" : "Sell",
        lotSize: order.lots,
    };

    if (order.stopLoss) {
        tradeOrderProps.isStopLoss = true;
        tradeOrderProps.stopLossPrice = order.stopLoss;
        tradeOrderProps.stopLossPips = 50;
        tradeOrderProps.stopLossPriceCalcMode = "Price";
    }

    if (order.takeProfit) {
        tradeOrderProps.isTakeProfit = true;
        tradeOrderProps.takeProfitPrice = order.takeProfit;
        tradeOrderProps.takeProfitPips = 50;
        tradeOrderProps.takeProfitPriceCalcMode = "Price";
    }

    const [tradeOrder, tradeOrderDispatch] = useTradeOrder(tradeOrderProps);

    const handleClose = () => {
        setOperationTimestamp();
        dispatch(modifyOrderCancel());
    };

    return (
        <Dialog
            className={styles.dialogWrap}
            open={true}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div className={styles[`container_${theme.palette.mode}`]}>
                <Header handleClose={handleClose}>
                    {t("stopLossTakeProfit")} #{order.ticket}
                </Header>
                <div className={styles.content}>
                    <ContentHeader order={order} />
                    <TradeOrderDispatchContext.Provider value={tradeOrderDispatch}>
                        <TradeOrderStateContext.Provider value={tradeOrder}>
                            <PopupForm order={order} />
                        </TradeOrderStateContext.Provider>
                    </TradeOrderDispatchContext.Provider>
                </div>
            </div>
        </Dialog>
    );
};
